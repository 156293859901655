import React, { useEffect } from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'

const useStyles = createUseStyles(() => ({
	root: {
		height: '90%',
		position: 'relative',
		paddingBottom: 40,
		'& th': {
			textAlign: 'left',
		},
	},
	sumTable: {
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
	},
	formControl: {
		width: '100%',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		height: '10%',
		right: 25,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	drawerLine: {
		padding: 12,
		boxShadow: 'inset 0px -1px 0px #F0F0F0',
	},
}))

export default function Complete({
	createContainer = () => {},
	updateContainer = () => {},
	prev = () => {},
	modalData = {},
}) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const complete = async () => {
		createContainer()
	}
	const update = async () => {
		updateContainer()
	}

	const getNetworkProfilesName = (id) => {
		if (id && dataContext.state?.getNetworkProfiles) {
			let findItem = dataContext.state.getNetworkProfiles.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworksName = (id) => {
		if (id && dataContext.state?.getNetworks) {
			let findItem = dataContext.state.getNetworks.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	const network = () => {
		let innerData
		if (modalData?.networkDriverType == 'cni') {
			innerData = modalData?.networkInterfaces?.map((item) => {
				return (
					<li>
						{getNetworksName(item?.connectedNetworkId) +
							' + ' +
							getNetworkProfilesName(item?.connectedNetworkProfileId)}
					</li>
				)
			})
		} else if (modalData?.networkDriverType == 'node') {
			innerData = modalData?.portBindings?.map((item) => {
				return <li>{item?.nodePort + ':' + item?.containerPort + '/' + item?.protocol}</li>
			})
		} else {
			return null
		}
		return <ul style={{ listStyle: 'none', margin: 0, paddingLeft: 0 }}>{innerData}</ul>
	}

	return (
		<>
			<div className={classes.root}>
				<span style={{ fontWeight: 700 }}>Summary</span>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[Name] </span>
					{modalData?.name}
				</div>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[Memory] </span>
					{modalData?.memory} GB
				</div>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[CPU] </span>
					{modalData?.cpu} CPU
				</div>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[IMAGE] </span>
					{modalData?.image}
				</div>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[NETWORK] </span>
					<span>Driver Type: {modalData?.networkDriverType}</span>
					{network()}
				</div>
				<div className={classes.drawerLine}>
					<span style={{ fontWeight: 700 }}>[Storage] </span>
					{modalData.mountClaims?.map((item) => {
						return <span>{item.name + ' '}</span>
					})}
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				{modalData?.id ? (
					<Button
						type="primary"
						onClick={() => update()}
						style={{ marginLeft: 8 }}
						id="containerUpdateSubmit"
						small>
						Update
					</Button>
				) : (
					<Button
						type="primary"
						onClick={() => complete()}
						style={{ marginLeft: 8 }}
						id="containerCreateSubmit"
						small>
						Complete
					</Button>
				)}
			</div>
		</>
	)
}
