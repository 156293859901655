import React, { useEffect, useState } from 'react'
import { Button, Drawer, Select } from 'antd'
import { useGlobalContext, useDataContext } from '../Context'
import { createUseStyles } from 'react-jss'
import dataService from '../service/dataService'

const { Option, OptGroup } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [networkCredentials, setNetworkCredentials] = useState({
		connectedNetworkProfileId: '',
		connectedNetworkId: '',
		functionTags: [],
	})
	const [list, setList] = useState([])
	const handleClose = () => {
		globalContext.updateState('setUplinkPopup', null)
		setNetworkCredentials({
			connectedNetworkProfileId: '',
			connectedNetworkId: '',
			functionTags: [],
		})
		setList([])
	}

	const handleChange = (value, key) => {
		if (key === 'functionTags') {
			setNetworkCredentials((prevCredentials) => ({
				...prevCredentials,
				[key]: value,
			}))
		} else {
			setNetworkCredentials((prevCredentials) => ({
				...prevCredentials,
				[key]: value,
			}))
		}
	}

	useEffect(() => {
		setNetworkCredentials({
			connectedNetworkProfileId: '',
			connectedNetworkId: globalContext.state.setUplinkPopup?.id || '',
			functionTags: [],
		})
	}, [globalContext.state.setUplinkPopup])

	const functionTags = ['Storage', 'Hci', 'Computation', 'Overlay', 'Management', 'Migration']

	const handleClick = async () => {
		try {
			const currentList = [...list]

			const responseData = await Promise.all(
				currentList.map(async (li) => {
					delete li.status
					const updatedItem = {
						...li,
						connectedNetworkProfileId: networkCredentials.connectedNetworkProfileId,
						connectedNetworkId: networkCredentials.connectedNetworkId,
						functionTags: networkCredentials.functionTags,
					}
					return await dataService.updateNodeNetworkInterface(updatedItem)
				})
			)

			responseData.forEach((res) => {
				if (res.success) {
					setTimeout(() => {
						globalContext.updateState('nodeNetworkUpdated', true)
					}, 2000)
					globalContext.Snackbar(
						`Update [${res.data.name}] node network operation started successfully`,
						'success'
					)
				} else {
					globalContext.Snackbar(res.errorMessage, 'error')
				}
			})
		} catch (error) {
			globalContext.Snackbar(`Error sending data to nodes: ${error}`, 'error')
		} finally {
			handleClose()
		}
	}

	return (
		<div>
			<Drawer
				title={'Set Uplink: ' + globalContext.state.setUplinkPopup?.name}
				placement="right"
				onClose={handleClose}
				visible={Boolean(globalContext.state.setUplinkPopup)}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={() => handleClose()} small id="setUplinkCancel">
							Cancel
						</Button>
						<Button
							onClick={() => handleClick()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="setUplinkSet">
							Set
						</Button>
					</div>
				}>
				<div>
					<label>Network</label>
					<Select
						value={networkCredentials?.connectedNetworkId}
						onChange={(value) => handleChange(value, 'connectedNetworkId')}
						style={{ width: '100%' }}
						disabled={networkCredentials?.connectedNetworkId}>
						{dataContext?.state?.getNetworks?.map((item) => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</div>
				<div>
					<label>Network Profile</label>
					<Select
						value={networkCredentials?.connectedNetworkProfileId}
						onChange={(value) => handleChange(value, 'connectedNetworkProfileId')}
						style={{ width: '100%' }}>
						{dataContext?.state?.getNetworkProfiles?.map((item) => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
						<Option key={'none'} value={''}>
							None
						</Option>
					</Select>
				</div>
				<div>
					<label>Node Network Interfaces</label>
					<Select
						mode="multiple"
						value={list.map((item) => item.id)}
						onChange={(values) =>
							setList(
								values.map((id) =>
									dataContext?.state?.getNodes
										?.flatMap((node) => node.networkInterfaces)
										.find((item) => item.id === id)
								)
							)
						}
						className="fullW"
						id="networkInterface">
						{dataContext?.state?.getNodes?.map((node) => (
							<OptGroup key={node.id} label={node.name}>
								{node?.networkInterfaces?.map((item) => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</OptGroup>
						))}
					</Select>
				</div>
				<div>
					<label>Tags</label>
					<Select
						mode="multiple"
						value={networkCredentials?.functionTags}
						onChange={(values) => handleChange(values, 'functionTags')}
						placeholder="Function Tags"
						className="fullW"
						id="networkFunctionTags">
						{functionTags?.map((option) => (
							<Option key={option} value={option.toLowerCase()} label={option}>
								{option}
							</Option>
						))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
