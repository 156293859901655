import { useEffect, useMemo, useReducer, useRef } from 'react'

function withLogger(dispatch) {
	return function (action) {
		// console.groupCollapsed('Action Type:', action.type)
		return dispatch(action)
	}
}
const useReducerWithLogger = (...args) => {
	let prevState = useRef(args.initialState)

	//    const [input, dispatch] = useReducer(
	//     (state, newState) => ({ ...state, ...newState }),
	//     {
	//       name: "",
	//       email: "",
	//       country: ""
	//     }
	//   );
	const [state, dispatch] = useReducer(...args)

	const dispatchWithLogger = useMemo(() => {
		return withLogger(dispatch)
	}, [dispatch])

	useEffect(() => {
		if (state !== args.initialState) {
			// console.log('Prev state: ', prevState.current)
			// console.log('Next state: ', state)
			// console.groupEnd()
		}
		prevState.current = state
	}, [state])

	return [state, dispatchWithLogger]
}

export default useReducerWithLogger
