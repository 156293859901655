import React, { useEffect, useState } from 'react'
import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext } from 'components'
import { DeleteOutlined } from '@ant-design/icons'

export default function NetworkContextMenu() {
	const globalContext = useGlobalContext()
	const [data, setdata] = useState(globalContext.state.contextMenuData)
	const dataContext = useDataContext()

	const DeletePopup = async ({ event, props }) => {
		globalContext.updateState('deletePopup', props)
	}

	useEffect(() => {
		setdata(globalContext.state.contextMenuData)
	}, [globalContext.state.contextMenuData])

	return (
		<>
			<Menu id={'vmTemplate'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					VM Template Actions
				</div>
				<hr />
				<Item onClick={DeletePopup} id="deleteVmTemplate">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete VM Template
				</Item>
			</Menu>
		</>
	)
}
