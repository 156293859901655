import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { useSideMenuContext, useGlobalContext, useAuthContext } from 'components'
//List
import SideMenuCluster from './SideMenuCluster'
import SideMenuStorage from './SideMenuStorage'
import SideMenuBackup from './SideMenuBackup'
import SideMenuNetwork from './SideMenuNetwork'
import SideMenuContainer from './SideMenuContainer'

import { Tabs, Typography, Tooltip } from 'antd'
const { TabPane } = Tabs
const { Text } = Typography
import { ClusterOutlined, DatabaseOutlined, SyncOutlined, AppstoreOutlined, GlobalOutlined } from '@ant-design/icons'
import BtslabsLogo from 'assets/img/btslabs-twoline.svg'

const useStyles = createUseStyles((theme) => ({
	rootContainer: {
		height: '100%',
	},
	root: {
		flexGrow: 1,
		height: 'calc(100% - 72px)',
		width: 'calc(100% - 1px)',
	},
	sideMenuHeader: {
		padding: '4px 16px',
		background: '#2B5889',
		boxShadow: 'inset -1px 0px 0px #F0F0F0;',
	},
	prodName: {
		display: 'inline-flex',
		lineHeight: '29px',
		color: '#fff',
		fontWeight: 400,
		borderLeft: '1px solid #D6D1E1',
		paddingLeft: 14,
	},
	tabList: {
		borderBottom: '1px solid #dee2e6',
		padding: '5px 10px 0 10px',
		justifyContent: 'space-around',
	},
	tab: {
		minWidth: 50,
	},
	icon: {
		height: 26,
		opacity: 1,
		margin: '0!important',
	},
	tabPanel: {
		padding: 10,
		fontSize: 14,
	},
}))

export default function SideMenu(props) {
	const classes = useStyles()
	let navigate = useNavigate()
	const sideMenuContext = useSideMenuContext()
	const globalContext = useGlobalContext()
	const authContext = useAuthContext()
	const handleChange = (event, newValue) => {
		sideMenuContext.updateState('value', newValue)
	}

	const getSideMenu = (userData) => {
		switch (userData?.userRole || '') {
			case 'clusterAdmin':
				return (
					<Tabs activeKey={sideMenuContext.state.value} centered>
						<TabPane
							tab={
								<Tooltip title="Virtual Machines" placement="bottomLeft" arrowPointAtCenter={true}>
									<ClusterOutlined
										onClick={() => handleChange('cluster')}
										className={classes.icon}
										id="clusterTabBtn"
									/>
								</Tooltip>
							}
							key="cluster">
							<SideMenuCluster className={classes.tabPanel} id="clusterTab" />
						</TabPane>
						<TabPane
							tab={
								<Tooltip title="Storage Pools" placement="bottom">
									<DatabaseOutlined
										onClick={() => handleChange('storage')}
										className={classes.icon}
										id="storagePoolsTabBtn"
									/>
								</Tooltip>
							}
							key="storage">
							<SideMenuStorage className={classes.tabPanel} id="storagePoolsTab" />
						</TabPane>
						<TabPane
							tab={
								<Tooltip title="Networks" placement="bottom">
									<GlobalOutlined
										onClick={() => handleChange('network')}
										className={classes.icon}
										id="networksTabBtn"
									/>
								</Tooltip>
							}
							key="network">
							<SideMenuNetwork className={classes.tabPanel} id="networksTab" />
						</TabPane>
						<TabPane
							tab={
								<Tooltip title="Backup Profiles" placement="bottom">
									<SyncOutlined
										onClick={() => handleChange('backupProfile')}
										className={classes.icon}
										id="backupProfilesTabBtn"
									/>
								</Tooltip>
							}
							key="backupProfile">
							<SideMenuBackup className={classes.tabPanel} id="backupProfilesTab" />
						</TabPane>
						<TabPane
							tab={
								<Tooltip title="Containers" placement="bottom">
									<AppstoreOutlined
										onClick={() => handleChange('container')}
										className={classes.icon}
										id="containerTabBtn"
									/>
								</Tooltip>
							}
							key="container"
							id="sidebarHolder">
							<SideMenuContainer className={classes.tabPanel} id="containerTab" />
						</TabPane>
					</Tabs>
				)
				break
			case 'tenantAdmin':
				return <SideMenuCluster className={classes.tabPanel} id="clusterTab" />
				break
			default:
				return null
				break
		}
	}

	return (
		<div className="sidebarContainer">
			<div className={classes.sideMenuHeader}>
				<img
					src={BtslabsLogo}
					style={{ maxHeight: 64, width: 64, cursor: 'pointer' }}
					onClick={() => {
						navigate('/')
					}}
				/>{' '}
				<Text className={classes.prodName}>Sybelle SDDC Platform</Text>
			</div>
			<div className={classes.root}>{getSideMenu(authContext?.state?.userInfo)}</div>
		</div>
	)
}
