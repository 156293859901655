// import React, { useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
// import { command, useDataContext, useGlobalContext } from '../../components'

// import InputLabel from '@material-ui/core/InputLabel'
// import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from '@material-ui/core/FormControl'
// import Select from '@material-ui/core/Select'
// import ButtonGroup from '@material-ui/core/ButtonGroup'

import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

import dataService from 'service/dataService'
const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))
export default function Name({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({
		primaryStoragePoolId: modalData.primaryStoragePoolId || '',
		secondaryStoragePoolId: modalData.secondaryStoragePoolId || '',
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp.toString() })
		// if(key == "primaryStoragePoolId" && modalData?.createOrUpdate != "update"){
		// 	setdata({ ...data, [key]: valueTemp.toString(), secondaryStoragePoolId: valueTemp.toString() })
		// } else {
		//
		// 	setdata({ ...data, [key]: valueTemp.toString() })
		// }
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	const fetch = async () => {
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.absolutePath.startsWith('/srv/qemu/local'))
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}
	useEffect(() => {
		fetch()
	}, [])
	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Primary Storage Pool
				</label>
				<Select
					value={data?.primaryStoragePoolId}
					onChange={handleChange('primaryStoragePoolId')}
					className="fullW"
					id="primaryStoragePool">
					{storageList?.map((item) => (
						<Option key={item.id} value={item.id}>
							<span style={{ fontWeight: 'bold' }}>{item.name}</span> - {item.vmStoragePoolStat.allocation} /{' '}
							{item.vmStoragePoolStat.capacity} ({item.vmStoragePoolStat.percentage}%)
						</Option>
					))}
				</Select>
			</div>

			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Secondary Storage Pool
				</label>
				<Select
					value={data?.secondaryStoragePoolId}
					onChange={handleChange('secondaryStoragePoolId')}
					className="fullW"
					disabled={!data?.primaryStoragePoolId}
					id="secondaryStoragePool">
					{storageList?.map((item) => (
						<Option key={item.id} value={item.id} disabled={item.id === data.primaryStoragePoolId}>
							<span style={{ fontWeight: 'bold' }}>{item.name}</span> - {item.vmStoragePoolStat.allocation} /{' '}
							{item.vmStoragePoolStat.capacity} ({item.vmStoragePoolStat.percentage}%)
						</Option>
					))}
					<Option key="None" value="">
						None
					</Option>
				</Select>
			</div>

			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data.primaryStoragePoolId === ''}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
