import React, { useState } from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button, Input, InputNumber, Switch, Typography } from 'antd'

const { Title } = Typography

const useStyles = createUseStyles((theme) => ({
	ipRow: {
		display: 'flex',
		width: '100%',
		padding: '4px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	ip: {
		width: '35%',
		minWidth: 100,
		paddingLeft: 12,
		paddingRight: 12,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	act: {
		width: '25%',
		minWidth: 120,
		paddingLeft: 12,
		paddingRight: 12,
		'& button': {
			width: '100%',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
}))

export default function Host({
	createPublicNetwork = () => {},
	updatePublicNetwork = () => {},
	prev = () => {},
	modalData = {},
	modalDataChange = () => {},
}) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const [data, setdata] = useState(modalData?.addressPools?.[0])

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
		modalDataChange({ addressPools: [{ ...data, [key]: valueTemp }] })
	}

	const handleChangeIp = (e, key, index, incStr) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = { ...data }
		newData[incStr][index][key] = valueTemp
		setdata(newData)
		modalDataChange({ addressPools: [newData] })
	}

	const add = (incStr) => {
		let newData = { ...data }
		if (!newData[incStr]) {
			newData[incStr] = []
		}
		newData[incStr].push({
			startAddress: '',
			endAddress: '',
		})
		setdata(newData)
		modalDataChange({ addressPools: [newData] })
	}

	const del = (index, incStr) => {
		let newData = [...data[incStr]]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata({ ...data, [incStr]: newData })
		modalDataChange({ addressPools: [{ ...data, [incStr]: newData }] })
	}

	const complete = async () => {
		createPublicNetwork()
	}

	const update = async () => {
		updatePublicNetwork()
	}

	return (
		<div className={classes.root}>
			<div
				className="fullW"
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				<div>
					<span style={{ fontSize: 14, marginRight: 8 }}>Enable DHCP Server:</span>
					<Switch
						checked={data?.dhcpServer}
						onChange={handleChange('dhcpServer')}
						name="dhcpServer"
						color="primary"
					/>
				</div>
			</div>
			<div className="form-group-ant fullW">
				<label>DHCP Lease Time</label>
				<InputNumber
					placeholder="e.g. 86400"
					value={data?.leaseTime}
					onChange={handleChange('leaseTime')}
					id="publicNetworkLeaseTime"
					className="fullW"
					disabled={!data?.dhcpServer}
				/>
			</div>
			<div className="form-group-ant">
				<label>Name Servers</label>
				<Input
					placeholder="e.g. 1.1.1.1,8.8.8.8"
					value={data?.nameServers}
					onChange={handleChange('nameServers')}
					disabled={!data?.dhcpServer}
				/>
				<small>Please seperate server IP addresses with a comma.</small>
			</div>

			<Title level={5}>DHCP Address Ranges</Title>
			<div className={classes.ipRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
				<span className={classes.hash}>#</span>
				<span className={classes.ip}>
					<span className="requiredHint">*</span>First Usable IP
				</span>
				<span className={classes.ip}>
					<span className="requiredHint">*</span>Last Usable IP
				</span>
				<span className={classes.act}>Action</span>
			</div>

			{data?.ipAddressRanges?.map((item, index) => (
				<div className={classes.ipRow}>
					<span className={classes.hash}>{index + 1}</span>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.startAddress}
							onChange={(e) => handleChangeIp(e, 'startAddress', index, 'ipAddressRanges')}
							disabled={!data?.dhcpServer}
						/>
					</div>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.endAddress}
							onChange={(e) => handleChangeIp(e, 'endAddress', index, 'ipAddressRanges')}
							disabled={!data?.dhcpServer}
						/>
					</div>
					<div className={classes.act}>
						<span
							onClick={() => del(index, 'ipAddressRanges')}
							style={{ color: '#0f427a', cursor: 'pointer' }}
							className="ipRangeDelete"
							disabled={!data?.dhcpServer}>
							Delete
						</span>
					</div>
				</div>
			))}
			<Button
				block
				onClick={() => add('ipAddressRanges')}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="vmStorageAdd"
				disabled={!data?.dhcpServer}>
				+ Add Row
			</Button>
			<small>
				Please manually input IP ranges to be leased by DHCP Server in the previously given {data?.ipNetCIDR}{' '}
				subnet.
			</small>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				{modalData?.id ? (
					<Button
						onClick={() => update()}
						type="primary"
						style={{ marginLeft: 8 }}
						small
						id="publicNetworkCreateUpdate">
						Update
					</Button>
				) : (
					<Button
						onClick={() => complete()}
						type="primary"
						style={{ marginLeft: 8, marginRight: 16 }}
						small
						id="publicNetworkCreateComplete">
						Create
					</Button>
				)}
			</div>
		</div>
	)
}
