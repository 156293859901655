import React from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		'& th': {
			textAlign: 'left',
		},
	},
	sumTable: {
		borderTop: '1px solid #dee2e6',
		width: '90%',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
}))

export default function Host({
	createBackupProfile = () => {},
	updateBackupProfile = () => {},
	prev = () => {},
	modalData = {},
}) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const complete = async () => {
		createBackupProfile()
	}

	const update = async () => {
		updateBackupProfile()
	}

	const getVmName = (id) => {
		if (id && dataContext.state?.getVms) {
			let findItem = dataContext.state.getVms.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	return (
		<div className={classes.root}>
			<span style={{ fontWeight: 700 }}>Summary</span>
			<table className={classes.sumTable}>
				<tbody>
					<tr>
						<th>Name:</th>
						<td>{modalData.policyName}</td>
					</tr>
					<tr>
						<th>Description:</th>
						<td>{modalData.description}</td>
					</tr>

					<tr>
						<th>Storage Unit:</th>
						<td>{modalData.storage}</td>
					</tr>

					<tr>
						<th>Virtual Machines:</th>
						<td>
							<div style={{ paddingLeft: 0 }}>
								{modalData?.objects?.map((item, index) => {
									return (
										<span>
											{getVmName(item)}
											{modalData?.objects?.length == index + 1 ? '' : ', '}
										</span>
									)
								})}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				{modalData?.id ? (
					<Button
						onClick={() => update()}
						type="primary"
						style={{ marginLeft: 8 }}
						small
						id="backupProfileCreateUpdate">
						Update
					</Button>
				) : (
					<Button
						onClick={() => complete()}
						type="primary"
						style={{ marginLeft: 8 }}
						small
						id="backupProfileCreateComplete">
						Complete
					</Button>
				)}
			</div>
		</div>
	)
}
