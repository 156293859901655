import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'

import dataService from 'service/dataService'

import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Name from './Name'
import Host from './Host'
import CpuMemory from './CpuMemory'
import Storage from './Storage'
import CdDvd from './CdDvd'
import Network from './Network'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 0,
		height: '100%',
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	actived: {
		cursor: 'pointer',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
}))

export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})
	const [backupCreds, setbackupCreds] = useState({})
	const [loaderShow, setloaderShow] = useState('data')
	const [isModalVisible, setIsModalVisible] = useState(false)

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('VMCreateAndUpdatePopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setbackupCreds({})
		setdata(null)
	}
	useEffect(() => {
		if (globalContext.state.VMCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.VMCreateAndUpdatePopup)
			setSelectedMenuMax(7)
			setSelectedMenu(0)
		} else if (globalContext.state.VMCreateAndUpdatePopup?.createType == 'fromRestorePoint') {
			setbackupCreds(globalContext.state.VMCreateAndUpdatePopup)
			setdata({ ...data, createType: 'fromRestorePoint', storage: [] })
		}
	}, [globalContext.state.VMCreateAndUpdatePopup])

	const createVM = async () => {
		setloaderShow('data')
		let sendData = { ...data }
		sendData.memory = data.memory.toString()
		sendData.maxMemory = data.maxMemory.toString()
		sendData.cpu = data.cpu.toString()
		sendData.maxCpu = data.maxCpu.toString()

		if (sendData.createType === 'fromRestorePoint') {
			delete sendData.createType

			let filesSelection = backupCreds?.backup?.files?.map((file) => ({
				path: backupCreds?.backup?.folder?.attributes?.path + backupCreds?.backup?.folder?.attributes?.name + file,
				backupTime: backupCreds?.backup?.folder?.attributes?.backupTime,
			}))

			let buSize =
				dataContext?.state?.getVmStoragePools?.find((e) => e.id == sendData.storagePoolId)?.poolType == 'sharedlv'
					? backupCreds?.backup?.folder?.attributes?.size
					: 0

			let recoveryObject = {
				recoveryRequest: {
					recoveryPoint: {
						client: backupCreds?.backup?.folder?.attributes?.clientName,
						policyType: 'STANDARD',
						startDate: '2023-04-26T05:00:00.000Z',
						endDate: '2090-04-26T05:01:01.000Z',
					},
					recoveryOptions: {
						destinationClient: dataContext?.state?.getNodes?.find((e) => e.id == sendData.nodeId).name,
						overwriteExistingFiles: true,
						restrictMountPoints: false,
						renameHardLinks: true,
						renameSoftLinks: true,
						accessControlAttributes: false,
						jobPriorityOverride: 90000,
					},
				},
				selection: filesSelection,
				profileID: backupCreds?.backup?.folder?.attributes?.policyName,
				storagePoolID: data.storagePoolId,
				overwriteExistingVm: false,
				totalBackupSize: buSize,
				createdVm: sendData,
			}
			let res = await dataService.createVmFromExternalBackupRp(recoveryObject)
			if (res.success) {
				globalContext.Snackbar(
					`Vm [${recoveryObject.createdVm.name}] create operation started successfully`,
					'success'
				)
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		} else {
			let res = await dataService.createVm(sendData)
			if (res.success) {
				globalContext.Snackbar(`Vm [${res.data.name}] create operation started successfully`, 'success')

				// setTimeout(() => {
				// 	history.push(`/cluster/${res.data.clusterId}/node/${res.data.nodeId}/vm/${res.data.id}`)
				// }, 3000)
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
		handleClose()
	}

	const updateVM = async () => {
		if (!data.devices) {
			data.devices = []
			let vmDevice = {
				deviceType: 'BOOT_DISK',
				vmId: data.id,
				isoPath: data.guestOs,
			}
			data.devices.push(vmDevice)
		}
		delete data.status
		delete data.state
		delete data.createOrUpdate
		delete data['@type']
		data.memory = data.memory.toString()
		data.maxMemory = data.maxMemory.toString()
		data.cpu = data.cpu.toString()
		data.maxCpu = data.maxCpu.toString()
		setloaderShow('data')
		let res = await dataService.updateVM(data)
		if (res.success) {
			globalContext.Snackbar(`Update [${res.data.name}] vm operation started successfully`, 'success')
			handleClose()
			// setTimeout(() => {
			// 	history.push(`/cluster/${data.clusterId}/node/${data.nodeId}/vm/${data.id}`)
			// }, 3000)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
	}

	let selectedMenuList = [
		{ element: <Name modalDataChange={handleChange} next={next} prev={prev} modalData={data} />, tabName: 'VM Name' },
		{
			element: <Host modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
			tabName: 'Node & Volume',
		},
		{
			element: <CpuMemory modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
			tabName: 'CPU & Memory',
		},
		globalContext.state.VMCreateAndUpdatePopup?.createType !== 'fromRestorePoint' && {
			element: <Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
			tabName: 'Storage',
		},
		{ element: <CdDvd modalDataChange={handleChange} next={next} prev={prev} modalData={data} />, tabName: 'CD/DVD' },
		{
			element: <Network modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
			tabName: 'Network',
		},
		{
			element: <Complete createVM={createVM} updateVM={updateVM} next={next} prev={prev} modalData={data} />,
			tabName: 'Complete',
		},
	].filter(Boolean)

	return (
		<div>
			<Modal
				title={
					globalContext.state.VMCreateAndUpdatePopup?.createOrUpdate == 'update'
						? 'Update Virtual Machine'
						: 'New Virtual Machine'
				}
				className="stepModal"
				width={1280}
				open={globalContext.state.VMCreateAndUpdatePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				destroyOnClose={true}
				onCancel={handleClose}>
				<Row>
					<Col span={6}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								{selectedMenuList.map((item, index) => (
									<li
										key={index} // Add a key prop
										className={`${classes.listItem} ${selectedMenuMax > index ? classes.actived : ''} ${
											selectedMenu === index ? classes.active : ''
										}`}
										onClick={() => (selectedMenuMax > index ? setSelectedMenu(index) : null)}>
										<span className="fw-600">{index + 1}</span>
										{item.tabName}
									</li>
								))}
							</ul>
						</div>
					</Col>
					<Col span={18} className={classes.content}>
						{selectedMenuList[selectedMenu].element}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
