import React, { useState, useEffect } from 'react'
import { command, useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { InputNumber, Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	form: {
		'& .MuiTextField-root': {
			width: 250,
		},
	},
	fw: {
		width: '100%',
	},
}))

export default function Host({
	modalDataChange = () => {},
	next = () => {},
	prev = () => {},
	modalData = {},
	originalData = {},
}) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const [data, setdata] = useState({
		cpu: modalData.cpu || 2,
		maxCpu: modalData.maxCpu || 2,
		memory: modalData.memory || 4,
		maxMemory: modalData.maxMemory || 4,
	})
	const [newCpuUsage, setNewCpuUsage] = useState(() => {
		if (modalData?.tenant?.tenantVolumeStats?.vCpus !== undefined) {
			return (
				modalData?.tenant?.tenantVolumeStats?.vCpus -
				parseInt(originalData.maxCpu || 0) +
				parseInt(data.maxCpu || 0)
			)
		} else {
			return 0
		}
	})

	const [newMemoryUsage, setNewMemoryUsage] = useState(() => {
		if (modalData?.tenant?.tenantVolumeStats?.memoryG !== undefined) {
			return (
				modalData?.tenant?.tenantVolumeStats?.memoryG -
				parseInt(originalData.maxMemory || 0) +
				parseInt(data.maxMemory || 0)
			)
		} else {
			return 0
		}
	})

	let tenantQuotaProfile = dataContext?.state?.quotaProfiles?.find(
		(profile) => profile.id === modalData?.tenant?.quotaProfileId
	)

	const maxCpuValue =
		tenantQuotaProfile?.vCpus - modalData?.tenant?.tenantVolumeStats?.vCpus + parseInt(originalData.maxCpu || 0)

	const maxMemoryValue =
		tenantQuotaProfile?.memoryG -
		modalData?.tenant?.tenantVolumeStats?.memoryG +
		parseInt(originalData.maxMemory || 0)

	//console.log(maxCpuValue + " = " + tenantQuotaProfile?.vCpus + " - " + modalData?.tenant?.tenantVolumeStats?.vCpus + " + " + parseInt(originalData.maxCpu || 0))
	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		// CPU and Memory Validation
		if (parseInt(valueTemp) < 1 || valueTemp === null) {
			valueTemp = '1'
		}

		switch (key) {
			case 'cpu':
				valueTemp =
					typeof data.maxCpu != undefined
						? parseInt(valueTemp) > parseInt(data.maxCpu)
							? data.maxCpu
							: valueTemp
						: valueTemp
				break
			case 'memory':
				valueTemp =
					typeof data.maxMemory != undefined
						? parseInt(valueTemp) > parseInt(data.maxMemory)
							? data.maxMemory
							: valueTemp
						: valueTemp
				break
			case 'maxCpu':
				valueTemp = parseInt(data.cpu) > parseInt(valueTemp) ? parseInt(data.cpu) : valueTemp

				setNewCpuUsage(
					(prev) =>
						modalData?.tenant?.tenantVolumeStats?.vCpus -
						(parseInt(originalData.maxCpu) || 0) +
						(parseInt(valueTemp) || 0)
				)
				break
			case 'maxMemory':
				valueTemp = parseInt(data.memory) > parseInt(valueTemp) ? parseInt(data.memory) : valueTemp

				setNewMemoryUsage(
					(prev) =>
						modalData?.tenant?.tenantVolumeStats?.memoryG -
						(parseInt(originalData.maxMemory) || 0) +
						(parseInt(valueTemp) || 0)
				)
				break
		}
		setdata({ ...data, [key]: valueTemp?.toString() })
		modalDataChange({ ...data, [key]: valueTemp?.toString() })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Max CPU (Usage: {`${newCpuUsage || 0}`} / Quota:{' '}
					{`${tenantQuotaProfile?.vCpus || 1}`})
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.maxCpu || 1}
					min={data?.cpu || 1}
					max={maxCpuValue}
					onChange={handleChange('maxCpu')}
					disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
					className={classes.fw}
					id="vmCpuMax"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>CPU
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.cpu || 1}
					min={1}
					max={data?.maxCpu}
					onChange={handleChange('cpu')}
					className={classes.fw}
					id="vmCpu"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Max Memory (Usage: {`${newMemoryUsage || 0}`} / Quota:{' '}
					{`${tenantQuotaProfile?.memoryG || 1}`})
				</label>

				<InputNumber
					placeholder="Enter here"
					value={data?.maxMemory || 1}
					min={data?.memory || 1}
					max={maxMemoryValue}
					onChange={handleChange('maxMemory')}
					disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
					className={classes.fw}
					id="vmMemoryMax"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Memory
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.memory || 1}
					min={1}
					max={data?.maxMemory}
					onChange={handleChange('memory')}
					className={classes.fw}
					id="vmMemory"
				/>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={command.checkValue(data).length > 0}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</div>
	)
}
