import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col, Steps } from 'antd'
const { Step } = Steps
import { CloseCircleOutlined } from '@ant-design/icons'

import Login from './Login'
import VmConfig from './VmConfig'
import VmSelections from './VmSelections'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 0,
		minHeight: 300,
		height: 'calc(100% - 62px)',
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
}))

export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)
	const [current, setCurrent] = useState(0)
	const [data, setdata] = useState({})
	const [loaderShow, setloaderShow] = useState('data')

	const myRef = useRef()

	const onChange = (value) => {
		setCurrent(value)
		setSelectedMenu(value)
		myRef.current?.filterVms()
	}
	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}

	const handleClose = () => {
		globalContext.updateState('vmConvertVmwarePopup', null)
		setCurrent(0)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata(null)
	}
	useEffect(() => {
		if (globalContext.state.vmConvertVmwarePopup) {
			setdata(globalContext.state.vmConvertVmwarePopup)
		}
	}, [globalContext.state.vmConvertVmwarePopup])

	const submitConversions = async (sendDt) => {
		const sendKeys = ['hostCredentials', 'nodeId', 'storageId', 'vmName', 'networkId', 'networkProfileId', 'sourceVm']
		sendDt.forEach((item) => {
			Object.keys(item).forEach((k) => {
				if (!sendKeys.includes(k)) {
					delete item[k]
				}
			})
		})
		let sendData = {
			vSphereCredentials: data.auth,
			virtualMachines: sendDt,
		}
		let res = await dataService.submitVmwareConversions(sendData)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
	}

	let selectedMenuList = [
		<Login modalDataChange={handleChange} next={next} prev={prev} modalData={data} stepChange={onChange} />,
		<VmSelections
			modalDataChange={handleChange}
			next={next}
			prev={prev}
			modalData={data}
			stepChange={onChange}
			ref={myRef}
		/>,
		<VmConfig
			modalDataChange={handleChange}
			next={next}
			prev={prev}
			modalData={data}
			stepChange={onChange}
			submitConversions={submitConversions}
			ref={myRef}
		/>,
	]

	return (
		<div>
			<Modal
				title="Convert VM from VMware vCenter"
				className="stepModal"
				width={1280}
				visible={globalContext.state.vmConvertVmwarePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={24}>
						<Steps style={{ padding: 16 }} size="small" type="navigation" current={current} onChange={onChange}>
							<Step title="vCenter Credentials" />
							<Step title="VMware VM Selection" disabled={!data?.vmwData} />
							<Step title="Sybelle VM Configuration" disabled={!data?.vmwData || current < 2} />
						</Steps>
					</Col>
					<Col span={24} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
