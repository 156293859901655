import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, InputNumber, Button, Select, Switch } from 'antd'
import { createUseStyles } from 'react-jss'
const { Option } = Select
const useStyles = createUseStyles({
	quotaProfilePopup: {
		'& .form-group-ant': {
			alignItems: 'center',
			marginBottom: 12,
		},
		'& label': {
			textAlign: 'right',
			paddingRight: 12,
		},
	},
})

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	const [data, setdata] = useState({ name: '' })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const onClose = () => {
		setVisible(false)
		globalContext.updateState('quotaProfilePopup', null)
		setdata({ name: '' })
	}

	const createQuotaProfile = async () => {
		let sendData = {
			...data,
			clusterId: dataContext?.state?.list?.[0]?.id,
		}
		delete sendData.status
		let res = await dataService.createQuotaProfile(sendData)
		if (res.success) {
			let qpRes = await dataService.getQuotaProfiles()
			if (qpRes.success) {
				dataContext.updateState('quotaProfiles', qpRes.data)
			}
			onClose()
			globalContext.Snackbar(`Create [${res.data.name}] quota profile operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateQuotaProfile = async () => {
		let sendData = { ...data }
		delete sendData.status
		let res = await dataService.updateQuotaProfile(sendData)
		if (res.success) {
			let qpRes = await dataService.getQuotaProfiles()
			if (qpRes.success) {
				dataContext.updateState('quotaProfiles', qpRes.data)
			}
			onClose()
			globalContext.Snackbar(`Update [${res.data.name}] quota profile operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		if (globalContext.state.quotaProfilePopup) {
			setdata(globalContext.state.quotaProfilePopup)
		}
	}, [globalContext.state.quotaProfilePopup])

	return (
		<Drawer
			title={
				globalContext?.state?.quotaProfilePopup?.createOrUpdate == 'update'
					? 'Edit Quota Profile'
					: 'New Quota Profile'
			}
			placement="right"
			onClose={onClose}
			visible={globalContext.state.quotaProfilePopup || false}
			open={globalContext.state.quotaProfilePopup}
			width={480}
			className={classes.quotaProfilePopup}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>

					{data?.id ? (
						<Button onClick={updateQuotaProfile} style={{ marginLeft: 8 }} type="primary" small>
							Update
						</Button>
					) : (
						<Button
							id="quotaProfileSubmit"
							onClick={createQuotaProfile}
							type="primary"
							style={{ marginLeft: 8 }}
							small>
							Create
						</Button>
					)}
				</div>
			}>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Name:</label>
				<Input value={data?.name} onChange={handleChange('name')} id="quotaName" className="ant-col ant-col-16" />
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">vCPUs:</label>
				<InputNumber
					value={data?.vCpus}
					onChange={handleChange('vCpus')}
					id="quotaVCpus"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Memory:</label>
				<InputNumber
					value={data?.memoryG}
					onChange={handleChange('memoryG')}
					id="quotaMemoryG"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Storage(GB):</label>
				<InputNumber
					value={data?.storageAreaG}
					onChange={handleChange('storageAreaG')}
					id="quotaStorageAreaG"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">VMs:</label>
				<InputNumber
					value={data?.vmCount}
					onChange={handleChange('vmCount')}
					id="quotaVmCount"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Containers:</label>
				<InputNumber
					value={data?.containerCount}
					onChange={handleChange('containerCount')}
					id="quotaContainerCount"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Executions:</label>
				<InputNumber
					value={data?.executionsAll}
					onChange={handleChange('executionsAll')}
					id="quotaExecutionsAll"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Public IPs:</label>
				<InputNumber
					value={data?.publicIps}
					onChange={handleChange('publicIps')}
					id="quotaPublicIps"
					className="ant-col ant-col-16"
				/>
			</div>
			<div className="ant-row form-group-ant">
				<label className="ant-col ant-col-8">Tenant Networks:</label>
				<InputNumber
					value={data?.tenantNetworks}
					onChange={handleChange('tenantNetworks')}
					id="quotaTenantNetworks"
					className="ant-col ant-col-16"
				/>
			</div>
			<div
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'space-between',
				}}>
				<div>
					<span style={{ fontSize: 14 }}>Underlay Network Access: </span>
					<Switch
						id="underlayNetworkAccess"
						checked={data?.underlayNetworkAccess}
						onChange={handleChange('underlayNetworkAccess')}
						name="underlayNetworkAccess"
						color="primary"
					/>
				</div>
			</div>
			{data?.underlayNetworkAccess && (
				<>
					<div className="ant-row form-group-ant">
						<label className="ant-col ant-col-8">Networks:</label>
						<Select
							mode="multiple"
							value={data?.networkIds}
							onChange={handleChange('networkIds')}
							placeholder="Select Networks"
							className="ant-col ant-col-16"
							id="quotaNetworkIds">
							{dataContext.state?.getNetworks?.map((option) => (
								<Option key={option.id} value={option.id} label={option.name}>
									{option.name}
								</Option>
							))}
						</Select>
					</div>
					<div className="ant-row form-group-ant">
						<label className="ant-col ant-col-8">Networks Profiles:</label>
						<Select
							mode="multiple"
							value={data?.networkProfileIds}
							onChange={handleChange('networkProfileIds')}
							placeholder="Select Networks Profiles"
							className="ant-col ant-col-16"
							id="quotaNetworkProfileIds">
							{dataContext.state?.getNetworkProfiles?.map((option) => (
								<Option key={option.id} value={option.id} label={option.name}>
									{option.name}
								</Option>
							))}
						</Select>
					</div>
				</>
			)}
		</Drawer>
	)
}
