import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Tree, Tooltip, Button, Switch, Empty, Select, Spin } from 'antd'
const { TreeNode } = Tree
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext, useAuthContext, useParams } from 'components'
import Icons from './Icons'

import {
	ClusterOutlined,
	CodeSandboxOutlined,
	ExclamationCircleOutlined,
	FolderOpenOutlined,
	CaretRightOutlined,
	PauseOutlined,
	PoweroffOutlined,
	BoxPlotOutlined,
	AppstoreAddOutlined,
} from '@ant-design/icons'
import { AuthContext } from 'Context/AuthContext'
import dataService from '../../service/dataService'

const useStyles = createUseStyles({
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '3px 0',
		height: 40,
	},
	TreeRightIcons: {
		display: 'flex',
		// justifyContent: 'space-around',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	TreeLeftIcons: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
	statusIcon: {
		width: 16,
		bottom: '-5px',
		position: 'absolute',
		right: 0,
		opacity: 0.75,
	},
	haWorkingIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		right: 0,
		fill: 'rgb(28, 176, 132)',
		stroke: 'rgb(28, 176, 132)',
		color: 'rgb(28, 176, 132)',
	},
	haCriticalIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		right: 0,
		fill: '#9f8728',
		stroke: '#9f8728',
		color: '#9f8728',
	},
	haBrokenIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		// position: 'absolute',
		right: 0,
		fill: 'rgb(194, 88, 88)',
		stroke: 'rgb(194, 88, 88)',
		color: 'rgb(194, 88, 88)',
	},
})

export default function SideMenuCluster() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const authContext = useAuthContext()
	let params = useParams()
	const classes = useStyles()
	const [expanded, setExpanded] = useState([])
	const [selected, setSelected] = useState([])

	const [expandedKeys, setExpandedKeys] = useState([])
	const [expandedKeysGroup, setexpandedKeysGroup] = useState([])
	const [vmDiff, setVmDiff] = useState([])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	function handleContextMenu(e, obj) {
		if (!obj.type) return console.log('Context menu does not exists.', obj)
		e.preventDefault()
		e.stopPropagation()
		globalContext.openContextMenu(obj.type, obj, e)
	}

	const handleGroupView = (key) => (e) => {
		let valueTemp = e?.target ? (key == 'groupView' ? e.target.checked : e.target.value) : e
		dataContext.updateState(key, valueTemp)
	}

	const handleSelect = (event, obj) => {
		if (obj.type) {
			let link = `/${obj.type}/${obj.id}`
			switch (obj.type) {
				case 'cluster':
					link = `/cluster/${obj.id}`
					break
				case 'vmGroup':
					link = `/`
					break
				case 'node':
					link = `/cluster/${obj.clusterId}/node/${obj.id}`
					break
				case 'vm':
					link = `/cluster/${obj.clusterId}/node/${obj.nodeId}/vm/${obj.id}`
					break
				case 'vmTemplate':
					link = `/vmTemplate/${obj.id}`
					break
				case 'tenant':
					link = `/tenant/${obj.id}`
					break
				default:
					console.error('You added a new parameter, check it!')
					break
			}
			navigate(link)
		}

		setSelected(obj.id)
	}

	const _getStateIcon = (state) => {
		switch (state) {
			case 'running':
				return <CaretRightOutlined className={classes.statusIcon} style={{ color: '#1cb084' }} />
				break

			case 'paused':
				return <PauseOutlined className={classes.statusIcon} style={{ color: '#c27620' }} />
				break

			// case 'shutdown':
			//  	return <PoweroffOutlined className={classes.statusIcon} style={{ color: '#c25858' }} />
			// break
			//
			// case 'shutoff':
			//  	return <PoweroffOutlined className={classes.statusIcon} style={{ color: '#c25858' }} />
			// break

			default:
				break
		}
	}

	const _getIcon = (item) => {
		switch (item.type) {
			case 'cluster':
				return <ClusterOutlined className={classes.icon} />
				break
			case 'peerCluster':
				return <ClusterOutlined className={classes.icon} />
				break
			case 'vmGroup':
				return <FolderOpenOutlined className={classes.icon} />
				break
			case 'node':
				return ''
				break
			case 'vm':
				return !item.isTransient ? (
					<div style={{ position: 'relative' }}>
						<CodeSandboxOutlined className={classes.icon} />
						{item.status === 'READY' ? _getStateIcon(item.state) : null}
						{item.state === 'start' ? 'start' : null}
					</div>
				) : (
					<div style={{ position: 'relative' }}>
						<BoxPlotOutlined className={classes.icon} />
						{item.status === 'READY' ? _getStateIcon(item.state) : null}
						{item.state === 'start' ? 'start' : null}
					</div>
				)
				break
			case 'vmTemplate':
				return <CodeSandboxOutlined className={classes.icon} />
				break
			case 'tenant':
				return <AppstoreAddOutlined className={classes.icon} />
				break
			default:
				return null
				break
		}
	}

	const _createListAnt = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (['node', 'tenant'].includes(el.type) ? el.child.virtualMachines?.length : el.child?.length)
				return (
					<TreeNode
						title={
							<div
								title={el?.name}
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
								</div>
								<div className={classes.TreeRightIcons}>
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
									<Icons el={el}></Icons>
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(['node', 'tenant'].includes(el.type) ? el.child.virtualMachines : el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								title={el?.name}
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
								</div>
								<div className={classes.TreeRightIcons}>
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
									<Icons el={el}></Icons>
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}

	const _createListAntPeer = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			return (
				<TreeNode
					title={
						<div
							className={classes.TreeItemLabelContainer}
							name={el?.clusterName}
							onContextMenu={(e) => handleContextMenu(e, el)}>
							<div className={classes.TreeLeftIcons}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						</div>
					}
					className={el?.type + '-sidebar-item'}
					key={el.id}
					nodeId={`${el?.type}${el?.id}`}
					dataRef={el}></TreeNode>
			)
		})
	}

	const _createGroupListAnt = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(el.child)}
					</TreeNode>
				)
		})
	}

	const onExpand = (expandedKeys) => {
		setExpandedKeys(expandedKeys)
	}
	const onExpandGroup = (expandedKeysGroup) => {
		setexpandedKeysGroup(expandedKeysGroup)
	}

	const renderTreeView = () => {
		if (authContext.state.userInfo.userRole == 'clusterAdmin') {
			switch (dataContext?.state?.groupView) {
				case 'group':
					return dataContext.state.getVmGroups?.length ? (
						<Tree
							defaultExpandAll={false}
							defaultExpandParent={false}
							expandedKeys={expandedKeysGroup}
							onExpand={onExpandGroup}
							className="tree-wrapper-mb2 vm-group-tab-content">
							{_createGroupListAnt(dataContext.state?.getVmGroups)}
							{_createListAnt(vmDiff)}
						</Tree>
					) : (
						<Empty imageStyle={{ height: 40 }} description={<span>No vm group.</span>} />
					)
					break
				case 'cluster':
					return dataContext.state.list?.length ? (
						<Tree
							expandedKeys={expandedKeys}
							onExpand={onExpand}
							className="tree-wrapper-mb1 cluster-tab-content">
							{_createListAnt(dataContext.state?.list)}
							{_createListAntPeer(dataContext.state?.peerClusters)}
						</Tree>
					) : (
						<Empty imageStyle={{ height: 40 }} description={<span>No cluster data.</span>} />
					)
					break
				case 'vmtemplate':
					return dataContext.state.getVmTemplates?.length ? (
						<Tree
							expandedKeys={expandedKeys}
							onExpand={onExpand}
							className="tree-wrapper-mb2 vm-template-tab-content">
							{_createGroupListAnt(dataContext.state?.getVmTemplates)}
						</Tree>
					) : (
						<Empty imageStyle={{ height: 40 }} description={<span>No vm template.</span>} />
					)
					break
				case 'tenant':
					return dataContext?.state?.tenants?.length > 0 && dataContext?.state?.tenants !== 'waiting' ? (
						<Tree expandedKeys={expandedKeys} onExpand={onExpand} className="tree-wrapper-mb2 tenant-tab-content">
							{_createListAnt(dataContext?.state?.tenants)}
						</Tree>
					) : (
						<Empty imageStyle={{ height: 40 }} description={<span>No tenant.</span>} />
					)
					break
				default:
					return null
					break
			}
		} else {
			let tenantIds = []
			let checkedKeys = []
			if (globalContext?.state?.params?.tenantID) {
				checkedKeys = [globalContext?.state?.params?.tenantID]
			}
			tenantIds = authContext?.state?.userInfo?.realmIds?.map((ri) => {
				let dt = ri.split(':')
				return dt?.[1]
			})
			if (dataContext.state.tenants == 'waiting') {
				return (
					<div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
						<Spin tip="Loading..." />
					</div>
				)
			} else {
				let userTenant = dataContext?.state?.tenants?.filter((t) => tenantIds?.includes(t.id)) || []
				if (userTenant && dataContext?.state?.groupView !== 'tenant') {
					dataContext.updateState('groupView', 'tenant')
				}
				return dataContext.state.tenants?.length ? (
					<Tree
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						selectedKeys={checkedKeys}
						className="tree-wrapper-mb2 tenant-tab-content">
						{_createListAnt(userTenant || dataContext.state.tenants)}
					</Tree>
				) : (
					<div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
						<Empty imageStyle={{ height: 40 }} description={<span>No tenant.</span>} />
					</div>
				)
			}
		}
	}
	const fetchVms = async () => {
		let resp = await dataService.getVms()
		if (resp.success) {
			dataContext.updateState('getVms', resp.data)
		} else {
			globalContext.Snackbar(resp.errorMessage, 'error')
			dataContext.updateState('getVms', [])
		}
	}

	useEffect(() => {
		let expArray = []
		if (dataContext?.state?.groupView === 'group') {
			fetchVms()
			const list = dataContext.state?.getVms || []
			const vmGroups = dataContext.state?.getVmGroups || []

			const allGroupVMs = vmGroups.reduce((acc, group) => {
				return acc.concat(group.child || [])
			}, [])

			const vmInListNotInGroup = list.filter((vm) => !allGroupVMs.some((groupVm) => vm.id === groupVm.id))

			setVmDiff([...vmInListNotInGroup])
		} else if (dataContext?.state?.groupView == 'cluster') {
			if (dataContext?.state?.list) {
				dataContext?.state.list.forEach((item) => {
					expArray.push(item.id)
					if (typeof item.child != 'undefined') {
						item.child.forEach((item2) => {
							expArray.push(item2.id)
						})
					}
				})
			}
		} else if (dataContext?.state?.groupView == 'tenant') {
			if (Array.isArray(dataContext?.state?.tenants)) {
				dataContext.state.tenants.forEach((item) => {
					expArray.push(item.id)
				})
			}
		}
		setExpandedKeys(expArray)
	}, [dataContext.state.list, dataContext.state?.getVmGroups, dataContext.state.groupView])

	return (
		<>
			{renderTreeView()}
			{authContext.state.userInfo.userRole == 'clusterAdmin' && dataContext.state.groupView == 'group' ? (
				<div
					style={{
						alignSelf: 'flex-end',
						marginLeft: 'auto',
						marginRight: 'auto',
						position: 'absolute',
						bottom: 40,
						left: 16,
						fontSize: 11,
						width: 275,
					}}>
					<Button
						onClick={() =>
							globalContext.updateState('vmGroupCreatePopup', {
								objects: [],
							})
						}
						id="vmGroupCreate"
						style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
						block>
						Create a VM Group
					</Button>
				</div>
			) : (
				dataContext.state.groupView == 'vmtemplate' && (
					<div
						style={{
							alignSelf: 'flex-end',
							marginLeft: 'auto',
							marginRight: 'auto',
							position: 'absolute',
							bottom: 40,
							left: 16,
							fontSize: 11,
							width: 275,
						}}>
						<Button
							onClick={() =>
								globalContext.updateState('vmTemplateCreatePopup', {
									objects: [],
								})
							}
							id="vmTemplateCreate"
							style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
							block>
							Create a VM Template
						</Button>
					</div>
				)
			)}
			{authContext.state.userInfo.userRole == 'clusterAdmin' && (
				<div
					style={{
						alignSelf: 'flex-end',
						marginLeft: 5,
						position: 'absolute',
						bottom: 8,
						left: 16,
						fontSize: 11,
					}}>
					<span style={{ fontSize: 14 }}>View: </span>
					{/* <Switch
					checked={dataContext?.state?.groupView}
					onChange={handleGroupView('groupView')}
					name="groupView"
					color="primary"
				/> */}
					<Select
						id="clusterViewSelect"
						value={dataContext?.state?.groupView}
						onChange={handleGroupView('groupView')}
						style={{ width: 235 }}>
						<Option value="cluster">Virtual Machines</Option>
						<Option value="group">VM Groups</Option>
						<Option value="vmtemplate">VM Templates</Option>
						<Option value="tenant">Tenant VMs</Option>
					</Select>
				</div>
			)}
		</>
	)
}
