import React, { useState } from 'react'
import { useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
	aps: {
		position: 'relative',

		'& .form-group-ant': {
			alignItems: 'center',
			marginBottom: 12,
		},
		'& label': {
			textAlign: 'right',
			paddingRight: 12,
		},
	},

	del: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}))
export default function Name({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState(modalData)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
		modalDataChange({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>Name</label>
				<Input placeholder="Enter here" value={modalData?.name} onChange={handleChange('name')} />
			</div>
			<div className="form-group-ant">
				<label>Address Block (CIDR)</label>
				<Input placeholder="e.g. 192.0.2.0/24" value={modalData?.ipNetCIDR} onChange={handleChange('ipNetCIDR')} />
			</div>
			<div className="form-group-ant">
				<label>Default Gateway</label>
				<Input
					placeholder="e.g. 192.0.2.1"
					value={modalData?.defaultGateway}
					onChange={handleChange('defaultGateway')}
				/>
			</div>

			<div className="form-group-ant">
				<label>VLAN ID</label>
				<Input
					placeholder="Enter a value between 2 and 4094"
					value={modalData?.vlanTag}
					onChange={handleChange('vlanTag')}
					id="publicNetworkVlanTag"
				/>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
