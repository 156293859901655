///SideMenu
import React, { createContext, useContext, useEffect } from 'react'
import useReducerWithLogger from './Logger'

export const SideMenuContext = createContext()
export const useSideMenuContext = () => useContext(SideMenuContext)
///gerekli ilk stateler buraya yazılacak
const initialState = { value: 'cluster', expanded: [] }

//reducer baglantısı
const reducer = (state, action) => {
	switch (action.type) {
		case 'SideMenuContext_UPDATE_STATE':
			let value = action.payload
			return { ...state, ...value }
		default:
			console.error(`${action.type} ->Action Not Found`)
			return state
	}
}

///Contex Provider
const SideMenuContextProvider = (props) => {
	const [state, dispatch] = useReducerWithLogger(reducer, initialState)

	const updateState = (key, value) => {
		dispatch({
			type: 'SideMenuContext_UPDATE_STATE',
			payload: { [key]: value },
		})
	}

	useEffect(() => {}, [])

	return (
		<SideMenuContext.Provider
			value={{
				updateState,
				state,
			}}>
			{props.children}
		</SideMenuContext.Provider>
	)
}

export default SideMenuContextProvider
