import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, InputNumber, Button, Row, Col, Select, Switch } from 'antd'
import dataService from 'service/dataService'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	root: {},
	footer: {
		position: 'absolute',
		bottom: 8,
		width: '100%',
		textAlign: 'right',
		right: 16,
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState(modalData)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'replicaCount') {
			valueTemp = valueTemp.toString()
		}
		setdata({ ...data, [key]: valueTemp })
		modalDataChange(data)
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<Row gutter={[8, 8]}>
				<Col span={12}>
					<label>
						<span className="requiredHint">*</span>Volume Name
					</label>
					<Input
						placeholder="Enter here"
						value={data?.name}
						onChange={handleChange('name')}
						id="informationVolumeName"
					/>
				</Col>
				<Col span={12}>
					<label className="fullW">
						<span className="requiredHint">*</span>Transport Type
					</label>
					<Select
						className="fullW"
						value={data?.transportType}
						onChange={handleChange('transportType')}
						id="transportType">
						<Option value={'tcp'}>TCP</Option>
						<Option value={'rdma'} disabled={true}>
							RDMA
						</Option>
					</Select>
				</Col>
				<Col span={12}>
					<label className="fullW">
						<span className="requiredHint">*</span>Replica Count
					</label>
					<InputNumber
						className="fullW"
						placeholder="Enter here"
						min={2}
						max={3}
						value={data?.replicaCount}
						onChange={handleChange('replicaCount')}
						disabled={data?.type == 'dispersed'}
						id="replicaCount"
					/>
				</Col>
				<Col span={12}>
					<label>
						<span className="requiredHint">*</span>Type
					</label>
					<Select
						className="fullW"
						value={data?.volumeType}
						onChange={handleChange('volumeType')}
						id="ınformationType">
						<Option value={'replicated'}>Replicated</Option>
						<Option value={'dispersed'}>Dispersed</Option>
					</Select>
				</Col>

				<Col span={12}>
					<label style={{ marginRight: 8 }}>
						<span className="requiredHint">*</span>Deduplication & Compression
					</label>
					<Switch
						checked={data?.dedupComp}
						onChange={handleChange('dedupComp')}
						name="dedupComp"
						color="primary"
					/>
				</Col>
			</Row>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="next">
					Next
				</Button>
			</div>
		</div>
	)
}
