import React, { useState, useEffect } from 'react'
import { command } from '../../components'
import { createUseStyles } from 'react-jss'
import { Row, Col, Input, Select, Button, Form, Typography, Spin, message, Tooltip, Result } from 'antd'
import {
	LoginOutlined,
	EyeInvisibleOutlined,
	EyeTwoTone,
	CodeSandboxOutlined,
	CloseCircleOutlined,
	ContainerOutlined,
	PlayCircleOutlined,
} from '@ant-design/icons'

import dataService from 'service/dataService'

const { Option } = Select
const { Title } = Typography

const useStyles = createUseStyles({
	root: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		paddingBottom: 40,
	},
	input: { width: '100%' },
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Name({ modalDataChange = () => {}, next = () => {}, modalData = {}, stepChange = () => {} }) {
	const classes = useStyles()
	const [data, setdata] = useState({})
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm()

	const getIcon = (vm) => {
		// treeIcon={true} was not used because 1) there may be multiple icons 2) icons has to be on the right

		let icons = [] // This will store our icons based on conditions
		if (vm?.mustBeStopped) {
			icons.push(
				<Tooltip placement="topRight" arrowPointAtCenter={true} title="VM must not be running">
					<PlayCircleOutlined style={{ color: 'red', marginRight: '4px' }} />
				</Tooltip>
			)
		}
		if (vm?.hasSnapshot) {
			icons.push(
				<Tooltip placement="topRight" arrowPointAtCenter={true} title="Snapshots must be deleted">
					<ContainerOutlined style={{ color: 'red', marginRight: '4px' }} />
				</Tooltip>
			)
		}
		if (!vm?.isEligible && !vm?.mustBeStopped && !vm?.hasSnapshot) {
			icons.push(
				<Tooltip placement="topRight" arrowPointAtCenter={true} title="vCenter Server can not be converted">
					<CloseCircleOutlined style={{ color: 'red', marginRight: '4px' }} />
				</Tooltip>
			)
		}

		return icons.length > 0 ? icons : null // Return null if no icons were added
	}

	const onFinish = async (values) => {
		message.loading('Action in progress..')
		setLoading(true)
		setdata(values)
		const checkList = command.checkValue(values)
		if (checkList.length > 0) {
			globalContext.Snackbar(
				`You have entered an invalid username or password. Please try again.
				(${checkList.toString()} error!)`,
				'error'
			)
			return
		}

		let response = await dataService.getVmwareTree(values)
		if (response.status == 200) {
			setLoading(false)
			message.destroy()
			if (response.data) {
				let vmwTreeArr = []
				let vmArr = []
				let arr = response?.data?.datacenter?.map((dc) => {
					let newDc = {
						title: dc.name,
						label: dc.name,
						key: dc.id,
						value: dc.id,
					}
					newDc['children'] = dc?.host?.map((host) => {
						let newHost = {
							title: (
								<div className="vm-select-tree-element-title">
									<span title={host.name}>{host.name}</span>
									<div>ESXi {host?.esxiVersion}</div>
								</div>
							),
							label: host.name,
							key: host.id,
							value: host.id,
							ip: host.hostIp,
						}
						newHost['children'] = host?.vm?.map((item) => {
							let newItem = {
								title: (
									<div className="vm-select-tree-element-title">
										<span title={item.name}>{item.name}</span>
										<div>{getIcon(item)}</div>
									</div>
								),
								label: item.name,
								key: item.id,
								value: item.id,
								disabled: !item.isEligible,
								datacenter: dc.name,
								sourceVm: {
									name: item.name,
									id: item.id,
									dc: {
										name: dc.name,
									},
								},
								url: host?.hostIp,
							}
							return newItem
						})
						vmArr = vmArr.concat(newHost['children'])
						return newHost
					})
					return newDc
				})
				vmwTreeArr = vmwTreeArr.concat(arr)

				modalDataChange({
					...modalData,
					auth: values,
					vmwData: response.data.datacenter,
					vmwTreeData: vmwTreeArr,
					vmArr: vmArr,
					selectedVms: [],
					detailedVms: [],
					configData: {},
					activeVm: {},
					filteredVms: [],
				})
				// message.success(response.data.datacenter)
				setTimeout(() => {
					stepChange(1)
				}, 300)
			} else {
				message.error(response.errorMessage)
			}
		} else if (response.status == 401) {
			setLoading(false)
			message.destroy()
			message.error(response.errorMessage)
		} else {
			setLoading(false)
			message.destroy()
			message.error(response.errorMessage)
			return
		}
	}

	useEffect(() => {
		form.setFieldsValue(modalData?.auth)
		setdata(modalData?.auth)
	}, [])

	return (
		<Row className={classes.root}>
			<Col
				span={12}
				style={{
					marginBottom: 8,
					width: '100%',
					position: 'relative',
				}}>
				{loading && (
					<div
						style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							background: '#fff',
							opacity: '0.45',
							justifyContent: 'center',
							alignItems: 'center',
							position: 'absolute',
							zIndex: 99,
							cursor: 'not-allowed',
						}}>
						<Spin />
					</div>
				)}
				{/* {modalData?.vmwTreeData && <div style={{ display: 'flex', width: '100%', height: '100%', background: '#fff', opacity: '0.45', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 99, cursor: 'not-allowed'}}>
					<Result
						status="success"
						title="Successfully logged in!"
						extra={[
							<Button
								type="primary"
								icon={<CloseCircleOutlined />}
								onClick={() => clearData()}
							/>
						]}
					/>
				</div>} */}
				<Form onFinish={onFinish} form={form} initialValues={data}>
					<Title level={5}>vCenter Credentials</Title>
					<Form.Item
						name="username"
						id="username"
						label="Username"
						labelCol={{ span: 24 }}
						style={{ marginBottom: 16 }}
						rules={[
							{
								required: true,
								message: 'This field cannot be blank.',
							},
						]}>
						<Input placeholder="example@domain.local" value={data?.username} />
					</Form.Item>
					<Form.Item
						name="password"
						id="password"
						label="Password"
						labelCol={{ span: 24 }}
						style={{ paddingBottom: 0 }}
						rules={[
							{
								required: true,
								message: 'This field cannot be blank.',
							},
						]}>
						<Input.Password
							type="password"
							placeholder="Password"
							value={data?.password}
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
						/>
					</Form.Item>
					<Form.Item
						name="url"
						id="url"
						label="IP Address or hostname"
						labelCol={{ span: 24 }}
						style={{ marginBottom: 16 }}
						rules={[
							{
								required: true,
								message: 'This field cannot be blank.',
							},
						]}>
						<Input
							placeholder="__.__.__.__ or example.com"
							value={data?.url}
							addonBefore="https://"
							addonAfter="/sdk"
						/>
					</Form.Item>
					<Form.Item>
						<Button icon={<LoginOutlined />} type="primary" htmlType="submit" id="loginSubmit" block>
							Login
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	)
}
