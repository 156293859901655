import React, { useEffect, useState } from 'react'
import { command, useAuthContext, useGlobalContext, useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Select, Button, Switch } from 'antd'
const { Option } = Select

const useStyles = createUseStyles(() => ({
	root: {
		height: '70vh',
		position: 'relative',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		position: 'relative',
		bottom: 20,
		width: '100%',
		textAlign: 'right',
		right: 4,
		alignItems: 'end',
		marginTop: 'auto',
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	dvdContainer: {
		overflowY: 'auto',
		height: '80%',
	},
	dvdRow: {
		display: 'flex',
		width: '95%',
		padding: '5px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	iso: {
		width: '70%',
		minWidth: 450,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 16,
		paddingRight: 16,
	},
	act: {
		width: '25%',
		minWidth: 120,
		paddingLeft: 12,
		paddingRight: 12,
		'& button': {
			width: '100%',
		},
	},
}))

export default function Storage({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const authContext = useAuthContext()
	const globalContext = useGlobalContext()
	const [accessibleISOlist, setAccessibleISOlist] = useState([])
	const [data, setdata] = useState(
		modalData.devices || [
			{
				deviceType: 'BOOT_DISK',
				vmId: '',
				isoPath: modalData.guestOs,
			},
		]
	)
	const keys = ['isoPath']
	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		newData[index][key] = valueTemp
		setdata(newData)
		modalDataChange({ devices: newData })
	}

	const add = () => {
		let newData = [...data]
		newData.push({
			deviceType: 'BOOT_DISK',
			vmId:
				globalContext.state.tenantVmPopup?.createOrUpdate == 'update' ? globalContext.state.tenantVmPopup?.id : '',
			isoPath: modalData.guestOs,
		})
		setdata(newData)
		modalDataChange({ devices: newData })
	}

	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata(newData)
		modalDataChange({ devices: newData })
	}

	const nextStep = () => {
		// let newData = data.filter((item) => item.isoPath)
		modalDataChange({ devices: data })
		next()
	}

	useEffect(() => {
		let tempIsoList = []

		const accessiblePools = dataContext.state.getVmStoragePools.filter((pool) =>
			pool.poolUnits.some((unit) => unit.nodeId === modalData?.nodeId)
		)

		const accessiblePoolIds = accessiblePools.map((pool) => pool.id)

		dataContext?.state?.getVmImages?.forEach((iso) => {
			if (accessiblePoolIds.includes(iso?.storagePoolId)) {
				tempIsoList = [...tempIsoList, iso]
			}
		})
		setAccessibleISOlist(tempIsoList)
	}, [modalData?.nodeId])

	return (
		<div className={classes.root}>
			<div className={classes.dvdContainer}>
				<div className={classes.dvdRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
					<span className={classes.hash}>#</span>
					<span className={classes.iso}>
						<span className="requiredHint">*</span>Choose ISO path
					</span>
					<span className={classes.act}>Action</span>
				</div>
				{data.map((item, index) => (
					<div className={classes.dvdRow}>
						<span className={classes.hash}>{index + 1}</span>
						<div className={classes.iso}>
							<Select
								className="vmIsoPath"
								value={item?.isoPath}
								onChange={(e) => handleChange(e, 'isoPath', index)}
								style={{ width: '100%' }}
								id="vmIsoPath">
								<Option value={''}>None</Option>
								{accessibleISOlist?.map((item) => (
									<Option value={item.absolutePath}>
										{authContext?.state?.userInfo?.userRole == 'clusterAdmin'
											? '[' + item.absolutePath.split('/').slice(3, 4) + '] '
											: ''}
										{item.name}{' '}
									</Option>
								))}
							</Select>
						</div>
						<div className={classes.act}>
							<span
								onClick={() => del(index)}
								style={{ color: '#0f427a', cursor: 'pointer' }}
								className="vmIsoPathDelete">
								Delete
							</span>
						</div>
					</div>
				))}
			</div>
			<Button
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="vmIsoPathAdd">
				+ Add Row
			</Button>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
