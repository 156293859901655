import React from 'react'
import {
	PlusOutlined,
	GlobalOutlined,
	UngroupOutlined,
	ProfileOutlined,
	SyncOutlined,
	DatabaseOutlined,
} from '@ant-design/icons'
import { Menu, Item } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useGlobalContext } from 'components'

export default function ClusterContextMenu() {
	const globalContext = useGlobalContext()

	return (
		<>
			<Menu id={'cluster'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Cluster Actions
				</div>
				<hr />
				<Item onClick={() => globalContext.updateState('vmConvertVmwarePopup', {})} id="vmConvertVmware">
					<SyncOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Convert VM from VMware vCenter
				</Item>
				<Item onClick={() => globalContext.updateState('ovfUploadPopup', {})} id="vmCreateFromOvfTemplate">
					<PlusOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Virtual Machine from OVF Template
				</Item>
				<Item onClick={() => globalContext.updateState('vmStoragePoolCreatePopup', {})} id="storagePoolCreate">
					<DatabaseOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Storage Pool
				</Item>
				<Item onClick={() => globalContext.updateState('networkCreateAndUpdatePopup', {})} id="networkCreate">
					<GlobalOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Network
				</Item>
				<Item
					onClick={() => globalContext.updateState('networkProfileCreateAndUpdatePopup', {})}
					id="networkProfileCreate">
					<ProfileOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Network Profile
				</Item>
				<Item
					onClick={() => globalContext.updateState('peerClusterCreateAndUpdatePopup', {})}
					id="peerClusterCreate">
					<UngroupOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Peer Cluster
				</Item>
			</Menu>
		</>
	)
}
