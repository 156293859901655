import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// console.log('LanguageDetector', LanguageDetector)

const config_i18n = (languages) => {
	let defaultLanguages = {
		en: {
			logout: 'logout',
			description: {
				part2: 'Second part',
			},
		},
		tr: {
			logout: 'Çıkış Yap',
			description: {
				part2: 'ikinci parca',
			},
		},
	}
	languages = languages || defaultLanguages || {}
	let languagesList = Object.keys(languages)
	let resources = languagesList.reduce((accumulator, item) => {
		let merged = { ...defaultLanguages[item], ...languages[item] }
		return { ...accumulator, [item]: { translation: merged } }
	}, {})
	i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			interpolation: { escapeValue: false },
			// fallbackLng: "tr",
			// lng: "en",

			resources: resources,
			// ns: ["default"],
			// defaultNS: "default",

			// debug: false,
			// keySeparator: false, // we use content as keys

			// interpolation: {
			// 	escapeValue: false,
			// },
		})
	i18n.languages = languagesList
}

// i18n.init({
// 	resources:{}// languages,
// });

export default config_i18n

// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     // we init with resources
//     resources: {
//       en: {
//         translations: {
//           "To get started, edit <1>src/App.js</1> and save to reload.":
//             "To get started, edit <1>src/App.js</1> and save to reload.",
//           "Welcome to React": "Welcome to React and react-i18next",
//           welcome: "Hello <br/> <strong>World</strong>"
//         }
//       },
//       de: {
//         translations: {
//           "To get started, edit <1>src/App.js</1> and save to reload.":
//             "Starte in dem du, <1>src/App.js</1> editierst und speicherst.",
//           "Welcome to React": "Willkommen bei React und react-i18next"
//         }
//       }
//     },
//     fallbackLng: "en",
//     debug: true,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//       escapeValue: false
//     }
//   });

// export default i18n;

//kullanımı

// import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import { useTranslation, Trans } from "react-i18next";

// export default function App() {
//   const { t, i18n } = useTranslation();

//   const changeLanguage = lng => {
//     i18n.changeLanguage(lng);
//   };

//   const index = 11;

//   return (
//     <div className="App">
//       <div className="App-header">
//         <h2>{t("Welcome to React")}</h2>
//         <button onClick={() => changeLanguage("de")}>de</button>
//         <button onClick={() => changeLanguage("en")}>en</button>
//       </div>
//       <div className="App-intro">
//         <Trans>
//           To get started, edit <code>src/App.js</code> and save to reload.
//         </Trans>
//         <Trans i18nKey="welcome">trans</Trans>
//         <Trans>
//           {index + 1} <a>xxx</a>
//         </Trans>
//       </div>
//       <div style={{ marginTop: 40 }}>
//         Learn more:&nbsp;
//         <a href="https://react.i18next.com">https://react.i18next.js</a>
//       </div>
//     </div>
//   );
// }
