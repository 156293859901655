import React from 'react'
import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function Error({ status, title }) {
	let navigate = useNavigate()

	return (
		<Result
			status={status}
			title={title}
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Button
					type="primary"
					onClick={() => {
						navigate('/')
					}}>
					Back Home
				</Button>
			}
		/>
	)
}
