import React, { useState, useEffect } from 'react'
import { command } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Select, Button, Switch } from 'antd'
const { Option } = Select

const useStyles = createUseStyles({
	root: {
		height: '90%',
		position: 'relative',
		paddingBottom: 40,
	},
	input: { width: '100%' },
	footer: {
		height: '10%',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 25,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Name({ modalDataChange = () => {}, next = () => {}, modalData = {} }) {
	const classes = useStyles()
	const [data, setdata] = useState({
		name: modalData?.name || '',
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<>
			<div className={classes.root}>
				<div
					style={{
						marginBottom: 8,
						width: '100%',
					}}>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Name
						</label>
						<Input
							placeholder="Enter here"
							value={data?.name}
							onChange={handleChange('name')}
							id="containerName"
						/>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.name?.length < 1}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</>
	)
}
