import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext, useParams } from '../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Input, InputNumber, Select, Button, Row, Col, Steps } from 'antd'
const { Option } = Select
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
const { Step } = Steps

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 200,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		paddingBottom: 0,
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: '0 16px 24px 16px',
		flex: 1,
		marginTop: 16,
		marginBottom: 16,
		width: '100%',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		height: 14,
		marginLeft: 4,
		color: 'rgb(194, 88, 88)',
	},
	sumTable: {
		width: '90%',
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			textAlign: 'left',
			borderBottom: '1px solid #dee2e6',
		},
	},
	subBtns: {
		display: 'inline-flex',
		justifyContent: 'end',
		width: '100%',
		padding: 16,
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const params = useParams()
	const [data, setdata] = useState({})
	const [current, setCurrent] = useState(0)
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const onChange = (value) => {
		setCurrent(value)
	}

	const onClose = () => {
		setVisible(false)
		setCurrent(0)
		globalContext.updateState('networkOverlayCreateAndUpdatePopup', null)
		setdata({})
	}

	const next = () => {
		setCurrent(current + 1)
	}

	const prev = () => {
		setCurrent(current - 1)
	}

	useEffect(() => {
		if (globalContext.state.networkOverlayCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.networkOverlayCreateAndUpdatePopup)
		} else {
			setdata({})
		}
	}, [globalContext.state.networkOverlayCreateAndUpdatePopup])

	const getNwOverlays = async () => {
		let res = await dataService.getNetworkOverlays()
		if (res.success) {
			dataContext.updateState('getNetworkOverlays', res.data)
		}
	}

	const createNwOverlay = async () => {
		let sendData = { ...data, clusterId: globalContext.state.params.clusterID, isEnabled: 'true' }
		let res = await dataService.createNwOverlay(sendData)
		if (res.success) {
			onClose()
			globalContext.Snackbar(`Create [${res.data.name}] overlay network operation started successfully`, 'success')
			getNwOverlays()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const updateNwOverlay = async () => {
		let sendData = { ...data, clusterId: globalContext.state.params.clusterID }
		let res = await dataService.updateNwOverlay(sendData)
		if (res.success) {
			onClose()
			globalContext.Snackbar(`Update [${res.data.name}] overlay network operation started successfully`, 'success')
			getNwOverlays()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Modal
			title={data?.id ? 'Update Overlay Network' : 'New Overlay Network'}
			className=""
			width={950}
			visible={globalContext.state.networkOverlayCreateAndUpdatePopup || false}
			closeIcon={<CloseCircleOutlined />}
			footer={null}
			onCancel={onClose}>
			<Row style={{ marginLeft: 16, marginRight: 16 }}>
				<Steps style={{ marginTop: 16 }} current={current} onChange={onChange}>
					<Step title="Cluster" />
					<Step title="Settings" />
					<Step title="Summary" />
				</Steps>
				<div className={classes.content}>
					{current == 0 && (
						<div className={classes.nodes}>
							<span style={{ width: '100%', fontWeight: 600 }}>Select cluster</span>
							<Select
								placeholder="Select cluster"
								style={{ width: '100%' }}
								value={data?.clusterId}
								onChange={handleChange('clusterId')}
								id="networkOverlaySelectGluster">
								{dataContext?.state?.list?.map((cl) => (
									<Option key={cl?.id} value={cl?.id}>
										{cl?.name}
									</Option>
								))}
							</Select>
						</div>
					)}
					{current == 1 && (
						<div className={classes.inputs}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<div className="form-group-ant" style={{ marginBottom: 0 }}>
										<label>
											<span className="requiredHint">*</span>Segment Name
										</label>
										<Input
											className="fullW"
											placeholder="Enter here"
											value={data?.segmentName}
											onChange={handleChange('segmentName')}
											id="networkOverlaySegmentName"
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="form-group-ant">
										<label>
											<span className="requiredHint">*</span>First Usable VNI
										</label>
										<InputNumber
											min={5000}
											max={data?.vxLanSegmentEnd ? data?.vxLanSegmentEnd : 65000}
											className="fullW"
											placeholder="Enter here"
											value={data?.vxLanSegmentStart}
											onChange={handleChange('vxLanSegmentStart')}
											id="networkOverlayVxLanSegmentStart"
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="form-group-ant">
										<label>
											<span className="requiredHint">*</span>Last Usable VNI
										</label>
										<InputNumber
											min={data?.vxLanSegmentStart ? data?.vxLanSegmentStart : 5000}
											max={65000}
											className="fullW"
											placeholder="Enter here"
											value={data?.vxLanSegmentEnd}
											onChange={handleChange('vxLanSegmentEnd')}
											id="networkOverlayVxLanSegmentEnd"
										/>
									</div>
								</Col>
							</Row>
						</div>
					)}
					{current == 2 && (
						<div className={classes.sum}>
							<span>
								<b>[Cluster]</b> {dataContext?.state?.list?.find((cl) => cl.id === data?.clusterId)?.name}
							</span>
							<br />
							<span>
								<b>[Segment Name]</b> {data?.segmentName}
							</span>
							<br />
							<span>
								<b>[VXLAN Segment Range]</b> {data?.vxLanSegmentStart} - {data?.vxLanSegmentEnd}
							</span>
						</div>
					)}
				</div>
				{/* <div className={classes.row}>
				</div> */}
			</Row>
			<Row>
				<div className={classes.subBtns}>
					{current > 0 && (
						<Button
							style={{
								margin: '0 8px',
							}}
							onClick={() => prev()}
							small>
							Previous
						</Button>
					)}
					{current < 2 && (
						<Button type="primary" onClick={() => next()} small className="next">
							Next
						</Button>
					)}
					{current == 2 &&
						(data?.id ? (
							<Button onClick={updateNwOverlay} type="primary" small id="networkOverlayUpdate">
								Update
							</Button>
						) : (
							<Button
								onClick={createNwOverlay}
								type="primary"
								disabled={
									!(data?.vxLanSegmentStart && data?.vxLanSegmentEnd && data?.clusterId && data?.segmentName)
								}
								small
								id="networkOverlayCreate">
								Create
							</Button>
						))}
				</div>
			</Row>
		</Modal>
	)
}
