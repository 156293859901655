import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({ name: '', nodeId: '' })

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('iscsiPopup', null)
	}

	const createIscsiDb = async () => {
		let sendData = { ...data, clusterId: dataContext.state?.list[0].id }
		let res = await dataService.createIscsiDb(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(`Create [${res.data.name}] iscsi db operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<div>
			<Drawer
				title={'New Iscsi Discovery'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.iscsiPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => createIscsiDb()}
							style={{ marginLeft: 8 }}
							type="primary"
							// disabled={`${command.checkValue(data).length ? 'true' : ''}`}
							small
							id="iscsiConfirm">
							Confirm
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Node
					</label>
					<Select
						value={data?.nodeId}
						onChange={handleChange('nodeId')}
						className="fullW"
						id="vmTemplateNodeSelect">
						{dataContext?.state?.list[0]?.child?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>IP
					</label>
					<Input placeholder="Enter here" value={data?.ip} onChange={handleChange('ip')} id="iscsiIP" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Port
					</label>
					<Input placeholder="e.g. 3260" value={data?.port} onChange={handleChange('port')} id="iscsiPort" />
				</div>
				<div className="form-group-ant">
					<label>Chap Username</label>
					<Input
						placeholder="Enter here"
						value={data?.chapUsername}
						onChange={handleChange('chapUsername')}
						id="iscsiChapUsername"
					/>
				</div>
				<div className="form-group-ant">
					<label>Chap Password</label>
					<Input.Password
						placeholder="Enter here"
						value={data?.chapPassword}
						onChange={handleChange('chapPassword')}
						id="iscsiChapPassword"
					/>
				</div>
			</Drawer>
		</div>
	)
}
