import React from 'react'
import { Button } from 'antd'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
	root: {},
	footer: {
		position: 'absolute',
		bottom: 8,
		width: '100%',
		textAlign: 'right',
		right: 16,
		paddingRight: 8,
	},
}))

export default function Host({ next = () => {} }) {
	const classes = useStyles()

	return (
		<div>
			<h4>Create Gluster Volume</h4>
			<div className={classes.footer}>
				<Button onClick={() => next()} style={{ marginLeft: 8 }} type="primary" small className="next">
					Next
				</Button>
			</div>
		</div>
	)
}
