import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select, message } from 'antd'

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ nodeId: '' })

	const handleChange = (key, value) => {
		setdata({ ...data, [key]: value })
	}

	const getGlusters = async () => {
		let res = await dataService.getGlusters()
		// message.loading({ content: 'Action in Progress', res, duration: 1 })
		if (res.success) {
			dataContext.updateState('getGlusters', res.data)
		} else {
			dataContext.updateState('getGlusters', [])
		}
	}

	useEffect(() => {
		if (globalContext?.state?.addNodeToGlusterPopup) {
			getGlusters()
		}
	}, [globalContext?.state?.addNodeToGlusterPopup])

	const onClose = () => {
		globalContext.updateState('addNodeToGlusterPopup', null)
		setdata({
			nodeId: '',
		})
	}

	const addNodeToGluster = async () => {
		let res = await dataService.addNodeToGluster(data.nodeId)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
			getGlusters()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title="Add Node to Gluster"
			placement="right"
			onClose={onClose}
			open={globalContext.state.addNodeToGlusterPopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					<Button
						id="nodeToGlusterSubmit"
						onClick={addNodeToGluster}
						disabled={`${command.checkValue(data).length ? 'true' : ''}`}
						type="primary"
						style={{ marginLeft: 8 }}
						small>
						Create
					</Button>
				</div>
			}>
			<label>
				<span className="requiredHint">*</span>Node
			</label>
			<div style={{ marginTop: '10px' }}>
				<Select
					style={{ width: '80%' }}
					defaultValue=""
					value={data.nodeId}
					onChange={(value) => handleChange('nodeId', value)}>
					{dataContext?.state?.getNodes?.length
						? !dataContext?.state?.getGlusters?.length || !dataContext?.state?.getGlusters[0]?.peers
							? dataContext?.state?.getNodes.map((newNode) => (
									<Option key={newNode?.id} value={newNode?.id}>
										{newNode?.name}
									</Option>
							  ))
							: dataContext?.state?.getNodes
									.filter(
										(node) =>
											!dataContext?.state?.getGlusters[0]?.peers.some((glus) => node?.id === glus?.nodeId)
									)
									.map((newNode) => (
										<Option key={newNode?.id} value={newNode?.id}>
											{newNode?.name}
										</Option>
									))
						: null}

					<Option id="none" value="">
						None
					</Option>
				</Select>
			</div>
		</Drawer>
	)
}
