import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select, message, InputNumber, Switch } from 'antd'

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'size') {
			valueTemp = valueTemp.toString()
		}
		setdata({ ...data, [key]: valueTemp })
	}

	// Used to eliminate the wait after opening storage pool selection
	const [poolList, setPoolList] = useState([])
	useEffect(() => {
		let newPoolList = dataContext?.state?.getVmStoragePools?.filter(
			(pool) =>
				pool.poolUnits.some((unit) => unit.nodeId === data?.nodeId) &&
				(pool?.poolType == 'local' || !pool?.isoStorage)
		)
		setPoolList(newPoolList)
	}, [data?.nodeId])

	const onClose = () => {
		globalContext.updateState('volumePathCreateAndUpdatePopup', null)
		setdata({})
	}

	const createVolumePath = async () => {
		let sendData = { ...data, clusterId: dataContext?.state?.list?.[0]?.id }
		delete sendData.createOrUpdate
		let res = await dataService.createVolumePath(sendData)
		if (res.success) {
			globalContext.Snackbar('Create volume path operation started successfully', 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}

		onClose()
	}

	useEffect(() => {
		if (globalContext?.state?.volumePathCreateAndUpdatePopup) {
			let modalData = globalContext?.state?.volumePathCreateAndUpdatePopup
			let defaultData = {
				...globalContext?.state?.volumePathCreateAndUpdatePopup,
				nodeId: modalData?.nodeId || '',
				name: modalData?.name || '',
				storagePoolId: modalData?.storagePoolId || '',
				readOnly: modalData?.readOnly || false,
				size: modalData?.size || '2',
			}
			setdata({ ...defaultData })
		}
	}, [globalContext?.state?.volumePathCreateAndUpdatePopup])

	return (
		<Drawer
			title={
				globalContext?.state?.volumePathCreateAndUpdatePopup?.createOrUpdate == 'update'
					? 'Update Volume Path'
					: 'Create Volume Path'
			}
			placement="right"
			onClose={onClose}
			width={450}
			open={globalContext?.state?.volumePathCreateAndUpdatePopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					<Button
						id="nodeToGlusterSubmit"
						onClick={createVolumePath}
						type="primary"
						style={{ marginLeft: 8 }}
						disabled={!(data?.name !== '' && data?.nodeId !== '' && data?.storagePoolId !== '')}
						small>
						Create
					</Button>
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Name
				</label>
				<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="volumeName" />
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Node
				</label>
				<Select
					className="fullW"
					disabled={data?.createOrUpdate == 'inlineCreate'}
					placeholder="Select Node"
					value={data?.nodeId}
					onChange={handleChange('nodeId')}
					id="volumeNode">
					{dataContext?.state?.getNodes?.map((node) => (
						<Option key={node.id}>{node.name}</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Storage Pool
				</label>
				<Select
					className="fullW"
					placeholder="Enter here"
					value={data?.storagePoolId}
					onChange={handleChange('storagePoolId')}
					id="volumeStorage">
					{poolList?.map((pool) => (
						<Option key={pool.id} value={pool.id}>
							<span style={{ fontWeight: 'bold' }}>{pool.name}</span> - {pool.vmStoragePoolStat.allocation} /{' '}
							{pool.vmStoragePoolStat.capacity} ({pool.vmStoragePoolStat.percentage}%)
						</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Size
				</label>
				<InputNumber
					className="fullW"
					placeholder="Enter here"
					value={parseInt(data?.size)}
					min={1}
					onChange={handleChange('size')}
					addonAfter="GB"
					id="volumeSize"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>
					<span>Read Only : </span>
				</label>
				<Switch value={data?.readOnly} onChange={handleChange('readOnly')} id="volumeReadOnly" />
			</div>
		</Drawer>
	)
}
