import React, { useEffect } from 'react'
import { contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext } from 'components'
import vm from 'assets/img/fill/vm.svg'

import { createUseStyles } from 'react-jss'
import { Tree, Tooltip, Button, Empty } from 'antd'
const { TreeNode } = Tree
import { SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const useStyles = createUseStyles({
	root: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '3px 0',
		height: 40,
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
})

export default function SideMenuCluster() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	function handleContextMenu(e, obj) {
		let typeBu = ''
		if (obj.type) {
			typeBu = obj.type
		} else {
			return console.log('Context menu does not exists.', obj)
		}
		e.preventDefault()
		e.stopPropagation()
		globalContext.openContextMenu(typeBu, obj, e)
	}
	const handleSelect = (event, obj) => {
		if (obj.type) {
			let link = `/${obj.type}/${obj.id}`
			switch (obj.type) {
				case 'backupProfile':
					link = `/cluster/${obj.clusterId}/backupProfile/${obj.id}`
					break
				case 'veritas':
					link = `/cluster/${obj.clusterId}/backupProfile/${obj.id}`
					break
				default:
					alert('Yeni parametre eklediniz lutfen kontrol ediniz')
					break
			}
			navigate(link)
		} else if (obj.policyType) {
			let link = `/cluster/${obj.clusterId}/backupProfile/${obj.id}`
			navigate(link)
		}
	}

	const _getIcon = (item) => {
		switch (item.type) {
			case 'backupProfile':
				return <SyncOutlined className={classes.icon} />
				break

			default:
				return null
				break
		}
	}
	const _createListAnt2 = (items) => {
		return items.map((el, index) => {
			if (!el) return null
			return (
				<TreeNode
					title={
						<div
							className={classes.TreeItemLabelContainer}
							name={el?.name}
							onContextMenu={(e) => handleContextMenu(e, el)}
							onClick={(e) => {
								handleSelect(e, el)
							}}>
							<SyncOutlined className={classes.icon} />
							<span>{el.policyName} (Veritas)</span>
						</div>
					}
					key={el.id}
					dataRef={el}
				/>
			)
		})
	}

	const _createList = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}

	return (
		<>
			{dataContext.state.getBackupProfiles?.length || dataContext.state.getExternalBackupProfiles?.length ? (
				<div>
					<Tree className="backu-profile-tab-content">
						{dataContext.state.getBackupProfiles?.length
							? _createList(dataContext.state?.getBackupProfiles)
							: null}
						{dataContext.state.getExternalBackupProfiles?.length
							? _createListAnt2(dataContext.state?.getExternalBackupProfiles)
							: null}
					</Tree>
				</div>
			) : (
				<Empty imageStyle={{ height: 40 }} description={<span>No backup profile.</span>} />
			)}

			<div
				style={{
					alignSelf: 'flex-end',
					marginLeft: 'auto',
					marginRight: 'auto',
					position: 'absolute',
					bottom: 48,
					left: 16,
					fontSize: 11,
					width: 275,
				}}>
				<Button
					id="backupProfileCreate"
					onClick={() => globalContext.updateState('BackupProfileCreatePopup', {})}
					style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
					block>
					Create a Backup Profile
				</Button>
			</div>
			<div
				style={{
					alignSelf: 'flex-end',
					marginLeft: 'auto',
					marginRight: 'auto',
					position: 'absolute',
					bottom: 8,
					left: 16,
					fontSize: 11,
					width: 275,
				}}>
				<Button
					id="backupProfileCreate"
					onClick={() => globalContext.updateState('VeritasBackupProfileCreatePopup', {})}
					style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
					block>
					Create an External Backup Profile
				</Button>
			</div>
		</>
	)
}
