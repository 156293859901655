import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { Error, PermissionCheck } from '..'

const PrivateRoute = ({ list = [], permissionCheck = false, ...props }) => {
	if (list.length === 0) {
		return (
			<Routes>
				<Route path="*" element={<Error status="404" title="404" errorMessage="Root Not Found" />} />
			</Routes>
		)
	} else {
		return (
			<Routes>
				{list.map((root) => {
					const MyComponent = root.element
					if (root.childs?.length) {
						return root.childs.map((subroot) => {
							const MySubComponent = subroot.element
							const path = `${root.path}${subroot.path}`
							return (
								<Route
									key={root.path + subroot.path}
									path={path}
									element={
										<PermissionCheck
											permissionList={permissionCheck ? subroot.permissionList : []}
											{...props}>
											<MySubComponent />
										</PermissionCheck>
									}
								/>
							)
						})
					} else {
						return (
							<Route
								key={root.path}
								path={root.path}
								element={
									<PermissionCheck permissionList={permissionCheck ? root.permissionList : []} {...props}>
										<MyComponent />
									</PermissionCheck>
								}
							/>
						)
					}
				})}
				<Route
					path="*"
					element={<Error status="404" title="404" errorMessage="The page you want to access does not exist." />}
				/>
			</Routes>
		)
	}
}

export default PrivateRoute
