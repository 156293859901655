import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Select, Button, TimePicker, DatePicker, InputNumber } from 'antd'
import moment from 'moment'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))
export default function Schedule({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()

	const classes = useStyles()
	const [data, setdata] = useState({
		timeHourly: modalData.timeHourly || 1,
		timeDay: modalData?.timeDay || 1,
		timeHour: modalData?.timeHour || '14:00',
		schedules: [
			{
				scheduleName: 'Full',
				backupType: 'Full Backup',
				frequencySeconds: 86400, // 24 hours
				retentionPeriod: {
					value: 30,
					unit: 'DAYS',
				},
			},
		],
	})

	const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	const retenionPeriodType = [
		{
			value: 1,
			unit: 'WEEKS',
		},
		{
			value: 2,
			unit: 'WEEKS',
		},
		{
			value: 3,
			unit: 'WEEKS',
		},
		{
			value: 30,
			unit: 'DAYS',
		},
		{
			value: 1,
			unit: 'MONTHS',
		},
		{
			value: 2,
			unit: 'MONTHS',
		},
		{
			value: 3,
			unit: 'MONTHS',
		},
		{
			value: 6,
			unit: 'MONTHS',
		},
		{
			value: 9,
			unit: 'MONTHS',
		},
		{
			value: 1,
			unit: 'YEARS',
		},
		{
			value: -1,
			unit: 'INFINITE',
		},
	]

	const handleBackupTypeChange = (value) => {
		setdata((prevState) => ({
			...prevState,
			schedules: [{ ...prevState.schedules[0], backupType: value }],
		}))
	}

	const handleFrequencyChange = (value) => {
		setdata((prevState) => ({
			...prevState,
			schedules: [{ ...prevState.schedules[0], frequencySeconds: value }],
		}))
	}

	const handleRetentionPeriodChange = (value) => {
		setdata((prevState) => ({
			...prevState,
			schedules: [{ ...prevState.schedules[0], retentionPeriod: JSON.parse(value) }],
		}))
	}

	const handleTimeChange = (time, timeString) => {
		setdata({ ...data, timeHour: timeString })
	}

	const handleDayChange = (value) => {
		setdata({ ...data, timeDay: value })
	}

	const handleTimeHourlyChange = (value) => {
		setdata({ ...data, timeHourly: value })
	}

	useEffect(() => {
		if (modalData.schedules) {
			let timeHourly = modalData?.timeHourly
			let timeDay = modalData?.timeDay
			let timeHour = modalData?.timeHour
			const schedule = modalData?.schedules
			const startWindow = schedule[0]?.startWindow

			if ((!timeHour || !timeDay) && modalData?.createOrUpdate == 'update') {
				if (schedule[0]?.frequencySeconds >= 604800) {
					const firstScheduledDay = startWindow?.find((window) => window.startSeconds !== 0)
					if (firstScheduledDay) {
						timeDay = firstScheduledDay.dayOfWeek
						const hours = Math.floor(firstScheduledDay.startSeconds / 3600)
						const minutes = Math.floor((firstScheduledDay.startSeconds % 3600) / 60)
						timeHour = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
					}
				} else if (schedule[0]?.frequencySeconds < 86400) {
					timeHourly = schedule[0]?.frequencySeconds / 3600
					schedule[0].frequencySeconds = 3600

					const allDaysScheduled = startWindow?.every((window) => window.startSeconds !== 0)
					if (allDaysScheduled) {
						timeDay = 1
						const firstScheduledDay = startWindow[0]
						const hours = Math.floor(firstScheduledDay.startSeconds / 3600)
						const minutes = Math.floor((firstScheduledDay.startSeconds % 3600) / 60)
						timeHour = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
					}
				} else {
					const allDaysScheduled = startWindow?.every((window) => window.startSeconds !== 0)
					if (allDaysScheduled) {
						timeDay = 1
						const firstScheduledDay = startWindow[0]
						const hours = Math.floor(firstScheduledDay.startSeconds / 3600)
						const minutes = Math.floor((firstScheduledDay.startSeconds % 3600) / 60)
						timeHour = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
					}
				}
			}

			setdata({
				...data,
				timeHourly: timeHourly || 1,
				timeDay: timeDay || 1,
				timeHour: timeHour || '14:00',
				schedules: schedule,
			})
		}
	}, [modalData])

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Backup Type
					<Select
						value={data.schedules[0].backupType}
						className="fullW"
						id="buType"
						onChange={handleBackupTypeChange}>
						<Option value="Full Backup">Full Backup</Option>
						<Option value="Differential Incremental Backup">Differential Incremental Backup</Option>
						<Option value="Cumulative Incremental Backup">Cumulative Incremental Backup</Option>
						<Option value="User Backup">User Backup</Option>
						<Option value="User Archive">User Archive</Option>
					</Select>
				</label>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Retention Period
				</label>
				<Select
					value={JSON.stringify(data.schedules[0].retentionPeriod)}
					className="fullW"
					id="retentionPeriod"
					onChange={handleRetentionPeriodChange}>
					{retenionPeriodType?.map((period, index) => (
						<Option key={index} value={JSON.stringify(period)}>
							{period.value} {period.unit}
						</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Type
				</label>
				<Select
					value={data.schedules[0].frequencySeconds}
					className="fullW"
					id="buType"
					onChange={handleFrequencyChange}>
					<Option value={3600}>Hourly</Option>
					<Option value={86400}>Daily</Option>
					<Option value={604800}>Weekly</Option>
					<Option value={2678400}>Monthly</Option>
				</Select>
			</div>
			{data.schedules[0].frequencySeconds === 604800 && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Day
					</label>
					<Select value={data.timeDay} className="fullW" onChange={handleDayChange}>
						{daysOfWeek.map((day, index) => (
							<Option key={day} value={index + 1}>
								{day}
							</Option>
						))}
					</Select>
				</div>
			)}
			{data.schedules[0].frequencySeconds < 86400 && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Hour
					</label>
					<InputNumber
						placeholder="Enter here"
						min={1}
						className="fullW"
						max={23}
						value={data.timeHourly}
						onChange={handleTimeHourlyChange}
					/>
				</div>
			)}
			{data.schedules[0].frequencySeconds >= 86400 && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Start Time
					</label>
					<TimePicker
						className="fullW"
						format={'HH:mm'}
						value={moment(data.timeHour, 'HH:mm')}
						onChange={handleTimeChange}
					/>
				</div>
			)}
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
