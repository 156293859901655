///Data
import React, { createContext, useContext, useEffect, useState } from 'react'
import useReducerWithLogger from './Logger'
import dataService from 'service/dataService'
import { message } from 'antd'
export const DataContext = createContext()
export const useDataContext = () => useContext(DataContext)

///gerekli ilk stateler buraya yazılacak
let initialState = {
	recentTask: [],
	list: [],
	peerClusters: [],
	rlbObjects: [],
	groupView: 'cluster',
	sGroupView: 'vmStoragePool',
	activePage: 'Host and Clusters',
	tenants: 'waiting',
}

//reducer baglantısı
const reducer = (state, action) => {
	switch (action.type) {
		case 'DataContext_UPDATE_STATE':
			let value = action.payload
			return { ...state, ...value }
		default:
			console.error(`${action.type} ->Action Not Found`)
			return state
	}
}

///Contex Provider
const DataContextProvider = (props) => {
	initialState = { ...initialState, ...props.initialState }
	const [state, dispatch] = useReducerWithLogger(reducer, initialState)

	const updateState = (key, value) => {
		dispatch({
			type: 'DataContext_UPDATE_STATE',
			payload: { [key]: value },
		})
	}

	const getClusters = async () => {
		let res = await dataService.getClusters()
		if (res) {
			if (res.success) {
				updateState('list', res.data)
				var nodeArray = []
				if (res.data.length > 0) {
					res.data.forEach((singleCluster) => {
						if (typeof singleCluster.child != 'undefined') {
							nodeArray = nodeArray.concat(singleCluster.child)
						}
					})
				}
				updateState('getNodes', nodeArray)
			} else {
				console.error(res.message)
				updateState('list', [])
				updateState('getNodes', [])
			}
		} else {
			console.error(res.message)
			updateState('list', [])
			updateState('getNodes', [])
		}
	}

	const getVmStoragePools = async () => {
		let res = await dataService.getVmStoragePools()

		if (res) {
			if (res.success) {
				updateState('getVmStoragePools', res.data)
			} else {
				console.error(res.message)
				updateState('getVmStoragePools', [])
			}
		} else {
			console.error(res.message)
			updateState('getVmStoragePools', [])
		}
	}
	const getNetworks = async () => {
		let res = await dataService.getNetworks()
		if (res) {
			if (res.success) {
				let resData = [...res.data]
				resData.sort((a, b) => a.name.localeCompare(b.name))
				updateState('getNetworks', resData)
			} else {
				console.error(res.message)
				updateState('getNetworks', [])
			}
		} else {
			console.error(res.message)
			updateState('getNetworks', [])
		}
	}

	const getContainers = async () => {
		let res = await dataService.getContainers()
		if (res) {
			if (res.success) {
				updateState('getContainers', res.data)
			} else {
				console.error(res.message)
				updateState('getContainers', [])
			}
		} else {
			console.error(res.message)
			updateState('getContainers', [])
		}
	}

	const getResticSnapshots = async () => {
		let res = await dataService.getResticSnapshots()
		if (res) {
			if (res.success) {
				updateState('getResticSnapshots', res.data)
			} else {
				console.error(res.message)
				updateState('getResticSnapshots', [])
			}
		} else {
			console.error(res.message)
			updateState('getResticSnapshots', [])
		}
	}
	const getNetworkProfiles = async () => {
		let res = await dataService.getNetworkProfiles()
		if (res) {
			if (res.success) {
				let resData = [...res.data]
				resData.sort((a, b) => a.name.localeCompare(b.name))
				updateState('getNetworkProfiles', resData)
			} else {
				console.error(res.message)
				updateState('getNetworkProfiles', [])
			}
		} else {
			console.error(res.message)
			updateState('getNetworkProfiles', [])
		}
	}
	const getImages = async () => {
		let vmImages = []
		let containerImages = []
		if (state.getVmStoragePools) {
			for (const pool of state.getVmStoragePools) {
				if (pool.isoStorage) {
					let res = await dataService.poolWalk(pool.id)
					if (res) {
						if (res.success) {
							let containerImagesInPool = res.data?.files?.filter((file) => file?.fileType === 'containerImage')
							if (containerImagesInPool?.length > 0) {
								containerImagesInPool = containerImagesInPool?.map((file) => ({
									...file,
									storagePoolId: pool.id,
								}))
								containerImages = [...containerImages, ...containerImagesInPool]
							}
							let vmImagesInPool = res.data?.files?.filter((file) => file?.fileType === 'vmImage')
							if (vmImagesInPool?.length > 0) {
								vmImagesInPool = vmImagesInPool.map((file) => ({
									...file,
									storagePoolId: pool.id,
								}))
								vmImages = [...vmImages, ...vmImagesInPool]
							}
						} else {
							console.error(res.message)
						}
					} else {
						console.error(res.message)
					}
				}
			}
		}
		updateState('getVmImages', vmImages)
		updateState('getContainerImages', containerImages)
	}
	const getOperations = async () => {
		let res = await dataService.getOperations()
		if (res) {
			if (res.success) {
				updateState('getOperations', res.data)
			} else {
				console.error('Error')
				updateState('getOperations', [])
			}
		} else {
			console.error('Error')
			updateState('getOperations', [])
		}
	}
	const getBackupProfiles = async () => {
		let res = await dataService.getBackupProfiles()
		if (res) {
			if (res.success) {
				updateState('getBackupProfiles', res.data)
			} else {
				console.error('Error')
				updateState('getBackupProfiles', [])
			}
		} else {
			console.error('Error')
			updateState('getBackupProfiles', [])
		}
	}

	const getExternalBackupProfiles = async () => {
		let res = await dataService.getVeritasBackupProfile()
		if (res) {
			if (res.success) {
				updateState('getExternalBackupProfiles', res.data)
			} else {
				updateState('getExternalBackupProfiles', [])
			}
		} else {
			updateState('getExternalBackupProfiles', [])
		}
	}

	const getVmTemplates = async () => {
		let res = await dataService.getVmTemplates()
		if (res) {
			if (res.success) {
				updateState('getVmTemplates', res.data)
			} else {
				console.error(res.message)
				updateState('getVmTemplates', [])
			}
		} else {
			console.error(res.message)
			updateState('getVmTemplates', [])
		}
	}
	const getMpaths = async () => {
		let res = await dataService.getMpaths()
		if (res.success) {
			updateState('getMpaths', res.data)
		} else {
			console.error(res.message)
			updateState('getMpaths', [])
		}
	}
	const getVmGroups = async () => {
		let res = await dataService.getVmGroups()
		if (res) {
			if (res.success) {
				updateState('getVmGroups', res.data)
			} else {
				console.error(res.message)
				updateState('getVmGroups', [])
			}
		} else {
			console.error(res.message)
			updateState('getVmGroups', [])
		}
	}
	const getBlockDevices = async () => {
		let res = await dataService.getBlockDevices()
		if (res) {
			if (res.success) {
				updateState('getBlockDevices', res.data)
			} else {
				console.error(res.message)
				updateState('getBlockDevices', [])
			}
		} else {
			console.error(res.message)
			updateState('getBlockDevices', [])
		}
	}
	const getNetworkFilters = async () => {
		let res = await dataService.getNetworkFilters()
		if (res) {
			if (res.success) {
				updateState('getNetworkFilters', res.data)
			} else {
				console.error(res.message)
				updateState('getNetworkFilters', [])
			}
		} else {
			console.error(res.message)
			updateState('getNetworkFilters', [])
		}
	}
	const getNetworkOverlays = async () => {
		let res = await dataService.getNetworkOverlays()
		if (res) {
			if (res.success) {
				updateState('getNetworkOverlays', res.data)
			} else {
				console.error(res.message)
				updateState('getNetworkOverlays', [])
			}
		} else {
			console.error(res.message)
			updateState('getNetworkOverlays', [])
		}
	}
	const getGlusters = async () => {
		let res = await dataService.getGlusters()
		if (res) {
			if (res.success) {
				updateState('getGlusters', res.data)
			} else {
				console.error(res.message)
				updateState('getGlusters', [])
			}
		} else {
			console.error(res.message)
			updateState('getGlusters', [])
		}
	}
	const getGlusterVolumes = async () => {
		let res = await dataService.getGlusterVolumes()
		if (res) {
			if (res.success) {
				updateState('getGlusterVolumes', res.data)
			} else {
				console.error(res.message)
				updateState('getGlusterVolumes', [])
			}
		} else {
			console.error(res.message)
			updateState('getGlusterVolumes', [])
		}
	}

	const getNodeNetworkInterfaces = async () => {
		let res = await dataService.getNodeNetworkInterfaces()
		if (res) {
			if (res.success) {
				updateState('nodeNetworkInterfaces', res.data)
			} else {
				console.error(res.message)
				updateState('nodeNetworkInterfaces', [])
			}
		} else {
			console.error(res.message)
			updateState('nodeNetworkInterfaces', [])
		}
	}

	const getVmNetworkInterfaces = async () => {
		let res = await dataService.getVmNetworkInterfaces()
		if (res) {
			if (res.success) {
				updateState('vmNetworkInterfaces', res.data)
			} else {
				console.error(res.message)
				updateState('vmNetworkInterfaces', [])
			}
		} else {
			console.error(res.message)
			updateState('vmNetworkInterfaces', [])
		}
	}

	const getTenants = async () => {
		let res = await dataService.getTenants()
		if (res) {
			if (res.success) {
				updateState('tenants', res.data)
			} else {
				console.error(res.message)
				updateState('tenants', [])
			}
		} else {
			console.error(res.message)
			updateState('tenants', [])
		}
	}

	const getSouthBoundNetworks = async () => {
		let res = await dataService.getSouthBoundNetworks()
		if (res) {
			if (res.success) {
				updateState('southBoundNetworks', res.data)
			} else {
				console.error(res.message)
				updateState('southBoundNetworks', [])
			}
		} else {
			console.error(res.message)
			updateState('southBoundNetworks', [])
		}
	}

	const getQuotaProfiles = async () => {
		let res = await dataService.getQuotaProfiles()
		if (res) {
			if (res.success) {
				updateState('quotaProfiles', res.data)
			} else {
				console.error(res.message)
				updateState('quotaProfiles', [])
			}
		} else {
			console.error(res.message)
			updateState('quotaProfiles', [])
		}
	}

	const getAddressPools = async () => {
		let res = await dataService.getAddressPools()
		if (res) {
			if (res.success) {
				updateState('addressPools', res.data)
			} else {
				console.error(res.message)
				updateState('addressPools', [])
			}
		} else {
			console.error(res.message)
			updateState('addressPools', [])
		}
	}

	const getPeerClusters = async () => {
		let res = await dataService.getPeerClusters()
		if (res) {
			if (res.success) {
				updateState('peerClusters', res.data)
			} else {
				console.error(res.message)
				updateState('peerClusters', [])
			}
		} else {
			console.error(res.message)
			updateState('peerClusters', [])
		}
	}

	const getIscsiInitiators = async () => {
		let res = await dataService.getIscsiInitiators()
		if (res) {
			if (res.success) {
				updateState('iscsiInitiators', res.data)
			} else {
				console.error(res.message)
				updateState('iscsiInitiators', [])
			}
		} else {
			console.error(res.message)
			updateState('iscsiInitiators', [])
		}
	}
	const getStoragePoolGroups = async () => {
		let res = await dataService.getSPoolGroups()
		if (res) {
			if (res.success) {
				updateState('getStoragePoolGroups', res.data)
			} else {
				console.error(res.message)
				updateState('getStoragePoolGroups', [])
			}
		} else {
			console.error(res.message)
			updateState('getStoragePoolGroups', [])
		}
	}
	const getRlbObjects = async () => {
		let res = await dataService.getRlbObjects()
		if (res.success) {
			updateState('rlbObjects', res.data)
		} else {
			console.error(res.message)
			updateState('rlbObjects', [])
		}
	}

	const getExternalBackupUsers = async () => {
		let res = await dataService.getExternalBackupUsers()
		if (res.success) {
			updateState('getExternalBackupUsers', res.data)
		} else {
			updateState('getExternalBackupUsers', [])
		}
	}

	const getVms = async () => {
		getVmNetworkInterfaces()
		let resp = await dataService.getVms()
		if (resp.success) {
			updateState('getVms', resp.data)
		} else {
			updateState('getVms', [])
		}
	}

	const getVmStorages = async () => {
		let resp = await dataService.getVmStorages()
		if (resp.success) {
			updateState('getVmStorages', resp.data)
		} else {
			updateState('getVmStorages', [])
		}
	}

	const getVolumePathObjects = async () => {
		let res = await dataService.getVolumePath()
		if (res.success) {
			let resData = [...res.data]
			resData.sort((a, b) => a.name.localeCompare(b.name))
			updateState('getVolumePaths', resData)
		} else {
			console.error(res.message)
			updateState('getVolumePaths', [])
		}
	}

	const getSystemAuthTokens = async () => {
		let res = await dataService.getSystemAuthTokens()
		if (res) {
			if (res.success) {
				updateState('getSystemAuthTokens', res.data)
			} else {
				console.error(res.message)
				updateState('getSystemAuthTokens', [])
			}
		} else {
			console.error(res.message)
			updateState('getSystemAuthTokens', [])
		}
	}

	const fetch = async () => {
		if (localStorage.getItem('token')) {
			message.loading('Fetching data.')
			await getClusters()
			await getTenants()
			await getResticSnapshots()
			await getVmGroups()
			await getStoragePoolGroups()
			await getNetworks()
			await getNetworkProfiles()
			await getVmStoragePools()
			await getImages()
			await getIscsiInitiators()
			await getContainers()
			await getBackupProfiles()
			await getExternalBackupProfiles()
			await getVmTemplates()
			await getBlockDevices()
			await getPeerClusters()
			await getNetworkFilters()
			await getNetworkOverlays()
			await getGlusters()
			await getGlusterVolumes()
			await getNodeNetworkInterfaces()
			await getVmNetworkInterfaces()
			await getAddressPools()
			await getSouthBoundNetworks()
			await getQuotaProfiles()
			await getVolumePathObjects()
			await getExternalBackupUsers()
			await getSystemAuthTokens()
			message.destroy()
		}
	}

	useEffect(() => {
		fetch()
	}, [])

	useEffect(() => {
		getImages()
	}, [state.getVmStoragePools])

	return (
		<DataContext.Provider
			value={{
				updateState,
				getClusters,
				getGlusters,
				getTenants,
				getNetworks,
				getNetworkProfiles,
				getVmGroups,
				getVmStoragePools,
				getImages,
				getBackupProfiles,
				getExternalBackupProfiles,
				getIscsiInitiators,
				getNetworkFilters,
				getNetworkOverlays,
				getPeerClusters,
				getGlusterVolumes,
				getVmTemplates,
				getContainers,
				getNodeNetworkInterfaces,
				getQuotaProfiles,
				getRlbObjects,
				getVms,
				getVolumePathObjects,
				getExternalBackupUsers,
				getSystemAuthTokens,
				fetch,
				state,
			}}>
			{props.children}
		</DataContext.Provider>
	)
}

export default DataContextProvider
