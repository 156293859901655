///Global
import React, { createContext, useContext, useState } from 'react'
import useReducerWithLogger from './Logger'
import { notification } from 'antd'
import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import { ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
export const GlobalContext = createContext()
export const useGlobalContext = () => useContext(GlobalContext)
///gerekli ilk stateler buraya yazılacak
const initialState = {
	sideMenu: localStorage.getItem('sideMenu') === 'true',
	newNodePopup: null,
	newVMPopup: null,
	newStoragePopup: null,
	newSNetworkPopup: null,
	initClusterPopup: null,
	vmState: {
		Running: 'running',
		Blocked: 'blocked',
		Paused: 'paused',
		Shutdown: 'shutdown',
		Shutoff: 'shutoff',
		Crashed: 'crashed',
		PmSuspended: 'pmSuspended',
	},
	contextMenuData: null,
	params: {},
	openTask: null,
	hci: true,
	theme: {
		default: {
			primary: '#331968',
			primary2: '#473077',
		},
		hci: {
			primary: '#0f427a',
			primary2: '#2B5889',
		},
	},
	uploads: [],
	uploadContent: false,
}

//reducer baglantısı
const reducer = (state, action) => {
	switch (action.type) {
		case 'GlobalContext_UPDATE_STATE':
			let value = action.payload
			return { ...state, ...value }
		default:
			console.error(`${action.type} ->Action Not Found`)
			return state
	}
}

///Context Provider
const GlobalContextProvider = (props) => {
	const inputRef = React.useRef()

	const [state, dispatch] = useReducerWithLogger(reducer, initialState)
	const updateState = (key, value) => {
		dispatch({
			type: 'GlobalContext_UPDATE_STATE',
			payload: { [key]: value },
		})
	}

	const toggleSideMenu = (key, value) => {
		dispatch({
			type: 'GlobalContext_UPDATE_STATE',
			payload: { sideMenu: !state.sideMenu },
		})
		localStorage.setItem('sideMenu', !state.sideMenu)
	}

	const Snackbar = async (message, type = 'success', icon = null) => {
		if (icon == null) {
			if (type == 'error') {
				icon = <CloseCircleOutlined style={{ color: '#c25858' }} />
			} else if (type == 'success' && message?.includes('enqueued')) {
				icon = <ClockCircleOutlined style={{ color: '#d9c709' }} />
			} else if (type == 'success') {
				icon = <CheckCircleOutlined style={{ color: '#1cb084' }} />
			} else {
				icon = <InfoCircleOutlined style={{ color: '#c25858' }} />
			}
		}

		notification[type]({
			icon: icon,
			closeIcon: (
				<div>
					<CloseOutlined />
				</div>
			),
			message: message,
		})
	}

	const openContextMenu = async (type, obj, e = null) => {
		updateState('contextMenuData', obj)
		contextMenu.show({
			id: type || '',
			event: e,
			props: obj,
		})
	}

	return (
		<GlobalContext.Provider
			value={{
				updateState,
				state,
				toggleSideMenu,
				openContextMenu,
				Snackbar,
			}}>
			{props.children}
			<audio ref={inputRef} />
		</GlobalContext.Provider>
	)

	// useEffect(() => {
	// 	if (initialState.hci) {
	// 		updateState('selectedTheme', initialState.theme.hci)
	// 	} else {
	// 		updateState('selectedTheme', initialState.theme.default)
	// 	}
	// }, [])
}

export default GlobalContextProvider
