import React from 'react'
import ClusterContextMenu from './ClusterContextMenu'
import NodeContextMenu from './NodeContextMenu'
import VMContextMenu from './VMContextMenu'
import NetworkContextMenu from './NetworkContextMenu'
import NetworkProfileContextMenu from './NetworkProfileContextMenu'
import VmStoragePoolContextMenu from './VmStoragePoolContextMenu'
import BackupProfileContextMenu from './BackupProfileContextMenu'
import VmGroupContextMenu from './VmGroupContextMenu'
import VMTemplateContextMenu from './VMTemplateContextMenu'
import ContainerContextMenu from './ContainerContextMenu'
import TenantContextMenu from './TenantContextMenu'
import StoragePoolGroupContextMenu from './StoragePoolGroupContextMenu'
import PeerClusterContextMenu from './PeerClusterContextMenu'
import VeritasBackupProfileContextMenu from './VeritasBackupProfileContextMenu'

export default function index() {
	return (
		<>
			<ClusterContextMenu style={{ background: '#fafafa' }} />
			<NodeContextMenu />
			<VMContextMenu />
			<NetworkContextMenu />
			<NetworkProfileContextMenu />
			<VmStoragePoolContextMenu />
			<BackupProfileContextMenu />
			<VmGroupContextMenu />
			<VMTemplateContextMenu />
			<ContainerContextMenu />
			<TenantContextMenu />
			<StoragePoolGroupContextMenu />
			<PeerClusterContextMenu />
			<VeritasBackupProfileContextMenu />
		</>
	)
}
