import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Switch } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('storagePoolGroupCreatePopup', null)
		setdata({})
	}

	const getSPoolGroups = async () => {
		let res = await dataService.getSPoolGroups()
		if (res.success) {
			dataContext.updateState('getStoragePoolGroups', res.data)
		}
	}

	const createStoragePoolGroup = async () => {
		var poolArr = []
		data.objects.forEach((poolId) => {
			poolArr.push({
				id: poolId,
				name: dataContext.state.getVmStoragePools.find((e) => e.id == poolId)?.name,
			})
		})
		let sendData = {
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			haCritical: data.haCritical || false,
			haExcluded: data.haExcluded || false,
			objects: poolArr,
		}

		let res = await dataService.createSPoolGroup(sendData)
		if (res.success) {
			getSPoolGroups()
			handleClose()

			globalContext.Snackbar(`Storage pool group [${res?.data?.name}] created successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateSPoolGroup = async () => {
		var poolArr = []
		data.objects.forEach((poolId) => {
			poolArr.push({
				id: poolId,
				name: dataContext.state.getVmStoragePools.find((e) => e.id == poolId)?.name,
			})
		})
		let sendData = {
			id: data.id,
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			haCritical: data.haCritical || false,
			haExcluded: data.haExcluded || false,
			objects: poolArr,
		}
		let res = await dataService.updateSPoolGroup(sendData)
		if (res.success) {
			getSPoolGroups()
			handleClose()

			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const fetch = async () => {
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}

	useEffect(() => {
		if (globalContext.state.storagePoolGroupCreatePopup) {
			setdata(globalContext.state.storagePoolGroupCreatePopup)
			fetch()
		}
	}, [globalContext.state.storagePoolGroupCreatePopup])

	return (
		<div>
			<Drawer
				title={
					globalContext.state.storagePoolGroupCreatePopup?.createOrUpdate == 'update'
						? 'Update Storage Pool Group'
						: 'New Storage Pool Group'
				}
				placement="right"
				width={450}
				onClose={handleClose}
				visible={globalContext.state.storagePoolGroupCreatePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						{globalContext.state.storagePoolGroupCreatePopup?.createOrUpdate == 'update' ? (
							<Button
								onClick={() => updateSPoolGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								small
								id="sPoolGroupUpdate">
								Update
							</Button>
						) : (
							<Button
								onClick={() => createStoragePoolGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								disabled={`${data?.objects?.length == 0 && data?.name?.length == 0 ? 'true' : ''}`}
								small
								id="sPoolGroupCreate">
								Create
							</Button>
						)}
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Group Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="sPoolGroupName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Select Storage Pools
					</label>
					<Select
						mode="multiple"
						value={data?.objects}
						onChange={handleChange('objects')}
						placeholder="Select Storage Pools"
						className="fullW"
						id="selectSpools">
						{dataContext.state?.getVmStoragePools?.map((item) => (
							<Option key={item.id} value={item.id}>
								<span style={{ fontWeight: 'bold' }}>{item.name}</span>
								{`- ${item?.vmStoragePoolStat?.allocation} / ${item?.vmStoragePoolStat?.capacity} (${item?.vmStoragePoolStat?.percentage}%)` ??
									''}
							</Option>
						))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
