import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext, http } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Upload, message, Progress, Typography } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
const { Option } = Select
const { Dragger } = Upload
const { Title } = Typography
export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [progress, setProgress] = useState(0)
	const [file, setFile] = useState(null)
	const [validErr, setValidErr] = useState(false)
	const [fileList, setFileList] = useState([])
	const initializedData = {
		name: '',
		nodeId: '',
		storagePoolId: '',
		networkId: '',
		networkProfileId: '',
	}
	const [data, setdata] = useState({ ...globalContext.state.ovfUploadPopup, ...initializedData })
	const [storageList, setStorageList] = useState([])
	const [nodeList, setnodeList] = useState([])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? (key == 'isoStorage' ? e.target.checked : e.target.value) : e
		if (key == 'name') {
			valueTemp == '' ? setValidErr(true) : setValidErr(false)
		}
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('ovfUploadPopup', null)
		setdata(initializedData)
		setFileList([])
		setFile(null)
	}

	const uploadSession = async (id) => {
		if (data?.name == '') {
			message.error('Please input a name.')
			setValidErr(true)
			return false
		}

		let sendData = {
			fileName: file?.name || '',
			nodeId: data?.nodeId,
			storageId: data?.storagePoolId,
			vmName: data?.name,
			networkId: data?.networkId,
			networkProfileId: data?.networkProfileId,
		}
		let res = await dataService.uploadSession(sendData)
		if (res.success) {
			let formData = new FormData()
			formData.append(fileList[0].name, fileList[0].originFileObj)
			handleClose()
			globalContext.updateState('uploadContent', true)
			message.loading('Upload in progress.', 3)
			message.warning('If page is refreshed, incomplete upload progress will be lost.', 10)
			let res2 = await http.post(`/operations/uploadSession/upload/${res.data.id}/`, formData, {
				headers: { 'content-type': 'multipart/form-data' },
				onUploadProgress: (event) => {
					const percent = Math.floor((event.loaded / event.total) * 100)
					setProgress(percent)
					if (percent === 100) {
						setTimeout(
							() => globalContext.updateState('uploads', [{ message: file.name + ' uploaded.', progress: 100 }]),
							1000
						)
						setTimeout(() => {
							globalContext.updateState('uploadContent', false)
							globalContext.updateState('uploads', [])
							setProgress(0)
						}, 10000)
					}
					globalContext.updateState('uploads', [{ message: file.name + ' uploading...', progress: percent }])
				},
				maxBodyLength: 10 ** 11,
				maxContentLength: 10 ** 11,
			})
			if (res2.success) {
				message.success(file.name + ' uploaded.')
				handleClose()
				dropFiles()
			} else {
				message.error('Upload failed.')
				globalContext.Snackbar(res2.errorMessage, 'error')
			}
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const handleFile = (info) => {
		if (info.fileList.length != 0) {
			setFileList(info.fileList)
			setFile(info.file)
		} else {
			setFile(null)
			setFileList([])
		}
	}

	const sendFile = (e) => {
		e.preventDefault()
		uploadSession()
	}

	const dropFiles = () => {
		setFile(null)
		setFileList([])
	}

	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}

		let responseStorages = await dataService.getVmStoragePools()
		if (responseStorages.success) {
			let filteredStoragePools = [...responseStorages.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setStorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorages.data)
		} else {
			globalContext.Snackbar(responseStorages.errorMessage, 'error')
			setStorageList([])
		}
	}

	const validateFile = (file) => {
		let fileNameUpper = file.name.toUpperCase()
		const isOVAExtension = fileNameUpper.endsWith('.OVA')
		if (isOVAExtension) {
			// false is required for manual upload with a confirm button
			return false
		} else {
			message.error(`${file.name} does not end with an .ova extension`)
			return Upload.LIST_IGNORE
		}
	}

	useEffect(() => {
		globalContext.state.ovfUploadPopup && fetch()
	}, [globalContext.state.ovfUploadPopup])

	return (
		<div>
			<Drawer
				title={'New VM from OVF Template'}
				placement="right"
				width={450}
				onClose={handleClose}
				visible={globalContext.state.ovfUploadPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={(e) => sendFile(e)}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={(() => {
								const uploadInProgress =
									globalContext?.state?.uploads?.length > 0 && globalContext?.state?.uploads[0].progress < 100
								const fileSelected = file !== null
								const formFilled = data?.name !== '' && data?.nodeId !== '' && data?.storagePoolId !== ''
								// && data?.networkId !== '' && data?.networkProfileId !== ''
								return uploadInProgress || !fileSelected || !formFilled
							})()}
							small
							id="ovfSubmit">
							Confirm
						</Button>
					</div>
				}>
				<Title level={5} style={{ marginBottom: 5 }}>
					Sybelle VM Configuration
				</Title>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>VM Name
					</label>
					<Input
						id="ovfVmName"
						placeholder="Enter here"
						value={data?.name}
						onChange={handleChange('name')}
						status={`${command.checkValue({ name: data.name }).length && validErr ? 'error' : ''}`}
					/>
					{command.checkValue({ name: data.name }).length && validErr ? (
						<small style={{ color: '#ff4d4f' }}>Please input a name.</small>
					) : (
						''
					)}
				</div>

				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Node
					</label>
					<Select
						value={data?.nodeId}
						onChange={handleChange('nodeId')}
						className="fullW"
						id="vmTemplateNodeSelect">
						{nodeList.map((item) => (
							<Option value={item.id}>
								<span style={{ fontWeight: 'bold' }}>{item.name}</span> - ({item.nodeStat.cpuPercentage}% CPU) (
								{item.nodeStat.memPercentage}% Memory)
							</Option>
						))}
					</Select>
				</div>

				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Storage Pool
					</label>
					<Select
						value={data?.storagePoolId}
						onChange={handleChange('storagePoolId')}
						className="fullW"
						id="vmTemplateStoragePoolSelect">
						{storageList.map((item) => {
							// select vm pools only
							if (item?.poolUnits?.map((pu) => pu?.nodeId)?.includes(data?.nodeId)) {
								return (
									<Option key={item.id} value={item.id}>
										<span style={{ fontWeight: 'bold' }}>{item.name}</span> -{' '}
										{item.vmStoragePoolStat.allocation} / {item.vmStoragePoolStat.capacity} (
										{item.vmStoragePoolStat.percentage}%)
									</Option>
								)
							}
							return null
						})}
					</Select>
				</div>

				<div className="form-group-ant">
					<label>Network</label>
					<Select
						value={data?.networkId}
						onChange={handleChange('networkId')}
						className="fullW"
						id="selectNetworkId">
						{dataContext?.state?.getNetworks?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>Network Profile</label>
					<Select
						value={data?.networkProfileId}
						onChange={handleChange('networkProfileId')}
						className="fullW"
						id="selectNetworkProfile">
						{dataContext?.state?.getNetworkProfiles?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<Title level={5} style={{ marginBottom: 5 }}>
					OVA File Upload
				</Title>
				<div>
					<Dragger onChange={handleFile} maxCount={1} fileList={fileList} beforeUpload={validateFile}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<p className="ant-upload-hint">Only a single .ova file is allowed</p>
					</Dragger>
					{progress > 0 && <Progress percent={progress} />}
				</div>
			</Drawer>
		</div>
	)
}
