import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select } from 'antd'
const { Option } = Select
const { OptGroup } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ name: '' })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const onClose = () => {
		setVisible(false)
		globalContext.updateState('southBoundNetworkPopup', null)
		setdata({ name: '' })
	}

	const createSouthBoundNetwork = async () => {
		let sendData = { ...data, clusterId: dataContext?.state?.list?.[0]?.id }
		let res = await dataService.createSouthBoundNetwork(sendData)
		if (res.success) {
			let sbNetworkRes = await dataService.getSouthBoundNetworks()
			if (sbNetworkRes.success) {
				dataContext.updateState('southBoundNetworks', sbNetworkRes.data)
			}
			onClose()
			globalContext.Snackbar(
				`Create [${res.data.name}] south bound network operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateSouthBoundNetwork = async () => {
		let sendData = { ...data }
		delete sendData['status']
		let res = await dataService.updateSouthBoundNetwork(sendData)
		if (res.success) {
			let sbNetworkRes = await dataService.getSouthBoundNetworks()
			if (sbNetworkRes.success) {
				dataContext.updateState('southBoundNetworks', sbNetworkRes.data)
			}
			onClose()
			globalContext.Snackbar(
				`Update [${res.data.name}] south bound network operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		if (globalContext.state.southBoundNetworkPopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.southBoundNetworkPopup)
		}
	}, [globalContext.state.southBoundNetworkPopup])

	return (
		<Drawer
			title={
				globalContext?.state?.southBoundNetworkPopup?.createOrUpdate == 'update'
					? 'Edit South Bound Network'
					: 'New South Bound Network'
			}
			placement="right"
			onClose={onClose}
			visible={globalContext.state.southBoundNetworkPopup || false}
			open={globalContext.state.southBoundNetworkPopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>

					{data?.id ? (
						<Button onClick={updateSouthBoundNetwork} style={{ marginLeft: 8 }} type="primary" small>
							Update
						</Button>
					) : (
						<Button
							id="sbNetworkSubmit"
							onClick={createSouthBoundNetwork}
							type="primary"
							style={{ marginLeft: 8 }}
							small>
							Create
						</Button>
					)}
				</div>
			}>
			<div className="form-group-ant">
				<label>Name</label>
				<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="sbNetworkName" />
				<span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>Specify a unique name</span>
			</div>
			<div className="form-group-ant">
				<label>Primary Network Interface</label>
				<Select
					value={data?.primaryNodeNetworkInterfaceId}
					onChange={handleChange('primaryNodeNetworkInterfaceId')}
					className="fullW"
					id="sbNetworkPrimaryInterface">
					{dataContext?.state?.getNodes?.map((node) => (
						<OptGroup label={node.name}>
							{node?.networkInterfaces?.map((item) => (
								<Option value={item.id}>{item.name}</Option>
							))}
						</OptGroup>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>Secondary Network Interface</label>
				<Select
					value={data?.secondaryNodeNetworkInterfaceId}
					onChange={handleChange('secondaryNodeNetworkInterfaceId')}
					className="fullW"
					id="sbNetworkPrimaryInterface">
					{dataContext?.state?.getNodes?.map((node) => (
						<OptGroup label={node.name}>
							{node?.networkInterfaces?.map((item) => (
								<Option value={item.id}>{item.name}</Option>
							))}
						</OptGroup>
					))}
				</Select>
			</div>
		</Drawer>
	)
}
