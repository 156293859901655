import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	fw: {
		width: '100%',
	},
}))
export default function Name({
	modalDataChange = () => {},
	next = () => {},
	prev = () => {},
	setSelectedMenu = () => {},
	setSelectedMenuMax = () => {},
	modalData = {},
}) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [nodeList, setnodeList] = useState([])
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({
		clusterId: modalData.clusterId || globalContext.state?.VMMigratePopup?.clusterId,
		nodeId: modalData.nodeId || globalContext.state?.VMMigratePopup?.id,
		selection: modalData.selection || globalContext.state?.VMMigratePopup?.selection,
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		if (data.selection == 'resource') {
			setSelectedMenu(3)
			setSelectedMenuMax(4)
		} else {
			next()
		}
	}

	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}
	useEffect(() => {
		fetch()
	}, [])
	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Host
				</label>
				<Select value={data?.nodeId} onChange={handleChange('nodeId')} className="fullW" id="computeResourceHost">
					{nodeList.map((item) => (
						<Option value={item.id}>
							<span style={{ fontWeight: 'bold' }}>{item.name}</span> - ({item.nodeStat.cpuPercentage}% CPU) (
							{item.nodeStat.memPercentage}% Memory)
						</Option>
					))}
				</Select>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={command.checkValue(data).length > 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
