import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Spin } from 'antd'

// import Skeleton from "@material-ui/lab/Skeleton";

import { Button } from '../components'

/** bir sistemin yuklenmesini yada calışma suresinde beklemesini istediginiz zaman kullanılır
 * @param  {object} children - gosterilecek ve gizlenecek olan component
 * @param  {boolean} loaderShow - true olunca içerik gizlenir ve beklenmesi için animasyon cıkar
 * @param  {boolean} button -true ise bu loader ozelliginin bir buton tarafından kullanıldıgını buton olarak gosterir
 * @param  {Array} serviceCheckList - herkesin görebilecegi dayfanın üst tarafında gorunecek component
 */

const Default = ({ children, loaderShow = false, button = false, serviceCheckList = [], ...props }) => {
	if (serviceCheckList.lengeth > 0) {
		return JSON.stringify(serviceCheckList)
	}
	return (
		<React.Fragment>
			{loaderShow ? (
				<div
					style={{
						display: button ? 'inline-block' : ' flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: button ? 'auto' : '100%',
						background: button ? 'whitesmoke' : '#ffffff',
						width: '100%',
					}}>
					{button ? (
						<Button variant="primary">
							<CircularProgress color="secondary" />
						</Button>
					) : (
						<Spin style={{ width: 100 }} />
					)}
				</div>
			) : (
				children
			)}
		</React.Fragment>
	)
}

export default Default
