// import React, { useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import { command, useDataContext, useGlobalContext } from '../../components'
// import Button from '@material-ui/core/Button'
// import FormControl from '@material-ui/core/FormControl'
// import ButtonGroup from '@material-ui/core/ButtonGroup'
// import Radio from '@material-ui/core/Radio'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'

import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button, Checkbox, Radio } from 'antd'
import dataService from 'service/dataService'

const useStyles = createUseStyles((theme) => ({
	root: {},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
}))

export default function Host({
	modalDataChange = () => {},
	next = () => {},
	prev = () => {},
	setSelectedMenu = () => {},
	setSelectedMenuMax = () => {},
	modalData = {},
}) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [nodeList, setnodeList] = useState([])
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({
		selection: modalData?.selection || 'both',
		coldCheck: modalData.coldCheck || true,
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleCheckboxChange = (e) => {
		let valueTemp = e?.target?.checked
		setdata({ ...data, coldCheck: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		if (data.selection == 'storage') {
			setSelectedMenu(2)
			setSelectedMenuMax(3)
		} else {
			next()
		}
	}

	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
		}

		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			setstorageList(responseStorage.data)
			dataContext.updateState('getVmStoragePools', responseStorage.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
			dataContext.updateState('getVmStoragePools', [])
		}
	}
	useEffect(() => {
		fetch()
	}, [])

	return (
		<div className={classes.root}>
			<h4>
				<span className="requiredHint">*</span>Select a migration type
			</h4>
			<Radio.Group
				onChange={handleChange('selection')}
				value={data?.selection}
				style={{ width: '100%' }}
				id="selectMigrationType">
				<Radio value={'resource'}>Change compute resource</Radio>
				<Radio value={'storage'}>Change storage</Radio>
				<Radio value={'both'}>Change both</Radio>
			</Radio.Group>
			<div style={{ padding: '15px 0 0 0', width: '100%' }}>
				<span>Cold migration :</span>
				<Checkbox
					style={{ padding: '0 0 0 5px' }}
					checked={data.coldCheck}
					onChange={handleCheckboxChange}></Checkbox>
			</div>
			<div className={classes.footer}>
				<Button
					style={{ marginLeft: 8 }}
					onClick={() => nextStep()}
					// disabled={command.checkValue(data).length > 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
