import React, { useEffect, useState } from 'react'
import { DeleteOutlined, CompassOutlined, EditOutlined } from '@ant-design/icons'
import { Menu, Item } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useAuthContext, useDataContext, useGlobalContext } from '../Context'
import loginService from '../service/loginService'
import { message } from 'antd'
import { changebaseURL } from '../components/axios/axios'
import { useNavigate } from 'react-router-dom'
import dataService from '../service/dataService'

export default function PeerClusterContextMenu() {
	const globalContext = useGlobalContext()
	const authContext = useAuthContext()
	const dataContext = useDataContext()
	const navigate = useNavigate()

	const redirectToPeerCluster = async (prCluster) => {
		for (const mngmtIpAddress of globalContext?.state?.contextMenuData?.managementIpAddress) {
			try {
				let urlCluster = `https://${mngmtIpAddress}:8000/api/v1/`
				let response = await dataService.getAllEndpoints(urlCluster)
				if (response.success) {
					message.destroy()
					let token = prCluster.token
					globalContext.Snackbar('Switch successful', 'success')
					authContext.login(token)
					changebaseURL(`https://${mngmtIpAddress}:8000/api/v1/`)
					dataContext.fetch()
					return
				} else {
					globalContext.Snackbar(
						'Permission error: Your account lacks access to this cluster. Please create a new account in the cluster you are attempting to access. For assistance, contact support.'
					)
					return
				}
			} catch (error) {
				globalContext.Snackbar(`Error during login: ${error}`, 'error')
			}
		}
		message.destroy()
		globalContext.Snackbar('All login attempts failed.', 'error')
	}

	return (
		<>
			<Menu id={'peerCluster'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Peer Cluster Actions
				</div>
				<hr />
				<Item onClick={() => redirectToPeerCluster(globalContext.state.contextMenuData)} id="peerClusterSwitch">
					<CompassOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Switch to the Cluster
				</Item>
				<Item
					id="peerClusterUpdate"
					onClick={() =>
						globalContext.updateState('peerClusterCreateAndUpdatePopup', {
							createOrUpdate: 'update',
							...globalContext.state.contextMenuData,
						})
					}>
					<EditOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Edit Peer Cluster
				</Item>
				<hr />
				<Item
					id="peerClusterDelete"
					onClick={() => globalContext.updateState('deletePopup', globalContext.state.contextMenuData)}>
					<DeleteOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Delete Peer Cluster
				</Item>
			</Menu>
		</>
	)
}
