import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Input, Button, Select, Switch } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))

export default function General({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const [data, setdata] = useState({
		id: modalData.policyName || '',
		policyName: modalData.policyName || '',
		description: modalData.description || '',
		active: modalData.active || true,
		type: modalData.type || 'veritas',
		policyType: 'Standard',
	})

	useEffect(() => {
		if (modalData) {
			setdata({
				id: modalData.id || '',
				policyName: modalData.policyName || '',
				description: modalData.description || '',
				active: modalData.active || true,
				type: modalData.type || 'veritas',
				policyType: 'Standard',
			})
		}
	}, [modalData])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<h4 style={{ marginBottom: 10 }}>General Properties</h4>

			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Backup Profile Name
				</label>
				<Input
					placeholder="Enter here"
					value={data?.policyName}
					onChange={handleChange('policyName')}
					id="backupPCreateName"
					disabled={modalData.createOrUpdate == 'update'}
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Description
				</label>
				<Input
					placeholder="Enter here"
					value={data?.description}
					onChange={handleChange('description')}
					id="backupPCreateDesc"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>State
				</label>
				<Select
					value={data?.active}
					onChange={handleChange('active')}
					className="fullW"
					id="backupProfileCreateStateSelect">
					<Option value={true}>Active</Option>
					<Option value={false}>Passive</Option>
				</Select>
			</div>
			<div
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'space-between',
				}}></div>
			<div className={classes.footer}>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
