import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext, useParams } from '../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Input, Select, Button, Row, Radio, Collapse, Table, Badge } from 'antd'
const { Option } = Select
const { Panel } = Collapse
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 200,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		paddingBottom: 0,
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: '0 16px 24px 16px',
		flex: 1,
	},
	treeView: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '8px 0',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		height: 14,
		marginLeft: 4,
		color: 'rgb(194, 88, 88)',
	},
	sumTable: {
		width: '90%',
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			textAlign: 'left',
			borderBottom: '1px solid #dee2e6',
		},
	},
	subBtns: {
		display: 'inline-flex',
		justifyContent: 'end',
		width: '100%',
		padding: 16,
	},
	delRule: {
		position: 'absolute',
		top: 8,
		right: 8,
	},
	tripleRow: {
		display: 'flex',
		width: '100%!important',
		'& .form-group-ant': {
			display: 'block',
			flex: '0 0 25%',
			maxWidth: '25%',
			'& label': {
				display: 'block',
				width: '100%',
			},
			'& .ant-select, & input': {
				width: '90%!important',
			},
		},
	},
	nwRuleRow: {
		position: 'relative',
		border: '1px solid #dee2e6',
		padding: 12,
		marginBottom: 12,
		display: 'flex',
		flexFlow: 'row wrap',
		'& .form-group-ant': {
			display: 'block',
			flex: '0 0 25%',
			maxWidth: '25%',
			'& label': {
				display: 'block',
				width: '100%',
			},
			'& .ant-select, & input': {
				width: '90%!important',
			},
		},
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const params = useParams()

	const [data, setdata] = useState({})
	const [visible, setVisible] = useState(false)

	const getNetworkProfileName = (id) => {
		if (id && dataContext.state?.getNetworkProfiles) {
			let findItem = dataContext.state.getNetworkProfiles.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworkName = (id) => {
		if (id && dataContext.state?.getNetworks) {
			let findItem = dataContext.state.getNetworks.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'State',
			dataIndex: 'opState',
			key: 'opState',
		},
		{
			title: 'MAC',
			dataIndex: 'macAddress',
			key: 'macAddress',
		},
		{
			title: 'IP Addresses',
			dataIndex: 'ipAddresses',
			key: 'ipAddresses',
			render: (text, row) => (
				<span>
					{row?.ipAddresses.length > 0 && row?.ipAddresses[0].length > 0 ? row?.ipAddresses.toString() : '-'}
				</span>
			),
		},
		{
			title: 'Connected Network',
			dataIndex: 'connectedNetworkId',
			key: 'connectedNetworkId',
			render: (text, row) => (
				<span>{row?.connectedNetworkId != '' ? getNetworkName(row.connectedNetworkId) : 'Not selected'}</span>
			),
		},
		{
			title: 'Connected Network Profile',
			dataIndex: 'connectedNetworkProfileId',
			key: 'connectedNetworkProfileId',
			render: (text, row) => (
				<span>
					{row?.connectedNetworkProfileId != ''
						? getNetworkProfileName(row.connectedNetworkProfileId)
						: 'Not selected'}
				</span>
			),
		},
		{
			title: 'Port Speed',
			dataIndex: 'portSpeed',
			key: 'portSpeed',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, row) => (
				<span>
					<Badge color={row['status'] == 'READY' ? 'green' : 'red'} text={row['status']} />
				</span>
			),
		},
	]
	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = { ...data }
		newData['peers'][index][key] = valueTemp
		setdata(newData)
	}

	const onClose = () => {
		setVisible(false)
		globalContext.updateState('glusterCreateAndUpdatePopup', null)
		setdata({})
	}

	useEffect(() => {
		setdata(globalContext.state.glusterCreateAndUpdatePopup)
	}, [globalContext.state.glusterCreateAndUpdatePopup])

	const getGlusters = async () => {
		let res = await dataService.getGlusters()
		if (res.success) {
			dataContext.updateState('getGlusters', res.data)
		}
	}

	const createGluster = async () => {
		let sendData = { ...data, clusterId: globalContext.state.params.clusterID }
		// TODO clusterId her bir peera eklenecek
		// sendData[0]['peers'] = sendData['peers']
		delete sendData['0']
		let res = await dataService.createGluster(sendData)
		if (res.success) {
			onClose()

			globalContext.Snackbar(`Create [${res.data.name}] gluster operation started successfully`, 'success')
			getGlusters()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const updateGluster = async () => {
		let sendData = { ...data }
		// TODO clusterId her bir peera eklenecek
		// sendData[0]['peers'] = sendData['peers']
		delete sendData['0']
		let res = await dataService.updateGluster(sendData)
		if (res.success) {
			onClose()

			globalContext.Snackbar(`Update [${res.data.name}] gluster operation started successfully`, 'success')
			getGlusters()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const onChange = (key) => {}

	return (
		<Modal
			title={data?.createOrUpdate ? 'Update Gluster Cluster' : 'New Gluster Cluster'}
			className=""
			width={1170}
			visible={globalContext.state.glusterCreateAndUpdatePopup || false}
			closeIcon={<CloseCircleOutlined />}
			footer={null}
			onCancel={onClose}>
			<Row style={{ marginLeft: 16, marginRight: 16 }}>
				{data?.peers?.map((node, index) => (
					<div style={{ width: '100%', display: 'flex', margin: '12px 16px', justifyContent: 'space-between' }}>
						<span>{node.name}</span>
						<Radio.Group
							onChange={(e) => handleChange(e, 'role', index)}
							value={node?.role}
							id="glusterCreateandUpdateRadio">
							<Radio value={'compute'}>Compute resource</Radio>
							<Radio value={'storage'}>Storage</Radio>
							<Radio value={'both'}>Both</Radio>
						</Radio.Group>
					</div>
				))}
			</Row>
			<Row>
				<div className={classes.subBtns}>
					<Button onClick={onClose} small id="glusterCancel">
						Cancel
					</Button>
					{data?.id ? (
						<Button onClick={updateGluster} style={{ marginLeft: 8 }} type="primary" small id="glusterUpdate">
							Update
						</Button>
					) : (
						<Button
							onClick={createGluster}
							style={{ marginLeft: 8 }}
							type="primary"
							// disabled={`${command.checkValue(data).length ? 'true' : ''}`}
							small
							id="glusterCorfirmButton">
							Confirm
						</Button>
					)}
				</div>
			</Row>
		</Modal>
	)
}
