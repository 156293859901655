import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { InputNumber, Button, Switch, Select, Tooltip } from 'antd'
import dataService from '../../service/dataService'
import { vmStorageCommon } from 'screens/Commons/VmStorage'

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		height: '100%',
		position: 'relative',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		position: 'relative',
		bottom: 20,
		width: '100%',
		textAlign: 'right',
		right: 4,
		alignItems: 'end',
		marginTop: 'auto',
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	form: {
		'& .MuiTextField-root': {
			width: '100%',
		},
	},
	storageContainer: {
		overflowY: 'auto',
		maxHeight: '65%',
	},
	storageRow: {
		display: 'flex',
		width: '100%',
		padding: '5px 0',
		alignItems: 'center',
	},
	hash: {
		width: '7%',
		minWidth: 35,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		marginLeft: '10px',
		paddingLeft: 5,
		paddingRight: 5,
	},
	owner: {
		width: '12%',
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		borderLeft: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 10,
		paddingRight: 10,
	},
	sharedSto: {
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		width: '20%',
		paddingLeft: 10,
		paddingRight: 10,
	},
	sto: {
		width: '20%',
		minWidth: 100,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 10,
		paddingRight: 10,
		'& .ant-input-number-group-wrapper': {
			width: '100%',
			'& .ant-input-number': {
				borderRadius: '6px 0px 0px 6px!important',
			},
			'& .ant-input-number-group-addon': {
				borderRadius: '0 6px 6px 0!important',
			},
		},
	},
	th: {
		width: '10%',
		minWidth: 100,
		paddingLeft: 10,
		paddingRight: 10,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	bus: {
		width: '12%',
		minWidth: 100,
		paddingLeft: 10,
		paddingRight: 10,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	sha: {
		width: '8%',
		minWidth: 80,
		paddingLeft: 10,
		paddingRight: 10,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	act: {
		width: '10%',
		minWidth: 60,
		paddingLeft: 10,
		paddingRight: 10,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
}))

export default function Storage({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const { filterTextMapping } = vmStorageCommon()
	const classes = useStyles()
	const [sharedStorages, setSharedStorages] = useState([])
	const [data, setData] = useState(
		modalData.storage || [
			{
				clusterId: modalData.clusterId,
				storagePoolId: modalData.storagePoolId,
				size: '1',
				thinProvisioning: true,
				bus: 'virtio',
				shareable: false,
				target: '',
				isShared: false,
				source: '',
			},
		]
	)

	useEffect(() => {
		dataService
			.getVmSharedStorage(modalData.nodeId)
			.then((response) => {
				if (response.success) {
					setSharedStorages(response.data)
				} else {
					setSharedStorages([])
				}
			})
			.catch((error) => {
				console.error('Error fetching disks:', error)
				setSharedStorages([])
			})
	}, [modalData.nodeId])

	// Initial setting of isShared based on shareable and bus
	useEffect(() => {
		const updatedData = data.map((item) => ({
			...item,
			isShared:
				item.shareable &&
				item.bus === 'ide' &&
				!item?.source?.includes('/' + modalData?.name + '-shareable/') &&
				item?.source != '',
		}))
		setData(updatedData)
	}, [])

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		newData[index][key] = key === 'size' ? (valueTemp ? valueTemp.toString() : '1') : valueTemp

		if (typeof valueTemp === 'string' && valueTemp.includes('-hd')) {
			let selectedSharedStorage = sharedStorages.find((item) => item.name === valueTemp)
			if (selectedSharedStorage) {
				newData[index] = { ...selectedSharedStorage, isShared: true, target: '' } // Kopya oluşturarak tüm değerleri değiştirme
			}
		}

		if (key === 'bus' && valueTemp !== 'ide') {
			newData[index].shareable = false
			newData[index].thinProvisioning = true
		}
		if (key === 'shareable' && valueTemp == true) {
			newData[index].bus = 'ide'
			newData[index].thinProvisioning = false
		}

		setData(newData)
		modalDataChange({ storage: newData })
	}

	const deleteButtonCredentialsControl = (item) => {
		return (
			(modalData?.state === 'running' && item?.bus === 'sata') ||
			(modalData?.state === 'running' && item?.bus === 'ide') ||
			(!item?.isShared && item?.shareable && item?.target != '')
		)
	}

	const nextStep = () => {
		let newData = data.filter((item) => item.size)
		let sanitizedData = newData.map(({ status, ...rest }) => rest)
		modalDataChange({ storage: sanitizedData })
		next()
	}

	const add = (isShared) => {
		let newData = [...data]
		if (isShared) {
			newData.push({
				id: '',
				clusterId: modalData.clusterId,
				storagePoolId: modalData.storagePoolId,
				size: '1',
				thinProvisioning: true,
				bus: 'ide',
				shareable: true,
				isShared: true,
				target: '',
				source: '',
			})
		} else {
			newData.push({
				id: '',
				clusterId: modalData.clusterId,
				storagePoolId: modalData.storagePoolId,
				size: '1',
				thinProvisioning: true,
				bus: 'virtio',
				shareable: false,
				isShared: false,
				target: '',
				source: '',
			})
		}

		setData(newData)
		modalDataChange({ storage: newData })
	}

	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem !== index)
		setData(newData)
		modalDataChange({ storage: newData })
	}

	const renderTooltipContent = (vp) => (
		<div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
			<p style={{ marginBottom: '0px' }}>
				{vp?.name} Size: {vp.size} GB
			</p>
		</div>
	)

	return (
		<div className={classes.root}>
			<div className={classes.storageContainer}>
				<div className={classes.storageRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
					<span className={classes.owner} style={{ paddingLeft: '5px' }}>
						Owner
					</span>
					<span className={classes.sharedSto} style={{ paddingLeft: '5px' }}>
						Shared Disk
					</span>
					<span className={classes.sto}>Capacity</span>
					<span className={classes.th}>Provisioning</span>
					<span className={classes.bus}>Bus</span>
					<span className={classes.hash}>Target</span>
					<span className={classes.sha}>Shareable</span>
					<span className={classes.act}>Action</span>
				</div>
				{data.map((item, index) => (
					<div className={classes.storageRow} key={index}>
						<div className={classes.owner}>
							<span>{item.isShared ? 'Shared' : item.shareable ? 'Sharer' : 'Dedicated'}</span>
						</div>
						<div className={classes.sharedSto}>
							{item.isShared ? (
								<Select
									placeholder="Select disk"
									value={item.name}
									onChange={(e) => handleChange(e, 'name', index)}
									className="fullW"
									id="name"
									showSearch={true}
									optionFilterProp="label"
									disabled={!item.isShared || item?.target != ''}>
									{sharedStorages.map((storage) => (
										<Select.Option
											key={storage.name}
											value={storage.name}
											label={storage.name}
											disabled={storage.name.includes(modalData?.name)}>
											<Tooltip title={renderTooltipContent(storage)} placement="rightTop">
												{storage.name}
											</Tooltip>
										</Select.Option>
									))}
								</Select>
							) : (
								''
							)}
						</div>

						<div className={classes.sto}>
							<InputNumber
								placeholder="Enter here"
								disabled={item.bus === 'ide' && item.isShared}
								value={parseInt(item?.size) || '1'}
								min={1}
								onChange={(e) => handleChange(e, 'size', index)}
								addonAfter="GB"
							/>
						</div>
						<div className={classes.th} style={{ textAlign: 'center' }}>
							{item.bus === 'ide' && item.shareable ? (
								''
							) : (
								<Switch
									className="vmStorageThin"
									disabled={(item.bus === 'ide' && item.shareable) || item?.target != ''}
									checked={item?.thinProvisioning}
									checkedChildren="Thin"
									unCheckedChildren="Thick"
									onChange={(e) => handleChange(e, 'thinProvisioning', index)}
									name="thinProvisioning"
									color="primary"
								/>
							)}
						</div>
						<div className={classes.bus}>
							<Select
								disabled={
									(modalData?.createOrUpdate === 'update' && item.id !== '') ||
									(item.bus === 'ide' && item.isShared)
								}
								value={item?.bus || 'virtio'}
								className="fullW"
								onChange={(e) => handleChange(e, 'bus', index)}
								id="bus">
								<Select.Option value="virtio">{filterTextMapping['virtio']}</Select.Option>
								<Select.Option
									value="sata"
									disabled={modalData?.state !== 'shutdown' && modalData?.createOrUpdate === 'update'}>
									{filterTextMapping['sata']}
								</Select.Option>
								<Select.Option
									value="ide"
									disabled={
										(modalData?.state !== 'shutdown' && modalData?.createOrUpdate === 'update') ||
										modalData?.bootLoader === 'uefi'
									}>
									{filterTextMapping['ide']}
								</Select.Option>
								<Select.Option value="scsi">{filterTextMapping['scsi']}</Select.Option>
							</Select>
						</div>
						<div className={classes.hash}>{item?.target}</div>
						<div className={classes.sha} style={{ textAlign: 'center' }}>
							<Switch
								className="shareable"
								checked={item?.shareable}
								onChange={(e) => handleChange(e, 'shareable', index)}
								name="shareable"
								color="primary"
								disabled={
									(item.bus === 'ide' && item.isShared) ||
									modalData?.state === 'running' ||
									item?.target !== '' ||
									modalData?.bootLoader === 'uefi'
								}
							/>
						</div>
						<div className={classes.act}>
							<span
								onClick={deleteButtonCredentialsControl(item) ? null : () => del(index)}
								style={{
									color: deleteButtonCredentialsControl(item) ? '#A9A9A9' : '#0f427a',
									cursor: deleteButtonCredentialsControl(item) ? 'not-allowed' : 'pointer',
									pointerEvents: deleteButtonCredentialsControl(item) ? 'none' : 'auto',
								}}
								className="vmStorageDelete">
								{item.isShared ? 'Detach' : 'Delete'}
							</span>
						</div>
					</div>
				))}
			</div>
			<div style={{ marginTop: 12, marginBottom: 12 }}>
				<Button
					// block
					onClick={() => add(false)}
					style={{ border: '1px dashed #D9D9D9', width: '50%' }}
					className="vmStorageAdd">
					+ Add New Dedicated / New Shareable Storage
				</Button>
				<Button
					// block
					onClick={() => add(true)}
					style={{ border: '1px dashed #D9D9D9', width: '50%' }}
					disabled={modalData?.bootLoader === 'uefi' || modalData?.state === 'running'}
					className="vmStorageAdd">
					+ Add Existing Shareable Storage
				</Button>
			</div>
			<div className={classes.footer}>
				{data?.[0]?.isShared || data?.[0]?.shareable ? (
					<div>
						<span style={{ color: 'red' }}>First Storage can not be shareable/shared</span>
					</div>
				) : (
					''
				)}
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={
						data?.length === 0 ||
						data?.[0]?.isShared ||
						data?.some((vmStorage) => vmStorage?.isShared && vmStorage?.source == '')
					}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
