import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import AddressPool from './AddressPool'
import Dhcp from './Dhcp'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 12,
		minHeight: 300,
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
	hide: {
		display: 'none',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('addressPoolPopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata({})
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			if (globalContext.state.addressPoolPopup) {
				setdata(globalContext.state.addressPoolPopup)
			} else {
				setdata({})
			}
		}
	}, [globalContext.state.addressPoolPopup])

	const createAddressPool = async () => {
		let sendData = { ...data }
		let nsArr = sendData?.nameServers?.split(',')
		sendData['nameServers'] = nsArr?.filter((i) => i != '') || []
		sendData.clusterId = dataContext?.state.list[0].id
		sendData.poolType = 'public'
		let res = await dataService.createAddressPool(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(`Create [${res.data.name}] operation started  successfully`, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateAddressPool = async () => {
		let sendData = { ...data }
		if (sendData?.['nameServers']) {
			if (typeof sendData['nameServers'] == 'string') {
				let nsArr = sendData?.nameServers?.split(',')
				sendData['nameServers'] = nsArr?.filter((i) => i != '') || []
			}
		}
		sendData.clusterId = dataContext?.state.list[0].id
		delete sendData.status
		let res = await dataService.updateAddressPool(sendData)
		if (res) {
			if (res.success) {
				handleClose()
				globalContext.Snackbar(`Update [${res.data.name}] address pool operation started successfully`, 'success')
				handleClose()
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
		setSelectedMenuMax(selectedMenu - 1)
	}

	let selectedMenuList = [
		<AddressPool modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Dhcp
			createAddressPool={createAddressPool}
			updateAddressPool={updateAddressPool}
			next={next}
			prev={prev}
			modalData={data}
			modalDataChange={handleChange}
		/>,
	]

	return (
		<div>
			<Modal
				title={
					globalContext.state.addressPoolPopup?.createOrUpdate == 'update'
						? 'Update Address Pool'
						: 'Create Address Pool'
				}
				className="stepModal"
				width={950}
				visible={globalContext.state.addressPoolPopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={8}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									} `}>
									1- Network
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									} `}>
									2- IP Ranges
								</li>
							</ul>
						</div>
					</Col>
					<Col span={16} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
