import React from 'react'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Menu, Item } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useGlobalContext } from 'components'

export default function NodeContextMenu() {
	const globalContext = useGlobalContext()

	const TenantVmPopup = ({ event, props }) => {
		globalContext.updateState('tenantVmPopup', {
			createOrUpdate: 'create',
			tenant: props,
			clusterId: props.clusterId,
		})
	}

	const editTenant = ({ event, props }) => {
		globalContext.updateState('tenantPopup', {
			createOrUpdate: 'update',
			...props,
		})
	}

	const DeletePopup = async ({ event, props }) => {
		globalContext.updateState('deletePopup', props)
	}

	return (
		<>
			<Menu id={'tenant'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Tenant Actions
				</div>
				<hr />
				<Item onClick={TenantVmPopup} id="vmCreate">
					<PlusOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Virtual Machine
				</Item>
				<hr />
				<Item onClick={editTenant} id="tenantEdit">
					<EditOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Edit Tenant
				</Item>
				<Item onClick={DeletePopup} id="tenantDelete">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete Tenant
				</Item>
			</Menu>
		</>
	)
}
