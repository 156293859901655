import React, { useState } from 'react'
import { command } from '../../components'
import { createUseStyles } from 'react-jss'
import { InputNumber, Button, Switch } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '70vh',
		position: 'relative',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		position: 'relative',
		bottom: 20,
		width: '100%',
		textAlign: 'right',
		right: 4,
		alignItems: 'end',
		marginTop: 'auto',
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	form: {
		'& .MuiTextField-root': {
			width: '100%',
		},
	},
	storageContainer: {
		overflowY: 'auto',
		height: '80%',
	},
	storageRow: {
		display: 'flex',
		width: '95%',
		padding: '5px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	sto: {
		width: '55%',
		minWidth: 350,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 16,
		paddingRight: 16,
		'& .ant-input-number-group-wrapper': {
			width: '100%',
			'& .ant-input-number': {
				borderRadius: '6px 0px 0px 6px!important',
			},
			'& .ant-input-number-group-addon': {
				borderRadius: '0 6px 6px 0!important',
			},
		},
	},
	th: {
		width: '20%',
		minWidth: 100,
		paddingLeft: 12,
		paddingRight: 12,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	act: {
		width: '20%',
		minWidth: 120,
		paddingLeft: 12,
		paddingRight: 12,
		'& button': {
			width: '100%',
		},
	},
}))

export default function Storage({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const [data, setdata] = useState(
		modalData.storage || [
			{
				clusterId: modalData.clusterId,
				storagePoolId: modalData.storagePoolId,
				size: '1',
				thinProvisioning: true,
			},
		]
	)

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		newData[index][key] = key == 'size' ? (valueTemp ? valueTemp.toString() : '1') : valueTemp
		let newData2 = newData.filter((item) => item.size)
		setdata(newData)
		modalDataChange({ storage: newData2 })
	}

	const nextStep = () => {
		let newData = data.filter((item) => item.size)
		modalDataChange({ storage: newData })
		next()
	}

	const add = () => {
		let newData = [...data]
		newData.push({
			clusterId: modalData.clusterId,
			storagePoolId: modalData.storagePoolId,
			size: '1',
			thinProvisioning: true,
		})
		setdata(newData)
		modalDataChange({ storage: newData })
	}

	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata(newData)
		modalDataChange({ storage: newData })
	}

	return (
		<div className={classes.root}>
			<div className={classes.storageContainer}>
				<div className={classes.storageRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
					<span className={classes.hash}>#</span>
					<span className={classes.sto}>
						<span className="requiredHint">*</span>Storage (GB)
					</span>
					<span className={classes.th}>
						<span className="requiredHint">*</span>Thin
					</span>
					<span className={classes.th}>Target</span>
					<span className={classes.act}>Action</span>
				</div>
				{data.map((item, index) => (
					<div className={classes.storageRow}>
						<span className={classes.hash}>{index + 1}</span>
						<div className={classes.sto}>
							<InputNumber
								className="vmStorageSize"
								placeholder="Enter here"
								value={parseInt(item?.size) || '1'}
								min={1}
								onChange={(e) => handleChange(e, 'size', index)}
								addonAfter="GB"
							/>
						</div>
						<div className={classes.th}>
							<Switch
								className="vmStorageThin"
								checked={item?.thinProvisioning}
								onChange={(e) => handleChange(e, 'thinProvisioning', index)}
								name="thinProvisioning"
								color="primary"
							/>
						</div>
						<div className={classes.th}>{item?.target}</div>
						<div className={classes.act}>
							<span
								onClick={() => del(index)}
								style={{ color: '#0f427a', cursor: 'pointer' }}
								className="vmStorageDelete">
								Delete
							</span>
						</div>
					</div>
				))}
			</div>
			<Button
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="vmStorageAdd">
				+ Add Row
			</Button>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.length == 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
