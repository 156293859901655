import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const vmProps = globalContext.state.takeSnapshotPopup
	const inputRef = React.useRef()
	const dataContext = useDataContext()
	const [data, setdata] = useState({
		name: '',
	})

	/*useEffect(() => {
		if(globalContext.state.takeSnapshotPopup){
			setdata({name: '', state: null});
		}
	}, [globalContext.state.takeSnapshotPopup]);
	 */

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}
	const handleClose = () => {
		globalContext.updateState('takeSnapshotPopup', null)
		setdata({
			name: '',
		})
	}

	const takeSnapShot = async () => {
		let sendData = {
			name: data.name,
			vmId: globalContext.state.takeSnapshotPopup.id,
			state: data.state,
		}

		if (command.checkValue(sendData).length > 0) {
			globalContext.Snackbar('Validation error.', 'error')
			return
		}

		let res = await dataService.takeSnapShot(sendData)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(`Take [${res.data.name}] snapshot operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	return (
		<div>
			<Drawer
				title={'Take Snapshot for ' + vmProps?.name}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.takeSnapshotPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => takeSnapShot()}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={command.checkValue(data).length > 0}
							small
							id="takeSnapshotConfirm">
							Confirm
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Vm Snapshot Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="vmSnapshotName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>State
					</label>
					<Select
						value={data?.state}
						onChange={handleChange('state')}
						placeholder="State"
						className="fullW"
						id="takeSnapshotStateSelect">
						<Option
							value={'LIVE_SNAPSHOT'}
							disabled={globalContext?.state?.takeSnapshotPopup?.state == 'shutdown'}>
							Live Snapshot
						</Option>
						<Option value={'OFFLINE_SNAPSHOT'}>Offline Snapshot</Option>
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
