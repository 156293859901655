// import React, { useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
// import { command, useDataContext, useGlobalContext } from '../../components'

// import InputLabel from '@material-ui/core/InputLabel'
// import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from '@material-ui/core/FormControl'
// import Select from '@material-ui/core/Select'
// import ButtonGroup from '@material-ui/core/ButtonGroup'

import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

import dataService from 'service/dataService'

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))
export default function Name({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const globalContext = useGlobalContext()
	const classes = useStyles()
	const [data, setdata] = useState({
		storage: modalData.storage || '',
	})
	const [storageUnits, setStorageUnits] = useState([])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	async function getAllExternalStorageUnits() {
		let res = await dataService.externalBackupGetStorageUnits('veritas')
		if (res.success) {
			setStorageUnits(res.data)
		} else {
			globalContext.Snackbar(res?.errorMessage, 'error')
		}
	}

	useEffect(() => {
		getAllExternalStorageUnits()
	}, [])

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Storage Units
				</label>
				<Select value={data?.storage} onChange={handleChange('storage')} className="fullW" id="storageUnit">
					{storageUnits?.map((item) => (
						<Option key={item} value={item}>
							<span style={{ fontWeight: 'bold' }}>{item}</span>
						</Option>
					))}
				</Select>
			</div>

			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data.storage == ''}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
