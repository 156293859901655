import { createUseStyles } from 'react-jss'
import { Tooltip } from 'antd'
import 'react-contexify/dist/ReactContexify.css'
import { useDataContext } from 'components'
import { ReactComponent as HaLogo } from 'assets/img/hacircle.svg'
import { ReactComponent as MaintenanceLogo } from 'assets/img/maintenance.svg'

const useStyles = createUseStyles({
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '3px 0',
		height: 40,
	},
	TreeRightIcons: {
		display: 'flex',
		// justifyContent: 'space-around',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	TreeLeftIcons: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
	statusIcon: {
		width: 16,
		bottom: '-5px',
		right: 0,
		opacity: 0.75,
	},
	haWorkingIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		right: 0,
		fill: 'rgb(28, 176, 132)',
		stroke: 'rgb(28, 176, 132)',
		color: 'rgb(28, 176, 132)',
	},
	haCriticalIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		right: 0,
		fill: '#9f8728',
		stroke: '#9f8728',
		color: '#9f8728',
	},
	haBrokenIcon: {
		width: 15,
		height: 15,
		marginRight: 6,
		// position: 'absolute',
		right: 0,
		fill: 'rgb(194, 88, 88)',
		stroke: 'rgb(194, 88, 88)',
		color: 'rgb(194, 88, 88)',
	},
})

export default function Icons({ el }) {
	const dataContext = useDataContext()
	const classes = useStyles()

	const parseHaStatus = (el) => {
		if (dataContext?.state?.list[0]?.highAvailability == 'true') {
			switch (el?.type) {
				case 'cluster':
					switch (el?.highAvailabilityStatus) {
						case 'failed':
							return (
								<Tooltip title="HighAvailability has failed, check it!" placement="top">
									<HaLogo className={classes.haBrokenIcon} />
								</Tooltip>
							)
						case 'critical':
							return (
								<Tooltip title={`HA Critical with ${el?.highAvailabilityFt} tolerance count`} placement="top">
									<HaLogo className={classes.haCriticalIcon} />
								</Tooltip>
							)
						case 'complete':
							return (
								<Tooltip title={`HA Active with ${el?.highAvailabilityFt} tolerance count`} placement="top">
									<HaLogo className={classes.haWorkingIcon} />
								</Tooltip>
							)
						default:
							return null
					}
				case 'node':
					switch (el?.highAvailability) {
						case 'true':
							// return  <Tooltip title="Node is part of HighAvailability" placement="top">
							// 			<HaLogo className={classes.haWorkingIcon} />
							// 		</Tooltip>
							return null
						case 'false':
							return (
								<Tooltip title="Node is not part of HighAvailability!" placement="top">
									<HaLogo className={classes.haBrokenIcon} />
								</Tooltip>
							)
						default:
							return null
					}
				case 'tenant':
					return null
			}
		}

		return null
	}

	const parseMaintenanceStatus = (el) => {
		switch (el?.type) {
			case 'node':
				if (el?.maintenanceMode && el?.maintenanceMode != 'enabled') {
					return null
				}

				switch (el?.maintenanceStatus) {
					case 'active':
						return (
							<Tooltip title="Maintenance Mode is Active" placement="top">
								<MaintenanceLogo className={classes.haWorkingIcon} />
								{/* <ToolOutlined
                                        style={{
                                            color: 'rgb(28, 176, 132)',
                                            paddingRight: 8,
                                        }}
                                    /> */}
							</Tooltip>
						)
					case 'waiting':
						return (
							<Tooltip title="Maintenance Mode is waiting for manual action" placement="top">
								<MaintenanceLogo className={classes.haCriticalIcon} />
								{/* <ToolOutlined
                                    style={{
                                        color: 'rgb(28, 176, 132)',
                                        paddingRight: 8,
                                    }}
                                    /> */}
							</Tooltip>
						)
					case 'off':
						return (
							<Tooltip title="Maintenance Mode is inactive" placement="top">
								<MaintenanceLogo className={classes.haBrokenIcon} />
							</Tooltip>
						)
					default:
						return null
				}
			case 'cluster':
				return null
			case 'tenant':
				return null
		}

		return null
	}

	return (
		<>
			{parseHaStatus(el)} {parseMaintenanceStatus(el)}
		</>
	)
}
