import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [networkProfilesList, setnetworkProfilesList] = useState([])
	const [networksList, setnetworksList] = useState([])
	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('editNetworkInterfacePopup', null)
	}

	const updateVmNetworkInterface = async () => {
		delete data.status
		let res = await dataService.updateVmNetworkInterface(data)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(
				`Updata [${res.data.name}] vm network interface operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const fetch = async () => {
		let responseNetworkProfiles = await dataService.getNetworkProfiles()
		if (responseNetworkProfiles.success) {
			setnetworkProfilesList(responseNetworkProfiles.data)
			dataContext.updateState('getNetworkProfiles', responseNetworkProfiles.data)
		} else {
			globalContext.Snackbar(responseNetworkProfiles.errorMessage, 'error')
			setnetworkProfilesList([])
			dataContext.updateState('getNetworkProfiles', [])
		}

		let responseNetworks = await dataService.getNetworks()
		if (responseNetworks.success) {
			setnetworksList(responseNetworks.data)
			dataContext.updateState('getNetworks', responseNetworks.data)
		} else {
			globalContext.Snackbar(responseNetworks.errorMessage, 'error')
			setnetworksList([])
			dataContext.updateState('getNetworks', [])
		}
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			globalContext.state.editNetworkInterfacePopup && fetch()
			setdata(globalContext.state.editNetworkInterfacePopup)
		}
	}, [globalContext.state.editNetworkInterfacePopup])

	return (
		<div>
			<Drawer
				title={'Update Network Interface: ' + globalContext.state.editNetworkInterfacePopup?.name}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.editNetworkInterfacePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="networkInterfaceCancel">
							Cancel
						</Button>
						<Button
							onClick={() => updateVmNetworkInterface()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="networkInterfaceUpdate">
							Update
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network Profile
					</label>
					<Select
						value={data?.connectedNetworkProfileId}
						onChange={handleChange('connectedNetworkProfileId')}
						placeholder="Network Profile"
						disabled={data?.status != 'READY'}
						className="fullW"
						id="selectNetworkProfile">
						{networkProfilesList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network
					</label>
					<Select
						value={data?.connectedNetworkId}
						onChange={handleChange('connectedNetworkId')}
						placeholder="Network Profile"
						disabled={data?.status != 'READY'}
						className="fullW"
						id="selectConnectedNetworkId">
						{networksList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
