import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import ComputeResource from './ComputeResource'
import Selection from './Selection'
import Storage from './Storage'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 12,
		minHeight: 300,
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
	hide: {
		display: 'none',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('VMMigratePopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata({})
	}
	useEffect(() => {
		if (globalContext.state.VMMigratePopup) {
			setdata(globalContext.state.VMMigratePopup)
		}
	}, [globalContext.state.VMMigratePopup])

	const migrateVM = async () => {
		let isColdCheck = data.coldCheck

		if (!data.devices) {
			data.devices = []
			let vmDevice = {
				deviceType: 'BOOT_DISK',
				vmId: data.id,
				isoPath: data.guestOs,
			}
			data.devices.push(vmDevice)
		}
		delete data.status
		delete data.state
		delete data.createOrUpdate
		delete data.coldCheck
		if (data.selection == 'resource') {
			data.storagePoolId = data?.['storage']?.[0]?.['storagePoolId']
		}
		data.storage.forEach((value, key) => {
			data['storage'][key]['storagePoolId'] = data.storagePoolId
		})
		let res = await dataService.migrateVm(data.id, data, isColdCheck)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		let minusP = 1
		if (data.selection == 'resource') {
			if (selectedMenu == 3) {
				minusP = 2
			}
		} else if (data.selection == 'storage') {
			if (selectedMenu == 2) {
				minusP = 2
			}
		}

		setSelectedMenu(selectedMenu - minusP)
		setSelectedMenuMax(selectedMenu - minusP)
	}

	let selectedMenuList = [
		<Selection
			modalDataChange={handleChange}
			next={next}
			prev={prev}
			setSelectedMenu={setSelectedMenu}
			setSelectedMenuMax={setSelectedMenuMax}
			modalData={data}
		/>,
		<ComputeResource
			modalDataChange={handleChange}
			next={next}
			prev={prev}
			setSelectedMenu={setSelectedMenu}
			setSelectedMenuMax={setSelectedMenuMax}
			modalData={data}
		/>,
		<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Complete migrateVM={migrateVM} next={next} prev={prev} modalData={data} />,
	]

	return (
		<div>
			<Modal
				title={'Migrate Virtual Machine: ' + data?.name}
				className="stepModal"
				width={950}
				visible={globalContext.state.VMMigratePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={8}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									} `}>
									1- Select a migration type
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									} ${data.selection == 'storage' && classes.hide} `}>
									2- Select a compute resource
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									} ${data.selection == 'resource' && classes.hide}`}>
									{data.selection == 'storage' ? '2' : '3'}- Select storage
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									}`}>
									{data.selection == 'both' ? '4' : '3'}- Complete
								</li>
							</ul>
						</div>
					</Col>
					<Col span={16} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
