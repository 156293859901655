/*eslint-disable-next-line no-extend-native*/
String.prototype.gunAyYil = function () {
	var d = new Date(this)
	//gun ay yıl
	var yil = d.getFullYear()
	var ay = d.getMonth() + 1
	var gun = d.getDate()
	//format "2020-04-20"
	ay = '0' + ay
	gun = '0' + gun
	return `${gun.substr(-2)}-${ay.substr(-2)}-${yil}`
}
/*eslint-disable-next-line no-extend-native*/
Date.prototype.YilAyGun = function () {
	var yil = this.getFullYear()
	var ay = this.getMonth() + 1
	var gun = this.getDate()
	//format "2020-04-20"
	ay = '0' + ay
	gun = '0' + gun
	return `${yil}-${ay.substr(-2)}-${gun.substr(-2)}`
}

/*eslint-disable-next-line no-extend-native*/
Number.prototype.ikiyeBol = function () {
	return this.valueOf() / 2
}

/*eslint-disable-next-line no-extend-native*/
Date.prototype.monthDays = function () {
	var d = new Date(this.getFullYear(), this.getMonth() + 1, 0)
	return d.getDate()
}

/*eslint-disable-next-line no-extend-native*/
Number.prototype.nFormatter = function (digits) {
	// return this.valueOf() / 2;
	//digits hangi basamakta yuvarliyacagın
	// digit 0  hiçbirşey yapmaz
	// digit 1 binlige yuvarlar
	// digit 2 milyona yuvarlar
	var si = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	]
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	var i
	for (i = si.length - 1; i > 0; i--) {
		if (this.valueOf() >= si[i].value) {
			break
		}
	}
	return (this.valueOf() / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

/*eslint-disable-next-line no-extend-native*/
String.prototype.capitalize = function () {
	let stringData = this.toString()
	if (typeof stringData !== 'string') return ''
	return this.charAt(0).toUpperCase() + this.slice(1)
}

/*eslint-disable-next-line no-extend-native*/
String.prototype.capitalizeTR = function () {
	// Stringin ilk harfini Türkçe karakterlere uygun şekilde büyütür
	let stringData = this.toString()
	if (typeof stringData !== 'string') return ''
	return this.charAt(0).toLocaleUpperCase('tr-TR') + this.slice(1)
}

/*eslint-disable-next-line no-extend-native*/
String.prototype.capitalizeAll = function () {
	let stringData = this.toString()
	if (typeof stringData !== 'string') return ''
	return stringData
		.split(' ')
		.map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
		.join(' ')
}

/*eslint-disable-next-line no-extend-native*/
Array.prototype.arrayToString = function () {
	let temp = this
	let query = ''
	for (let index = 0; index < temp.length; index++) {
		query += `${temp[index]}`
		if (index < temp.length - 1) {
			query += `,`
		}
	}
	return query
}

export const checkValue = function (thistemp) {
	let temp = Object.keys(thistemp)
	let checkList = []
	for (let index = 0; index < temp.length; index++) {
		if (temp[index] == 'socket' || temp[index] == 'thread' || temp[index] == 'core') {
			continue
		}
		const element = thistemp[temp[index]]
		if (!element) {
			checkList.push(temp[index])
		}
	}
	return checkList
}

export const goTo = (event, obj, navigate) => {
	obj.type = obj['type'] || obj['@type']
	if (obj.type) {
		let link = `/${obj.type}/${obj.id}`
		switch (obj.type) {
			case 'cluster':
				link = `/cluster/${obj.id}`
				break
			case 'node':
				link = `/cluster/${obj.clusterId}/node/${obj.id}`
				break
			case 'vm':
				link = `/cluster/${obj.clusterId}/node/${obj.nodeId}/vm/${obj.id}`
				break
			case 'vmStoragePool':
				link = `/cluster/${obj.clusterId}/vmStoragePool/${obj.id}`
				break
			case 'network':
				link = `/cluster/${obj.clusterId}/network/${obj.id}`
				break
			case 'networkProfile':
				link = `/cluster/${obj.clusterId}/networkProfile/${obj.id}`
				break
			case 'backupProfile':
				link = `/cluster/${obj.clusterId}/backupProfile/${obj.id}`
				break
		}
		navigate(link)
	}
}

export const convertQuery = function (thistemp) {
	if (!thistemp) return ''
	let temp = Object.keys(thistemp)
	let query = '?'
	for (let index = 0; index < temp.length; index++) {
		const element = thistemp[temp[index]]

		if (element) {
			query += `${temp[index]}=${element}`
			if (index < temp.length - 1) {
				query += `&`
			}
		} else {
			console.warn('data boş', temp[index], element)
		}
	}
	return query
}

/*eslint-disable-next-line no-extend-native*/
//  Object.prototype.checkValue = function () {
//     let temp = Object.keys(this);
//     let checkList = [];
//     for (let index = 0; index < temp.length; index++) {
//        const element = this[temp[index]];
//        if (!element) {
//           checkList.push(temp[index]);
//        }
//     }
//     return checkList;
//  };

/*eslint-disable-next-line no-extend-native*/
Array.prototype.checkPermissionList = function (ComponentPermissionList) {
	let PermissionList = [...this]
	let tempPermissionListLength = PermissionList.length
	let tempComponentPermissionListlength = ComponentPermissionList.length
	if (tempComponentPermissionListlength == 0 || tempPermissionListLength == 0) return false

	let newPermissionList = [...new Set([...PermissionList, ...ComponentPermissionList])]
	let tempnewPermissionListlength = newPermissionList.length

	if (tempnewPermissionListlength < tempPermissionListLength + tempComponentPermissionListlength) return true

	return false
}
Array.prototype.checkStatusDataMessageKey = function (ComponentPermissionList) {
	let PermissionList = [...this]
	let tempPermissionListLength = PermissionList.length
	let tempComponentPermissionListlength = ComponentPermissionList.length
	if (tempComponentPermissionListlength == 0 || tempPermissionListLength == 0) return false

	let newPermissionList = [...new Set([...PermissionList, ...ComponentPermissionList])]
	let tempnewPermissionListlength = newPermissionList.length

	if (tempnewPermissionListlength < tempPermissionListLength + tempComponentPermissionListlength) return true

	return false
}

/*eslint-disable-next-line no-extend-native*/
String.prototype.convertTurkceGunAyYıl = function () {
	let stringData = this.toString()
	if (typeof stringData !== 'string') return ''

	let regex = /^\d{4}-\d{2}-\d{2}$/
	if (!regex.test(stringData)) return 'Tanımlanamıyor'

	var tarih = new Date(stringData)
	var gun = tarih.getDay()
	var ay = tarih.getMonth()
	var yil = tarih.getFullYear()
	var gun = tarih.getDate()

	var gunler = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi']
	var aylar = [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
	]
	return gun + ' ' + aylar[ay] + ' ' + yil
}

Date.prototype.convertTurkceGunAyYıl = function () {
	var tarih = this
	var gun = tarih.getDay()
	var ay = tarih.getMonth()
	var yil = tarih.getFullYear()
	var gun = tarih.getDate()

	var gunler = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi']
	var aylar = [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
	]
	return gun + ' ' + aylar[ay] + ' ' + yil
}

/*eslint-disable-next-line no-extend-native*/
String.prototype.convertAyYil = function () {
	let stringData = this.toString()
	if (typeof stringData !== 'string') return ''

	let regex = /^\d{4}-\d{2}-\d{2}$/
	if (!regex.test(stringData)) return 'Tanımlanamıyor'

	var tarih = new Date(stringData)
	var ay = tarih.getMonth()
	var yil = tarih.getFullYear()

	var aylar = [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
	]
	return aylar[ay] + ' ' + yil
}

/*eslint-disable-next-line no-extend-native*/
Date.prototype.convertAyYil = function () {
	var aylar = [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
	]
	return `${aylar[this.getMonth()]} ${this.getFullYear()}`
}

const months = [
	'Ocak',
	'Şubat',
	'Mart',
	'Nisan',
	'Mayıs',
	'Haziran',
	'Temmuz',
	'Ağustos',
	'Eylül',
	'Ekim',
	'Kasım',
	'Aralık',
]

/**
 * Used to subscribe to a single futures websocket endpoint
 * * @param  {string} endpoint - endpoint to connect to
 * @param {function} callback - the function to call when information is received
 * @param {object} params - Optional reconnect {boolean} (whether to reconnect on disconnect), openCallback {function}, id {string}
 * @return {WebSocket} - websocket reference
 *  @param {string} endpoint - endpoint to connect to
 * @param {function} callback - the function to call when information is received
 * @param {object} params - Optional reconnect {boolean} (whether to reconnect on disconnect), openCallback {function}, id {string}
 * @return {WebSocket} - websocket reference
 *    @param {string} endpoint - endpoint to connect to
 * @param {function} callback - the function to call when information is received
 * @param {object} params - Optional reconnect {boolean} (whether to reconnect on disconnect), openCallback {function}, id {string}
 * @return {WebSocket} - websocket reference
 *   @param {string} endpoint - endpoint to connect to
 * @param {function} callback - the function to call when information is received
 * @param {object} params - Optional reconnect {boolean} (whether to reconnect on disconnect), openCallback {function}, id {string}
 * @return {WebSocket} - websocket reference
 */
export const getMonth = (month) => {
	return months[month - 1]
}
