import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, InputNumber, Button, Switch } from 'antd'
const { Option } = Select
const { TextArea } = Input

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [storageList, setstorageList] = useState([])
	const [netSelect, setNetSelect] = useState('networkInterfaces')
	const [accessibleISOlist, setAccessibleISOlist] = useState([])
	const [nodeIp, setNodeIp] = useState('')
	const [data, setdata] = useState({
		name: '',
		nodeId: '',
		portBindings: [],
		memory: '1',
		cpu: '1',
		networkInterfaces: [{ connectedNetworkId: '', connectedNetworkProfileId: '', useDhcpClient: true }],
	})
	const intStr = ['memory', 'cpu']

	useEffect(() => {
		// refetch accessible pools
		let tempIsoList = []

		const accessiblePools = dataContext.state.getVmStoragePools?.filter((pool) =>
			pool.poolUnits.some((unit) => unit.nodeId === data?.nodeId)
		)

		const accessiblePoolIds = accessiblePools?.map((pool) => pool.id)

		dataContext?.state?.getContainerImages?.forEach((iso) => {
			if (accessiblePoolIds.includes(iso?.storagePoolId)) {
				tempIsoList = [...tempIsoList, iso]
			}
		})
		setAccessibleISOlist(tempIsoList)

		// refind mgmt ip
		let node = dataContext.state?.getNodes?.find((e) => e.id === data?.nodeId)

		if (typeof node?.managementIpAddress == 'string') {
			setNodeIp(node?.managementIpAddress)
		} else {
			setNodeIp('')
		}
	}, [data?.nodeId])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (intStr.includes(key)) {
			valueTemp = valueTemp?.toString()
		}
		setdata({ ...data, [key]: valueTemp ? valueTemp : valueTemp?.toString() })
	}

	const handleChangeNetworkInterface = (e, field, index) => {
		const newData = { ...data }
		let valueTemp = e?.target ? e.target.value : e
		newData.networkInterfaces[index][field] = valueTemp
		setdata(newData)
	}

	const handleChangePort = (e, key, index) => {
		const newData = { ...data }
		let valueTemp = e?.target ? e.target.value : e
		newData.portBindings[index][key] = valueTemp
		setdata(newData)
	}
	const del = (index) => {
		let newData = [...data.portBindings]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata({ ...data, portBindings: newData })
	}
	const add = () => {
		let newData = [...(data.portBindings || [])]
		newData.push({ nodePort: 0, containerPort: 0, protocol: 'tcp' })
		setdata({ ...data, portBindings: newData })
	}

	const addNetwork = () => {
		let newData = [...(data.networkInterfaces || [])]
		newData.push({ connectedNetworkId: '', connectedNetworkProfileId: '', useDhcpClient: true })
		setdata({ ...data, networkInterfaces: newData })
	}
	const delNetwork = (index) => {
		let newData = [...data.networkInterfaces]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata({ ...data, networkInterfaces: newData })
	}

	const handleChangeSelect = (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setNetSelect(valueTemp)
	}

	const handleClose = () => {
		globalContext.updateState('containerPopup', null)
	}

	const fetch = async () => {
		let responseNetworkProfiles = await dataService.getNetworkProfiles()
		if (responseNetworkProfiles.success) {
			dataContext.updateState('getNetworkProfiles', responseNetworkProfiles.data)
		} else {
			globalContext.Snackbar(responseNetworkProfiles.errorMessage, 'error')
			dataContext.updateState('getNetworkProfiles', [])
		}
		let responseNetworks = await dataService.getNetworks()
		if (responseNetworks.success) {
			dataContext.updateState('getNetworks', responseNetworks.data)
		} else {
			globalContext.Snackbar(responseNetworks.errorMessage, 'error')
			dataContext.updateState('getNetworks', [])
		}
	}

	useEffect(() => {
		if (globalContext.state.containerPopup) {
			if (globalContext.state.containerPopup.createOrUpdate == 'update') {
				setdata({
					...globalContext.state.containerPopup,
					entrypoint: globalContext.state.containerPopup.entrypoint?.join('\n'),
					command: globalContext.state.containerPopup.command?.join('\n'),
					environment: globalContext.state.containerPopup.environment?.join('\n'),
				})
				setNetSelect(
					globalContext.state.containerPopup?.networkDriverType != ''
						? globalContext.state.containerPopup?.networkDriverType
						: 'cni'
				)
			} else {
				setNetSelect('cni')
				setdata(globalContext.state.containerPopup)
			}
			fetch()
		}
	}, [globalContext.state.containerPopup])

	const setClusters = async () => {
		let res = await dataService.getClusters()
		if (res.success) {
			dataContext.updateState('list', res.data)
			var nodeArray = []
			if (res.data.length > 0) {
				res.data.forEach((singleCluster) => {
					if (typeof singleCluster.child != 'undefined') {
						nodeArray = nodeArray.concat(singleCluster.child)
					}
				})
			}
			dataContext.updateState('getNodes', nodeArray)
		} else {
			console.error(res.data)
		}
	}

	const createContainer = async () => {
		let sendData = { ...data, type: 'container', clusterId: dataContext?.state?.list[0]?.id }
		let multiLines = ['command', 'environment', 'entrypoint']
		multiLines.forEach((ml) => {
			if (sendData[ml]) {
				sendData[ml] = sendData[ml].split(/\r?\n/)
			} else {
				delete sendData[ml]
			}
		})
		if (netSelect === 'cni') {
			sendData.portBindings = []
		} else if (netSelect === 'node') {
			sendData.networkInterfaces = []
		} else {
			sendData.portBindings = []
			sendData.networkInterfaces = []
		}
		sendData.networkDriverType = netSelect
		let res = await dataService.createContainer(sendData)
		if (res.success) {
			setClusters()
			handleClose()
			globalContext.Snackbar(`Create [${res.data.name}] container operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateContainer = async () => {
		let sendData = { ...data }
		delete sendData.status
		delete sendData.createOrUpdate
		let multiLines = ['command', 'environment', 'entrypoint']
		multiLines.forEach((ml) => {
			if (sendData[ml]) {
				sendData[ml] = sendData[ml].split(/\r?\n/)
			} else {
				delete sendData[ml]
			}
		})
		if (netSelect === 'cni') {
			sendData.portBindings = []
		} else if (netSelect === 'node') {
			sendData.networkInterfaces = []
		} else {
			sendData.portBindings = []
			sendData.networkInterfaces = []
		}
		sendData.networkDriverType = netSelect
		let res = await dataService.updateContainer(sendData)
		if (res.success) {
			setClusters()
			handleClose()
			globalContext.Snackbar(`Edit [${res.data.name}] container operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<div>
			<Drawer
				title={
					globalContext?.state?.containerPopup?.createOrUpdate == 'update'
						? 'Update Container'
						: 'Create Container'
				}
				placement="right"
				onClose={handleClose}
				width={480}
				visible={globalContext.state.containerPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						{globalContext?.state?.containerPopup?.createOrUpdate == 'update' ? (
							<Button
								onClick={() => updateContainer()}
								style={{ marginLeft: 8 }}
								type="primary"
								// disabled={`${command.checkValue(data).length ? 'true' : ''}`}
								small
								id="editContainer">
								Edit
							</Button>
						) : (
							<Button
								onClick={() => createContainer()}
								style={{ marginLeft: 8 }}
								type="primary"
								// disabled={`${command.checkValue(data).length ? 'true' : ''}`}
								small
								id="createContainer">
								Submit
							</Button>
						)}
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Name
					</label>
					<Input
						placeholder="Enter here"
						value={data?.name}
						onChange={handleChange('name')}
						id="containerName"
						disabled={globalContext?.state?.containerPopup?.createOrUpdate == 'update'}
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Node
					</label>
					<Select
						value={data?.nodeId}
						disabled={globalContext?.state?.containerPopup?.createOrUpdate == 'update'}
						onChange={handleChange('nodeId')}
						className="fullW"
						id="containerPopupNodeName">
						{dataContext?.state?.list[0]?.child?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Container Image
					</label>
					<Select
						value={data?.image}
						onChange={handleChange('image')}
						disabled={globalContext?.state?.containerPopup?.createOrUpdate == 'update'}
						className="fullW"
						id="containerImage">
						{accessibleISOlist?.map((item) => (
							<Option value={item.absolutePath}>
								[{item.absolutePath.split('/').slice(3, 4)}] {item.name}{' '}
							</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>CPU
					</label>
					<InputNumber
						placeholder="Enter here"
						value={data?.cpu}
						min={1}
						onChange={handleChange('cpu')}
						className="fullW"
						id="containerCpu"
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Memory
					</label>
					<InputNumber
						placeholder="Enter here"
						value={data?.memory}
						min={1}
						onChange={handleChange('memory')}
						className="fullW"
						id="containerMemory"
						addonAfter="GB"
					/>
				</div>
				<div className="form-group-ant">
					<label>Entrypoint</label>
					<TextArea
						placeholder="/bin/ls"
						rows={3}
						value={data?.entrypoint}
						onChange={handleChange('entrypoint')}
						id="containerEntrypoint"
					/>
					<small>Leave empty to use the default in the specified container image</small>
				</div>
				<div className="form-group-ant">
					<label>Command</label>
					<TextArea
						placeholder="-alh&#10;/my/path"
						rows={3}
						value={data?.command}
						onChange={handleChange('command')}
						id="containerCommand"
					/>
					<small>Leave empty to use the default in the specified container image</small>
				</div>
				<div className="form-group-ant">
					<label>Environment Variables</label>
					<TextArea
						placeholder="KEY1=VALUE1&#10;KEY2=VALUE2"
						rows={3}
						id="environment"
						value={data?.environment}
						onChange={handleChange('environment')}
					/>
				</div>
				<div className="form-group-ant">
					<span>Connection Type : </span>
					<Select value={netSelect} onChange={handleChangeSelect}>
						<Option value={'cni'}>Network Interfaces</Option>
						<Option value={'node'}>Node Port Bindings</Option>
						<Option value={'none'}>None</Option>
					</Select>
				</div>
				<div className="form-group-ant">
					{netSelect === 'cni' ? (
						<div>
							<label>Network / Network Profile / DHCP Client</label>
							{data?.networkInterfaces?.map((p, index) => (
								<div
									key={index}
									style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', width: '100%' }}>
									<Select
										className="vmNetwork"
										value={p.connectedNetworkId}
										onChange={(e) => handleChangeNetworkInterface(e, 'connectedNetworkId', index)}
										style={{ width: '45%', marginTop: '5px' }}>
										{dataContext?.state?.getNetworks?.map((network) => (
											<Option value={network.id} key={network.id}>
												{network.name}
											</Option>
										))}
									</Select>
									<Select
										className="vmNetworkProfile"
										value={p.connectedNetworkProfileId}
										onChange={(e) => handleChangeNetworkInterface(e, 'connectedNetworkProfileId', index)}
										style={{ width: 'calc(45% - 55px)', margin: '5px 5px 0 0' }}>
										{dataContext?.state?.getNetworkProfiles?.map((profile) => (
											<Option value={profile.id} key={profile.id}>
												{profile.name}
											</Option>
										))}
									</Select>
									<Switch
										className="useDhcpClient"
										checked={p.useDhcpClient || true}
										disabled={true} // force dhcp client until manual ip feature is developed in frontend
										onChange={(e) => handleChange(e, 'useDhcpClient', index)}
										title="Use DHCP Client?"
									/>
									<span
										onClick={() => delNetwork(index)}
										style={{ color: '#0f427a', cursor: 'pointer', width: '50px', marginLeft: 8 }}>
										Delete
									</span>
								</div>
							))}
							<Button
								block
								onClick={() => addNetwork()}
								style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
								id="containerAddNetworkRow">
								+ Add Row
							</Button>
						</div>
					) : netSelect === 'node' ? (
						<div>
							<label>Node {nodeIp !== '' ? '( ' + nodeIp + ' )' : ''} Port Bindings</label>

							{data.portBindings?.map((p, index) => (
								<div
									key={index}
									style={{
										width: '100%',
										display: 'inline-flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginTop: 8,
									}}>
									<InputNumber
										placeholder="A node port is randomly decided"
										value={p?.nodePort}
										style={{ width: 'calc(50% - 50px)' }}
										id="nodePort"
										disabled={true}
										title="A node port is randomly decided"
									/>
									<InputNumber
										placeholder="e.g. 443"
										value={p?.containerPort}
										onChange={(e) => handleChangePort(e, 'containerPort', index)}
										min={1}
										max={65353}
										style={{ width: 'calc(50% - 50px)' }}
										id="containerPort"
									/>
									<Select value={p?.protocol} onChange={(e) => handleChangePort(e, 'protocol', index)}>
										<Option value={'tcp'} key={'tcp'}>
											TCP
										</Option>
										<Option value={'udp'} key={'udp'}>
											UDP
										</Option>
										<Option value={'both'} key={'both'}>
											Both
										</Option>
									</Select>
									<span
										onClick={() => del(index)}
										style={{ color: '#0f427a', cursor: 'pointer', width: '50px', marginLeft: 8 }}>
										Delete
									</span>
								</div>
							))}
							<Button
								block
								onClick={() => add()}
								style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
								id="containerAddRow">
								+ Add Row
							</Button>
						</div>
					) : (
						<div>
							<label>None network completely isolates the networking stack of a container</label>
						</div>
					)}
				</div>
			</Drawer>
		</div>
	)
}
