import 'react'
import { Button, Drawer, Input, Select, Spin } from 'antd'
import { command, useDataContext, useGlobalContext } from '../components'
import React, { useEffect, useState } from 'react'
import dataService from '../service/dataService'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	nodeNumber: {
		padding: '5px 10px',
		borderRadius: '5px 0 0 5px', // Rounded border on the left side
		fontWeight: 'bold',
		textAlign: 'left',
	},
	deleteButton: {
		position: 'relative',
		marginLeft: '82%',
		cursor: 'pointer',
		color: 'red',
	},
})

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const classes = useStyles()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ name: '', managementIpAddress: [''], token: '' })
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (globalContext.state.peerClusterCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata({ ...globalContext.state.peerClusterCreateAndUpdatePopup })
		} else {
			setdata({ name: '', managementIpAddress: [''] })
		}
	}, [globalContext.state.peerClusterCreateAndUpdatePopup])

	//handleChange
	const handleChange = (index) => (event) => {
		const newData = { ...data }
		newData.managementIpAddress[index] = event?.target?.value
		setdata(newData)
	}

	const nameHandleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp ? valueTemp : '' })
	}

	const add = () => {
		const newData = {
			...data,
			managementIpAddress: [...data.managementIpAddress, ''],
		}
		setdata(newData)
	}

	const onClose = () => {
		globalContext.updateState('peerClusterCreateAndUpdatePopup', null)
		setdata({ name: '', managementIpAddress: [''], token: '' })
	}

	const del = (index) => {
		const newData = { ...data }
		newData.managementIpAddress.splice(index, 1)
		setdata(newData)
	}

	const startPeerCluster = async () => {
		let res = await dataService.createPeerCluster(data)
		if (res.success) {
			let res2 = await dataService.getPeerClusters()
			if (res2.success) {
				dataContext.updateState('peerClusters', res2.data)
			}
			globalContext.Snackbar(res.message, 'success')
			globalContext.updateState('peerClusterCreateAndUpdatePopup', null)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updatePeerCluster = async () => {
		let res = await dataService.updatePeerCluster(data.id, data)
		if (res.success) {
			let res2 = await dataService.getPeerClusters()
			if (res2.success) {
				dataContext.updateState('peerClusters', res2.data)
			}
			globalContext.Snackbar(`Peer cluster [${res?.data?.name}] updated successfully`, 'success')
			globalContext.updateState('peerClusterCreateAndUpdatePopup', null)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title={
				globalContext?.state?.peerClusterCreateAndUpdatePopup?.createOrUpdate === 'update'
					? 'Update Peer Cluster'
					: 'Create Peer Cluster'
			}
			placement="right"
			onClose={!isLoading ? onClose : ''}
			drawerStyle={{
				backgroundColor: isLoading ? 'rgba(255,255,255,0.5)' : 'transparent',
				filter: isLoading ? 'blur(0.8px)' : '',
				position: 'relative', // Required for the spinner positioning
			}}
			visible={globalContext.state.peerClusterCreateAndUpdatePopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button
						disabled={isLoading}
						id="peerClusterSubmit"
						onClick={
							globalContext?.state?.peerClusterCreateAndUpdatePopup?.createOrUpdate === 'update'
								? updatePeerCluster
								: startPeerCluster
						}
						type="primary"
						style={{ marginLeft: 8 }}
						small>
						Complete
					</Button>
				</div>
			}>
			<div className={classes.nodeNumber}>Peer Cluster</div>
			<Input
				disabled={isLoading}
				placeholder="Name"
				value={data?.name}
				onChange={nameHandleChange('name')}
				id={`name`}
			/>
			<hr />
			<div className={classes.nodeNumber}>System Token from Peer Cluster</div>
			<Input
				onChange={nameHandleChange('token')}
				className="fullW"
				placeholder="Enter Here"
				value={data?.token}
				id={`token`}
			/>
			<hr />
			{data?.managementIpAddress?.map((item, index) => (
				<div key={index} style={{ marginBottom: '10px' }}>
					<div className={classes.nodeNumber}>
						Node {index + 1}
						<span className={classes.deleteButton} onClick={!isLoading ? () => del(index) : undefined}>
							&#x2715;{' '}
						</span>
					</div>
					<Input
						disabled={isLoading}
						placeholder="Ip"
						value={item}
						onChange={handleChange(index)}
						id={`initClusterIp_${index}`}
						style={{ marginBottom: '6px' }}
					/>
				</div>
			))}
			<Button
				disabled={isLoading}
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}>
				+ Add Row
			</Button>
			{isLoading && (
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}>
					<Spin spinning={isLoading} />
				</div>
			)}
		</Drawer>
	)
}
