import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Switch, Tag, Badge, Table, Modal, message } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [data, setdata] = useState({ ...globalContext.state.vmStoragePoolCreatePopup })

	const [blockDevs, setBlockDevs] = useState(dataContext?.state?.getBlockDevices)
	const [nodeNetworkInterface, setNodeInterface] = useState([])
	const [networkInterfaceInformationModal, setNetworkInterfaceInformationModal] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? (key == 'isoStorage' ? e.target.checked : e.target.value) : e
		if (key == 'host') {
			var blockDevsData = dataContext?.state?.getBlockDevices.filter(function (b) {
				return b.nodeId == valueTemp
			})

			setBlockDevs(blockDevsData)
		}
		setdata({ ...data, [key]: valueTemp })
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				let res = await dataService.getNasNodeInterfaces(data)
				if (res.success) {
					setNodeInterface(res.data)
				}
			} catch (error) {
				// Handle errors here
				message.error('Error fetching node network interfaces:', error)
			}
		}

		if (networkInterfaceInformationModal) {
			fetchData()
		}
	}, [networkInterfaceInformationModal])

	const handleChangeNas = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key === 'nasServer' || key === 'nasFolder') {
			setdata((prevData) => ({
				...prevData,
				stNasAttributes: {
					...prevData.stNasAttributes,
					[key]: valueTemp,
				},
			}))
		}
		// You can add more conditions for other attributes within stNasAttributes if needed
	}

	const handleClose = () => {
		globalContext.updateState('vmStoragePoolCreatePopup', null)
		setdata({})
	}

	const getNetworkName = (id) => {
		if (id && dataContext.state?.getNetworks) {
			let findItem = dataContext.state.getNetworks.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	const getNetworkProfileName = (id) => {
		if (id && dataContext.state?.getNetworkProfiles) {
			let findItem = dataContext.state.getNetworkProfiles.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	const showNodeNetworkInterfaceInfo = () => {
		const columns = [
			{
				title: 'Node Name',
				dataIndex: 'name',
				key: 'name',
				sorter: (a, b) => a.name.localeCompare(b.name),
			},
			{
				title: 'Interface Name',
				dataIndex: 'interfaceName',
				key: 'interfaceName',
				render: (text, row) => <span>{row?.networkInterfaces[0].name}</span>,
			},
			{
				title: 'State',
				dataIndex: 'opState',
				key: 'opState',
				render: (text, row) => <span>{row?.networkInterfaces[0]?.opState}</span>,
			},
			{
				title: 'IP Addresses',
				dataIndex: 'ipAddresses',
				key: 'ipAddresses',
				render: (text, row) => (
					<span>
						{row?.networkInterfaces[0]?.ipAddresses?.length > 0 &&
						row?.networkInterfaces[0]?.ipAddresses[0].length > 0
							? row?.networkInterfaces[0]?.ipAddresses?.toString()
							: '-'}
					</span>
				),
			},
			{
				title: 'Connected Network',
				dataIndex: 'connectedNetworkId',
				key: 'connectedNetworkId',
				render: (text, row) => (
					<span>
						{row?.networkInterfaces[0]?.connectedNetworkId != ''
							? getNetworkName(row?.networkInterfaces[0]?.connectedNetworkId)
							: 'Not selected'}
					</span>
				),
			},
			{
				title: 'Connected Network Profile',
				dataIndex: 'connectedNetworkProfileId',
				key: 'connectedNetworkProfileId',
				render: (text, row) => (
					<span>
						{row?.networkInterfaces[0]?.connectedNetworkProfileId != ''
							? getNetworkProfileName(row?.networkInterfaces[0]?.connectedNetworkProfileId)
							: 'Not selected'}
					</span>
				),
			},
			{
				title: 'Port Mtu',
				dataIndex: 'portMtu',
				key: 'portMtu',
				render: (text, row) => <span>{row?.networkInterfaces[0]?.portMtu}</span>,
			},

			{
				title: 'Port Speed',
				dataIndex: 'portSpeed',
				key: 'portSpeed',
				render: (text, row) => <span>{row?.networkInterfaces[0]?.portSpeed}</span>,
			},
			{
				title: 'Tags',
				dataIndex: 'functionTags',
				key: 'functionTags',
				render: (text, row) =>
					row?.networkInterfaces[0]?.functionTags.map(
						(tag) =>
							tag && (
								<Tag key={tag.id} style={{ marginBottom: 4 }} color="success">
									{tag}
								</Tag>
							)
					),
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				filters: [
					{ text: 'Ready', value: 'READY' },
					{ text: 'Failed', value: 'FAILED' },
				],
				onFilter: (value, record) => record.status.includes(value),
				render: (text, row) => (
					<span>
						<Badge color={row['status'] == 'READY' ? 'green' : 'red'} text={row['status']} />
					</span>
				),
			},
		]

		return (
			<Modal
				title={'Nodes Network Interfaces Informations'}
				onCancel={() => setNetworkInterfaceInformationModal(false)}
				width={'70%'}
				open={networkInterfaceInformationModal}
				footer={null}>
				<Table
					dataSource={nodeNetworkInterface}
					columns={columns}
					pagination={{ pageSize: 5, hideOnSinglePage: true }}
				/>
			</Modal>
		)
	}

	const createVmStoragePool = async () => {
		let vmStoragePoolCreatePopup = globalContext.state.vmStoragePoolCreatePopup

		let sendData = {
			name: data.name,
			poolType: data.poolType,
			clusterId: dataContext.state.list[0].id,
			isoStorage: data.isoStorage,
			poolUnits: [],
			blockDevices: [],
			stNasAttributes: {
				nasType: data?.stNasAttributes?.nasServer || data?.stNasAttributes?.nasFolder ? 'nfs4' : '',
				nasServer: data?.stNasAttributes?.nasServer,
				nasFolder: data?.stNasAttributes?.nasFolder,
			},
		}

		if (data?.blockDevice) {
			sendData.blockDevices = dataContext?.state?.getBlockDevices.filter(function (b) {
				return b.id == data?.blockDevice
			})
		}

		if (vmStoragePoolCreatePopup.id) {
			// let findVmStoragePoolIndex = newList.findIndex((i) => i.id == vmStoragePoolCreatePopup.id)
		} else {
			let res = await dataService.createVmStoragePool(sendData)
			if (res) {
				if (res.success) {
					handleClose()
					globalContext.Snackbar(
						`Create [${res.data.name}] vm storagepool operation started successfully`,
						'success'
					)
				} else {
					globalContext.Snackbar(res.errorMessage, 'error')
				}
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}
	return (
		<div>
			<Drawer
				title={'New Vm Storage Pool'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.vmStoragePoolCreatePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => createVmStoragePool()}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={`${command.checkValue({ name: data.name }).length ? 'true' : ''}`}
							small
							id="storagePoolSubmit">
							Confirm
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Storage Pool Name
					</label>
					<Input
						id="storagePoolName"
						placeholder="Enter here"
						value={data?.name}
						onChange={handleChange('name')}
						disabled={data?.poolType == 'gluster'}
						status={`${command.checkValue({ name: data.name }).length ? 'error' : ''}`}
					/>
					{command.checkValue({ name: data.name }).length && data?.poolType != 'gluster' ? (
						<small style={{ color: '#ff4d4f' }}>Please input storage pool name.</small>
					) : (
						''
					)}
				</div>

				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Pool Type
					</label>
					<Select
						id="storagePoolType"
						value={data?.poolType}
						onChange={handleChange('poolType')}
						className="fullW"
						disabled={data.isoStorage}>
						<Option value={'local'}>Local Storage Pool</Option>
						<Option value={'gluster'}>Gluster Pool</Option>
						<Option value={'sharedlv'}>Shared Storage Pool</Option>
						<Option value={'shared'}>Distributed Storage Pool</Option>
						<Option value={'nas'}>Nfs4 Storage Pool</Option>
					</Select>
				</div>

				{['shared', 'sharedlv'].includes(data?.poolType) ? (
					<div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Node
							</label>
							<Select value={data?.host} onChange={handleChange('host')} className="fullW" id="storagePoolHost">
								{dataContext?.state?.getNodes?.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Block Device
							</label>
							<Select
								value={data?.blockDevice}
								onChange={handleChange('blockDevice')}
								showSearch={true}
								optionFilterProp="label"
								className="fullW"
								id="storagePoolBlockDevice">
								{blockDevs?.map((item) => (
									<Option
										value={item.id}
										disabled={item.storagePoolId != '' || item.osInstalled == 'true'}
										label={item?.name}>
										{item.name} / {item.size}
									</Option>
								))}
							</Select>
						</div>
					</div>
				) : data?.poolType == 'local' ? (
					<div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Node
							</label>
							<Select value={data?.host} onChange={handleChange('host')} className="fullW" id="storagePoolHost">
								{dataContext?.state?.getNodes?.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Block Device
							</label>
							<Select
								value={data?.blockDevice}
								onChange={handleChange('blockDevice')}
								className="fullW"
								id="storagePoolBlockDevice">
								{blockDevs?.map((item) => (
									<Option value={item.id} disabled={item.storagePoolId != '' || item.osInstalled == 'true'}>
										{item.name} / {item.size}
									</Option>
								))}
							</Select>
						</div>
					</div>
				) : (
					''
				)}
				{data?.poolType == 'gluster' ? (
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Volume
						</label>
						<Select value={data?.name} onChange={handleChange('name')} className="fullW" id="storagePoolVolume">
							{dataContext?.state?.getGlusterVolumes?.map((vol) => (
								<Option value={vol.name} key={vol.name} disabled={vol.vmStoragePoolId}>
									{vol.name}
								</Option>
							))}
						</Select>
					</div>
				) : (
					''
				)}
				{data.poolType == 'nas' ? (
					<>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>NFS4 Server
							</label>
							<Input
								id="nfs4Server"
								placeholder="_._._._ / example.com"
								value={data?.stNasAttributes?.nasServer}
								onChange={handleChangeNas('nasServer')}
								status={`${
									command.checkValue({ name: data?.stNasAttributes?.nasServer }).length ? 'error' : ''
								}`}
							/>
							{command.checkValue({ name: data?.stNasAttributes?.nasServer }).length ? (
								<small style={{ color: '#ff4d4f' }}>Please input Nfs4 server name/ip.</small>
							) : (
								''
							)}
						</div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>NFS4 Folder
							</label>
							<Input
								id="nfs4Folder"
								placeholder="/Directory/Folder"
								value={data?.stNasAttributes?.nasFolder}
								onChange={handleChangeNas('nasFolder')}
								status={`${
									command.checkValue({ name: data?.stNasAttributes?.nasFolder }).length ? 'error' : ''
								}`}
							/>
							{command.checkValue({ name: data?.stNasAttributes?.nasFolder }).length ? (
								<small style={{ color: '#ff4d4f' }}>Please input Nfs4 folder directory.</small>
							) : (
								''
							)}
						</div>
						<div className="form-group-ant">
							<Button
								type="primary"
								disabled={!data?.stNasAttributes?.nasServer || !data?.stNasAttributes?.nasFolder}
								onClick={() => {
									setNetworkInterfaceInformationModal(true)
								}}
								icon={<InfoCircleOutlined />}>
								Review Node Networking
							</Button>
						</div>
						<div className="form-group-ant">{showNodeNetworkInterfaceInfo()}</div>
					</>
				) : (
					''
				)}
				{data?.poolType != 'local' ? (
					<div
						style={{
							marginBottom: 8,
							display: 'inline-flex',
							width: '100%',
							borderRadius: 3,
							justifyContent: 'space-between',
						}}>
						<div>
							<span style={{ fontSize: 14 }}>Repository: </span>
							<Switch
								id="storagePoolIsoStorage"
								checked={data?.isoStorage}
								onChange={handleChange('isoStorage')}
								disabled={data?.poolType == 'sharedlv'}
								name="isoStorage"
								color="primary"
							/>
						</div>
					</div>
				) : (
					''
				)}
			</Drawer>
		</div>
	)
}
