import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'

import dataService from 'service/dataService'

import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Name from './Name'
import Hardware from './Hardware'
import Container from './Container'
import Network from './Network'
import Storage from './Storage'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 0,
		height: '100%',
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	actived: {
		cursor: 'pointer',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('containerCreateAndUpdatePopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata(null)
	}
	useEffect(() => {
		if (globalContext.state.containerCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.containerCreateAndUpdatePopup)
			setSelectedMenuMax(7)
			setSelectedMenu(0)
		}
	}, [globalContext.state.containerCreateAndUpdatePopup])

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
	}

	const createContainer = async () => {
		let sendData = { ...data }

		let multiLines = ['command', 'environment', 'entrypoint']
		multiLines.forEach((ml) => {
			if (sendData[ml]) {
				sendData[ml] = sendData[ml].split(/\r?\n/)
			} else {
				delete sendData[ml]
			}
		})

		if (sendData?.networkDriverType === 'cni') {
			sendData.portBindings = []
			if (typeof sendData['networkInterfaces'][0]['nameServers'][0] == 'string') {
				let nsArr = sendData?.networkInterfaces?.[0]?.nameServers?.[0]?.split(',')
				let trimmedArr = nsArr.map((ns) => ns.trim())
				sendData['networkInterfaces'][0]['nameServers'] = trimmedArr?.filter((i) => i != '') || []
			} else {
				sendData['networkInterfaces'][0]['nameServers'] = []
			}
		} else if (sendData?.networkDriverType === 'node') {
			sendData.networkInterfaces = []
		} else {
			sendData.portBindings = []
			sendData.networkInterfaces = []
		}

		sendData.clusterId = dataContext?.state.list[0].id

		let res = await dataService.createContainer(sendData)
		if (res.success) {
			globalContext.Snackbar(`Create [${res.data.name}] container operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
		handleClose()
	}

	const updateContainer = async () => {
		let sendData = { ...data }

		delete sendData.createOrUpdate

		let multiLines = ['command', 'environment', 'entrypoint']
		multiLines.forEach((ml) => {
			if (sendData[ml]) {
				sendData[ml] = sendData[ml].split(/\r?\n/)
			} else {
				delete sendData[ml]
			}
		})
		if (sendData?.networkDriverType === 'cni') {
			sendData.portBindings = []
			if (typeof sendData['networkInterfaces'][0]['nameServers'][0] == 'string') {
				let nsArr = sendData?.networkInterfaces?.[0]?.nameServers?.[0]?.split(',')
				let trimmedArr = nsArr.map((ns) => ns.trim())
				sendData['networkInterfaces'][0]['nameServers'] = trimmedArr?.filter((i) => i != '') || []
			} else {
				sendData['networkInterfaces'][0]['nameServers'] = []
			}
		} else if (sendData?.networkDriverType === 'node') {
			sendData.networkInterfaces = []
		} else {
			sendData.portBindings = []
			sendData.networkInterfaces = []
		}
		let res = await dataService.updateContainer(sendData)
		if (res.success) {
			globalContext.Snackbar(`Edit [${res.data.name}] container operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}

		handleClose()
	}

	let selectedMenuList = [
		<Name modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Hardware modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Container modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Network modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Complete createContainer={createContainer} updateContainer={updateContainer} prev={prev} modalData={data} />,
	]

	return (
		<div>
			<Modal
				title={
					globalContext.state.containerCreateAndUpdatePopup?.createOrUpdate == 'update'
						? 'Update Container'
						: 'New Container'
				}
				className="stepModal"
				width={1280}
				open={globalContext.state.containerCreateAndUpdatePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				destroyOnClose={true}
				onCancel={handleClose}>
				<Row>
					<Col span={6}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 0 ? setSelectedMenu(0) : null)}>
									<span className="fw-600">1</span>Name
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 1 ? setSelectedMenu(1) : null)}>
									<span className="fw-600">2</span>Compute
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 2 ? setSelectedMenu(2) : null)}>
									<span className="fw-600">3</span>Container
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 3 ? setSelectedMenu(3) : null)}>
									<span className="fw-600">4</span>Network
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 4 ? classes.actived : ''} ${
										selectedMenu === 4 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 4 ? setSelectedMenu(4) : null)}>
									<span className="fw-600">5</span>Storage
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 5 ? classes.actived : ''} ${
										selectedMenu === 5 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 5 ? setSelectedMenu(5) : null)}>
									<span className="fw-600">6</span>Complete
								</li>
							</ul>
						</div>
					</Col>
					<Col span={18} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
