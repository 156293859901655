import React, { useEffect, useState } from 'react'
import { useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import General from './General'
import Objects from './Objects'
import Storage from './Storage'
import Schedule from './Schedule'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 12,
		minHeight: 300,
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
	hide: {
		display: 'none',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({ ...globalContext?.state?.VeritasBackupProfileCreatePopup })

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata({})
		globalContext.updateState('VeritasBackupProfileCreatePopup', null)
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			if (globalContext.state.VeritasBackupProfileCreatePopup?.createOrUpdate == 'update') {
				setdata({ ...globalContext.state.VeritasBackupProfileCreatePopup })
			}
		}
	}, [globalContext.state.VeritasBackupProfileCreatePopup])

	const createBackupProfile = async () => {
		let sendData = { ...data }

		sendData.clusterId = dataContext?.state.list[0].id

		let timeComponents = sendData?.timeHour?.split(':')
		let hours = parseInt(timeComponents?.[0]) * 3600
		let minutes = parseInt(timeComponents?.[1]) * 60
		let totalSeconds = hours + minutes

		let scheduleArray = []
		let selectedDay = sendData.timeDay
		let frequencySeconds = sendData.schedules[0].frequencySeconds

		if (frequencySeconds === 604800) {
			for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
				let startSeconds = selectedDay === dayOfWeek ? totalSeconds : 0
				let durationSeconds = selectedDay === dayOfWeek ? 1800 : 0
				let schedule = {
					dayOfWeek: dayOfWeek,
					startSeconds: startSeconds,
					durationSeconds: durationSeconds,
				}
				scheduleArray.push(schedule)
			}
		} else if (frequencySeconds === 2678400) {
			// Set startWindow to cover the entire day with a duration of 86399 seconds (23 hours, 59 minutes, and 59 seconds)
			scheduleArray = Array.from({ length: 7 }, (_, index) => ({
				dayOfWeek: index + 1,
				startSeconds: totalSeconds,
				durationSeconds: 86399 - totalSeconds,
			}))
		} else if (frequencySeconds < 86400) {
			// If frequency is hourly
			sendData.schedules[0].frequencySeconds = frequencySeconds * sendData.timeHourly
			// Set startWindow to cover the entire day with a duration of 86399 seconds (23 hours, 59 minutes, and 59 seconds)
			scheduleArray = Array.from({ length: 7 }, (_, index) => ({
				dayOfWeek: index + 1,
				startSeconds: 0,
				durationSeconds: 86399,
			}))
		} else {
			for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
				let schedule = {
					dayOfWeek: dayOfWeek,
					startSeconds: totalSeconds,
					durationSeconds: 1800,
				}
				scheduleArray.push(schedule)
			}
		}

		sendData.schedules[0].startWindow = scheduleArray

		sendData.id = sendData.policyName

		delete sendData.timeHourly
		delete sendData.timeDay
		delete sendData.timeHour

		let res = await dataService.createVeritasBackupProfile(sendData)
		if (res.success) {
			globalContext.Snackbar(`Create [${res.data.policyName}] backup operation started successfully`, 'success')
			globalContext.updateState('VeritasBackupProfileCreatePopup', null)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateBackupProfile = async () => {
		let sendData = { ...data }

		sendData.clusterId = dataContext?.state.list[0].id

		let timeComponents = sendData?.timeHour?.split(':')
		let hours = parseInt(timeComponents?.[0]) * 3600
		let minutes = parseInt(timeComponents?.[1]) * 60
		let totalSeconds = hours + minutes

		let scheduleArray = []
		let selectedDay = sendData.timeDay
		let frequencySeconds = sendData.schedules[0].frequencySeconds

		if (frequencySeconds === 604800) {
			for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
				let startSeconds = selectedDay === dayOfWeek ? totalSeconds : 0
				let durationSeconds = selectedDay === dayOfWeek ? 1800 : 0
				let schedule = {
					dayOfWeek: dayOfWeek,
					startSeconds: startSeconds,
					durationSeconds: durationSeconds,
				}
				scheduleArray.push(schedule)
			}
		} else if (frequencySeconds === 2678400) {
			// Set startWindow to cover the entire day with a duration of 86399 seconds (23 hours, 59 minutes, and 59 seconds)
			scheduleArray = Array.from({ length: 7 }, (_, index) => ({
				dayOfWeek: index + 1,
				startSeconds: totalSeconds,
				durationSeconds: 86399 - totalSeconds,
			}))
		} else if (frequencySeconds < 86400) {
			// If frequency is hourly
			sendData.schedules[0].frequencySeconds = frequencySeconds * sendData.timeHourly
			// Set startWindow to cover the entire day with a duration of 86399 seconds (23 hours, 59 minutes, and 59 seconds)
			scheduleArray = Array.from({ length: 7 }, (_, index) => ({
				dayOfWeek: index + 1,
				startSeconds: 0,
				durationSeconds: 86399,
			}))
		} else {
			for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
				let schedule = {
					dayOfWeek: dayOfWeek,
					startSeconds: totalSeconds,
					durationSeconds: 1800,
				}
				scheduleArray.push(schedule)
			}
		}

		sendData.schedules[0].startWindow = scheduleArray

		delete sendData.createOrUpdate
		delete sendData.timeHourly
		delete sendData.timeDay
		delete sendData.timeHour

		let res = await dataService.updateVeritasBackupProfile(sendData, sendData.id)
		if (res.success) {
			globalContext.Snackbar(`Update [${res.data.policyName}] backup operation started successfully`, 'success')
			globalContext.updateState('VeritasBackupProfileCreatePopup', null)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
		setSelectedMenuMax(selectedMenu - 1)
	}

	let selectedMenuList = [
		<General modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Objects modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Schedule modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Complete
			createBackupProfile={createBackupProfile}
			updateBackupProfile={updateBackupProfile}
			next={next}
			prev={prev}
			modalData={data}
		/>,
	]

	return (
		<div>
			<Modal
				title={
					globalContext.state.VeritasBackupProfileCreatePopup?.createOrUpdate == 'update'
						? 'Update External Backup Profile'
						: 'Create External Backup Profile'
				}
				className="stepModal"
				width={950}
				open={globalContext.state.VeritasBackupProfileCreatePopup || false}
				footer={null}
				destroyOnClose={true}
				closeIcon={<CloseCircleOutlined />}
				afterClose={handleClose}
				onCancel={handleClose}>
				<Row>
					<Col span={8}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									} `}>
									1- General
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									} `}>
									2- Objects
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									} `}>
									3- Storage
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 3 ? setSelectedMenu(3) : null)}>
									4- Schedule
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 4 ? classes.actived : ''} ${
										selectedMenu === 4 ? classes.active : ''
									}`}>
									5- Complete
								</li>
							</ul>
						</div>
					</Col>
					<Col span={16} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
