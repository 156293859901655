import 'react'
import { Button, Drawer, Input, message, Modal, Spin, Switch } from 'antd'
import { command, useDataContext, useGlobalContext } from '../components'
import React, { useState } from 'react'
import dataService from '../service/dataService'
import { createUseStyles } from 'react-jss'
import { getInitialProps } from 'react-i18next'

const useStyles = createUseStyles({
	nodeNumber: {
		padding: '5px 10px',
		borderRadius: '5px 0 0 5px', // Rounded border on the left side
		fontWeight: 'bold',
		textAlign: 'left',
	},
	deleteButton: {
		position: 'relative',
		marginLeft: '82%',
		cursor: 'pointer',
		color: 'red',
	},
})

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const classes = useStyles()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ name: '', nodes: [{ name: '', managementIpAddress: '', isDkvs: false }] })
	const [isLoading, setIsLoading] = useState(false)
	const [warningShown, setWarningShown] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)

	const nodeHandleChange = (field, index) => (e) => {
		const newData = { ...data }
		let valueTemp = e?.target ? e.target.value : e
		newData.nodes[index][field] = valueTemp

		if (!warningShown) {
			const trueIsDkvsCount = newData.nodes.filter((node) => node.isDkvs).length
			if (trueIsDkvsCount == 8) {
				message.warn(
					'Using more than 7 DKVS nodes is not recommended. If you still wish to proceed, please force the operation'
				)
				setWarningShown(true)
			}
		}

		setdata(newData)
	}

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const add = () => {
		const newData = {
			...data,
			nodes: [...data.nodes, { name: '', managementIpAddress: '', isDkvs: false }],
		}
		setdata(newData)
	}

	const onClose = () => {
		globalContext.updateState('initClusterPopup', null)
		setdata({ name: '', nodes: [{ name: '', managementIpAddress: '', isDkvs: false }] })
		setWarningShown(false)
	}

	const del = (index) => {
		const newData = { ...data }
		newData.nodes.splice(index, 1)
		setdata(newData)
	}

	const handleInitiateClick = () => {
		const hasTrueIsDkvs = data.nodes.some((node) => node.isDkvs)

		if (!hasTrueIsDkvs) {
			message.warn('At least one Dkvs node needs to be added.')
			return
		}

		// Check if the number of true isDkvs is greater than 7
		const trueIsDkvsCount = data.nodes.filter((node) => node.isDkvs).length
		if (trueIsDkvsCount > 7) {
			setIsModalVisible(true)
		} else {
			startInitCluster()
		}
	}

	const handleModalOk = () => {
		setIsModalVisible(false)
		startInitCluster()
	}

	const handleModalCancel = () => {
		setIsModalVisible(false)
	}

	const startInitCluster = async () => {
		if (data.nodes.length > 0 && data.name !== '') {
			globalContext.Snackbar(`Please do not close or reload the page until the operation is completed.`, 'warning')
			setIsLoading(true)
			let sendData = { ...data }

			sendData.nodes = sendData.nodes.map((node, index) => ({
				...node,
				nodeIndex: index,
			}))

			let url = 'https://' + sendData.nodes[0].managementIpAddress + ':8000/api/v1/operations/cluster/deploy/'

			let res = await dataService.deployCluster(url, sendData)
			if (res.success) {
				globalContext.Snackbar('Cluster deployment started successful', 'success')
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		} else {
			globalContext.Snackbar(
				`Insufficient data provided to initiate cluster. Please provide necessary information.`,
				'error'
			)
		}

		setIsLoading(false)
		globalContext.updateState('initClusterPopup', null)
	}
	return (
		<Drawer
			title="Initiate Cluster"
			placement="right"
			onClose={!isLoading ? onClose : ''}
			drawerStyle={{
				backgroundColor: isLoading ? 'rgba(255,255,255,0.5)' : 'transparent',
				filter: isLoading ? 'blur(0.8px)' : '',
				position: 'relative', // Required for the spinner positioning
			}}
			open={globalContext.state.initClusterPopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button
						disabled={isLoading}
						id="initClusterSubmit"
						onClick={handleInitiateClick}
						type="primary"
						style={{ marginLeft: 8 }}
						small>
						Initiate
					</Button>
				</div>
			}>
			<Modal title="Confirmation" open={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
				<p>
					Using more than 7 DKVS nodes is not recommended. If you still wish to proceed, the operation will be
					forced.
				</p>
			</Modal>
			<div className={classes.nodeNumber}>Cluster</div>
			<Input
				disabled={isLoading}
				placeholder="Name"
				value={data?.name}
				onChange={handleChange('name')}
				id={`clusterName`}
			/>
			<hr />
			{data?.nodes?.map((item, index) => (
				<div key={index} style={{ marginBottom: '10px' }}>
					<div className={classes.nodeNumber}>
						Node {index + 1}
						<span className={classes.deleteButton} onClick={!isLoading ? () => del(index) : undefined}>
							&#x2715;{' '}
						</span>
					</div>
					<Input
						disabled={isLoading}
						placeholder="Ip"
						value={item.managementIpAddress}
						onChange={nodeHandleChange('managementIpAddress', index)}
						id={`initClusterIp_${index}`}
						style={{ marginBottom: '6px' }}
					/>
					<Input
						disabled={isLoading}
						placeholder="Hostname"
						value={item.name}
						onChange={nodeHandleChange('name', index)}
						id={`initClusterHostname_${index}`}
						style={{ marginBottom: '6px' }}
					/>
					<Switch
						checked={item.isDkvs}
						checkedChildren="Dkvs"
						unCheckedChildren="Dkvs"
						onChange={nodeHandleChange('isDkvs', index)}
						id={`initClusterIsDkvs_${index}`}
					/>
				</div>
			))}
			<Button
				disabled={isLoading}
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}>
				+ Add Row
			</Button>
			{isLoading && (
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}>
					<Spin spinning={isLoading} />
				</div>
			)}
		</Drawer>
	)
}
