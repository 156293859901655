import React, { useState } from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button, Input, InputNumber, Switch, Typography } from 'antd'

const { Title } = Typography

const useStyles = createUseStyles((theme) => ({
	ipRow: {
		display: 'flex',
		width: '100%',
		padding: '4px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	ip: {
		width: '35%',
		minWidth: 100,
		paddingLeft: 12,
		paddingRight: 12,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	act: {
		width: '25%',
		minWidth: 120,
		paddingLeft: 12,
		paddingRight: 12,
		'& button': {
			width: '100%',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
}))

export default function Host({
	createAddressPool = () => {},
	updateAddressPool = () => {},
	prev = () => {},
	modalData = {},
	modalDataChange = () => {},
}) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const [data, setdata] = useState(modalData)

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
		modalDataChange({ ...data, [key]: valueTemp })
	}

	const handleChangeIp = (e, key, index, incStr) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = { ...data }
		newData[incStr][index][key] = valueTemp
		setdata(newData)
		modalDataChange(newData)
	}

	const add = (incStr) => {
		let newData = { ...data }
		if (!newData[incStr]) {
			newData[incStr] = []
		}
		newData[incStr].push({
			startAddress: '',
			endAddress: '',
		})
		setdata(newData)
		modalDataChange(newData)
	}

	const del = (index, incStr) => {
		let newData = [...data[incStr]]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata({ ...data, [incStr]: newData })
		modalDataChange({ ...data, [incStr]: newData })
	}

	const complete = async () => {
		createAddressPool()
	}

	const update = async () => {
		updateAddressPool()
	}

	return (
		<div className={classes.root}>
			<div
				className="fullW"
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}></div>
			<Title level={5}>Allowed IP Ranges</Title>
			<div className={classes.ipRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
				<span className={classes.hash}>#</span>
				<span className={classes.ip}>
					<span className="requiredHint">*</span>First Usable IP
				</span>
				<span className={classes.ip}>
					<span className="requiredHint">*</span>Last Usable IP
				</span>
				<span className={classes.act}>Action</span>
			</div>
			{data?.ipAddressRanges?.map((item, index) => (
				<div className={classes.ipRow}>
					<span className={classes.hash}>{index + 1}</span>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.startAddress}
							onChange={(e) => handleChangeIp(e, 'startAddress', index, 'ipAddressRanges')}
						/>
					</div>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.endAddress}
							onChange={(e) => handleChangeIp(e, 'endAddress', index, 'ipAddressRanges')}
						/>
					</div>
					<div className={classes.act}>
						<span
							onClick={() => del(index, 'ipAddressRanges')}
							style={{ color: '#0f427a', cursor: 'pointer' }}
							className="ipRangeDelete">
							Delete
						</span>
					</div>
				</div>
			))}
			<Button
				block
				onClick={() => add('ipAddressRanges')}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="vmStorageAdd">
				+ Add Row
			</Button>
			<small>
				Please manually input allowed IP ranges in the previously given {modalData?.ipNetCIDR} subnet for use by
				Tenants.
			</small>

			{data?.excludedIps?.map((item, index) => (
				<div className={classes.ipRow}>
					<span className={classes.hash}>{index + 1}</span>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.startAddress}
							onChange={(e) => handleChangeIp(e, 'startAddress', index, 'excludedIps')}
						/>
					</div>
					<div className={classes.ip}>
						<Input
							className="ip"
							value={item?.endAddress}
							onChange={(e) => handleChangeIp(e, 'endAddress', index, 'excludedIps')}
						/>
					</div>
					<div className={classes.act}>
						<span
							onClick={() => del(index, 'excludedIps')}
							style={{ color: '#0f427a', cursor: 'pointer' }}
							className="ipRangeDelete">
							Delete
						</span>
					</div>
				</div>
			))}
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				{modalData?.id ? (
					<Button onClick={() => update()} type="primary" style={{ marginLeft: 8 }} small id="addressPoolUpdate">
						Update
					</Button>
				) : (
					<Button onClick={() => complete()} type="primary" style={{ marginLeft: 8 }} small id="addressPoolCreate">
						Create
					</Button>
				)}
			</div>
		</div>
	)
}
