import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [data, setdata] = useState({ vmId: '', templateName: '', templateStoragePoolId: '' })
	const [selectedNodeId, setSelectedNodeId] = useState('')

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata((prevData) => {
			return { ...prevData, [key]: valueTemp }
		})

		if (key === 'vmId') {
			const selectedVm = dataContext?.state?.getVms?.find((vm) => vm.id === valueTemp)
			if (selectedVm) {
				setSelectedNodeId(selectedVm.nodeId)
				setdata((prevData) => ({
					...prevData,
					vmId: valueTemp,
					templateStoragePoolId: '',
				}))
			}
		}
	}

	const handleClose = () => {
		globalContext.updateState('vmTemplateCreatePopup', null)
		setdata({ vmId: '', templateName: '', templateStoragePoolId: '' })
	}

	useEffect(() => {
		if (globalContext.state.vmTemplateCreatePopup) {
			setdata({ ...data, vmId: globalContext.state.vmTemplateCreatePopup.id })

			const selectedVm = dataContext?.state?.getVms?.find(
				(vm) => vm.id === globalContext.state.vmTemplateCreatePopup.id
			)
			if (selectedVm) {
				setSelectedNodeId(selectedVm.nodeId)
			}
		}
	}, [globalContext.state.vmTemplateCreatePopup])

	const createVmTemplate = async () => {
		let sendData = {
			templateName: data.templateName,
			templateStoragePoolId: data.templateStoragePoolId,
			vmId: data.vmId,
		}
		let res = await dataService.createVmTemplate(sendData)
		if (res.success) {
			let res2 = await dataService.getVmTemplates()
			if (res2.success) {
				dataContext.updateState('getVmTemplates', res2.data)
			}
			handleClose()

			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<div>
			<Drawer
				title={'New VM Template'}
				placement="right"
				width={450}
				onClose={handleClose}
				visible={globalContext.state.vmTemplateCreatePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => createVmTemplate()}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={`${data?.objects?.length == 0 && data?.name?.length == 0 ? 'true' : ''}`}
							small
							id="vmTemplateCreate">
							Create
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>VM Template Name
					</label>
					<Input
						placeholder="Enter here"
						value={data?.templateName}
						onChange={handleChange('templateName')}
						id="vmTemplateCreateName"
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Vm
					</label>
					<Select
						value={data?.vmId}
						onChange={handleChange('vmId')}
						className="fullW"
						id="vmTemplateVmSelect"
						placeholder="Select Vm">
						{dataContext?.state?.getVms?.map((item) => {
							return (
								<Option key={item.id} value={item.id}>
									<span style={{ fontWeight: 'bold' }}>{item.name}</span>
								</Option>
							)
						})}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Volume
					</label>
					<Select
						value={data?.templateStoragePoolId}
						onChange={handleChange('templateStoragePoolId')}
						className="fullW"
						id="vmTemplateStoragePoolSelect"
						placeholder="Select Volume">
						{dataContext?.state?.getVmStoragePools
							?.filter((item) => item?.poolUnits?.some((pu) => pu?.nodeId === selectedNodeId))
							.map((filteredItem) => (
								<Option key={filteredItem.id} value={filteredItem.id}>
									<span style={{ fontWeight: 'bold' }}>{filteredItem.name}</span> -{' '}
									{filteredItem.vmStoragePoolStat.allocation} / {filteredItem.vmStoragePoolStat.capacity}(
									{filteredItem.vmStoragePoolStat.percentage}%)
								</Option>
							))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
