import React from 'react'
// import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";

import { Grid as MUGrid } from '@material-ui/core'
import { Loader, PermissionCheck } from '..'

// const useStyles = makeStyles((props) => {
// 	return {
// 		root: {
// 			textAlign: props.center ? "center" : "left",
// 		},
// 	};
// });

export default function Grid({
	absolute,
	flex,
	center,
	left,
	right,
	middle,
	top,
	bottom,

	loaderShow = false,
	children,
	permissionList,
	style,
	...props
}) {
	// const classes = useStyles(props);
	const customStyle = {
		root: {
			position: absolute ? 'absolute' : 'relative',
			display: flex ? 'flex' : '',
			justifyContent: center ? 'center' : left ? 'left' : right ? 'right' : '',
			textAlign: center ? 'center' : left ? 'left' : right ? 'right' : '',
			alignItems: middle ? 'center' : top ? 'baseline' : bottom ? 'end' : '',
			...style,
		},
	}

	return (
		<PermissionCheck permissionList={permissionList}>
			<MUGrid style={customStyle.root} {...props}>
				<Loader loaderShow={loaderShow}>{children}</Loader>
			</MUGrid>
		</PermissionCheck>
	)
}

// Grid.propTypes = {
// 	backgroundColor: PropTypes.string,
// 	label: PropTypes.string.isRequired,
// 	onClick: PropTypes.func,
// };

// Grid.defaultProps = {
// 	backgroundColor: null,
// 	onClick: undefined,
// };
