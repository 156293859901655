import React, { useEffect, useState } from 'react'
import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useGlobalContext, useDataContext } from 'components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dataService from 'service/dataService'

export default function BackupProfileContextMenu() {
	const globalContext = useGlobalContext()
	const [data, setdata] = useState(globalContext.state.contextMenuData)

	const BackupProfileUpdatePopup = async ({ event, props }) => {
		let response = await dataService.getBackupProfileWithId(props.id)
		globalContext.updateState('BackupProfileCreatePopup', {
			createOrUpdate: 'update',
			...response.data,
		})
	}
	const dataContext = useDataContext()

	const DeletePopup = async ({ event, props }) => {
		// let response = await dataService.getVmSnapshots(props.id)
		globalContext.updateState('deletePopup', props)
	}

	useEffect(() => {
		setdata(globalContext.state.contextMenuData)
	}, [globalContext.state.contextMenuData])

	return (
		<>
			<Menu id={'backupProfile'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Backup Profile Actions
				</div>
				<hr />
				<Item onClick={BackupProfileUpdatePopup} id="backupProfileEdit">
					<EditOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Edit Backup Profile
				</Item>
				<Item onClick={DeletePopup} id="backupProfileDelete">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete Backup Profile
				</Item>
			</Menu>
		</>
	)
}
