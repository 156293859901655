import React, { useState, useEffect } from 'react'
import { command, useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Row, Col, Input, Select, Button, Radio, Space, Typography, message, Menu, Alert } from 'antd'
import { LoginOutlined, EyeInvisibleOutlined, EyeTwoTone, DownOutlined } from '@ant-design/icons'

import dataService from 'service/dataService'
import { DataContext } from 'Context/DataContext'
const { Option } = Select
const { SubMenu } = Menu
const { Title } = Typography

const useStyles = createUseStyles({
	root: {
		height: '100%',
		justifyContent: 'space-between',
		position: 'relative',
	},
	input: { width: '100%' },
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	confArea: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'&>div': {
			marginBottom: 8,
		},
	},
})
export default function Name({
	modalDataChange = () => {},
	next = () => {},
	modalData = {},
	stepChange = () => {},
	submitConversions = () => {},
}) {
	const classes = useStyles()
	const [data, setdata] = useState({})
	const [activeVm, setActiveVm] = useState({})
	const [filteredVms, setFilteredVms] = useState([])
	const [nodeList, setnodeList] = useState([])
	const [storageList, setstorageList] = useState([])
	const dataContext = useDataContext()

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.key
		setdata({ ...data, [key]: valueTemp })
		let vm = modalData?.filteredVms?.find((v) => v.key == valueTemp)
		if (!vm?.hostCredentials) {
			vm['hostCredentials'] = {
				username: 'root',
				password: '',
				url: vm?.url,
			}
		}
		setActiveVm(vm)
		modalDataChange({ ...modalData, activeVm: vm, configData: { ...data, [key]: valueTemp } })
	}

	const handleChange2 = (vm, key) => (e) => {
		let valueTemp = e?.target ? e?.target?.value : e
		setActiveVm({ ...activeVm, [key]: valueTemp })
		let filteredVmsTmp = [...filteredVms]
		let activeVmIndex = filteredVmsTmp.findIndex((obj) => obj.key == vm)
		filteredVmsTmp[activeVmIndex][key] = valueTemp
		setFilteredVms(filteredVmsTmp)
		modalDataChange({ ...modalData, filteredVms: filteredVmsTmp, activeVm: { ...activeVm, [key]: valueTemp } })
	}

	const handleChange3 = (vm, key) => (e) => {
		let valueTemp = e?.target ? e?.target?.value : e
		let activeVmTmp = { ...activeVm }
		if (!activeVmTmp?.hostCredentials) {
			activeVmTmp['hostCredentials'] = {
				username: 'root',
				password: '',
				url: activeVmTmp?.url,
			}
		}
		activeVmTmp['hostCredentials'][key] = valueTemp
		setActiveVm(activeVmTmp)
		let filteredVmsTmp = [...filteredVms]
		let activeVmIndex = filteredVmsTmp.findIndex((obj) => obj.key == vm)
		filteredVmsTmp[activeVmIndex] = activeVmTmp
		setFilteredVms(filteredVmsTmp)
		modalDataChange({ ...modalData, filteredVms: filteredVmsTmp, activeVm: activeVmTmp })
	}

	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}

	useEffect(() => {
		setFilteredVms(modalData?.filteredVms)
		setActiveVm(modalData?.activeVm)
		setdata(modalData?.configData)
		fetch()
	}, [])

	return (
		<Row className={classes.root}>
			<Col span={8} className={classes.confArea} style={{ overflowY: 'auto' }}>
				{filteredVms && (
					<Menu
						onClick={handleChange('activeVmKey')}
						selectedKeys={[data?.activeVmKey]}
						items={filteredVms}
						mode="inline"
					/>
				)}
			</Col>
			<Col
				span={16}
				className={classes.confArea}
				style={{ paddingLeft: 24, paddingRight: 12, overflowY: 'auto', height: 'calc(100% - 42px)' }}>
				{data?.activeVmKey ? (
					<>
						<Title level={5} style={{ marginBottom: 0 }}>
							Sybelle VM Configuration
						</Title>
						<div>
							<label>Name</label>
							<Input
								placeholder="Enter here"
								value={activeVm?.vmName}
								onChange={handleChange2(data.activeVmKey, 'vmName')}
								id="vmName"
							/>
						</div>
						<div>
							<label>Node</label>
							<Select
								value={activeVm?.nodeId}
								onChange={handleChange2(data.activeVmKey, 'nodeId')}
								className="fullW"
								id="computeResourceHost">
								{nodeList.map((item) => (
									<Option value={item.id}>
										<span style={{ fontWeight: 'bold' }}>{item.name}</span> - ({item.nodeStat.cpuPercentage}%
										CPU) ({item.nodeStat.memPercentage}% Memory)
									</Option>
								))}
							</Select>
						</div>
						<div>
							<label>Storage Pool</label>
							<Select
								value={activeVm?.storageId}
								onChange={handleChange2(data.activeVmKey, 'storageId')}
								className={'fullW vmStoragePool'}>
								{storageList.map((item) => {
									if (item?.poolUnits?.map((pu) => pu?.nodeId)?.includes(activeVm?.nodeId)) {
										return (
											<Option key={item.id} value={item.id}>
												<span style={{ fontWeight: 'bold' }}>{item.name}</span> -{' '}
												{item.vmStoragePoolStat.allocation} / {item.vmStoragePoolStat.capacity} (
												{item.vmStoragePoolStat.percentage}%)
											</Option>
										)
									}
									return null
								})}
							</Select>
						</div>
						<div>
							<label>Network</label>
							<Select
								className="vmNetwork"
								value={activeVm?.networkId}
								onChange={handleChange2(data.activeVmKey, 'networkId')}
								style={{ width: '100%' }}>
								{dataContext?.state?.getNetworks?.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div>
							<label>Network Profile</label>
							<Select
								className="vmNetworkProfile"
								value={activeVm?.networkProfileId}
								onChange={handleChange2(data.activeVmKey, 'networkProfileId')}
								style={{ width: '100%' }}>
								{dataContext?.state?.getNetworkProfiles?.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<Title level={5} style={{ marginBottom: 0 }}>
							ESXi Host SSH Credentials
						</Title>
						<div>
							<label>SSH Username</label>
							<Input
								placeholder="Username"
								value={activeVm?.hostCredentials?.username}
								onChange={handleChange3(data.activeVmKey, 'username')}
								id="hostUsername"
							/>
						</div>
						<div>
							<label>SSH Password</label>
							<Input.Password
								placeholder="Password"
								value={activeVm?.hostCredentials?.password}
								onChange={handleChange3(data.password, 'password')}
								id="hostPwd"
							/>
						</div>
						<div>
							<label>IP Address or hostname</label>
							<Input
								placeholder="__.__.__.__ or example.com"
								value={activeVm?.hostCredentials?.url}
								onChange={handleChange3(data.activeVmKey, 'url')}
								id="hostUrl"
								addonBefore="ssh://"
							/>
						</div>
					</>
				) : (
					<Alert message="Please select a VM from the list." type="warning" showIcon />
				)}
			</Col>
			<div className={classes.footer}>
				<Button onClick={() => stepChange(1)} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => submitConversions(filteredVms)}
					className="next"
					style={{ marginLeft: 8 }}
					type="primary"
					small>
					Submit
				</Button>
			</div>
		</Row>
	)
}
