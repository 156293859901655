import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext, useParams } from '../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Input, Select, Button, Row } from 'antd'
const { Option } = Select
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 200,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		paddingBottom: 0,
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: '0 16px 24px 16px',
		flex: 1,
	},
	treeView: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '8px 0',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		height: 14,
		marginLeft: 4,
		color: 'rgb(194, 88, 88)',
	},
	sumTable: {
		width: '90%',
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			textAlign: 'left',
			borderBottom: '1px solid #dee2e6',
		},
	},
	subBtns: {
		display: 'inline-flex',
		justifyContent: 'end',
		width: '100%',
		padding: 16,
	},
	delRule: {
		position: 'absolute',
		top: 8,
		right: 8,
	},
	tripleRow: {
		display: 'flex',
		width: '100%!important',
		'& .form-group-ant': {
			display: 'block',
			flex: '0 0 25%',
			maxWidth: '25%',
			'& label': {
				display: 'block',
				width: '100%',
			},
			'& .ant-select, & input': {
				width: '90%!important',
			},
		},
	},
	nwRuleRow: {
		position: 'relative',
		border: '1px solid #dee2e6',
		padding: 12,
		marginBottom: 12,
		display: 'flex',
		flexFlow: 'row wrap',
		'& .form-group-ant': {
			display: 'block',
			flex: '0 0 25%',
			maxWidth: '25%',
			'& label': {
				display: 'block',
				width: '100%',
			},
			'& .ant-select, & input': {
				width: '90%!important',
			},
		},
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const params = useParams()

	const [data, setdata] = useState({ name: '', chain: '', priority: '', nwFilterRules: [] })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	//handleChange2
	const handleChange2 = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = { ...data }
		newData['nwFilterRules'][index][key] = valueTemp
		setdata(newData)
	}
	const add = () => {
		let newData = { ...data }
		newData?.nwFilterRules.push({})
		setdata(newData)
	}

	const del = (index) => {
		let newData = { ...data }
		newData.nwFilterRules = newData?.nwFilterRules.filter((item, indexItem) => indexItem != index)
		setdata(newData)
	}
	const onClose = () => {
		setVisible(false)
		globalContext.updateState('networkFilterCreateAndUpdatePopup', null)
		setdata({ name: '' })
	}

	// const chains = ['stp', 'mac', 'vlan', 'ipv4', 'ipv6', 'arp', 'rarp']
	const chains = ['ipv4']

	useEffect(() => {
		if (globalContext.state.networkFilterCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata({ ...globalContext.state.networkFilterCreateAndUpdatePopup, status: '' })
		} else {
			setdata({ nwFilterRules: [] })
		}
	}, [globalContext.state.networkFilterCreateAndUpdatePopup])

	const getNwFilters = async () => {
		let res = await dataService.getNetworkFilters()
		if (res.success) {
			dataContext.updateState('getNetworkFilters', res.data)
		}
	}

	const createNwFilter = async () => {
		let sendData = { ...data, clusterId: globalContext.state.params.clusterID }
		let res = await dataService.createNwFilter(sendData)
		if (res.success) {
			onClose()

			globalContext.Snackbar(`Create [${res.data.name}] network filter operation started successfully`, 'success')
			getNwFilters()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const updateNwFilter = async () => {
		let sendData = { ...data, clusterId: globalContext.state.params.clusterID }
		let res = await dataService.updateNwFilter(sendData)
		if (res.success) {
			onClose()

			globalContext.Snackbar(`Update [${res.data.name}] network filter operation started successfully`, 'success')
			getNwFilters()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Modal
			title={data?.id ? 'Update Network Filter' : 'New Network Filter'}
			className=""
			width={950}
			visible={globalContext.state.networkFilterCreateAndUpdatePopup || false}
			closeIcon={<CloseCircleOutlined />}
			footer={null}
			onCancel={onClose}>
			<Row style={{ marginLeft: 16, marginRight: 16 }}>
				<div className={classes.tripleRow}>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Name
						</label>
						<Input
							disabled={globalContext.state.networkFilterCreateAndUpdatePopup?.createOrUpdate == 'update'}
							placeholder="Enter here"
							value={data?.name}
							onChange={handleChange('name')}
							id="networkFilterName"
						/>
					</div>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Chain
						</label>
						<Select
							className="fullW"
							value={data?.chain}
							onChange={handleChange('chain')}
							id="networkFilterChainSelect">
							{chains.map((chain) => {
								return <Option value={chain}>{chain?.toLocaleUpperCase()}</Option>
							})}
						</Select>
					</div>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Priority
						</label>
						<Input placeholder="Enter here" value={data?.priority} onChange={handleChange('priority')} />
					</div>
				</div>
				{data?.nwFilterRules?.map((row, index) => (
					<div className={classes.nwRuleRow}>
						<div className={classes.delRule}>
							<DeleteOutlined onClick={() => del(index)} style={{ color: '#0f427a', cursor: 'pointer' }} />
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Action
							</label>
							<Select
								className="fullW"
								value={row?.action}
								onChange={(e) => handleChange2(e, 'action', index)}
								id="networkFilterActionSelect">
								<Option value={'drop'}>Drop</Option>
								<Option value={'accept'}>Accept</Option>
							</Select>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Direction
							</label>
							<Select
								className="fullW"
								value={row?.direction}
								onChange={(e) => handleChange2(e, 'direction', index)}
								id="networkFilterDirectionSelect">
								<Option value={'drop'}>In</Option>
								<Option value={'accept'}>Out</Option>
								<Option value={'any'}>Any</Option>
							</Select>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Priority
							</label>
							<Input
								placeholder="Enter here"
								value={row?.priority}
								onChange={(e) => handleChange2(e, 'priority', index)}
								id="networkFilterPriority"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Protocol
							</label>
							<Select
								className="fullW"
								value={row?.protocol}
								onChange={(e) => handleChange2(e, 'protocol', index)}
								id="networkFilterProtocolSelect">
								<Option value={'tcp'}>TCP</Option>
								<Option value={'udp'}>UDP</Option>
								<Option value={'icmp'}>ICMP</Option>
								<Option value={'any'}>Any</Option>
							</Select>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Src IP Range
							</label>
							<Input
								placeholder="Enter here"
								value={row?.srcIpRange}
								onChange={(e) => handleChange2(e, 'srcIpRange', index)}
								id="srcIPRange"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Src IP Mask
							</label>
							<Input
								placeholder="Enter here"
								value={row?.scrIpMask}
								onChange={(e) => handleChange2(e, 'scrIpMask', index)}
								id="srcIPMask"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Dst IP Range
							</label>
							<Input
								placeholder="Enter here"
								value={row?.dstIpRange}
								onChange={(e) => handleChange2(e, 'dstIpRange', index)}
								id="dstIPRange"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Dst IP Mask
							</label>
							<Input
								placeholder="Enter here"
								value={row?.dstIpMask}
								onChange={(e) => handleChange2(e, 'dstIpMask', index)}
								id="dstIPMask"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Src Port Range
							</label>
							<Input
								placeholder="Enter here"
								value={row?.srcPortRange}
								onChange={(e) => handleChange2(e, 'srcPortRange', index)}
								id="srcPortRange"
							/>
						</div>
						<div className="form-group-ant fullW">
							<label>
								<span className="requiredHint">*</span>Dst Port Range
							</label>
							<Input
								placeholder="Enter here"
								value={row?.dstPortRange}
								onChange={(e) => handleChange2(e, 'dstPortRange', index)}
								id="dstPortRange"
							/>
						</div>
					</div>
				))}
				<Button
					block
					onClick={() => add()}
					style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}>
					+ Add Row
				</Button>
			</Row>
			<Row>
				<div className={classes.subBtns}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					{data?.id ? (
						<Button
							onClick={updateNwFilter}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="networkFilterUpdate">
							Update
						</Button>
					) : (
						<Button
							onClick={createNwFilter}
							style={{ marginLeft: 8 }}
							type="primary"
							// disabled={`${command.checkValue(data).length ? 'true' : ''}`}
							small
							id="networkFilterConfirm">
							Confirm
						</Button>
					)}
				</div>
			</Row>
		</Modal>
	)
}
