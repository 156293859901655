import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button } from 'antd'

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ name: '' })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const onClose = () => {
		setVisible(false)
		globalContext.updateState('networkCreateAndUpdatePopup', null)
		setdata({ name: '' })
	}

	const createNetwork = async () => {
		let networkCreateAndUpdatePopup = globalContext.state.networkCreateAndUpdatePopup
		let newList = [...dataContext.state.getNetworks]

		let sendData = {
			name: data.name,
			networkType: 'test-network',
			clusterId: dataContext.state.list[0].id,
		}

		let res = await dataService.createNetwork(sendData)
		if (res.success) {
			// newList.push({
			// 	id: res.data.id,
			// 	name: res.data.name,
			// 	status: res.data.status,
			// 	clusterId: res.data.clusterId,
			// 	type: 'network',
			// })

			// dataContext.updateState('getNetworks', newList)
			onClose()

			globalContext.Snackbar(`Create [${res.data.name}] network operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title="New Network"
			placement="right"
			onClose={onClose}
			visible={globalContext.state.networkCreateAndUpdatePopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					<Button
						id="networkSubmit"
						onClick={createNetwork}
						disabled={`${command.checkValue(data).length ? 'true' : ''}`}
						type="primary"
						style={{ marginLeft: 8 }}
						small>
						Create
					</Button>
				</div>
			}>
			<label>
				<span className="requiredHint">*</span>Network Name
			</label>
			<Input placeholder="Enter here" defaultValue={data?.name} onChange={handleChange('name')} id="networkName" />
			<span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>Specify a unique name</span>
		</Drawer>
	)
}
