import React, { useState, useEffect } from 'react'
import { command } from '../../components'
import { createUseStyles } from 'react-jss'
import { InputNumber, Button, Checkbox, Row, Col } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	form: {
		'& .MuiTextField-root': {
			width: 250,
		},
	},
	fw: {
		width: '100%',
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const [data, setdata] = useState({
		cpu: modalData.cpu || 2,
		maxCpu: modalData.maxCpu || 2,
		memory: modalData.memory || 4,
		maxMemory: modalData.maxMemory || 4,
		socket: modalData.socket || '',
		core: modalData.core || '',
		thread: modalData.thread || '',
	})
	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		if (modalData.socket == undefined || modalData.socket == '') {
			setIsChecked(false)
		} else {
			setIsChecked(true)
		}
	}, [])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		// CPU and Memory Validation
		if (parseInt(valueTemp) < 1 || valueTemp === null) {
			valueTemp = '1'
		}

		switch (key) {
			case 'cpu':
				valueTemp = parseInt(valueTemp) > parseInt(data.maxCpu) ? data.maxCpu : valueTemp
				break
			case 'memory':
				valueTemp = parseInt(valueTemp) > parseInt(data.maxMemory) ? data.maxMemory : valueTemp
				break
			case 'maxCpu':
				valueTemp = parseInt(data.cpu) > parseInt(valueTemp) ? parseInt(data.cpu) : valueTemp
				break
			case 'maxMemory':
				valueTemp = parseInt(data.memory) > parseInt(valueTemp) ? parseInt(data.memory) : valueTemp
				break
		}
		setdata({ ...data, [key]: valueTemp?.toString() })
		modalDataChange({ ...data, [key]: valueTemp?.toString() })
	}

	const handleCheckboxChange = (e) => {
		const { checked } = e.target
		setIsChecked(checked)
		if (!checked) {
			setdata({ ...data, core: '', thread: '', socket: '' })
		} else {
			setdata({
				...data,
				core: modalData.core || '1',
				thread: modalData.thread || '1',
				socket: modalData.socket || data.maxCpu.toString(),
			})
		}
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}
	const cpuTopologyRender = () => {
		return (
			<div>
				<Row>
					<Col span={3}>Socket</Col>
					<Col span={3}>Core</Col>
					<Col span={3}>Thread</Col>
				</Row>
				<Row>
					<Col span={3}>
						<InputNumber
							placeholder="Socket"
							value={data?.socket || 1}
							min={1}
							onChange={handleChange('socket')}
							disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
							id="socket"
						/>
					</Col>
					<Col span={3}>
						<InputNumber
							placeholder="Core"
							value={data?.core || 1}
							min={1}
							onChange={handleChange('core')}
							disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
							id="core"
						/>
					</Col>
					<Col span={3}>
						<InputNumber
							placeholder="Thread"
							value={data?.thread || 1}
							min={1}
							onChange={handleChange('thread')}
							disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
							id="thread"
						/>
					</Col>
				</Row>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Max CPU
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.maxCpu || 1}
					min={data?.cpu || 1}
					onChange={handleChange('maxCpu')}
					disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
					className={classes.fw}
					id="vmCpuMax"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>CPU
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.cpu || 1}
					min={1}
					max={data?.maxCpu}
					onChange={handleChange('cpu')}
					className={classes.fw}
					id="vmCpu"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Max Memory
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.maxMemory || 1}
					min={data?.memory || 1}
					onChange={handleChange('maxMemory')}
					disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
					className={classes.fw}
					id="vmMemoryMax"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Memory
				</label>
				<InputNumber
					placeholder="Enter here"
					value={data?.memory || 1}
					min={1}
					max={data?.maxMemory}
					onChange={handleChange('memory')}
					className={classes.fw}
					id="vmMemory"
				/>
			</div>
			<div className="form-group-ant">
				<Checkbox
					onChange={handleCheckboxChange}
					disabled={modalData?.createOrUpdate == 'update' && modalData?.state != 'shutdown'}
					checked={isChecked}>
					Cpu Topology
				</Checkbox>
				{isChecked ? cpuTopologyRender() : null}
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={command.checkValue(data).length > 0}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</div>
	)
}
