import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import Instructions from './Instructions'
import Information from './Information'
import DiskMgmt from './DiskMgmt'
import VolOpts from './VolOpts'
import Complete from './Complete'
import { DataContext } from 'Context/DataContext'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 12,
		minHeight: 300,
		height: '100%',
		overflow: 'hidden',
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#A1B5CB;!important',
		color: '#0F427A;',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
	hide: {
		display: 'none',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('GlusterVolumePopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata({})
	}
	const getPeerId = (nodeId) => {
		let peer = dataContext?.state?.getGlusters[0]?.peers?.find((p) => p.nodeId == nodeId)
		let id = peer ? peer?.id : ''
		return id
	}
	useEffect(() => {
		if (globalContext.state.GlusterVolumePopup) {
			setdata(globalContext.state.GlusterVolumePopup)
		}
	}, [globalContext.state.GlusterVolumePopup])

	const createGlusterVolume = async () => {
		data.clusterId = dataContext?.state?.list[0]?.id
		data.glusterClusterId = dataContext?.state?.getGlusters[0]?.id
		data.type = 'glusterVolume'
		data.bricks.forEach((o) => {
			o.type = 'glusterVolumeBrick'
			o.glusterPeerId = getPeerId(o.nodeId)
			o.clusterId = dataContext?.state?.list[0]?.id
			o.glusterClusterId = dataContext?.state?.getGlusters[0]?.id
		})
		data.bricks = data.bricks.filter((o) => o.blockDeviceId)
		let sendData = data
		delete sendData['selectedRowKeys']
		data.bricks = data.bricks.filter((brick, index) => {
			const _brick = JSON.stringify(brick)
			return (
				index ===
				data.bricks.findIndex((obj) => {
					return JSON.stringify(obj) === _brick
				})
			)
		})
		let res = await dataService.createGlusterVolume(sendData)
		if (res) {
			if (res.success) {
				globalContext.Snackbar(`Create [${res.data.name}] gluster volume operation started successfully`, 'success')
				handleClose()
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		} else {
			globalContext.Snackbar('Service error!', 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
	}

	let selectedMenuList = [
		<Instructions next={next} />,
		<Information modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<DiskMgmt modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		// <VolOpts next={next} prev={prev} modalData={data} />,
		<Complete createGlusterVolume={createGlusterVolume} next={next} prev={prev} modalData={data} />,
	]

	return (
		<div>
			<Modal
				title={'Create Gluster Volume'}
				className="stepModal"
				width={1280}
				style={{ maxHeight: '90%!important', overflow: 'auto' }}
				visible={globalContext.state.GlusterVolumePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={8}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									} `}>
									<b>1</b>&nbsp;&nbsp;Instructions
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									} ${data.selection == 'information' && classes.hide} `}>
									<b>2</b>&nbsp;&nbsp;Volume Information
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									} ${data.selection == 'diskMgmt' && classes.hide}`}>
									<b>3</b>&nbsp;&nbsp;Disk Management
								</li>
								{/* <li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									} ${data.selection == 'volOpts' && classes.hide}`}>
									<b>4</b>&nbsp;&nbsp;Volume Options
								</li> */}
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									} ${data.selection == 'complete' && classes.hide}`}>
									<b>4</b>&nbsp;&nbsp;Summary
								</li>
							</ul>
						</div>
					</Col>
					<Col span={16} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
