import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, InputNumber, Button } from 'antd'
const { Option } = Select
const { TextArea } = Input

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		// TODO eğer local storage seçiliyse node değiştirirken otomatik ilgili node'un locali seçilmeli
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('moveContainerPopup', null)
	}

	useEffect(() => {
		if (globalContext.state.moveContainerPopup) {
			setdata(globalContext.state.moveContainerPopup)
		}
	}, [globalContext.state.moveContainerPopup])

	const setClusters = async () => {
		let res = await dataService.getClusters()
		if (res.success) {
			dataContext.updateState('list', res.data)
			var nodeArray = []
			if (res.data.length > 0) {
				res.data.forEach((singleCluster) => {
					if (typeof singleCluster.child != 'undefined') {
						nodeArray = nodeArray.concat(singleCluster.child)
					}
				})
			}
			dataContext.updateState('getNodes', nodeArray)
		} else {
			console.error(res.message)
		}
	}

	const moveContainer = async () => {
		let res = await dataService.moveContainer(data?.id, data)
		if (res.success) {
			setClusters()
			handleClose()
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<div>
			<Drawer
				title={'Move Container: ' + globalContext?.state?.moveContainerPopup?.name}
				placement="right"
				onClose={handleClose}
				width={480}
				visible={globalContext.state.moveContainerPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => moveContainer()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="containerMoveSubmit">
							Submit
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Node
					</label>
					<Select
						value={data?.nodeId}
						onChange={handleChange('nodeId')}
						className="fullW"
						id="containerMovePopupNodeName">
						{dataContext?.state?.list[0]?.child?.map((item) => (
							<Option value={item.id} disabled={data?.nodeId == item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
