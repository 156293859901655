import React, { useEffect } from 'react'
import { contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext } from 'components'
import vm from 'assets/img/fill/vm.svg'

import { createUseStyles } from 'react-jss'
import { Tree, Tooltip, Button, Empty, Select } from 'antd'
const { TreeNode } = Tree
const { Option } = Select
import { DatabaseOutlined, ExclamationCircleOutlined, FolderOpenOutlined } from '@ant-design/icons'

const useStyles = createUseStyles({
	root: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '3px 0',
		height: 40,
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
})

export default function SideMenuCluster() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	const [expanded, setExpanded] = React.useState([])
	const [selected, setSelected] = React.useState([])
	const [expandedKeys, setExpandedKeys] = React.useState([])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}
	const onExpand = (expandedKeys) => {
		setExpandedKeys(expandedKeys)
	}

	function handleContextMenu(e, obj) {
		if (!obj.type) return console.log('Context menu does not exists.', obj)
		// console.log("handleContextMenu", obj)
		e.preventDefault()
		e.stopPropagation()
		globalContext.openContextMenu(obj.type, obj, e)
	}
	const handleGroupView = (key) => (e) => {
		let valueTemp = e?.target ? (key == 'sGroupView' ? e.target.checked : e.target.value) : e
		dataContext.updateState(key, valueTemp)
	}

	const handleSelect = (event, obj) => {
		if (obj.type) {
			let link = `/${obj.type}/${obj.id}`
			switch (obj.type) {
				case 'vmStoragePool':
					link = `/cluster/${obj.clusterId}/vmStoragePool/${obj.id}`
					break
				case 'storagePoolGroup':
					link = `/`
					break
				default:
					alert('Yeni parametre eklediniz lutfen kontrol ediniz')
					break
			}
			navigate(link)
		}

		setSelected(obj.id)
	}

	const _getIcon = (item) => {
		switch (item.type) {
			case 'storagePoolGroup':
				return <FolderOpenOutlined className={classes.icon} />
				break
			case 'vmStoragePool':
				return <DatabaseOutlined className={classes.icon} />
				break

			default:
				return null
				break
		}
	}

	const _createList = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createList(el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}

	const renderTreeView = () => {
		switch (dataContext?.state?.sGroupView) {
			case 'vmStoragePool':
				return dataContext.state.getVmStoragePools?.length ? (
					<Tree expandedKeys={expandedKeys} onExpand={onExpand} className="tree-wrapper-mb2 vm-group-tab-content">
						{_createList(dataContext.state?.getVmStoragePools)}
					</Tree>
				) : (
					<Empty imageStyle={{ height: 40 }} description={<span>No storage pool.</span>} />
				)
				break
			case 'storagePoolGroup':
				return dataContext.state.getStoragePoolGroups?.length ? (
					<Tree expandedKeys={expandedKeys} onExpand={onExpand} className="tree-wrapper-mb1 cluster-tab-content">
						{_createList(dataContext.state?.getStoragePoolGroups)}
					</Tree>
				) : (
					<Empty imageStyle={{ height: 40 }} description={<span>No storage pool group.</span>} />
				)
				break
			default:
				return null
				break
		}
	}

	useEffect(() => {
		setExpanded([])
	}, [dataContext?.state?.getVmStoragePools, dataContext?.state?.getStoragePoolGroups])
	return (
		<>
			{renderTreeView()}

			{dataContext.state.sGroupView == 'storagePoolGroup' ? (
				<div
					style={{
						alignSelf: 'flex-end',
						marginLeft: 'auto',
						marginRight: 'auto',
						position: 'absolute',
						bottom: 40,
						left: 16,
						fontSize: 11,
						width: 275,
					}}>
					<Button
						id="storagePoolGroupCreate"
						onClick={() => globalContext.updateState('storagePoolGroupCreatePopup', {})}
						style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
						block>
						Create a Storage Pool Group
					</Button>
				</div>
			) : (
				<div
					style={{
						alignSelf: 'flex-end',
						marginLeft: 'auto',
						marginRight: 'auto',
						position: 'absolute',
						bottom: 40,
						left: 16,
						fontSize: 11,
						width: 275,
					}}>
					<Button
						id="storagePoolCreate"
						onClick={() => globalContext.updateState('vmStoragePoolCreatePopup', {})}
						style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
						block>
						Create a Vm Storage Pool
					</Button>
				</div>
			)}

			<div style={{ alignSelf: 'flex-end', marginLeft: 5, position: 'absolute', bottom: 8, left: 16, fontSize: 11 }}>
				<span style={{ fontSize: 14 }}>View: </span>
				<Select
					id="clusterViewSelect"
					value={dataContext?.state?.sGroupView}
					onChange={handleGroupView('sGroupView')}
					style={{ width: 235 }}>
					<Option value="vmStoragePool">Storage Pool</Option>
					<Option value="storagePoolGroup">Storage Pool Group</Option>
				</Select>
			</div>
		</>
	)
}
