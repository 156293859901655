///Main
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	changebaseURL,
	LazyLoader,
	config_i18n,
	AuthContextProvider,
	useAuthContext,
	GlobalContextProvider,
	useGlobalContext,
	DataContextProvider,
	SideMenuContextProvider,
	useDataContext,
} from '../'

import { ThemeProvider } from 'styled-components'
import { createUseStyles } from 'react-jss'
import { BrowserRouter } from 'react-router-dom'
const useStyles = createUseStyles((theme) => ({
	root: { background: '#f5f5f5' },
	header: { height: 72 },
	sideMenuAndContentContainer: {
		height: 'calc(100vh - 58px)',
		overflowY: 'hidden',
		display: 'flex',
	},
	shrinked: {
		height: 'calc(100% - 300px)!important',
	},
	sideMenu: {
		position: 'relative',
		overflow: 'hidden',
		transition: 'width 0.5s',
		background: 'white',
	},
	sideMenuOpen: {
		maxWidth: 320,
		minWidth: 310,
		boxShadow: 'inset -1px 0px 0px #F0F0F0',
	},
	contentContainer: {
		width: '100%',
		height: '100%',
	},
	content: {
		width: '100%',
		height: 'calc(100% - 72px)',
		marginLeft: 0,
		marginRight: 0,
		background: '#ffffff',
		overflowY: 'auto',
		overflowX: 'hidden',
		borderRadius: 0,
		borderLeft: 'none',
	},
	footer: {},
	online: {
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0,
		background: 'rgba(102,102,102,.6)',
		color: 'white',
		position: 'fixed',
		display: 'flex',
		zIndex: 999,
		justifyContent: 'center',
		alignItems: 'center',
	},
}))
export function Main({ Header, PublicHeader, SideMenu, PublicRouting, PrivateRoute, Footer }) {
	const authContext = useAuthContext()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [isOnline, setNetwork] = useState(navigator.onLine)
	const updateNetwork = () => {
		setNetwork(navigator.onLine)
	}
	const checkNetwork = () => {
		if (!isOnline) {
			return <div className={classes.online}>Check your internet connection!</div>
		}
	}
	const getSideMenu = () => {
		try {
			let Element = React.cloneElement(SideMenu, {
				authContext,
				globalContext,
				dataContext,
			})
			return Element
		} catch (error) {
			//console.log(error)
		}
	}
	const getHeader = () => {
		let seletedHeader
		if (!authContext.state.token) {
			seletedHeader = PublicHeader
		} else {
			seletedHeader = Header
		}
		if (!seletedHeader) return null
		try {
			let Element = React.cloneElement(seletedHeader, {
				authContext,
				globalContext,
				dataContext,
			})
			return Element
		} catch (error) {
			//console.log(error)
		}
	}

	const getRouting = () => {
		try {
			let seletedRoooting
			if (!authContext.state.token) {
				seletedRoooting = PublicRouting
			} else {
				seletedRoooting = PrivateRoute
			}
			let Element = React.cloneElement(seletedRoooting, {
				authContext,
				globalContext,
				dataContext,
			})
			return Element
		} catch (error) {
			//console.log(error)
		}
	}

	useEffect(() => {
		window.addEventListener('offline', updateNetwork)
		window.addEventListener('online', updateNetwork)
		return () => {
			window.removeEventListener('offline', updateNetwork)
			window.removeEventListener('online', updateNetwork)
		}
	})

	if (!authContext.state.token) {
		return (
			<div className={classes.root}>
				{checkNetwork()}
				{getHeader() && <div className={classes.header}>{getHeader()}</div>}
				<div className={classes.contentContainer}>
					<React.Suspense fallback={<LazyLoader />}>
						<div className={classes.content}>{getRouting()}</div>
					</React.Suspense>
				</div>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{/* {checkNetwork()} */}
			<div className={classes.sideMenuAndContentContainer}>
				<div
					className={` ${classes.sideMenu} ${classes.sideMenuOpen} ${
						globalContext.state.openTask ? classes.shrinked : ''
					}`}>
					{getSideMenu()}
				</div>
				<div className={`${classes.contentContainer} ${globalContext.state.openTask ? classes.shrinked : ''}`}>
					<div className={classes.header}>{getHeader()}</div>
					<React.Suspense fallback={<LazyLoader />}>
						<div className={classes.content}> {getRouting()}</div>
					</React.Suspense>
				</div>
			</div>
			<div className={` ${classes.footer}  `}>
				<Footer />
			</div>
		</div>
	)
}

/**
 * Ana Yapıyı Oluşturmak için Kullanılır
 * @param  {object} PublicHeader - Herkesin görebilecegi Sayfanın üst tarafında gorunecek component
 * @param  {object} Header - Giriş yapıldıktan sonra sayfanın üst tarafında gorunecek component
 * @param  {object} PublicRouting - Herkesin görebilecegi site içi gezinme için Routing sitemi
 * @param  {object} PrivateRoute - Giriş yapıldıktan sonra site içi gezinme için Routing sistemi
 * @param  {object} SideMenu - Giriş yapıldıktan sonra sol tarafta gözükecek menu listesi
 * @param {string} baseUrl - Servislerin  baglanacak olan site IP'si
 * @param  {object} theme - Site içinde kullanılacak componentlerin şekillendirlmesi ve reklendirilmesi için kullanılacak theme dosyası
 * @param  {object} dataContextProviderInitialState - Data context'in başlangıç degeri eklemek için kullanılır
 * @param  {object} authContextProviderInitialState - Auth context'in başlangıç degeri eklemek için kullanılır
 * @param  {object} languages - Eklenmesini istediginiz translate dokumantasyonunuzu ekleyiniz. Bu sayede çeviri butonunda istediginiz seçeneği aktif etmiş olacaksınız
 **/
export default function MainContainer({
	baseUrl,
	theme,
	languages,
	modals = () => null,
	contextMenu = () => null,
	...props
}) {
	let Modals = modals
	let ContextMenu = contextMenu
	const dataContext = useDataContext()
	config_i18n(languages)
	useEffect(() => {
		if (baseUrl) {
			changebaseURL(baseUrl)
		}
	}, [baseUrl])

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<GlobalContextProvider>
					<SideMenuContextProvider>
						<AuthContextProvider initialState={props.authContextProviderInitialState}>
							<DataContextProvider initialState={props.dataContextProviderInitialState}>
								<Main {...props} />
								<Modals />
								<ContextMenu />
							</DataContextProvider>
						</AuthContextProvider>
					</SideMenuContextProvider>
				</GlobalContextProvider>
			</BrowserRouter>
		</ThemeProvider>
	)
}

Main.propTypes = {
	PublicHeader: PropTypes.object,
	Header: PropTypes.object,
	PublicRouting: PropTypes.object,
	PrivateRoute: PropTypes.object,
	SideMenu: PropTypes.object,
	baseUrl: PropTypes.string,
	theme: PropTypes.object,
	dataContextProviderInitialState: PropTypes.object,
	authContextProviderInitialState: PropTypes.object,
}

Main.defaultProps = {
	PublicHeader: null,
	Header: null,
	PublicRouting: null,
	PrivateRoute: null,
	SideMenu: null,
	baseUrl: '',
	theme: {},
	dataContextProviderInitialState: null,
	authContextProviderInitialState: null,
	Footer: null,
}
