import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	fw: {
		width: '100%',
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [nodeList, setnodeList] = useState([])
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({
		clusterId: modalData.clusterId || globalContext.state?.VMCreateAndUpdatePopup?.clusterId,
		nodeId: modalData.nodeId || globalContext.state?.VMCreateAndUpdatePopup?.id,
		storagePoolId: modalData.storagePoolId || '',
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
		modalDataChange({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}

		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}
	useEffect(() => {
		fetch()
	}, [])

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Node
				</label>
				<Select
					value={data?.nodeId}
					onChange={handleChange('nodeId')}
					className={classes.fw + ' vmHost'}
					disabled={modalData?.createOrUpdate == 'update'}>
					{nodeList.map((item) => (
						<Option value={item.id}>
							<span style={{ fontWeight: 'bold' }}>{item.name}</span> - ({item.nodeStat.cpuPercentage}% CPU) (
							{item.nodeStat.memPercentage}% Memory)
						</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Volume
				</label>
				<Select
					value={data?.storagePoolId}
					onChange={handleChange('storagePoolId')}
					className={classes.fw + ' vmStoragePool'}
					disabled={modalData?.createOrUpdate === 'update'}>
					{storageList.map((item) => {
						if (item?.poolUnits?.map((pu) => pu?.nodeId)?.includes(data?.nodeId)) {
							return (
								<Option key={item.id} value={item.id}>
									<span style={{ fontWeight: 'bold' }}>{item.name}</span> - {item.vmStoragePoolStat.allocation}{' '}
									/ {item.vmStoragePoolStat.capacity} ({item.vmStoragePoolStat.percentage}%)
								</Option>
							)
						}
						return null
					})}
				</Select>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					className="next"
					style={{ marginLeft: 8 }}
					disabled={command.checkValue(data).length > 0}
					type="primary"
					small>
					Save & Next
				</Button>
			</div>
		</div>
	)
}
