import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import { createUseStyles } from 'react-jss'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	fw: {
		width: '100%',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [storageList, setstorageList] = useState([])
	const [nodeList, setnodeList] = useState([])
	const [data, setdata] = useState({ ...globalContext.state.vmTemplateCreatePopup })

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('vmClonePopup', null)
		setdata({
			name: '',
			nodeId: '',
			storagePoolId: '',
		})
	}

	const fetch = async () => {
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			setstorageList(responseStorage.data)
			dataContext.updateState('getVmStoragePools', responseStorage.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
			dataContext.updateState('getVmStoragePools', [])
		}
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}
	}
	useEffect(() => {
		globalContext.state.vmClonePopup && fetch()
	}, [globalContext.state.vmClonePopup])

	const cloneVm = async () => {
		let sendData = {
			vmName: data?.name,
			vmId: globalContext?.state?.vmClonePopup?.id,
			targetNodeId: data?.nodeId,
			targetStoragePoolId: data?.storagePoolId,
		}
		let res = await dataService.cloneVm(sendData)
		if (res.success) {
			let res2 = await dataService.getClusters()
			if (res2.success) {
				dataContext.updateState('list', res2.data)
			}
			handleClose()
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<div>
			<Drawer
				title={'Clone VM'}
				placement="right"
				width={450}
				onClose={handleClose}
				visible={globalContext.state.vmClonePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => cloneVm()}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={`${data?.objects?.length == 0 && data?.name?.length == 0 ? 'true' : ''}`}
							small
							id="vmCloneSubmit">
							Submit
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>VM Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="vmCloneVmName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Host
					</label>
					<Select
						value={data?.nodeId}
						onChange={handleChange('nodeId')}
						className="fullW"
						id="computeResourceHost">
						{nodeList.map((item) => (
							<Option value={item.id}>
								<span style={{ fontWeight: 'bold' }}>{item.name}</span> - ({item.nodeStat.cpuPercentage}% CPU) (
								{item.nodeStat.memPercentage}% Memory)
							</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Storage Pool
					</label>
					<Select
						value={data?.storagePoolId}
						onChange={handleChange('storagePoolId')}
						className={classes.fw + ' vmStoragePool'}>
						{storageList.map((item) => {
							if (item?.poolUnits?.map((pu) => pu?.nodeId)?.includes(data?.nodeId)) {
								return (
									<Option key={item.id} value={item.id}>
										<span style={{ fontWeight: 'bold' }}>{item.name}</span> -{' '}
										{item.vmStoragePoolStat.allocation} / {item.vmStoragePoolStat.capacity} (
										{item.vmStoragePoolStat.percentage}%)
									</Option>
								)
							}
							return null
						})}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
