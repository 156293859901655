import React, { useState, useEffect } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Button, Select, Switch } from 'antd'
const { Option } = Select
import dataService from 'service/dataService'

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState({
		name: '',
		description: '',
		state: '',
	})

	useEffect(() => {
		if (modalData) {
			setdata({
				name: modalData.name,
				description: modalData.description,
				state: modalData.state,
				wipeEntirely: modalData.wipeEntirely,
			})
		}
	}, [modalData])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<h4 style={{ marginBottom: 10 }}>General Properties</h4>

			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Backup Profile Name
				</label>
				<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="backupPCreateName" />
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Description
				</label>
				<Input
					placeholder="Enter here"
					value={data?.description}
					onChange={handleChange('description')}
					id="backupPCreateDesc"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>State
				</label>
				<Select
					value={data?.state}
					onChange={handleChange('state')}
					className="fullW"
					id="backupProfileCreateStateSelect">
					<Option value={'active'}>Active</Option>
					<Option value={'passive'}>Passive</Option>
				</Select>
			</div>
			<div
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'space-between',
				}}>
				<div>
					<span style={{ fontSize: 14 }}>Wipe Entirely: </span>
					<Switch
						checked={data?.wipeEntirely}
						onChange={handleChange('wipeEntirely')}
						name="wipeEntirely"
						color="primary"
					/>
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => nextStep()} style={{ marginLeft: 8 }} type="primary" small className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
