import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles(() => ({
	root: {
		height: '100%',
		position: 'relative',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		position: 'relative',
		bottom: 20,
		width: '100%',
		textAlign: 'right',
		right: 4,
		alignItems: 'end',
		marginTop: 'auto',
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	fw: {
		width: '100%',
	},
	netContainer: {
		overflowY: 'auto',
		maxHeight: '80%',
	},
	netRow: {
		display: 'flex',
		width: '95%',
		padding: '5px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	net: {
		width: '30%',
		minWidth: 140,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 16,
		paddingRight: 16,
	},
	act: {
		width: '25%',
		minWidth: 120,
		paddingLeft: 12,
		paddingRight: 12,
		'& button': {
			width: '100%',
		},
	},
	mac: {
		width: '40%',
		minWidth: 140,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 10,
		paddingRight: 10,
	},
}))

export default function Network({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const [networkProfilesList, setnetworkProfilesList] = useState([])
	const [networksList, setnetworksList] = useState([])

	const [data, setdata] = useState(
		modalData.networkInterfaces || [
			{
				connectedNetworkId: null,
				connectedNetworkProfileId: null,
				networkFilterId: null,
			},
		]
	)

	const netKeys = ['connectedNetworkProfileId', 'connectedNetworkId']

	const isDefaultNetwork = (i) => {
		let activeNet = networksList.find((n) => n.id == data?.[i]?.connectedNetworkId)
		return activeNet?.name == 'default' ? true : false
	}

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		if (netKeys.includes(key) && valueTemp == '') {
			newData[index]['connectedNetworkProfileId'] = valueTemp
			newData[index]['connectedNetworkId'] = valueTemp
			newData[index]['networkFilterId'] = valueTemp
		} else {
			newData[index][key] = valueTemp
			if (key == 'connectedNetworkId') {
				let activeNet = networksList.find((n) => n.id == valueTemp)
				if (activeNet?.name == 'default') {
					let defNetP = networkProfilesList.find((np) => np.name == 'default')
					newData[index]['connectedNetworkProfileId'] = defNetP.id
				}
			}
		}
		let newData2 = newData.filter((item) => item.connectedNetworkProfileId)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData2 })
	}

	const nextStep = () => {
		let newData = data.filter((item) => item.connectedNetworkProfileId)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
		next()
	}
	const add = () => {
		let newData = [...data]
		let defNet = networksList.find((np) => np.name == 'default')
		let defNetP = networkProfilesList.find((np) => np.name == 'default')

		newData.push({
			connectedNetworkId: defNet.id || null,
			connectedNetworkProfileId: defNetP.id || null,
			networkFilterId: null,
		})
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
	}
	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
	}
	const fetch = async () => {
		let responseNetworkProfiles = await dataService.getNetworkProfiles()
		if (responseNetworkProfiles.success) {
			setnetworkProfilesList(responseNetworkProfiles.data)
			dataContext.updateState('getNetworkProfiles', responseNetworkProfiles.data)
		} else {
			globalContext.Snackbar(responseNetworkProfiles.errorMessage, 'error')
			setnetworkProfilesList([])
			dataContext.updateState('getNetworkProfiles', [])
		}

		let responseNetworks = await dataService.getNetworks()
		if (responseNetworks.success) {
			setnetworksList(responseNetworks.data)
			dataContext.updateState('getNetworks', responseNetworks.data)
		} else {
			globalContext.Snackbar(responseNetworks.errorMessage, 'error')
			setnetworksList([])
			dataContext.updateState('getNetworks', [])
		}
	}

	const sortDataByNetworkName = () => {
		const sortedData = [...data].sort((a, b) => {
			const numA = parseInt(a?.name?.match(/\d+$/)[0])
			const numB = parseInt(b?.name?.match(/\d+$/)[0])
			return numA - numB
		})
		setdata(sortedData)
	}

	useEffect(() => {
		const fetchAndSortData = async () => {
			await fetch()
			sortDataByNetworkName()
		}
		fetchAndSortData()
	}, [])

	return (
		<div className={classes.root}>
			<div className={classes.netContainer}>
				<div className={classes.netRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
					<span className={classes.hash}>#</span>
					<span className={classes.mac}>
						<span className="requiredHint">*</span>MAC
					</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span>Network
					</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span>Network Profile
					</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span>Network Filter
					</span>
					<span className={classes.act}></span>
				</div>
				{data.map((item, index) => (
					<div className={classes.netRow}>
						<span className={classes.hash}>{index + 1}</span>
						<div className={classes.mac}>
							<span className="">{item?.macAddress}</span>
						</div>
						<div className={classes.net}>
							<Select
								className="vmNetwork"
								value={item?.connectedNetworkId}
								onChange={(e) => handleChange(e, 'connectedNetworkId', index)}
								style={{ width: '100%' }}>
								{networksList.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div className={classes.net}>
							<Select
								className="vmNetworkProfile"
								value={item?.connectedNetworkProfileId}
								onChange={(e) => handleChange(e, 'connectedNetworkProfileId', index)}
								disabled={isDefaultNetwork(index)}
								style={{ width: '100%' }}>
								{networkProfilesList.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div className={classes.net}>
							<Select
								className="vmNetworkFilter"
								value={item?.networkFilterId || ''}
								onChange={(e) => handleChange(e, 'networkFilterId', index)}
								style={{ width: '100%' }}>
								<Option value="">None</Option>
								{dataContext?.state?.getNetworkFilters?.map((item) => (
									<Option value={item.id}>{item.name}</Option>
								))}
							</Select>
						</div>
						<div className={classes.act}>
							<span
								onClick={() => del(index)}
								style={{ color: '#0f427a', cursor: 'pointer' }}
								className="vmNetConfigDelete">
								Delete
							</span>
						</div>
					</div>
				))}
			</div>
			<Button
				block
				onClick={() => add()}
				disabled={data.filter((item) => !item.connectedNetworkId || !item.connectedNetworkProfileId).length > 0}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				id="vmNetConfigAdd">
				+ Add Network Interface
			</Button>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					className="next"
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					type="primary"
					disabled={data.filter((item) => !item.connectedNetworkId).length > 0}
					small>
					Save & Next
				</Button>
			</div>
		</div>
	)
}
