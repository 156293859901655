// import React, { Fragment, useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
// import { command, useDataContext } from '../../components'
// import TextField from '@material-ui/core/TextField'
// import FormControl from '@material-ui/core/FormControl'
// import ButtonGroup from '@material-ui/core/ButtonGroup'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import InputLabel from '@material-ui/core/InputLabel'
// import Checkbox from '@material-ui/core/Checkbox'
// import ListItemIcon from '@material-ui/core/ListItemIcon'
// import ListItemText from '@material-ui/core/ListItemText'

// import dataService from 'service/dataService'

import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { InputNumber, Button, Select, TimePicker } from 'antd'
const { Option } = Select
import moment from 'moment'
import dataService from 'service/dataService'

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
	formControl50: {
		width: '50%',
	},
	formControl100: {
		width: '100%',
		display: 'inline-flex',
	},
	hide: {
		display: 'none',
	},
	input50: {
		width: '50%!important',
		marginLeft: '0!important',
	},
}))

export default function Network({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const [selectedDate, handleDateChange] = useState(`${new Date().getHours()}:${new Date().getMinutes()}`)
	const [data, setdata] = useState({
		incrementalSchedulePeriod:
			(modalData?.incrementalSchedulePeriod == 'periodically'
				? modalData?.periodically2
				: modalData?.incrementalSchedulePeriod) || 'day',
		incrementalScheduleClock:
			(modalData?.incrementalSchedulePeriod == 'periodically'
				? modalData?.periodically2 == 'hour'
					? modalData?.hour
					: modalData?.minute
				: modalData?.incrementalScheduleClock) || selectedDate,
		incrementalScheduleDays: ['everyday'],
		incrementalScheduleRetention: modalData?.incrementalScheduleRetention || '',
		monthlyDays: modalData?.incrementalSchedulePeriod == 'month' ? modalData?.incrementalScheduleDays : [],
		weeklyDays:
			modalData?.incrementalSchedulePeriod == 'week'
				? modalData?.weeklyDays || modalData?.incrementalScheduleDays
				: [],
		periodically2: 'hour',
		hour: (modalData?.incrementalSchedulePeriod == 'hour' ? modalData?.incrementalScheduleClock : 1) || 1,
		minute: (modalData?.incrementalSchedulePeriod == 'minute' ? modalData?.incrementalScheduleClock : 15) || 15,
	})

	const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
	const daysMonths = Array.from({ length: 30 }, (_, i) => i + 1)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'incrementalScheduleRetention') {
			if (parseInt(valueTemp) < 1) {
				valueTemp = '1'
			} else if (parseInt(valueTemp) > 30) {
				valueTemp = '30'
			}
		} else if (key == 'incrementalScheduleClock') {
			let hh = moment(valueTemp).hour() < 10 ? '0' + moment(valueTemp).hour() : moment(valueTemp).hour().toString()
			let mm =
				moment(valueTemp).minute() < 10 ? '0' + moment(valueTemp).minute() : moment(valueTemp).minute().toString()
			valueTemp = hh + ':' + mm
		}
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		variant: 'menu',
	}

	const format = 'HH:mm'

	useEffect(() => {
		if (data?.incrementalSchedulePeriod == 'minute' || data?.incrementalSchedulePeriod == 'hour') {
			setdata({
				...data,
				periodically2: data?.incrementalSchedulePeriod,
				incrementalSchedulePeriod: 'periodically',
				[data?.incrementalSchedulePeriod]: data?.incrementalScheduleClock,
			})
		}
	}, [])

	return (
		<div className={classes.root}>
			<h4>Select a type for schedule</h4>

			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Type
				</label>
				<Select
					value={data?.incrementalSchedulePeriod}
					onChange={handleChange('incrementalSchedulePeriod')}
					className="fullW"
					id="scheduleType">
					<Option value={'day'}>Daily at</Option>
					<Option value={'week'}>Weekly at</Option>
					<Option value={'month'}>Monthly at</Option>
					<Option value={'periodically'}>Periodically every</Option>
				</Select>
			</div>
			{data?.incrementalSchedulePeriod != 'periodically' ? (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Time
					</label>
					<TimePicker
						className="fullW"
						value={moment(data?.incrementalScheduleClock, format)}
						onChange={handleChange('incrementalScheduleClock')}
						format={format}
					/>
				</div>
			) : (
				''
			)}

			{data?.incrementalSchedulePeriod == 'week' ? (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Days
					</label>
					<Select
						mode="multiple"
						value={data?.weeklyDays}
						onChange={handleChange('weeklyDays')}
						className="fullW"
						id="scheduleWeekDays">
						{days.map((item) => (
							<Option value={item}>{item}</Option>
						))}
					</Select>
				</div>
			) : (
				''
			)}

			{data?.incrementalSchedulePeriod == 'month' ? (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Days
					</label>
					<Select
						mode="multiple"
						value={data?.monthlyDays}
						onChange={handleChange('monthlyDays')}
						className="fullW"
						id="scheduleMonthDays">
						{daysMonths.map((item) => (
							<Option value={item}>{item}</Option>
						))}
					</Select>
				</div>
			) : (
				''
			)}

			<div
				className={`${classes.formControl100} ${
					data?.incrementalSchedulePeriod != 'periodically' ? classes.hide : ''
				}`}
				style={{ width: '100%' }}>
				{data?.periodically2 == 'minute' ? (
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Minute
						</label>
						<InputNumber
							placeholder="Enter here"
							className="fullW"
							min={15}
							max={60}
							value={data?.minute}
							onChange={handleChange('minute')}
							id="scheduleMinute"
						/>
					</div>
				) : (
					''
				)}

				{data?.periodically2 == 'hour' ? (
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Hour
						</label>
						<InputNumber
							placeholder="Enter here"
							min={1}
							className="fullW"
							max={24}
							value={data?.hour}
							onChange={handleChange('hour')}
							id="scheduleHour"
						/>
					</div>
				) : (
					''
				)}

				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>
					</label>
					<Select
						value={data?.periodically2}
						onChange={handleChange('periodically2')}
						className="fullW"
						id="schedulePeriodically">
						<Option value={'hour'}>Hours</Option>
						<Option value={'minute'}>Minutes</Option>
					</Select>
				</div>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Restore Points
				</label>
				<InputNumber
					placeholder="Enter here"
					min={1}
					className="fullW"
					max={30}
					value={parseInt(data?.incrementalScheduleRetention)}
					onChange={handleChange('incrementalScheduleRetention')}
					id="scheduleRestorePoints"
				/>
			</div>

			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={command.checkValue(data).length > 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
