import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Input, Select, Button, Switch, Table, Tooltip, Empty, Divider, Space, Typography } from 'antd'
const { Link } = Typography
import { useDataContext, useGlobalContext } from '../../components' // Adjust the import paths as needed
const { Option } = Select
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
const useStyles = createUseStyles({
	root: {
		maxHeight: '80%', // Adjust this height as needed
		// position: 'relative',
		display: 'flex',
		// paddingBottom: 40,
		overflow: 'hidden', // Ensure the container handles overflow correctly
	},
	input: { width: '100%' },
	footerHead: {
		height: '10%',
		position: 'relative',
		width: '100%',
		display: 'flex',
		textAlign: 'right',
		alignItems: 'end',
		justifyContent: 'right',
	},
	footer: {
		height: '10%',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 25,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
})

export default function Storage({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()
	const classes = useStyles()

	const [data, setdata] = useState({
		mountClaims: modalData.mountClaims || [
			{
				clusterId: dataContext?.state?.list[0]?.id,
				name: '',
				volumePathId: '',
				readOnly: false,
				destinationPath: '',
				sourceSubPath: '',
			},
		],
	})

	const [vpList, setVPList] = useState([])

	useEffect(() => {
		let poolList = dataContext?.state?.getVmStoragePools?.filter(
			(pool) =>
				pool.poolUnits.some((unit) => unit.nodeId === modalData?.nodeId) &&
				(pool?.poolType == 'local' || !pool?.isoStorage)
		)
		let newVPlist = dataContext?.state?.getVolumePaths?.filter((vp) => !poolList?.includes(vp?.storagePoolId))
		setVPList(newVPlist)
	}, [data?.nodeId, dataContext?.state?.getVolumePaths])

	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata((prevState) => {
			const newData = [...prevState.mountClaims]
			newData[index] = {
				...newData[index],
				[key]: valueTemp,
			}
			return {
				...prevState,
				mountClaims: newData,
			}
		})
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	const addMountClaim = () => {
		const newMountClaim = {
			clusterId: dataContext?.state?.list[0]?.id,
			name: '',
			volumePathId: '',
			readOnly: false,
			destinationPath: '',
			sourceSubPath: '',
		}

		setdata((prevState) => ({
			...prevState,
			mountClaims: [...prevState.mountClaims, newMountClaim],
		}))
	}

	const deleteMountClaim = (index) => {
		setdata((prevState) => {
			let updatedMountClaims = [...prevState.mountClaims]
			updatedMountClaims.splice(index, 1)
			return {
				...prevState,
				mountClaims: updatedMountClaims,
			}
		})
	}

	const renderTooltipContent = (vp) => (
		<div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
			<p style={{ marginBottom: '0px' }}>
				Storage Pool: {dataContext?.state?.getVmStoragePools?.find((sp) => sp.id == vp.storagePoolId).name}
			</p>
			{/* <p>Node ID: {vp.nodeId}</p> */}
			<p style={{ marginBottom: '0px' }}>Volume Path Size: {vp.size} GB</p>
			<p style={{ marginBottom: '0px' }}>Read Only: {vp.readOnly ? 'Yes' : 'No'}</p>
		</div>
	)

	const getStatusIcon = (status) => {
		if (status == 'FAILED') {
			return <ExclamationCircleOutlined className={classes.errorIcon} />
		} else {
			return null
		}
	}

	const columns = [
		// Disk Order is irrelevant in the context of Container Volumes
		{
			title: 'Mount Claim Name',
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			render: (text, row, index) => (
				<Input
					style={{ borderColor: row?.name ? '' : 'red' }}
					onChange={(e) => handleChange(e, 'name', index)}
					value={row.name}
					placeholder={'Enter Here'}
				/>
			),
		},
		{
			title: 'Absolute Destination Path',
			// title: <div>xxx <Tooltip title="tooltip"><InfoCircleOutlined style={{ color: '#c25858', marginRight: 6 }}/></Tooltip></div>,
			dataIndex: 'destinationPath',
			key: 'destinationPath',
			width: '25%',
			render: (text, row, index) => (
				<Input
					style={{ borderColor: row?.destinationPath ? '' : 'red' }}
					onChange={(e) => handleChange(e, 'destinationPath', index)}
					value={row.destinationPath}
					placeholder={'e.g. /srv/example/www'}
				/>
			),
		},
		{
			title: 'Source Volume Path and Relative Subpath',
			dataIndex: 'sourceSubPath',
			key: 'sourceSubPath',
			width: '35%',
			render: (text, row, index) => {
				const vps = (
					<Select
						style={{ minWidth: '150px', width: '90%', textAlign: 'left' }}
						className="fullW"
						onChange={(e) => handleChange(e, 'volumePathId', index)}
						status={row?.volumePathId ? '' : 'error'}
						notFoundContent={
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Volume Path Found'}>
								{/* <Button type={'primary'}
							onClick={() => globalContext.updateState('volumePathCreateAndUpdatePopup', {})}>
							Add Volume Path
						</Button> */}
							</Empty>
						}
						dropdownRender={(menu) => (
							<>
								{menu}
								<Divider
									style={{
										margin: '8px 0',
									}}
								/>
								<Space
									style={{
										padding: '0 8px 4px',
									}}>
									<Button
										type={'default'}
										icon={<PlusOutlined />}
										onClick={() =>
											globalContext.updateState('volumePathCreateAndUpdatePopup', {
												nodeId: modalData?.nodeId,
												createOrUpdate: 'inlineCreate',
											})
										}>
										Volume Path
									</Button>
								</Space>
							</>
						)}
						defaultValue={row?.volumePathId}
						defaultOpen={row?.volumePathId == ''}
						autoFocus={row?.volumePathId == ''}
						dropdownStyle={{ minWidth: '150px' }}
						showSearch={true}
						optionFilterProp="label">
						{vpList?.map((vp) => (
							<Option key={vp.id} value={vp.id} disabled={vp.status == 'FAILED'} label={vp.name}>
								<Tooltip title={renderTooltipContent(vp)} placement="rightTop">
									{vp.name} {getStatusIcon(vp.status)}
								</Tooltip>
							</Option>
						))}
					</Select>
				)
				return (
					<Input
						onChange={(e) => handleChange(e, 'sourceSubPath', index)}
						value={row.sourceSubPath}
						placeholder={'e.g. example/www'}
						addonBefore={vps}
					/>
				)
			},
		},
		{
			title: 'Read Only',
			dataIndex: 'readOnly',
			key: 'readOnly',
			width: '11%',
			render: (text, row, index) => (
				<Switch onChange={(e) => handleChange(e, 'readOnly', index)} value={row.readOnly} checked={row.readOnly} />
			),
		},
		{
			title: '',
			dataIndex: '-',
			key: 'action',
			width: '9%',
			render: (text, row, index) => (
				<Link onClick={() => deleteMountClaim(index)} id="unclaimVP" type="danger">
					Unclaim
				</Link>
			),
		},
	]

	return (
		<>
			<div className={classes.root}>
				<Table
					pagination={false}
					dataSource={data?.mountClaims}
					columns={columns}
					scroll={{ y: '50vh' }} // Set the height for vertical scrolling
				/>
			</div>
			<Button
				block
				onClick={addMountClaim}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}>
				+ Add Mount Claim
			</Button>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.mountClaims.some(
						(mc) => mc?.volumePathId === '' || mc?.destinationPath === '' || mc?.name === ''
					)}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</>
	)
}
