import React, { useState, useEffect } from 'react'
import { Button, Drawer, Input, message, Select } from 'antd'
import { useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'

const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setData] = useState({})
	const [visible, setVisible] = useState(false)

	// handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'userRole' && data?.userRole !== valueTemp) {
			setData({ ...data, [key]: valueTemp, realmIds: [] })
		} else {
			setData({ ...data, [key]: valueTemp })
		}
	}

	const handleClose = () => {
		setVisible(false)
		globalContext.updateState('localUserPopup', null)
		setData({ profileName: '' })
	}

	useEffect(() => {
		if (globalContext.state.localUserPopup?.createOrUpdate === 'update') {
			let luTmp = { ...globalContext.state.localUserPopup }
			luTmp['realmIds'] = luTmp?.realmIds?.map((r) => {
				return r.split(':')?.[1]
			})
			setData(luTmp)
		}
	}, [globalContext.state.localUserPopup])

	const createLocalUser = async () => {
		let sendData = {
			clusterId: dataContext?.state?.list[0]?.id || '',
			userName: data?.userName,
			password: data?.password,
			userRole: data?.userRole,
			realmIds:
				data?.userRole === 'clusterAdmin'
					? data?.realmIds?.map((clusterId) => `cluster:${clusterId}`)
					: data?.realmIds?.map((tenantId) => `tenant:${tenantId}`),
		}

		let res = await dataService.createLocalUser(sendData)
		if (res.success) {
			handleClose()
			message.success('Create local user operation started successfully')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateLocalUser = async () => {
		let sendData = {
			clusterId: dataContext?.state?.list[0]?.id || '',
			id: data.id,
			userName: data?.userName,
			password: data?.password,
			newPassword: data?.newPassword,
			userRole: data?.userRole,
			realmIds:
				data?.userRole === 'clusterAdmin'
					? data?.realmIds?.map((clusterId) => `cluster:${clusterId}`)
					: data?.realmIds?.map((tenantId) => `tenant:${tenantId}`),
		}

		let res = await dataService.updateLocalUser(sendData)
		if (res.success) {
			message.success('Update local user operation started successfully')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title={data?.id ? 'Update Local User' : 'New Local User'}
			placement="right"
			onClose={handleClose}
			visible={globalContext.state.localUserPopup || false}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={handleClose} small>
						Cancel
					</Button>
					{data?.id ? (
						<Button onClick={updateLocalUser} style={{ marginLeft: 8 }} type="primary" id="localUserSubmit" small>
							Update
						</Button>
					) : (
						<Button id="localUserSubmit" onClick={createLocalUser} style={{ marginLeft: 8 }} type="primary" small>
							Confirm
						</Button>
					)}
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Username
				</label>
				<Input
					placeholder="Enter here"
					value={data?.userName}
					onChange={handleChange('userName')}
					id="localUserName"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Password
				</label>
				<Input.Password
					placeholder="Enter here"
					value={data?.password}
					onChange={handleChange('password')}
					id="localUserPassword"
				/>
			</div>
			{data?.id && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>New Password
					</label>
					<Input.Password
						placeholder="Enter here"
						value={data?.newPassword}
						onChange={handleChange('newPassword')}
						id="localUserNewPassword"
					/>
				</div>
			)}
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Role
				</label>
				<Select
					style={{ width: '100%' }}
					onChange={handleChange('userRole')}
					value={data?.userRole}
					placeholder="Select Role">
					<Option value="clusterAdmin">Cluster Admin</Option>
					<Option value="tenantAdmin">Tenant Admin</Option>
				</Select>
			</div>
			{data?.userRole === 'clusterAdmin' && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Clusters
					</label>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						onChange={handleChange('realmIds')}
						value={data?.realmIds}>
						{dataContext.state.list.map((cluster) => (
							<Option key={cluster.id} value={cluster.id}>
								{cluster.name}
							</Option>
						))}
					</Select>
				</div>
			)}
			{data?.userRole === 'tenantAdmin' && (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Tenants
					</label>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						onChange={handleChange('realmIds')}
						value={data?.realmIds}>
						{dataContext?.state?.tenants?.map((tenant) => (
							<Option key={tenant.id} value={tenant.id}>
								{tenant.name}
							</Option>
						))}
					</Select>
				</div>
			)}
		</Drawer>
	)
}
