import React, { useState, useEffect } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Select, Button, Switch, InputNumber, Row, Col, Tooltip } from 'antd'
import dataContext from '../../Context/DataContext'
import TextArea from 'antd/es/input/TextArea'
const { Option } = Select

const useStyles = createUseStyles({
	root: {
		height: '90%',
		position: 'relative',
		paddingBottom: 40,
		overflowY: 'auto',
	},
	input: {
		width: '100%',
	},
	footer: {
		height: '10%',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 25,
	},
	dhcpClient: {
		width: '100%',
		padding: '10px 0 0 0',
	},
	row: {
		paddingBottom: 5,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Network({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()

	const [data, setdata] = useState({
		networkDriverType: modalData.networkDriverType || 'cni',
		networkInterfaces: modalData?.networkInterfaces?.length
			? modalData.networkInterfaces
			: [
					{
						connectedNetworkId: '',
						connectedNetworkProfileId: '',
						useDhcpClient: true,
						gateway: '',
						nameServers: [''],
						ipAddresses: [''],
					},
			  ],
		portBindings: modalData?.portBindings?.length
			? modalData.portBindings
			: [{ nodePort: 0, containerPort: 80, protocol: 'tcp' }],
	})

	const handleChangeSelect = (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({
			...data,
			networkDriverType: valueTemp,
		})
	}

	const handleChangeNetworkInterface = (e, field, index) => {
		const newData = { ...data }
		let valueTemp = e?.target ? e.target.value : e
		if (field == 'useDhcpClient') {
			if (valueTemp == true) {
				newData.networkInterfaces[index] = {
					...newData.networkInterfaces[index],
					useDhcpClient: true,
					gateway: '',
					nameServers: null,
					ipAddresses: null,
				}
			} else {
				newData.networkInterfaces[index] = {
					...newData.networkInterfaces[index],
					useDhcpClient: false,
					gateway: '',
					nameServers: [''],
					ipAddresses: [''],
				}
			}
		} else if (field == 'ipAddresses' || field == 'nameServers') {
			newData.networkInterfaces[index][field] = [`${valueTemp}`]
		} else {
			newData.networkInterfaces[index][field] = valueTemp
		}
		setdata(newData)
	}

	useEffect(() => {}, [])

	const checkDisabled = () => {
		if (data.networkDriverType == 'cni') {
			if (
				data?.networkInterfaces?.[0]?.connectedNetworkId == '' ||
				data?.networkInterfaces?.[0]?.connectedNetworkProfileId == ''
			) {
				return true
			}
			if (data?.networkInterfaces?.[0]?.useDhcpClient == false) {
				let missingGateway = data?.networkInterfaces?.[0]?.gateway == ''
				let missingNS = data?.networkInterfaces?.[0]?.nameServers?.[0] == ''
				let missingIP = data?.networkInterfaces?.[0]?.ipAddresses?.[0] == ''
				if (missingIP || missingGateway || missingNS) {
					return true
				}
			}
		}
		return false
	}

	const handleChangePort = (e, key, index) => {
		const newData = { ...data }
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'containerPort' && valueTemp == null) {
			valueTemp = 1
		}
		newData.portBindings[index][key] = valueTemp
		setdata(newData)
	}

	const del = (type, index) => {
		let newData = []
		switch (type) {
			case 'cni':
				newData = [...data.networkInterfaces]
				newData = newData.filter((item, indexItem) => indexItem != index)
				setdata({ ...data, networkInterfaces: newData })
				break
			case 'node':
				newData = [...data.portBindings]
				newData = newData.filter((item, indexItem) => indexItem != index)
				setdata({ ...data, portBindings: newData })
				break
		}
	}

	const add = (type) => {
		let newData = []
		switch (type) {
			case 'cni':
				newData = [...(data.networkInterfaces || [])]
				newData.push({ connectedNetworkId: '', connectedNetworkProfileId: '', useDhcpClient: true })
				setdata({ ...data, networkInterfaces: newData })
				break
			case 'node':
				newData = [...(data.portBindings || [])]
				newData.push({ nodePort: 0, containerPort: 1, protocol: 'tcp' })
				setdata({ ...data, portBindings: newData })
				break
		}
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<>
			<div className={classes.root}>
				<div
					style={{
						marginBottom: 8,
						width: '100%',
					}}>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Connection Type
						</label>
						<div className="form-group-ant">
							<Select value={data.networkDriverType} className="fullW" onChange={handleChangeSelect}>
								<Option value={'cni'}>Network Interfaces</Option>
								<Option value={'node'}>Node Port Bindings</Option>
								<Option value={'none'}>None</Option>
							</Select>
						</div>
						<div className="form-group-ant">
							{data.networkDriverType === 'cni' ? (
								<div>
									<Row className={classes.row}>
										<Col span={10}>
											<span className="requiredHint">*</span>
											<label>Network </label>
										</Col>
										<Col span={10}>
											<span className="requiredHint">*</span>
											<label>Network Profile</label>
										</Col>
										<Col span={4}>
											<span className="requiredHint">*</span>
											<label>Configuration</label>
										</Col>
									</Row>
									{data?.networkInterfaces?.map((p, index) => (
										<>
											<Row className={classes.row}>
												<Col span={10}>
													<Select
														className="vmNetwork"
														showSearch={true}
														optionFilterProp="label"
														value={p.connectedNetworkId}
														onChange={(e) => handleChangeNetworkInterface(e, 'connectedNetworkId', index)}
														style={{ width: '95%' }}>
														{dataContext?.state?.getNetworks?.map((network) => (
															<Option value={network.id} key={network.id} label={network.name}>
																{network.name}
															</Option>
														))}
													</Select>
												</Col>
												<Col span={10}>
													<Select
														className="vmNetworkProfile"
														value={p.connectedNetworkProfileId}
														showSearch={true}
														optionFilterProp="label"
														onChange={(e) =>
															handleChangeNetworkInterface(e, 'connectedNetworkProfileId', index)
														}
														style={{ width: '95%' }}>
														{dataContext?.state?.getNetworkProfiles?.map((profile) => (
															<Option value={profile.id} key={profile.id} label={profile.name}>
																<Tooltip title={profile.tag} placement="right">
																	{profile.name}
																</Tooltip>
															</Option>
														))}
													</Select>
												</Col>
												<Col span={4} style={{ alignItems: 'center', display: 'flex' }}>
													<Switch
														className="useDhcpClient"
														checked={p.useDhcpClient}
														onChange={(e) => handleChangeNetworkInterface(e, 'useDhcpClient', index)}
														title="Use DHCP Client?"
														checkedChildren="Automatic"
														unCheckedChildren="Manual"
														style={{ marginLeft: '4px' }}
													/>
												</Col>
											</Row>
											{p.useDhcpClient == false && (
												<div>
													<div className={classes.dhcpClient}>
														<span className="requiredHint">*</span>
														<span>IP Address CIDR</span>
														<Input
															value={p.ipAddresses}
															onChange={(e) => handleChangeNetworkInterface(e, 'ipAddresses', index)}
															placeholder={'e.g. 192.0.2.0/24'}
														/>
													</div>
													<div className={classes.dhcpClient}>
														<span className="requiredHint">*</span>
														<span>Default Gateway</span>
														<Input
															value={p.gateway}
															onChange={(e) => handleChangeNetworkInterface(e, 'gateway', index)}
															placeholder={'e.g. 192.0.2.1'}
														/>
													</div>
													<div className={classes.dhcpClient}>
														<span className="requiredHint">*</span>
														<span>Name Servers</span>
														<Input
															value={p.nameServers}
															onChange={(e) => handleChangeNetworkInterface(e, 'nameServers', index)}
															placeholder={'e.g. 192.0.2.1'}
														/>
													</div>
												</div>
											)}
										</>
									))}
								</div>
							) : data.networkDriverType === 'node' ? (
								<div>
									<Row className={classes.row}>
										<Col span={9}>
											<label>Node Port</label>
										</Col>
										<Col span={9}>
											<label>Container Port</label>
										</Col>
										<Col span={3}>Protocol</Col>
										<Col span={3}></Col>
									</Row>

									{data.portBindings?.map((p, index) => (
										<Row className={classes.row}>
											<Col span={9}>
												<InputNumber
													placeholder="A node port is randomly decided"
													value={p?.nodePort}
													style={{ width: '95%' }}
													id="nodePort"
													disabled={true}
													title="A node port is randomly decided"
												/>
											</Col>
											<Col span={9}>
												<InputNumber
													placeholder="e.g. 443"
													value={p?.containerPort}
													onChange={(e) => handleChangePort(e, 'containerPort', index)}
													min={1}
													max={65353}
													style={{ width: '95%' }}
													id="containerPort"
												/>
											</Col>
											<Col span={3}>
												<Select
													value={p?.protocol}
													onChange={(e) => handleChangePort(e, 'protocol', index)}>
													<Option value={'tcp'} key={'tcp'}>
														TCP
													</Option>
													<Option value={'udp'} key={'udp'}>
														UDP
													</Option>
													<Option value={'both'} key={'both'}>
														Both
													</Option>
												</Select>
											</Col>
											<Col span={3} style={{ alignItems: 'center', display: 'flex' }}>
												<span
													onClick={() => del('node', index)}
													style={{ color: '#0f427a', cursor: 'pointer', width: '50px', marginLeft: 8 }}>
													Delete
												</span>
											</Col>
										</Row>
									))}
									<Button
										block
										onClick={() => add('node')}
										style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
										id="containerAddRow">
										+ Add Row
									</Button>
								</div>
							) : (
								<div>
									<label>None network completely isolates the networking stack of a container</label>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={checkDisabled()}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</>
	)
}
