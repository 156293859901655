import axios from 'axios'
import { message } from 'antd'

export let BASE_API = window.location.protocol + '//' + window.location.hostname + ':8000/'
export let API_URL = BASE_API + 'api/v1'

// Localden bağlanmak için sadece alta url girin. Yukarıyı yorum satırına almak zorunda değilsiniz.
// Developer moda gerek yok direk https üstünden bağlanabilirsiniz
// Değişiklik yapıcaksanız boş göndermeyi unutmayın -> API_URL_LOCAL = ""
export let API_URL_LOCAL = ''

let instance = axios.create({
	baseURL: API_URL_LOCAL ? API_URL_LOCAL : API_URL,
})

async function refreshToken(config) {
	var token = localStorage.getItem('token')
	if (token) {
		config.headers.Authorization = 'X-Sybelle-Auth-Token ' + token //TODO:token sistemi eklenince acılacak
	}
	return config
}

instance.interceptors.request.use(
	(config) => {
		if (config.port) {
			config.baseURL += `:${config.port}`
		}
		return refreshToken(config)
	},
	(error) => {
		return Promise.reject(error)
	}
)

// response parse
instance.interceptors.response.use(
	(response) => {
		return parseBody(response)
	},
	(error, data) => {
		console.log(error.response)
		return parseBody(error.response)
	}
)

function parseBody(response) {
	if (!response) {
		console.log(response)
		return {
			status: 500,
			data: [],
			success: false,
			errorMessage: 'BTSVP can not connect to server!',
		}
	}
	// 1. Bilgilendirme yanıtları (100-199),
	// 2. Başarı yanıtları (200-299),
	// 3. Yönlendirmeler (300-399),
	// 4. İstemci hataları (400-499) ve,
	// 5. Sunucu hataları (500-599).

	if (response.status < 400) {
		return {
			status: response.status,
			success: true,
			data: response.data,
			message: response.data?.msg || response.data?.message || '',
		}
	} else {
		if (response.status == 401) {
			console.log(response)
			localStorage.removeItem('token')
			localStorage.removeItem('tokenExpiry')
			localStorage.removeItem('permissionList')
			message.warning('Session expired!')
			//localStorage.removeItem('userInfo')
			if (!response?.data?.errorText?.includes('Login failed')) {
				window.location.href = '/'
			}
			return {
				status: response.status,
				success: false,
				errorMessage: response.data.errorText,
			}
		} else {
			return {
				status: response.status,
				success: false,
				errorMessage: response.data.errorText,
			}
		}
		console.warn('WebService Warning!', response.config.url)
	}
}

export const http = instance

export const changebaseURL = (url) => {
	instance.defaults.baseURL = url
}
