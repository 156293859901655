import { http } from 'components'

function serializeObj(obj) {
	var queryStr = ''
	if (obj) {
		if (Object.keys(obj).includes('query')) {
			obj['query'] = JSON.stringify(obj['query'])
		}
		queryStr = '?' + new URLSearchParams(obj).toString()
	}
	return queryStr
}

// GETS
async function getClusters(data) {
	// Cluster, node and vm mapping
	let getClustersData = await http.get(`/objects/cluster/`)
	if (getClustersData.success) {
		let newData = getClustersData.data?.map((item) => {
			let newItem = {
				id: item.id,
				name: item.name,
				state: item.state,
				status: item.status,
				highAvailability: item.highAvailability,
				highAvailabilityStatus: item.highAvailabilityStatus,
				highAvailabilityFt: item.highAvailabilityFt,
				type: item['type'],
			}
			if (typeof item.nodes != 'undefined') {
				newItem['child'] = item.nodes?.map((item) => {
					let newItem = {
						id: item.id,
						name: item.name,
						clusterId: item.clusterId,
						state: item.state,
						status: item.status,
						type: item['type'],
						networkInterfaces: item.networkInterfaces,
						managementIpAddress: item?.managementIpAddress,
						highAvailability: item.highAvailability,
						maintenanceMode: item.maintenanceMode,
						maintenanceStatus: item.maintenanceStatus,
					}
					newItem['child'] = item.Executions
					return newItem
				})
				return newItem
			} else {
				return []
			}
		})

		return {
			...getClustersData,
			data: newData,
		}
	}
	return getClustersData
}
async function getClusterWithId(id) {
	return await http.get(`/objects/cluster/${id}/`)
}
async function getNodeWithId(id) {
	return await http.get(`/objects/node/${id}/`)
}
async function getVmWithId(id) {
	return await http.get(`/objects/vm/${id}/`)
}
async function getNetworkWithId(id) {
	return await http.get(`/objects/network/${id}/`)
}
async function getNetworkProfileWithId(id) {
	return await http.get(`/objects/networkProfile/${id}/`)
}
async function getVmStoragePoolWithId(id) {
	return await http.get(`/objects/vmStoragePool/${id}/`)
}
async function getNodesList() {
	return await http.get(`/objects/node/`)
}
async function getVms() {
	return await http.get(`/objects/vm/`)
}
async function getVmStorages() {
	return await http.get(`/objects/vmStorage/`)
}

async function getVmStoragePools() {
	return await http.get(`/objects/vmStoragePool/`)
}

async function getNetworkProfiles() {
	let getNetworkProfileData = await http.get(`/objects/networkProfile/`)
	if (getNetworkProfileData.success) {
		let newData = getNetworkProfileData.data?.map((item) => {
			let newItem = {
				id: item.id,
				name: item.profileName,
				status: item.status,
				clusterId: item.clusterId,
				pvId: item.pvId,
				tag: item.tag,
				type: 'networkProfile',
			}

			return newItem
		})
		return {
			...getNetworkProfileData,
			data: newData,
		}
	}
	return getNetworkProfileData
}

async function getNetworks() {
	let getNetworkData = await http.get(`/objects/network/`)
	// let newData = Json_getNetworks.objects?.map((item) => {
	if (getNetworkData.success) {
		let newData = getNetworkData.data?.map((item) => {
			let newItem = {
				id: item.id,
				name: item.name,
				status: item.status,
				clusterId: item.clusterId,
				vmId: null,
				type: 'network',
			}

			return newItem
		})
		return {
			...getNetworkData,
			data: newData,
		}
	}
	return getNetworkData
}

async function getVmNetworkInterfaces() {
	return await http.get(`/objects/vmNetworkInterface/`)
}

// Posts
async function createVm(data) {
	return await http.post(`/objects/vm/`, data)
}

async function createNetwork(data) {
	return await http.post(`/objects/network/`, data)
}

async function createNode(data) {
	return await http.post(`/objects/node/`, data)
}

async function createNetworkProfile(data) {
	return await http.post(`/objects/networkProfile/`, data)
}

async function createVmStoragePool(data) {
	return await http.post(`/objects/vmStoragePool/`, data)
}

async function createVmStorage(data) {
	return await http.post(`/objects/vmStorage/`, data)
}

// --OPTIONS,PUT--
async function updateVM(data) {
	let { id, ...as } = data
	return await http.put(`/objects/vm/${id}/`, data)
}

async function updateCls(data) {
	let { id, ...as } = data
	return await http.put(`/objects/cluster/${id}/`, data)
}

async function updateNode(data) {
	let { id, ...as } = data
	return await http.put(`/objects/node/${id}/`, data)
}

// VM Operations
async function startVM(id) {
	return await http.get(`/operations/vm/start/${id}/`)
}

async function shutdownVM(id) {
	return await http.get(`/operations/vm/shutdown/${id}/`)
}

async function suspendVM(id) {
	return await http.get(`/operations/vm/suspend/${id}/`)
}

async function resumeVM(id) {
	return await http.get(`/operations/vm/resume/${id}/`)
}

async function shutoffVM(id) {
	return await http.get(`/operations/vm/poweroff/${id}/`)
}
async function takeSnapShot(data) {
	return await http.post(`/objects/vmSnapshot/`, data)
}
// --OPTIONS,PUT--
async function updateNetworkProfile(data) {
	return await http.put(`/objects/networkProfile/${data.id}/`, data)
}

async function createVolumePath(data) {
	return await http.post(`/objects/volumePath/`, data)
}

async function getVolumePath() {
	return await http.get(`/objects/volumePath/`)
}

async function deleteVolumePath(id) {
	return await http.delete(`/objects/volumePath/${id}/`)
}

async function getVmSnapshots() {
	return {
		status: 200,
		data: [
			{
				id: '631634cd-6d74-4044-9710-c27c26b66c56',
				status: 'NOT_READY',
				name: 'test',
				vmId: '8d04815f-ee6c-492e-a9a0-218dcc5b61cd',
				state: 'LIVE_SNAPSHOT',
				parent: '',
				timestamp: '',
				memoryfile: '',
				fullxml: '',
				domxml: '',
				current: false,
				date: 1631048364, // [timestamp]
				size: '10G',
			},
			{
				id: '631634cd-6d74-4044-9710-c27c26b66c56',
				status: 'NOT_READY',
				name: 'test',
				vmId: '8d04815f-ee6c-492e-a9a0-218dcc5b61cd',
				state: 'LIVE_SNAPSHOT',
				parent: '',
				timestamp: '',
				memoryfile: '',
				fullxml: '',
				domxml: '',
				current: false,
				date: 1631048367, // [timestamp]
				size: '17G',
			},
		],
		success: true,
		message: '',
	}
}
async function deleteVm(id) {
	return await http.delete(`/objects/vm/${id}/`)
}
async function deleteNetwork(id) {
	return await http.delete(`/objects/network/${id}/`)
}
async function deleteNetworkProfile(id) {
	return await http.delete(`/objects/networkProfile/${id}/`)
}
async function deleteVmStoragePool(id) {
	return await http.delete(`/objects/vmStoragePool/${id}/`)
}
async function deleteVmSnapshot(id) {
	return await http.delete(`/objects/vmSnapshot/${id}/`)
}
async function revertVmSnapshot(id) {
	return await http.get(`/operations/vmSnapshot/revert/${id}/`)
}
async function updateVmNetworkInterface(data) {
	let { id, ...as } = data
	return await http.put(`/objects/vmNetworkInterface/${id}/`, data)
}
async function migrateVm(id, data, cold) {
	return await http.post(`/operations/vm/migrate/${id}/?coldMigration=${cold}`, data)
}
async function createBondInterface(data) {
	return await http.post(`/objects/nodeNetworkInterface/`, data)
}
async function updateNodeNetworkInterface(data) {
	let { id, ...as } = data
	return await http.put(`/objects/nodeNetworkInterface/${id}/`, data)
}
async function deleteNodeNetworkInterface(id) {
	return await http.delete(`/objects/nodeNetworkInterface/${id}/`)
}
async function getOperations() {
	let getOperationsData = await http.get(`/operations/operation/`)
	if (getOperationsData.success) {
		let getOperationsDataSorted = getOperationsData.data.sort(function (x, y) {
			return y.creationTime - x.creationTime
		})
		getOperationsDataSorted = getOperationsDataSorted.slice(0, 49)
		getOperationsData.operations = getOperationsDataSorted
		return getOperationsData
	}
}
async function triggerOperationWithId(id) {
	return await http.get(`/operation/trigger/${id}/`)
}
async function createBackupProfile(data) {
	return await http.post(`/objects/backupProfile/`, data)
}
async function getBackupProfileWithId(id) {
	return await http.get(`/objects/backupProfile/${id}/`)
}
async function getBackupProfiles() {
	let getBackupData = await http.get(`/objects/backupProfile/`)
	// let newData = Json_getNetworks.objects?.map((item) => {
	if (getBackupData.success) {
		let newData = getBackupData.data?.map((item) => {
			let newItem = {
				id: item.id,
				name: item.name,
				status: item.status,
				clusterId: item.clusterId,
				type: 'backupProfile',
				restorePoints: item.IncrementalScheduleRetention,
			}

			return newItem
		})
		return {
			...getBackupData,
			data: newData,
		}
	}
	return getBackupData
}
async function deleteBackupProfile(id) {
	return await http.delete(`/objects/backupProfile/${id}/`)
}
async function updateBackupProfile(data) {
	let { id, ...as } = data
	return await http.put(`/objects/backupProfile/${id}/`, data)
}
async function createVmTemplate(data) {
	return await http.get(
		`/operations/vm/createTemplateFromVm/${data.vmId}/${data.templateStoragePoolId}/${data.templateName}/`
	)
}
async function getVmTemplates() {
	return await http.get(`/objects/vmTemplate/`)
}
async function getVmTemplateWithId(id) {
	return await http.get(`/objects/vmTemplate/${id}/`)
}
async function deleteVmTemplate(id) {
	return await http.delete(`/objects/vmTemplate/${id}/`)
}
async function createVmFromTemplate(data, vmTemplate) {
	return await http.get(
		`/operations/vm/createVmFromTemplate/${data.name}/${vmTemplate}/${data.nodeId}/${data.storagePoolId}/`,
		data
	)
}
async function cloneVm(data) {
	return await http.get(
		`/operations/vm/clone/${data.vmName}/${data.vmId}/${data.targetNodeId}/${data.targetStoragePoolId}/`
	)
}
async function scanMpaths(clId) {
	return await http.get(`/operations/scan/mpaths/${clId}/`)
}
async function getMpaths() {
	return await http.get(`/objects/multiPathDevices/`)
}
async function getVmGroups() {
	// vmGroup & vm mapping
	let getVmGroupsData = await http.get(`/objects/vmGroup/`)
	if (getVmGroupsData.success) {
		let newData = getVmGroupsData.data?.map((item) => {
			let newItem = { ...item }
			if (typeof item.objects != 'undefined') {
				newItem['child'] = item.objects?.map((item) => {
					let newItem = {
						id: item.id,
						name: item.name,
						type: 'vm',
						state: item.state,
						status: item.status,
					}
					return newItem
				})
				return newItem
			} else {
				return []
			}
		})

		return {
			...getVmGroupsData,
			data: newData,
		}
	}
	return getVmGroupsData
}

async function createVmGroup(data) {
	return await http.post(`/objects/vmGroup/`, data)
}
async function updateVmGroup(data) {
	return await http.put(`/objects/vmGroup/${data.id}/`, data)
}
async function createNodeGroup(data) {
	return await http.post(`/objects/nodeGroup/`, data)
}
async function updateNodeGroup(data) {
	return await http.put(`/objects/nodeGroup/${data.id}/`, data)
}
async function deleteVmGroup(id) {
	return await http.delete(`/objects/vmGroup/${id}/`)
}
async function getVmImage(id) {
	return await http.get(`/operations/vm/screenshot/${id}/`)
}
async function getBlockDevices() {
	return await http.get(`/objects/blockDevice/`)
}
async function getHbaPorts() {
	return await http.get(`/objects/hbaPort/`)
}
async function getNodeGroups() {
	return await http.get(`/objects/nodeGroup/`)
}
async function getEventInfos() {
	return await http.get(`/objects/eventInfo/`)
}
async function getNetworkFilters() {
	return await http.get(`/objects/networkFilter/`)
}
async function createNwFilter(data) {
	return await http.post(`/objects/networkFilter/`, data)
}
async function updateNwFilter(data) {
	return await http.put(`/objects/networkFilter/${data.id}/`, data)
}
async function deleteNwFilter(id) {
	return await http.delete(`/objects/networkFilter/${id}/`)
}

async function getNetworkOverlays() {
	let nwOverlays = await http.get(`/objects/networkOverlay/`)
	if (nwOverlays.success) {
		let newData = nwOverlays.data?.map((item) => {
			let newItem = { ...item }
			newItem['type'] = 'networkOverlay'

			return newItem
		})
		return {
			...nwOverlays,
			data: newData,
		}
	}
	return nwOverlays
}
async function createNwOverlay(data) {
	return await http.post(`/objects/networkOverlay/`, data)
}
async function updateNwOverlay(data) {
	return await http.put(`/objects/networkOverlay/${data.id}/`, data)
}
async function deleteNwOverlay(id) {
	return await http.delete(`/objects/networkOverlay/${id}/`)
}

async function getGlusterVolumes() {
	return await http.get(`/objects/gluster/volume/`)
}
async function createGlusterVolume(data) {
	return await http.post(`/objects/gluster/volume/`, data)
}
async function updateGlusterVolume(data) {
	return await http.put(`/objects/gluster/volume/${data.id}/`, data)
}
async function deleteGlusterVolume(id) {
	return await http.delete(`/objects/gluster/volume/${id}/`)
}

async function getGlusters() {
	return await http.get(`/objects/gluster/cluster/`)
}
async function createGluster(data) {
	return await http.post(`/objects/gluster/cluster/`, data)
}
async function updateGluster(data) {
	return await http.put(`/objects/gluster/cluster/${data.id}/`, data)
}
async function deleteGluster(id) {
	return await http.delete(`/objects/gluster/cluster/${id}/`)
}

async function getGlusterPeers() {
	return await http.get(`/objects/glusterPeer/`)
}
async function createGlusterPeer(data) {
	return await http.post(`/objects/gluster/peer/`, data)
}
async function updateGlusterPeer(data) {
	return await http.put(`/objects/gluster/peer/${data.id}/`, data)
}
async function deleteGlusterPeer(id) {
	return await http.delete(`/objects/gluster/peer/${id}/`)
}

async function getGlusterVolumeBricks() {
	return await http.get(`/objects/gluster/volumeBrick/`)
}
async function createGlusterVolumeBrick(data) {
	return await http.post(`/objects/gluster/volumeBrick/`, data)
}
async function updateGlusterVolumeBrick(data) {
	return await http.put(`/objects/gluster/volumeBrick/${data.id}/`, data)
}
async function deleteGlusterVolumeBrick(id) {
	return await http.delete(`/objects/gluster/volumeBrick/${id}/`)
}

async function getGlusterVolumeOptions() {
	return await http.get(`/objects/glusterVolumeOption/`)
}
async function createGlusterVolumeOption(data) {
	return await http.post(`/objects/gluster/volumeOption/`, data)
}
async function updateGlusterVolumeOption(data) {
	return await http.put(`/objects/gluster/volumeOption/${data.id}/`, data)
}
async function deleteGlusterVolumeOption(id) {
	return await http.delete(`/objects/gluster/volumeOption/${id}/`)
}
async function revertRestorePoint(id) {
	return await http.get(`/operations/vmRestorePoint/revert/${id}/`)
}
async function deleteRestorePoint(id) {
	return await http.delete(`/objects/vmRestorePoint/${id}/`)
}
async function startVolume(id) {
	return await http.get(`/operations/gluster/volume/start/${id}/`)
}
async function stopVolume(id) {
	return await http.get(`/operations/gluster/volume/stop/${id}/`)
}
async function poolWalk(id) {
	return await http.get(`/operations/poolWalk/${id}/`)
}
async function deleteFile(data) {
	return await http.delete(`/operations/uploadSession/delete/`, { data: data })
}
async function uploadSession(data) {
	return await http.post(`/operations/uploadSession/`, data)
}
async function uploadFile(id, file) {
	return await http.post(`/operations/uploadSession/upload/${id}/`, file, {
		headers: { 'content-type': 'multipart/form-data' },
		maxBodyLength: 10 ** 11,
		maxContentLength: 10 ** 11,
	})
}
async function scanNics(clId) {
	return await http.get(`/operations/scan/nics/${clId}/`)
}
async function scanDisks(clId) {
	return await http.get(`/operations/scan/disks/${clId}/`)
}
async function scanHbaPorts(clId) {
	return await http.get(`/operations/scan/hbaports/${clId}/`)
}
async function getContainers() {
	return await http.get(`/objects/container/`)
}
async function createContainer(data) {
	return await http.post(`/objects/container/`, data)
}

async function updateContainer(data) {
	return await http.put(`/objects/container/${data.id}/`, data)
}

async function getContainer(id) {
	return await http.get(`/objects/container/${id}/`)
}
async function startContainer(id) {
	return await http.get(`/operations/container/start/${id}/`)
}
async function stopContainer(id) {
	return await http.get(`/operations/container/shutdown/${id}/`)
}
async function deleteContainer(id) {
	return await http.delete(`/objects/container/${id}/`)
}
async function getAppData() {
	return await http.get('http://10.20.101.161:8000/')
}
async function moveContainer(id, data) {
	return await http.post(`/operations/container/move/${id}/`, data)
}
async function getLocalUsers() {
	return await http.get(`/objects/localUser/`)
}
async function createLocalUser(data) {
	return await http.post(`/objects/localUser/`, data)
}
async function updateLocalUser(data) {
	return await http.put(`/objects/localUser/${data.id}/`, data)
}
async function deleteLocalUser(id) {
	return await http.delete(`/objects/localUser/${id}/`)
}
async function getNodeNetworkInterfaces() {
	return await http.get(`/objects/nodeNetworkInterface/`)
}
async function createTenant(data) {
	return await http.post(`/objects/tenant/`, data)
}
async function updateTenant(data) {
	let tnId = data?.id
	delete data.status
	return await http.put(`/objects/tenant/${tnId}/`, data)
}
async function deleteTenant(id) {
	return await http.delete(`/objects/tenant/${id}/`)
}
async function getTenants(data) {
	let getTenantData = await http.get(`/objects/tenant/`)
	if (getTenantData.success) {
		let newData = getTenantData.data?.map((item) => {
			let newItem = { ...item }
			newItem['child'] = item.Executions
			return newItem
		})

		return {
			...getTenantData,
			data: newData,
		}
	}
	return getTenantData
}
async function getTenant(id) {
	return await http.get(`/objects/tenant/${id}/`)
}
async function createSouthBoundNetwork(data) {
	return await http.post(`/objects/southBoundNetwork/`, data)
}
async function updateSouthBoundNetwork(data) {
	return await http.put(`/objects/southBoundNetwork/${data.id}/`, data)
}
async function deleteSouthBoundNetwork(id) {
	return await http.delete(`/objects/southBoundNetwork/${id}/`)
}
async function getSouthBoundNetworks() {
	return await http.get(`/objects/southBoundNetwork/`)
}
async function getSouthBoundNetwork(id) {
	return await http.get(`/objects/southBoundNetwork/${id}/`)
}
async function createAddressPool(data) {
	return await http.post(`/objects/addressPool/`, data)
}
async function updateAddressPool(data) {
	return await http.put(`/objects/addressPool/${data.id}/`, data)
}
async function deleteAddressPool(id) {
	return await http.delete(`/objects/addressPool/${id}/`)
}
async function getAddressPools() {
	return await http.get(`/objects/addressPool/`)
}
async function getAddressPool(id) {
	return await http.get(`/objects/addressPool/${id}/`)
}
async function createQuotaProfile(data) {
	return await http.post(`/objects/quotaProfile/`, data)
}
async function updateQuotaProfile(data) {
	return await http.put(`/objects/quotaProfile/${data.id}/`, data)
}
async function deleteQuotaProfile(id) {
	return await http.delete(`/objects/quotaProfile/${id}/`)
}
async function getQuotaProfiles() {
	return await http.get(`/objects/quotaProfile/`)
}
async function getQuotaProfile(id) {
	return await http.get(`/objects/quotaProfile/${id}/`)
}
async function createTenantNetwork(data) {
	return await http.post(`/objects/tenantNetwork/`, data)
}
async function updateTenantNetwork(data) {
	return await http.put(`/objects/tenantNetwork/${data.id}/`, data)
}
async function deleteTenantNetwork(id) {
	return await http.delete(`/objects/tenantNetwork/${id}/`)
}
async function getTenantNetworks() {
	return await http.get(`/objects/tenantNetwork/`)
}
async function getTenantNetwork(id) {
	return await http.get(`/objects/tenantNetwork/${id}/`)
}
async function assignPrivateIp(data) {
	return await http.post(`/operations/nat/basic/`, data)
}
async function unAssignPublicIp(id) {
	return await http.delete(`/operations/nat/basic/${id}/`)
}

async function createIscsiDb(data) {
	return await http.post(`/objects/iscsidb/`, data)
}
async function getIscsiDbs() {
	return await http.get(`/objects/iscsidb/`)
}
async function getIscsiDb(id) {
	return await http.get(`/objects/iscsidb/${id}/`)
}

async function createVmFromBackupInstance(data) {
	return await http.post(`/operations/backup/createVmFromRestorePoint/`, data)
}

async function deleteIscsiDb(id) {
	return await http.delete(`/objects/iscsidb/${id}/`)
}

async function getIscsis() {
	return await http.get(`/objects/iscsi/`)
}
async function getIscsi(id) {
	return await http.get(`/objects/iscsi/${id}/`)
}
async function deleteIscsi(id) {
	return await http.delete(`/objects/iscsi/${id}/`)
}
async function loginIscsi(id) {
	return await http.get(`/operations/iscsi/login/${id}/`)
}
async function logoutIscsi(id) {
	return await http.get(`/operations/iscsi/logout/${id}/`)
}

async function getIscsiInitiators() {
	return await http.get(`/objects/iscsiInitiator/`)
}
async function getIscsiInitiator(id) {
	return await http.get(`/objects/iscsiInitiator/${id}/`)
}
async function updateIscsiInitiator(data) {
	return await http.put(`/objects/iscsiInitiator/${data.id}/`, data)
}

async function getRlbObjects() {
	return await http.get(`/objects/rlbObject/`)
}
async function deleteRlbObject(id) {
	return await http.delete(`/objects/rlbObject/${id}/`)
}
async function commitRlb(id) {
	return await http.get(`/operations/rlbObject/commit/${id}/`)
}
async function commitAllRlb() {
	return await http.get(`/operations/rlbObject/commit/`)
}
async function clearAllRlb() {
	return await http.delete(`/operations/rlbObject/clear/`)
}
async function commitAllStorageRlb() {
	return await http.get(`/operations/rlbObject/commit/storage/`)
}
async function clearAllStorageRlb() {
	return await http.delete(`/operations/rlbObject/clear/storage/`)
}

async function getPeerClusters() {
	return await http.get(`/objects/peer/cluster/`)
}

async function getPeerCluster(id) {
	return await http.get(`/objects/peer/cluster/${id}/`)
}

async function deletePeerCluster(id) {
	return await http.delete(`/objects/peer/cluster/${id}/`)
}
async function createPeerCluster(data) {
	return await http.post(`/objects/peer/cluster/`, data)
}

async function updatePeerCluster(id, data) {
	return await http.put(`/objects/peer/cluster/${id}/`, data)
}

async function getSPoolGroups() {
	let sPoolGroups = await http.get(`/objects/storagePoolGroup/`)
	if (sPoolGroups.success) {
		let newData = sPoolGroups.data?.map((item) => {
			let newItem = item
			if (typeof item.objects != 'undefined') {
				newItem['child'] = item.objects
				return newItem
			} else {
				return []
			}
		})

		return {
			...sPoolGroups,
			data: newData,
		}
	}
	return sPoolGroups
}
async function getSPoolGroup(id) {
	return await http.get(`/objects/storagePoolGroup/${id}/`)
}
async function createSPoolGroup(data) {
	return await http.post(`/objects/storagePoolGroup/`, data)
}
async function updateSPoolGroup(data) {
	return await http.put(`/objects/storagePoolGroup/${data.id}/`, data)
}
async function deleteSPoolGroup(id) {
	return await http.delete(`/objects/storagePoolGroup/${id}/`)
}

async function getVmwareTree(data) {
	return await http.post(`/objects/vmware/`, data)
}
async function submitVmwareConversions(data) {
	return await http.post(`/operations/vmware/convert/`, data)
}

async function getResticSnapshots() {
	return await http.get(`/objects/resticSnapshot/`)
}
async function deleteResticSnapshots(resticId, vmStoragePoolId) {
	return await http.delete(`/objects/resticSnapshot/${resticId}/${vmStoragePoolId}/`)
}
async function getVmwareVm(data) {
	return await http.post(`/objects/vmware/vm/${data.key}/`, data.auth)
}
async function rebootNode(id) {
	return await http.post(`/operations/node/reboot/${id}/`)
}
async function shutdownNode(id) {
	return await http.post(`/operations/node/shutdown/${id}/`)
}
async function setMaintenanceNode(id) {
	return await http.post(`/operations/node/setmaintenance/${id}/`)
}
async function unsetMaintenanceNode(id) {
	return await http.post(`/operations/node/unsetmaintenance/${id}/`)
}
async function removeNodeCls(id) {
	return await http.post(`/operations/cluster/removeNode/${id}`)
}

async function startInitCluster(url, data) {
	const trueIsDkvsCount = data.nodes.filter((node) => node.isDkvs).length
	let headers = {}

	// Merge default instance headers with custom headers
	headers = { ...http.defaults.headers.common, ...headers }

	if (trueIsDkvsCount > 7) {
		headers['X-Force-Operation'] = 'true'
	}

	return await http.post(url, data, { headers })
}

async function createVmFromTransientVm(id) {
	return await http.get(`/operations/backup/createVmFromTransientVm/${id}/`)
}

async function exploreBackupRepository(id) {
	return await http.get(`/operations/backup/exploreBackupRepository/${id}/`)
}

async function purgeBackupRepository(id) {
	return await http.get(`/operations/backup/purgeBackupRepository/${id}/`)
}

async function getVmRestorePoints() {
	return await http.get(`/objects/vmRestorePoint/`)
}

async function removeNodeFromGluster(id) {
	return await http.post(`/operations/gluster/removeNode/${id}/`)
}

async function addNodeToGluster(id) {
	return await http.post(`/operations/gluster/addNode/${id}/`)
}
async function getNasNodeInterfaces(data) {
	return await http.post('/objects/nasNodeInterface/', data)
}

async function extendAuthToken(token, seconds) {
	return await http.post(`/objects/authToken/${token}/extend/${seconds}`)
}

async function externalBackupGetStorageUnits(serviceType) {
	return await http.get(`/operations/externalBackupProfile/storageUnits/${serviceType}`)
}

async function externalBackupProfileInitiateBackup(profileName, data) {
	return await http.post(`/operations/externalBackupProfile/initiateBackup/${profileName}`, data)
}

async function getExternalBackupByProfileName(profileName) {
	return await http.get(`/operations/externalBackupProfile/backupImagesByProfile/${profileName}?serviceType=veritas`)
}

async function createVeritasBackupProfile(data) {
	return await http.post(`/objects/externalBackupProfile/`, data)
}

async function getVeritasBackupProfile() {
	return await http.get(`/objects/externalBackupProfile/`)
}

async function getVeritasBackupById(id) {
	return await http.get(`/objects/externalBackupProfile/${id}`)
}

async function deleteVeritasBackupProfile(id) {
	return await http.delete(`/objects/externalBackupProfile/${id}`)
}

async function updateVeritasBackupProfile(data, id) {
	return await http.put(`/objects/externalBackupProfile/${id}`, data)
}

async function startVeritasBackupRecovery(data) {
	return await http.post(`/operations/externalBackupProfile/startRecovery`, data)
}

async function getVeritasBackupImages(vmId, serviceType, nodeName) {
	return await http.get(
		`/operations/externalBackupProfile/backupImages/${vmId}?serviceType=${serviceType}&clientName=${nodeName}`
	)
}

async function createExternalBackupUserCredentials(data, serviceType) {
	return await http.post(`/objects/externalBackupCredentials/${serviceType}`, data)
}

async function getExternalBackupUsers() {
	return await http.get(`/objects/externalBackupCredentials/urls`)
}

async function deleteExternalBackupUserCredentials(serviceType) {
	return await http.delete(`/objects/externalBackupCredentials/${serviceType}`)
}

async function updateExternalBackupUserCredentials(data, serviceType) {
	return await http.put(`/objects/externalBackupCredentials/${serviceType}`, data)
}

async function createVmFromExternalBackupRp(data) {
	return await http.post(`/operations/externalBackupProfile/startRecovery`, data)
}

async function deployCluster(url, data) {
	return await http.post(url, data)
}

async function getSystemAuthTokens() {
	return await http.get(`/objects/authToken/system/`)
}
async function getSystemAuthTokenById(id) {
	return http.get(`/objects/authToken/system/${id}`)
}

async function createSystemAuthToken() {
	return await http.post(`/objects/authToken/system/`)
}

async function deleteSystemAuthToken(id) {
	return http.delete(`/objects/authToken/system/${id}`)
}

async function getAllEndpoints(url) {
	return http.get(url)
}

async function getVmSharedStorage(id) {
	return await http.get(`/objects/vmSharedStorage/${id}/`)
}

async function deleteSharedVmStorage(id) {
	return await http.delete(`/objects/vmSharedStorage/${id}/`)
}

export default {
	deployCluster,
	getClusters,
	getClusterWithId,
	getNodeWithId,
	getVmWithId,
	getNetworkProfileWithId,
	getNodesList,
	getPeerClusters,
	getPeerCluster,
	updatePeerCluster,
	createPeerCluster,
	deletePeerCluster,
	getVmStoragePools,
	createVmFromBackupInstance,
	getNetworkProfiles,
	getNetworks,
	exploreBackupRepository,
	purgeBackupRepository,
	getVms,
	createVm,
	updateVM,
	updateCls,
	updateNode,
	createVmFromTransientVm,
	updateNetworkProfile,
	createNode,
	createNetwork,
	createVmStoragePool,
	createVmStorage,
	createNetworkProfile,
	startVM,
	shutdownVM,
	suspendVM,
	resumeVM,
	shutoffVM,
	takeSnapShot,
	startInitCluster,
	getVmSnapshots,
	deleteResticSnapshots,
	deleteVm,
	deleteNetwork,
	deleteNetworkProfile,
	deleteVmStoragePool,
	getVmStoragePoolWithId,
	getNetworkWithId,
	deleteVmSnapshot,
	revertVmSnapshot,
	getVmNetworkInterfaces,
	updateVmNetworkInterface,
	migrateVm,
	createBondInterface,
	updateNodeNetworkInterface,
	deleteNodeNetworkInterface,
	getOperations,
	triggerOperationWithId,
	createBackupProfile,
	getBackupProfileWithId,
	getBackupProfiles,
	deleteBackupProfile,
	updateBackupProfile,
	createVmTemplate,
	getResticSnapshots,
	getVmTemplates,
	getVmTemplateWithId,
	deleteVmTemplate,
	createVmFromTemplate,
	cloneVm,
	scanMpaths,
	getMpaths,
	getVmGroups,
	createVmGroup,
	updateVmGroup,
	createNodeGroup,
	updateNodeGroup,
	deleteVmGroup,
	getVmImage,
	getBlockDevices,
	getHbaPorts,
	getNodeGroups,
	getEventInfos,
	getNetworkFilters,
	createNwFilter,
	updateNwFilter,
	deleteNwFilter,
	getNetworkOverlays,
	createNwOverlay,
	updateNwOverlay,
	deleteNwOverlay,
	getGlusterVolumes,
	createGlusterVolume,
	updateGlusterVolume,
	deleteGlusterVolume,
	getGlusters,
	createGluster,
	updateGluster,
	deleteGluster,
	getGlusterPeers,
	createGlusterPeer,
	updateGlusterPeer,
	deleteGlusterPeer,
	getGlusterVolumeBricks,
	createGlusterVolumeBrick,
	updateGlusterVolumeBrick,
	deleteGlusterVolumeBrick,
	getGlusterVolumeOptions,
	createGlusterVolumeOption,
	updateGlusterVolumeOption,
	deleteGlusterVolumeOption,
	revertRestorePoint,
	deleteRestorePoint,
	startVolume,
	stopVolume,
	poolWalk,
	deleteFile,
	uploadSession,
	uploadFile,
	scanNics,
	scanDisks,
	scanHbaPorts,
	getContainers,
	getContainer,
	createContainer,
	updateContainer,
	startContainer,
	stopContainer,
	deleteContainer,
	getAppData,
	moveContainer,
	getLocalUsers,
	createLocalUser,
	updateLocalUser,
	deleteLocalUser,
	getNodeNetworkInterfaces,
	createTenant,
	updateTenant,
	deleteTenant,
	getTenants,
	getTenant,
	createSouthBoundNetwork,
	updateSouthBoundNetwork,
	deleteSouthBoundNetwork,
	getSouthBoundNetworks,
	getSouthBoundNetwork,
	createAddressPool,
	updateAddressPool,
	deleteAddressPool,
	getAddressPools,
	getAddressPool,
	createQuotaProfile,
	updateQuotaProfile,
	deleteQuotaProfile,
	getQuotaProfiles,
	getQuotaProfile,
	createTenantNetwork,
	updateTenantNetwork,
	deleteTenantNetwork,
	getTenantNetworks,
	getTenantNetwork,
	assignPrivateIp,
	unAssignPublicIp,
	createIscsiDb,
	getIscsiDbs,
	getIscsiDb,
	deleteIscsiDb,
	getIscsis,
	getIscsi,
	deleteIscsi,
	loginIscsi,
	logoutIscsi,
	getIscsiInitiators,
	getIscsiInitiator,
	updateIscsiInitiator,
	getRlbObjects,
	deleteRlbObject,
	commitRlb,
	commitAllRlb,
	clearAllRlb,
	commitAllStorageRlb,
	clearAllStorageRlb,
	getSPoolGroups,
	getSPoolGroup,
	createSPoolGroup,
	updateSPoolGroup,
	deleteSPoolGroup,
	getVmwareTree,
	submitVmwareConversions,
	getVmwareVm,
	rebootNode,
	shutdownNode,
	setMaintenanceNode,
	unsetMaintenanceNode,
	removeNodeCls,
	getVmRestorePoints,
	removeNodeFromGluster,
	addNodeToGluster,
	getNasNodeInterfaces,
	extendAuthToken,
	externalBackupGetStorageUnits,
	createVeritasBackupProfile,
	getVeritasBackupProfile,
	getVeritasBackupById,
	deleteVeritasBackupProfile,
	updateVeritasBackupProfile,
	startVeritasBackupRecovery,
	getVeritasBackupImages,
	createExternalBackupUserCredentials,
	getExternalBackupUsers,
	deleteExternalBackupUserCredentials,
	updateExternalBackupUserCredentials,
	createVmFromExternalBackupRp,
	getExternalBackupByProfileName,
	externalBackupProfileInitiateBackup,
	createVolumePath,
	getVolumePath,
	deleteVolumePath,
	getVmSharedStorage,
	deleteSharedVmStorage,
	getVmStorages,
	getSystemAuthTokens,
	getSystemAuthTokenById,
	createSystemAuthToken,
	deleteSystemAuthToken,
	getAllEndpoints,
}
