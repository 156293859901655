import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'

import dataService from 'service/dataService'

import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Name from './Name'
import Host from './Host'
import CpuMemory from './CpuMemory'
import Storage from './Storage'
import CdDvd from './CdDvd'
import Network from './Network'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 0,
		minHeight: 300,
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	actived: {
		cursor: 'pointer',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
}))

export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})
	const [originalData, setOriginalData] = useState({})
	const [loaderShow, setloaderShow] = useState('data')
	const [isModalVisible, setIsModalVisible] = useState(false)

	const getTenantQuotaProfile = async (id) => {
		let res = await dataService.getQuotaProfile(id)
		if (res) {
			if (res.success) {
				return res.data || null
			} else {
				console.error(res.message)
			}
		} else {
			console.error(res.message)
		}
	}

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('tenantVmPopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata(null)
	}

	async function getTenantQuotaProfiles() {
		if (globalContext.state.tenantVmPopup) {
			let quota = await getTenantQuotaProfile(globalContext?.state?.tenantVmPopup?.tenant?.quotaProfileId)
			setdata({ ...globalContext.state.tenantVmPopup, quotaProfile: quota })
			setOriginalData({ ...globalContext.state.tenantVmPopup, quotaProfile: quota })
			if (globalContext.state.tenantVmPopup.createOrUpdate == 'update') {
				setSelectedMenuMax(7)
				setSelectedMenu(0)
			}
		}
	}

	useEffect(() => {
		getTenantQuotaProfiles()
	}, [globalContext.state.tenantVmPopup])

	const createVM = async () => {
		setloaderShow('data')
		let sendData = { ...data }
		sendData.memory = sendData.memory.toString()
		sendData.maxMemory = sendData.maxMemory.toString()
		sendData.cpu = sendData.cpu.toString()
		sendData.maxCpu = sendData.maxCpu.toString()
		sendData.tenantId = sendData.tenant.id
		delete sendData.tenant
		let res = await dataService.createVm(sendData)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
			handleClose()
			// setloaderShow('')
			// setTimeout(() => {
			// 	dataContext.getTenants()
			// 	dataContext.getClusters()
			// 	navigate(`/cluster/${res.data.clusterId}/node/${res.data.nodeId}/vm/${res.data.id}`)
			// }, 3000)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateVM = async () => {
		if (!data.devices) {
			data.devices = []
			let vmDevice = {
				deviceType: 'BOOT_DISK',
				vmId: data.id,
				isoPath: data.guestOs,
			}
			data.devices.push(vmDevice)
		}
		delete data.status
		delete data.state
		delete data.createOrUpdate
		delete data['@type']
		data.memory = data.memory.toString()
		data.maxMemory = data.maxMemory.toString()
		data.cpu = data.cpu.toString()
		data.maxCpu = data.maxCpu.toString()
		setloaderShow('data')
		let res = await dataService.updateVM(data)
		if (res.success) {
			globalContext.Snackbar(`Update [${res.data.name}] vm operation started successfully`, 'success')
			handleClose()
			// setloaderShow('')
			// setTimeout(() => {
			// 	dataContext.getTenants()
			// 	dataContext.getClusters()
			// 	navigate(`/cluster/${data.clusterId}/node/${data.nodeId}/vm/${data.id}`)
			// }, 3000)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
	}

	let selectedMenuList = globalContext.state.tenantVmPopup?.tenant?.tenantNetworks?.length
		? [
				<Name modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<Host modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<CpuMemory
					modalDataChange={handleChange}
					next={next}
					prev={prev}
					modalData={data}
					originalData={originalData}
				/>,
				<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<CdDvd modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<Network modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<Complete createVM={createVM} updateVM={updateVM} next={next} prev={prev} modalData={data} />,
		  ]
		: [
				<Name modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<Host modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<CpuMemory
					modalDataChange={handleChange}
					next={next}
					prev={prev}
					modalData={data}
					originalData={originalData}
				/>,
				<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<CdDvd modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
				<Complete createVM={createVM} updateVM={updateVM} next={next} prev={prev} modalData={data} />,
		  ]

	return (
		<div>
			<Modal
				title={
					globalContext.state.tenantVmPopup?.createOrUpdate == 'update'
						? 'Update Virtual Machine'
						: 'New Virtual Machine'
				}
				className="stepModal"
				width={1280}
				visible={globalContext.state.tenantVmPopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={6}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 0 ? setSelectedMenu(0) : null)}>
									<span className="fw-600">1</span>VM Name
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 1 ? setSelectedMenu(1) : null)}>
									<span className="fw-600">2</span>Host & Volume
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 2 ? setSelectedMenu(2) : null)}>
									<span className="fw-600">3</span>CPU & Memory
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 3 ? setSelectedMenu(3) : null)}>
									<span className="fw-600">4</span>Storage
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 4 ? classes.actived : ''} ${
										selectedMenu === 4 ? classes.active : ''
									}`}
									onClick={() => (selectedMenuMax > 4 ? setSelectedMenu(4) : null)}>
									<span className="fw-600">5</span>CD/DVD
								</li>
								{globalContext.state.tenantVmPopup?.tenant?.tenantNetworks?.length ? (
									<>
										<li
											className={`${classes.listItem}   ${selectedMenuMax > 5 ? classes.actived : ''} ${
												selectedMenu === 5 ? classes.active : ''
											}`}
											onClick={() => (selectedMenuMax > 5 ? setSelectedMenu(5) : null)}>
											<span className="fw-600">6</span>Network
										</li>
										<li
											className={`${classes.listItem}   ${selectedMenuMax > 6 ? classes.actived : ''} ${
												selectedMenu === 6 ? classes.active : ''
											}`}
											onClick={() => (selectedMenuMax > 6 ? setSelectedMenu(6) : null)}>
											<span className="fw-600">7</span>Complete
										</li>
									</>
								) : (
									<li
										className={`${classes.listItem}   ${selectedMenuMax > 5 ? classes.actived : ''} ${
											selectedMenu === 5 ? classes.active : ''
										}`}
										onClick={() => (selectedMenuMax > 5 ? setSelectedMenu(5) : null)}>
										<span className="fw-600">6</span>Complete
									</li>
								)}
							</ul>
						</div>
					</Col>
					<Col span={18} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
