import React, { useState, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, InputNumber, Button, Select } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ profileName: '' })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		setVisible(false)
		globalContext.updateState('networkProfileCreateAndUpdatePopup', null)
		setdata({ profileName: '' })
	}

	useEffect(() => {
		if (globalContext.state.networkProfileCreateAndUpdatePopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.networkProfileCreateAndUpdatePopup)
		}
	}, [globalContext.state.networkProfileCreateAndUpdatePopup])

	const createNetworkProfile = async () => {
		let sendData = {
			profileName: data.profileName,
			tag: data.tag,
			clusterId: dataContext.state.list[0].id,
			profileType: data.profileType,
		}

		if (data?.profileType == 'VXLAN') {
			delete sendData.tag
			sendData['vxLanId'] = data?.vxLanId
		}

		let res = await dataService.createNetworkProfile(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(
				`Create [${res.data.profileName}] network profile operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
		dataContext.getNetworkProfiles()
	}

	const updateNetworkProfile = async () => {
		let sendData = { ...data }
		delete sendData['createOrUpdate']
		delete sendData['status']
		let res = await dataService.updateNetworkProfile(sendData)
		if (res.success) {
			globalContext.Snackbar(
				`Update [${res.data.profileName}] network profile operation started successfully`,
				'success'
			)
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title={data?.id ? 'Update Network Profile' : 'New Network Profile'}
			placement="right"
			onClose={handleClose}
			visible={globalContext.state.networkProfileCreateAndUpdatePopup || false}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={handleClose} small>
						Cancel
					</Button>
					{data?.id ? (
						<Button
							onClick={updateNetworkProfile}
							style={{ marginLeft: 8 }}
							type="primary"
							id="networkProfileSubmit"
							small>
							Update
						</Button>
					) : (
						<Button
							id="networkProfileSubmit"
							onClick={createNetworkProfile}
							style={{ marginLeft: 8 }}
							type="primary"
							disabled={`${command.checkValue(data).length ? 'true' : ''}`}
							small>
							Confirm
						</Button>
					)}
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Network Profile Name
				</label>
				<Input
					placeholder="Enter here"
					value={data?.profileName}
					onChange={handleChange('profileName')}
					id="networkProfileName"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Profile Type
				</label>
				<Select
					placeholder="Select Profile Type"
					value={data?.profileType}
					onChange={handleChange('profileType')}
					className="fullW"
					disabled={data?.createOrUpdate == 'update'}
					id="networkProfileType">
					<Option value={'VLAN'}>VLAN</Option>
					<Option value={'VXLAN'}>VXLAN</Option>
				</Select>
			</div>
			{data?.profileType == 'VXLAN' ? (
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>VNI
					</label>
					<InputNumber
						id="networkProfileVxLanId"
						className="fullW"
						placeholder="Enter here"
						value={data?.vxLanId}
						min={5000}
						max={10000}
						onChange={handleChange('vxLanId')}
					/>
				</div>
			) : (
				data?.profileType == 'VLAN' && (
					<div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Tags
							</label>
							<Input
								placeholder="Enter here"
								value={data?.tag}
								onChange={handleChange('tag')}
								id="networkProfileTag"
							/>
						</div>
					</div>
				)
			)}
		</Drawer>
	)
}
