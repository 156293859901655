import React from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		'& th': {
			textAlign: 'left',
		},
	},
	sumTable: {
		borderTop: '1px solid #dee2e6',
		width: '90%',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 8,
		width: '100%',
		textAlign: 'right',
		right: 16,
		paddingRight: 8,
	},
}))

export default function Host({ createGlusterVolume = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const complete = async () => {
		createGlusterVolume()
	}

	const getBlockDeviceName = (id) => {
		if (id && dataContext.state?.getBlockDevices) {
			let findItem = dataContext.state.getBlockDevices.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	return (
		<div className={classes.root}>
			<span style={{ fontWeight: 700 }}>Summary</span>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Name] </span>
				{modalData?.name}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Transport Type] </span>
				{modalData?.transportType}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Replica Count] </span>
				{modalData?.replicaCount}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Type] </span>
				{modalData?.type}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Deduplication & Compression] </span>
				{modalData?.dedupComp?.toString()}
			</div>
			{/* <div className={classes.drawerLine}>
				{dataContext?.state?.bricks}
				<span style={{ fontWeight: 700 }}>[Discs] </span>
				{modalData?.bricks.map(
					(br) =>
						br.blockDeviceId && (
							<div>
								<span style={{ fontWeight: 700 }}>[Block Device]</span> {getBlockDeviceName(br.blockDeviceId)}
								<br />
							</div>
						)
				)}
			</div> */}
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button
					onClick={() => complete()}
					type="primary"
					style={{ marginLeft: 8 }}
					small
					id="glusterVolumeComplete">
					Complete
				</Button>
			</div>
		</div>
	)
}
