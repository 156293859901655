import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext, useNavigate } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, InputNumber, Button, Collapse, Switch, Radio } from 'antd'
import { createUseStyles } from 'react-jss'
const { Option } = Select
const { Panel } = Collapse

const useStyles = createUseStyles((theme) => ({
	rGroup: {
		marginBottom: 12,
	},
}))

export default function DraggableDialog() {
	const classes = useStyles()
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (['highAvailability', 'highAvailabilityFt'].includes(key)) {
			valueTemp = valueTemp.toString()
			let newData = { ...data }
			newData = newData.nodes.map((n) => {
				return { ...n, highAvailability: valueTemp }
			})
		}
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('EditHighAvailabilityPopup', null)
	}

	const updateClusterData = async () => {
		let sendData = { ...data }
		delete sendData.status
		if (!sendData.haExcludedObjectIds) {
			sendData.haExcludedObjectIds = []
		}
		let res = await dataService.updateCls(sendData)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(`Update [${res.data.name}] cluster operation started successfully`, 'success')
			setTimeout(() => {
				navigate(window.location.pathname, { replace: true })
			}, 3000)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		setdata(globalContext.state.EditHighAvailabilityPopup)
	}, [globalContext.state.EditHighAvailabilityPopup])

	return (
		<div>
			<Drawer
				title={'Edit High Availability'}
				placement="right"
				onClose={handleClose}
				width={550}
				visible={globalContext.state.EditHighAvailabilityPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="highAvalibilityCancel">
							Cancel
						</Button>
						<Button
							onClick={() => updateClusterData()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="highAvalibityUpdate">
							Update
						</Button>
					</div>
				}>
				{
					<div>
						<div
							style={{
								marginBottom: 8,
								marginLeft: 16,
								display: 'inline-flex',
								width: '100%',
								borderRadius: 3,
								justifyContent: 'space-between',
							}}>
							<div>
								<span style={{ fontSize: 14, marginRight: 5 }}>Enable: </span>
								<Switch
									checked={data?.highAvailability == 'true'}
									onChange={handleChange('highAvailability')}
									name="highAvailability"
									color="primary"
									id="highAvalibitySwitch"
								/>
							</div>
						</div>
						<Collapse defaultActiveKey={['1', '2', '3']} ghost>
							<Panel header="Node Failure" key="1">
								<Radio.Group
									disabled={!data?.highAvailability}
									onChange={handleChange('hostFailureAction')}
									className={classes.rGroup}
									value={data?.hostFailureAction}>
									<Radio value={'none'}>No Action</Radio>
									<Radio value={'restartVms'}>Restart VMs</Radio>
								</Radio.Group>
							</Panel>
							<Panel header="Node Management Failure" key="2">
								<Radio.Group
									disabled={!data?.highAvailability}
									className={classes.rGroup}
									onChange={handleChange('hostMgmtFailureAction')}
									value={data?.hostMgmtFailureAction}
									id="hostManagementFailure">
									<Radio value={'none'}>No Action</Radio>
									<Radio value={'shutoffRestartVms'}>Shutdown & Restart VMs</Radio>
									<Radio value={'powerOffRestartVms'}>Power off & Restart VMs</Radio>
								</Radio.Group>
							</Panel>
							<Panel header="Storage Pool Failure" key="3">
								<Radio.Group
									disabled={!data?.highAvailability}
									className={classes.rGroup}
									onChange={handleChange('hostStoragePoolFailureAction')}
									value={data?.hostStoragePoolFailureAction}
									id="storageFailure">
									<Radio value={'none'}>No Action</Radio>
									<Radio value={'shutoffRestartVms'}>Shutdown & Restart VMs</Radio>
									<Radio value={'powerOffRestartVms'}>Power off & Restart VMs</Radio>
								</Radio.Group>
							</Panel>
						</Collapse>
					</div>
				}
			</Drawer>
		</div>
	)
}
