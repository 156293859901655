import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('nodeGroupCreatePopup', null)
		setdata({})
	}

	const getNodeGroups = async () => {
		let res = await dataService.getNodeGroups()
		if (res.success) {
			dataContext.updateState('getNodeGroups', res.data)
		}
	}

	const createNodeGroup = async () => {
		var nodesArr = []
		data.objects.forEach((nodeId) => {
			nodesArr.push({
				id: vmId,
				name: dataContext.state.getNodes.find((e) => e.id == nodeId).name,
			})
		})
		let sendData = {
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			objects: nodesArr,
		}

		let res = await dataService.createNodeGroup(sendData)
		if (res.success) {
			getNodeGroups()
			handleClose()

			globalContext.Snackbar(`Create [${res.data.name}] node group operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateNodeGroup = async () => {
		var nodessArr = []
		data.objects.forEach((nodeId) => {
			nodessArr.push({
				id: vmId,
				name: dataContext.state.getNodes.find((e) => e.id == nodeId).name,
			})
		})
		let sendData = {
			id: data.id,
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			objects: nodessArr,
		}
		let res = await dataService.updateNodeGroup(sendData)
		if (res.success) {
			getNodeGroups()
			handleClose()

			globalContext.Snackbar(`Update [${res.data.name}] node group operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			if (globalContext.state.nodeGroupCreatePopup) {
				setdata(globalContext.state.nodeGroupCreatePopup)
			}
		}
	}, [globalContext.state.nodeGroupCreatePopup])

	return (
		<div>
			<Drawer
				title={
					globalContext.state.nodeGroupCreatePopup?.createOrUpdate == 'update'
						? 'Update Node Group'
						: 'New Node Group'
				}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.nodeGroupCreatePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						{globalContext.state.nodeGroupCreatePopup?.createOrUpdate == 'update' ? (
							<Button
								onClick={() => updateNodeGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								small
								id="nodeGroupSubmit">
								Update
							</Button>
						) : (
							<Button
								onClick={() => createNodeGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								disabled={`${data?.objects?.length == 0 && data?.name?.length == 0 ? 'true' : ''}`}
								small
								id="nodeGroupSubmit">
								Create
							</Button>
						)}
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Group Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="nodeGroupName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Select Nodes
					</label>
					<Select
						mode="multiple"
						value={data?.objects}
						onChange={handleChange('objects')}
						placeholder="Select Nodes"
						id="selectNodes"
						className="fullW">
						{dataContext.state?.getNodes?.map((option) => (
							<Option key={option.id} value={option.id} label={option.name}>
								{option.name}
							</Option>
						))}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
