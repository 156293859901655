import React from 'react'
import { Route } from 'react-router-dom'
import { useAuthContext, Error } from 'components'

const PermissionCheck = ({ isroute, children, route, permissionList = [], ...rest }) => {
	let authContext = useAuthContext()
	let Element = children
	let result = authContext.state.permissionList.checkPermissionList(permissionList)
	if (permissionList.length === 0) result = true
	if (route) {
		return (
			<Route
				{...rest}
				element={(props) => (result ? <Element {...rest} /> : <Error errorMessage="Not authorized."></Error>)}
			/>
		)
	} else if (result) {
		return Element
	} else return null
}

export default PermissionCheck
