import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button } from 'antd'

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ ...globalContext.state.nodeCreateAndUpdatePopup })

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('nodeCreateAndUpdatePopup', null)
	}

	const createNode = async () => {
		let nodeCreateAndUpdatePopup = globalContext.state.nodeCreateAndUpdatePopup
		let newList = [...dataContext.state.getNetworks]

		if (nodeCreateAndUpdatePopup.id) {
			let findNetworkIndex = newList.findIndex((i) => i.id == nodeCreateAndUpdatePopup.id)
		} else {
			let res = await dataService.createNode(data)
			if (res.success) {
				newList.push({
					id: res.data.id,
					name: res.data.name,
					status: res.data.status,
					clusterId: res.data.clusterId,
					type: 'node',
				})

				dataContext.updateState('getNodes', newList)
				handleClose()
				globalContext.Snackbar(`Create [${res.data.name}] node operation started successfully`, 'success')
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}

	return (
		<div>
			<Drawer
				title="New Node"
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.nodeCreateAndUpdatePopup || false}
				destroyOnClose={true}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="nodeCreateCancel">
							Cancel
						</Button>
						<Button onClick={createNode} type="primary" style={{ marginLeft: 8 }} small id="nodeCreate">
							Create
						</Button>
					</div>
				}>
				<label>
					<span className="requiredHint">*</span>Node Name
				</label>
				<Input placeholder="Enter here" defaultValue={data?.name} onChange={handleChange('name')} id="netName" />
				<span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>Specify a unique name</span>
			</Drawer>
		</div>
	)
}
