import React, { useEffect, useState } from 'react'
import {
	PlayCircleOutlined,
	PlaySquareOutlined,
	PauseCircleOutlined,
	StopOutlined,
	PoweroffOutlined,
	RotateLeftOutlined,
	ContainerOutlined,
	SyncOutlined,
	BlockOutlined,
	PlusSquareOutlined,
	EditOutlined,
	DeleteOutlined,
	ArrowsAltOutlined,
} from '@ant-design/icons'

import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useAuthContext, useGlobalContext, useDataContext } from 'components'
import dataService from 'service/dataService'

export let startVM = (globalContext) => async ({ event, props }) => {
	let res = await dataService.startVM(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let shutdownVM = (globalContext) => async ({ event, props }) => {
	let res = await dataService.shutdownVM(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let suspendVM = (globalContext) => async ({ event, props }) => {
	let res = await dataService.suspendVM(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let resumeVM = (globalContext) => async ({ event, props }) => {
	let res = await dataService.resumeVM(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let shutoffVM = (globalContext) => async ({ event, props }) => {
	let res = await dataService.shutoffVM(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export const migrate = ({ event, props }) => {
	alert('Action not defined!')
}

export const clone = ({ event, props }) => {
	alert('Action not defined!')
}
export const editSetting = ({ event, props }) => {
	alert('Action not defined!')
}

export const takeSnapshot = (globalContext) => async ({ event, props }) => {
	globalContext.updateState('takeSnapshotPopup', props)
}
export default function VMContextMenu() {
	let navigate = useNavigate()
	const authContext = useAuthContext()
	const globalContext = useGlobalContext()
	const [data, setdata] = useState(globalContext.state.contextMenuData)
	const dataContext = useDataContext()

	const VMCreateAndUpdatePopup = async ({ event, props }) => {
		let response = await dataService.getVmWithId(props.id)
		response.data.storagePoolId = response?.data?.storage[0]?.storagePoolId
		if (props.tenantId) {
			let res = await dataService.getTenant(props.tenantId)
			if (res) {
				globalContext.updateState('tenantVmPopup', {
					createOrUpdate: 'update',
					tenant: res?.data,
					...response.data,
				})
			}
		} else {
			globalContext.updateState('VMCreateAndUpdatePopup', {
				createOrUpdate: 'update',
				...response.data,
			})
		}
	}

	const VMMigratePopup = async ({ event, props }) => {
		let response = await dataService.getVmWithId(props.id)
		response.data.storagePoolId = response.data.storage.storagePoolId
		response.data.selection = 'both'
		globalContext.updateState('VMMigratePopup', response.data)
	}

	const ManageSnapshotsPopup = async ({ event, props }) => {
		let response = await dataService.getVmWithId(props.id)
		if (response.data.snapshots.length) {
			globalContext.updateState('manageSnapshotPopup', response.data)
		} else {
			globalContext.Snackbar('There is no snapshot on this VM! You can take a new snapshot.', 'info')
		}
	}

	const ManageBackupProfile = async ({ event, props }) => {
		let response = await dataService.getVmWithId(props.id)
		globalContext.updateState('manageBackupPopup', response.data)
	}

	const VmClonePopup = async ({ event, props }) => {
		let response = await dataService.getVmWithId(props.id)
		globalContext.updateState('vmClonePopup', response.data)
	}

	const DeletePopup = async ({ event, props }) => {
		globalContext.updateState('deletePopup', props)
	}

	const convertVmFromTransient = async ({ event, props }) => {
		let res = await dataService.createVmFromTransientVm(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const VmTemplatePopup = async ({ event, props }) => {
		globalContext.updateState('vmTemplateCreatePopup', props)
	}

	useEffect(() => {
		setdata(globalContext.state.contextMenuData)
	}, [globalContext.state.contextMenuData])

	return (
		<>
			<Menu id={'vm'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					VM Actions | ({data?.state})
				</div>
				<hr />
				<Item
					id="vmStart"
					onClick={startVM(globalContext)}
					disabled={
						![globalContext.state.vmState.Crashed, globalContext.state.vmState.Shutdown].checkPermissionList([
							data?.state,
						])
					}>
					<PlayCircleOutlined
						style={{
							color: '#1cb084',
							paddingRight: 8,
						}}
					/>
					Start
				</Item>
				<Item
					id="vmShutdown"
					onClick={shutdownVM(globalContext)}
					disabled={![globalContext.state.vmState.Running].checkPermissionList([data?.state])}>
					<StopOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Shutdown
				</Item>
				<Item
					id="vmSuspend"
					onClick={suspendVM(globalContext)}
					disabled={![globalContext.state.vmState.Running].checkPermissionList([data?.state])}>
					<PauseCircleOutlined
						style={{
							color: '#c27620',
							paddingRight: 8,
						}}
					/>
					Suspend
				</Item>
				<Item
					id="vmResume"
					onClick={resumeVM(globalContext)}
					disabled={![globalContext.state.vmState.Paused].checkPermissionList([data?.state])}>
					<PlaySquareOutlined
						style={{
							color: '#1cb084',
							paddingRight: 8,
						}}
					/>
					Resume
				</Item>
				<Item
					id="vmShutoff"
					onClick={shutoffVM(globalContext)}
					disabled={[globalContext.state.vmState.Shutoff].checkPermissionList([data?.state])}>
					<PoweroffOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Power off
				</Item>
				<hr />
				{data?.isTransient && (
					<Item onClick={convertVmFromTransient} id="transientVmConvert">
						<ArrowsAltOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Convert to Persistent Vm
					</Item>
				)}
				{!data?.isTransient && (
					<Item onClick={takeSnapshot(globalContext)} id="vmSnapshotTake">
						<RotateLeftOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Take Snapshot
					</Item>
				)}
				{!data?.isTransient && (
					<Item onClick={ManageSnapshotsPopup} id="snapshotManage">
						<ContainerOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Manage Snapshots
					</Item>
				)}
				<hr />
				{!data?.isTransient && (
					<Item
						onClick={ManageBackupProfile}
						disabled={authContext?.state?.userInfo?.userRole == 'tenantAdmin'}
						id="backupProfileManage">
						<SyncOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Manage Backups
					</Item>
				)}
				{!data?.isTransient && <hr />}
				{!data?.isTransient && (
					<Item
						onClick={VMMigratePopup}
						disabled={authContext?.state?.userInfo?.userRole === 'tenantAdmin'}
						id="vmMigrate">
						<BlockOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Migrate VM
					</Item>
				)}
				{!data?.isTransient && (
					<Item onClick={VmClonePopup} disabled={false} id="vmClone">
						<BlockOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Clone VM
					</Item>
				)}
				{!data?.isTransient && (
					<Item onClick={VmTemplatePopup} disabled={data?.tenantId} id="vmTemplateCreate">
						<PlusSquareOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Create Template
					</Item>
				)}
				{!data?.isTransient && <hr />}
				{!data?.isTransient && (
					<Item onClick={VMCreateAndUpdatePopup} id="vmEdit">
						<EditOutlined
							style={{
								color: '#004085',
								paddingRight: 8,
							}}
						/>
						Edit VM
					</Item>
				)}

				<Item onClick={DeletePopup} id="deleteVm">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete VM
				</Item>
			</Menu>
		</>
	)
}
