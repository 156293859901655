import React, { useEffect } from 'react'
import { contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext } from 'components'
import vm from 'assets/img/fill/vm.svg'

import { createUseStyles } from 'react-jss'
import { Tree, Tooltip, Button, Empty } from 'antd'
const { TreeNode } = Tree
import { ProfileOutlined, ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons'

const useStyles = createUseStyles({
	root: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '3px 0',
		height: 40,
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
})

export default function SideMenuNetwork() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	function handleContextMenu(e, obj) {
		if (!obj.type) return console.log('Context menu does not exists.', obj)
		e.preventDefault()
		e.stopPropagation()
		globalContext.openContextMenu(obj.type, obj, e)
	}
	const handleSelect = (event, obj) => {
		if (obj.type) {
			let link = `/${obj.type}/${obj.id}`
			switch (obj.type) {
				case 'network':
					link = `/cluster/${obj.clusterId}/network/${obj.id}`
					break
				case 'networkProfile':
					link = `/cluster/${obj.clusterId}/networkProfile/${obj.id}`
					break
				default:
					alert('New parameter added.')
					break
			}
			navigate(link)
		}

		// setSelected(obj.id)
	}

	const _getIcon = (item) => {
		switch (item.type) {
			case 'network':
				return <GlobalOutlined className={classes.icon} />
				break
			case 'networkProfile':
				return <ProfileOutlined className={classes.icon} />
				break
			default:
				return null
				break
		}
	}

	const _createList = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								{_getIcon(el)}
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}
	return (
		<>
			<h4 style={{ paddingLeft: 12 }}>Networks</h4>
			{dataContext.state.getNetworks?.length ? (
				<Tree className="tree-wrapper-mb1 tree-wrapper-h network-tab-content">
					{_createList(dataContext.state?.getNetworks)}
				</Tree>
			) : (
				<Empty imageStyle={{ height: 40 }} description={<span>No network.</span>} />
			)}
			<h4 style={{ paddingLeft: 12 }}>Network Profiles</h4>
			{dataContext.state.getNetworkProfiles?.length ? (
				<Tree className="tree-wrapper-mb1 tree-wrapper-h network-profile-tab-content">
					{_createList(dataContext.state?.getNetworkProfiles)}
				</Tree>
			) : (
				<Empty imageStyle={{ height: 40 }} description={<span>No network profile.</span>} />
			)}

			<div
				style={{
					alignSelf: 'flex-end',
					marginLeft: 'auto',
					marginRight: 'auto',
					position: 'absolute',
					bottom: 8,
					left: 16,
					fontSize: 11,
					width: 275,
				}}>
				<Button
					id="networkCreate"
					onClick={() => globalContext.updateState('networkCreateAndUpdatePopup', {})}
					style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
					block>
					Create a Network
				</Button>
				<Button
					id="networkProfileCreate"
					onClick={() => globalContext.updateState('networkProfileCreateAndUpdatePopup', {})}
					style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
					block>
					Create a Network Profile
				</Button>
			</div>
		</>
	)
}
