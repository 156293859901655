import React, { useState, useEffect } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Select, Button, Switch, InputNumber } from 'antd'
import dataContext from '../../Context/DataContext'
import TextArea from 'antd/es/input/TextArea'
const { Option } = Select

const useStyles = createUseStyles({
	root: {
		height: '90%%',
		position: 'relative',
		paddingBottom: 40,
	},
	input: { width: '100%' },
	footer: {
		height: '10%',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 25,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Container({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [accessibleISOlist, setAccessibleISOlist] = useState([])
	const [data, setdata] = useState({
		image: modalData.image || '',
		entrypoint: modalData.entrypoint || '',
		environment: modalData.environment || '',
		command: modalData.command || '',
		restartPolicy: modalData.restartPolicy || 'on-failure',
		autoStart: modalData.autoStart || false,
	})

	useEffect(() => {
		if (globalContext.state.containerCreateAndUpdatePopup) {
			if (globalContext.state.containerCreateAndUpdatePopup.createOrUpdate === 'update') {
				setdata({
					...data,
					entrypoint: Array.isArray(modalData?.entrypoint)
						? modalData.entrypoint.join('\n')
						: modalData?.entrypoint,
					command: Array.isArray(modalData?.command) ? modalData.command.join('\n') : modalData?.command,
					environment: Array.isArray(modalData?.environment)
						? modalData.environment.join('\n')
						: modalData?.environment,
				})
			}
		}
	}, [globalContext.state.containerCreateAndUpdatePopup])

	useEffect(() => {
		let tempIsoList = []

		const accessiblePools = dataContext.state.getVmStoragePools?.filter((pool) =>
			pool.poolUnits.some((unit) => unit.nodeId === modalData?.nodeId)
		)

		const accessiblePoolIds = accessiblePools?.map((pool) => pool.id)

		dataContext?.state?.getContainerImages?.forEach((iso) => {
			if (accessiblePoolIds.includes(iso?.storagePoolId)) {
				tempIsoList = [...tempIsoList, iso]
			}
		})
		setAccessibleISOlist(tempIsoList)
	}, [])

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<>
			<div className={classes.root}>
				<div
					style={{
						marginBottom: 8,
						width: '100%',
					}}>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Container Image
						</label>
						<Select
							value={data?.image}
							onChange={handleChange('image')}
							className="fullW"
							showSearch={true}
							id="containerImage">
							{accessibleISOlist?.map((item) => (
								<Option value={item.absolutePath}>
									[{item.absolutePath.split('/').slice(3, 4)}] {item.name}{' '}
								</Option>
							))}
						</Select>
					</div>
					<div className="form-group-ant">
						<label>Entrypoint</label>
						<TextArea
							placeholder="e.g. /bin/ls"
							rows={3}
							value={data?.entrypoint}
							onChange={handleChange('entrypoint')}
							id="containerEntrypoint"
						/>
						<small>Leave empty to use the default in the specified container image</small>
					</div>
					<div className="form-group-ant">
						<label>Command</label>
						<TextArea
							placeholder="e.g. -alh&#10;/my/path"
							rows={3}
							value={data?.command}
							onChange={handleChange('command')}
							id="containerCommand"
						/>
						<small>Leave empty to use the default in the specified container image</small>
					</div>
					<div className="form-group-ant">
						<label>Environment Variables</label>
						<TextArea
							placeholder="e.g. KEY1=VALUE1&#10;KEY2=VALUE2"
							rows={3}
							id="environment"
							value={data?.environment}
							onChange={handleChange('environment')}
						/>
					</div>
					<div className="form-group-ant">
						<label title="Container exit strategy">Restart Policy</label>
						<Select value={data.restartPolicy} className="fullW" onChange={handleChange('restartPolicy')}>
							<Option value={'unless-stopped'} title="Restart regardless of container exit status">
								Unless-Stopped
							</Option>
							<Option value={'on-failure'} title="Restart on non-zero container exit status">
								On-Failure
							</Option>
							<Option value={'no'} title="Never restart container on exit">
								Never
							</Option>
						</Select>
					</div>
					<div className="form-group-ant">
						<label title="Autostart on Node Reboot?">Autostart :</label>
						<Switch
							checked={data?.autoStart}
							onChange={handleChange('autoStart')}
							style={{ marginLeft: '5px' }}
							id="autoStart"
						/>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.image === ''}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</>
	)
}
