import React, { useEffect, useState } from 'react'
import { useNavigate, useGlobalContext, useDataContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Objects from './Objects'
import General from './General'
import Storage from './Storage'
import Schedule from './Schedule'
import Complete from './Complete'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 450,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		padding: '1rem',
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: 24,
		flex: 1,
		paddingTop: 16,
		marginBottom: 12,
		minHeight: 300,
	},
	menuList: {
		height: '100%',
		borderRight: '1px solid #31435121',
		paddingRight: 0,
	},
	listItem: {
		padding: '9px 24px',
		fontSize: 14,
	},
	active: {
		background: '#D6D1E1;!important',
		color: 'rgba(0, 0, 0, 0.85);',
		borderRight: '3px solid #0f427a;',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.5,
	},
	hide: {
		display: 'none',
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [selectedMenu, setSelectedMenu] = useState(0)
	const [selectedMenuMax, setSelectedMenuMax] = useState(0)

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (updateData) => {
		setdata({ ...data, ...updateData })
	}
	const handleClose = () => {
		globalContext.updateState('BackupProfileCreatePopup', null)
		setSelectedMenu(0)
		setSelectedMenuMax(0)
		setdata({})
	}
	const fetchVms = async () => {
		let resp = await dataService.getVms()
		if (resp.success) {
			dataContext.updateState('getVms', resp.data)
		} else {
			globalContext.Snackbar(resp.errorMessage, 'error')
			dataContext.updateState('getVms', [])
		}
	}
	useEffect(() => {
		if (localStorage.getItem('token')) {
			if (globalContext.state.BackupProfileCreatePopup?.createOrUpdate == 'update') {
				setdata(globalContext.state.BackupProfileCreatePopup)
			}
			fetchVms()
		}
	}, [globalContext.state.BackupProfileCreatePopup])

	const createBackupProfile = async () => {
		data.clusterId = dataContext?.state.list[0].id
		if (data.incrementalSchedulePeriod == 'week') {
			data.incrementalScheduleDays = data.weeklyDays
		} else if (data.incrementalSchedulePeriod == 'month') {
			data.incrementalScheduleDays = data.monthlyDays
		} else if (data.incrementalSchedulePeriod == 'periodically' && data.periodically2) {
			data.incrementalSchedulePeriod = data.periodically2.toString()
			data.incrementalScheduleDays = []
			data.incrementalScheduleClock = data.periodically2 == 'hour' ? data.hour.toString() : data.minute.toString()
		}
		delete data.weeklyDays
		delete data.monthlyDays
		delete data.periodically2
		delete data.hour
		delete data.minute
		let newList = [...dataContext.state.getBackupProfiles]
		let res = await dataService.createBackupProfile(data)
		if (res.success) {
			newList.push({
				id: res.data.id,
				name: res.data.name,
				status: res.data.status,
				clusterId: res.data.clusterId,
				type: 'backupProfile',
			})

			dataContext.updateState('getBackupProfiles', newList)
			handleClose()

			globalContext.Snackbar(`Create [${res.data.name}] backup profile operation started successfully`, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateBackupProfile = async () => {
		data.clusterId = dataContext?.state.list[0].id
		if (data.incrementalSchedulePeriod == 'week') {
			data.incrementalScheduleDays = data.weeklyDays
		} else if (data.incrementalSchedulePeriod == 'month') {
			data.incrementalScheduleDays = data.monthlyDays
		} else if (data.incrementalSchedulePeriod == 'periodically' && data.periodically2) {
			data.incrementalSchedulePeriod = data.periodically2
			data.incrementalScheduleDays = []
			data.incrementalScheduleClock = data.periodically2 == 'hour' ? data.hour.toString() : data.minute.toString()
		}
		delete data.weeklyDays
		delete data.monthlyDays
		delete data.periodically2
		delete data.hour
		delete data.minute
		delete data.status
		delete data.createOrUpdate
		let res = await dataService.updateBackupProfile(data)
		if (res) {
			if (res.success) {
				handleClose()

				globalContext.Snackbar(`Update [${res.data.name}] backup profile operation started successfully`, 'success')
				handleClose()
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}

	const next = () => {
		setSelectedMenu(selectedMenu + 1)
		setSelectedMenuMax(selectedMenu + 2)
	}
	const prev = () => {
		setSelectedMenu(selectedMenu - 1)
		setSelectedMenuMax(selectedMenu - 1)
	}

	let selectedMenuList = [
		<General modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Objects modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Storage modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Schedule modalDataChange={handleChange} next={next} prev={prev} modalData={data} />,
		<Complete
			createBackupProfile={createBackupProfile}
			updateBackupProfile={updateBackupProfile}
			next={next}
			prev={prev}
			modalData={data}
		/>,
	]

	return (
		<div>
			<Modal
				title={
					globalContext.state.BackupProfileCreatePopup?.createOrUpdate == 'update'
						? 'Update Backup Profile'
						: 'Create Backup Profile'
				}
				className="stepModal"
				width={950}
				visible={globalContext.state.BackupProfileCreatePopup || false}
				footer={null}
				closeIcon={<CloseCircleOutlined />}
				onCancel={handleClose}>
				<Row>
					<Col span={8}>
						<div className={classes.menuList}>
							<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 0 ? classes.actived : ''} ${
										selectedMenu === 0 ? classes.active : ''
									} `}>
									1- General
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 1 ? classes.actived : ''} ${
										selectedMenu === 1 ? classes.active : ''
									} `}>
									2- Objects
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 2 ? classes.actived : ''} ${
										selectedMenu === 2 ? classes.active : ''
									} `}>
									3- Select storage
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 3 ? classes.actived : ''} ${
										selectedMenu === 3 ? classes.active : ''
									}`}>
									4- Schedule
								</li>
								<li
									className={`${classes.listItem}   ${selectedMenuMax > 4 ? classes.actived : ''} ${
										selectedMenu === 4 ? classes.active : ''
									}`}>
									5- Complete
								</li>
							</ul>
						</div>
					</Col>
					<Col span={16} className={classes.content}>
						{selectedMenuList[selectedMenu]}
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
