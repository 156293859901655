import React, { useState, useRef } from 'react'
import { Progress, Badge, Popconfirm, Typography, message } from 'antd'
import { useTableSearch } from './Search'
import { useDataContext } from '../../components'
import dataService from '../../service/dataService'
const { Link } = Typography

export const vmStorageCommon = () => {
	const [openConfirm, setOpenConfirm] = useState('')

	const dataContext = useDataContext()
	const { getColumnSearchProps } = useTableSearch()
	const busFilters = [
		{ text: 'VirtIO', value: 'virtio' },
		{ text: 'SCSI', value: 'scsi' },
		{ text: 'SATA', value: 'sata' },
		{ text: 'IDE', value: 'ide' },
	]

	const filterTextMapping = {
		virtio: 'VirtIO',
		scsi: 'SCSI',
		sata: 'SATA',
		ide: 'IDE',
	}

	const thinFilters = [
		{ text: 'Thin', value: true },
		{ text: 'Thick', value: false },
	]

	const ownerFilters = [
		{ text: 'Dedicated', value: 'Dedicated' },
		{ text: 'Sharer', value: 'Sharer' },
		{ text: 'Shared', value: 'Shared' },
	]

	const getVmName = (id) => {
		if (id && dataContext.state?.getVms) {
			let findItem = dataContext.state.getVms.find((item) => item.id === id)
			if (findItem?.name) {
				return findItem?.name
			} else {
				return 'N/A'
			}
		} else {
			return 'N/A'
		}
	}

	const renderOwner = (row) => {
		const hasShareAttributes = row?.shareable && row?.bus === 'ide'
		const isSharerWithDeletedVm = row?.vmId == 'N/A' && row?.source.includes('-shareable/')
		const hasSamePath = row?.source.includes('/' + getVmName(row?.vmId) + '-shareable/')
		if (isSharerWithDeletedVm || (hasShareAttributes && hasSamePath)) {
			return 'Sharer'
		} else if (hasShareAttributes && !hasSamePath) {
			return 'Shared'
		} else {
			return 'Dedicated'
		}
	}

	const filterOwner = (value, record) => {
		return renderOwner(record) == value
	}

	const deleteSharedStorage = async (row) => {
		let res = await dataService.deleteSharedVmStorage(row.id)
		if (res.success) {
			message.success(res.message ? res.message : 'Deleted.')
			setOpenConfirm('')
		} else {
			message.error(res?.errorMessage || 'Error!')
		}
	}

	const vmStorageColumns = [
		{
			title: 'VM Name',
			dataIndex: 'vmId',
			key: 'vmId',
			// ...getColumnSearchProps('name', 'Name'), // TODO: vmName must be presented as a string so sorting and searching can be done
			// sorter: (a, b) => a.name.localeCompare(b.name),
			render: (text, row) => getVmName(text),
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			...getColumnSearchProps('name', 'Name'),
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: 'Utilization',
			dataIndex: 'physize',
			key: 'physize',
			sorter: (a, b) => parseInt(a?.physize, 10) - parseInt(b?.physize, 10),
			render: (text, row) => (
				<div>
					<Progress
						percent={((row?.physize / row?.size) * 100).toFixed(2)}
						size="small"
						strokeColor={{
							from: '#108ee9',
							to: '#2b5889',
						}}
						status={((row?.physize / row?.size) * 100).toFixed(2) > 99 ? 'exception' : 'normal'}
					/>
					<div>{`${row?.physize} / ${row?.size} GB`}</div>
				</div>
			),
		},
		{
			title: 'Capacity',
			dataIndex: 'size',
			key: 'size',
			sorter: (a, b) => parseInt(a?.size, 10) - parseInt(b?.size, 10),
			render: (text, row) => <span>{text} GB</span>,
		},
		{
			title: 'Provisioning',
			dataIndex: 'thinProvisioning',
			key: 'thinProvisioning',
			filters: thinFilters,
			onFilter: (value, record) => record?.thinProvisioning === value,
			render: (input) => {
				const filter = thinFilters.find((filter) => filter.value === input)
				return <span>{filter ? filter.text : ''}</span>
			},
		},
		{
			title: 'Source',
			dataIndex: 'source',
			key: 'source',
			...getColumnSearchProps('source', 'Source'),
		},
		{
			title: 'Target',
			dataIndex: 'target',
			key: 'target',
		},
		{
			title: 'Bus',
			dataIndex: 'bus',
			key: 'bus',
			filters: busFilters,
			onFilter: (value, record) => record?.bus === value,
			render: (text, row) => filterTextMapping[text] || text,
		},
		{
			title: 'Owner',
			dataIndex: 'shareable',
			key: 'shareable',
			filters: ownerFilters,
			onFilter: (value, record) => filterOwner(value, record),
			render: (text, row) => renderOwner(row),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{ text: 'Ready', value: 'READY' },
				{ text: 'Not ready', value: 'NOT_READY' },
			],
			onFilter: (value, record) => record.status.includes(value),
			render: (text, row) => (
				<span>
					<Badge color={row['status'] == 'READY' ? 'green' : 'red'} text={row['status']} />
				</span>
			),
		},
		{
			title: 'Action',
			// dataIndex: 'action',
			key: 'action',
			onFilter: (value, record) => record.status.includes(value),
			render: (text, row) => (
				<div>
					{filterOwner('Sharer', row) ? (
						<Popconfirm
							placement="bottomLeft"
							title={'Are you sure?'}
							onConfirm={() => deleteSharedStorage(row)}
							okText="Yes"
							open={openConfirm == row?.id}
							onCancel={() => setOpenConfirm('')}
							cancelText="No">
							<Link
								onClick={() => (openConfirm == row?.id ? setOpenConfirm('') : setOpenConfirm(row?.id))} // toggle logic
								style={{ marginRight: 8 }}
								id="deleteSharedStorage">
								Delete
							</Link>
						</Popconfirm>
					) : (
						''
					)}
				</div>
			),
		},
	]

	return {
		vmStorageColumns,
		filterTextMapping,
	}
}
