import { http } from 'components'

async function login(data, url = `/login/`) {
	url = http.defaults.baseURL + '/login/'
	return http.post(url, data)
	// return {
	// 	status: 200,
	// 	data: { token: 'loginService Local' },
	// 	message: 'sdsdsdsd',
	// }
}

async function userInfo() {
	// return http.get(`/api/currentuser/`);
	return {
		status: 200,
		data: {
			permissions: [
				'IATA',
				'viewCountryDetailReports',
				'viewCountryGeneralReports',
				'addNewUser',
				'modifyUserDetail',
				'viewUserDetail',
				'viewUserList',
				'postCountryData',
				'viewCountryData',
			],
			user: 'aaa@aaa.com',
			name: 'ali veli',
			id: 5,
			type: 'admin',
		},
		message: 'ok',
	}
}

export default {
	login,
	userInfo,
}
