import React, { useEffect, useState } from 'react'
import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useGlobalContext, useDataContext } from 'components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dataService from 'service/dataService'
import { SaveOutlined } from '@material-ui/icons'
import { Checkbox, Modal } from 'antd'

export default function VeritasBackupProfileContextMenu() {
	const globalContext = useGlobalContext()
	const [data, setdata] = useState(globalContext.state.contextMenuData)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [clients, setClients] = useState([])

	const BackupProfileUpdatePopup = async ({ event, props }) => {
		let response = await dataService.getVeritasBackupById(props.id)
		globalContext.updateState('VeritasBackupProfileCreatePopup', {
			createOrUpdate: 'update',
			...response.data,
		})
	}

	const DeletePopup = async ({ event, props }) => {
		// let response = await dataService.getVmSnapshots(props.id)
		props.type = 'veritas'
		props.name = props.policyName
		globalContext.updateState('deletePopup', props)
	}

	const OnCancel = () => {
		setIsModalOpen(false)
		setClients([])
	}

	const OnConfirm = async () => {
		let sendData = { clients: clients }
		setIsModalOpen(false)
		let res = await dataService.externalBackupProfileInitiateBackup(data?.policyName, sendData)
		if (res.success) {
			globalContext.Snackbar('Manual Backup operation started', 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
		setClients([])
	}

	const handleChange = (e, hostname) => {
		if (e.target.checked) {
			setClients((prevClients) => [...prevClients, hostname])
		} else {
			setClients((prevClients) => prevClients.filter((name) => name !== hostname))
		}
	}

	useEffect(() => {
		setdata(globalContext.state.contextMenuData)
	}, [globalContext.state.contextMenuData])

	return (
		<>
			<Menu id={'veritas'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Veritas Backup Profile Actions
				</div>
				<hr />
				<Item onClick={() => setIsModalOpen(!isModalOpen)} id="backupProfileManualBackup">
					<SaveOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Manual Backup
				</Item>
				<hr />
				<Item onClick={BackupProfileUpdatePopup} id="backupProfileEdit">
					<EditOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Edit Backup Profile
				</Item>
				<Item onClick={DeletePopup} id="backupProfileDelete">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete Backup Profile
				</Item>
			</Menu>
			<Modal
				title={`Manual Backup`}
				open={isModalOpen}
				onCancel={OnCancel}
				destroyOnClose={true}
				onOk={OnConfirm}
				okButtonProps={{ disabled: clients.length === 0 }}>
				<p style={{ fontSize: 'small', fontWeight: 'bold', marginBottom: 0 }}>Start backup of policy </p>
				<p>{data?.policyName}</p>
				<p style={{ fontSize: 'small', fontWeight: 'bold' }}>Select one or more clients you want to back up. </p>
				{data?.clients?.map((client, index) => (
					<Checkbox style={{ marginLeft: 0 }} key={index} onChange={(e) => handleChange(e, client.hostName)}>
						{client.hostName}
					</Checkbox>
				))}
			</Modal>
		</>
	)
}
