import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Tree, Tooltip, Button, Switch, Empty, Select } from 'antd'
const { TreeNode } = Tree
import 'react-contexify/dist/ReactContexify.css'
import { useNavigate, useGlobalContext, useDataContext } from 'components'
import Icons from './Icons'

import {
	ClusterOutlined,
	CodeSandboxOutlined,
	AppstoreOutlined,
	ExclamationCircleOutlined,
	FolderOpenOutlined,
	CaretRightOutlined,
	PauseOutlined,
	PoweroffOutlined,
} from '@ant-design/icons'

const useStyles = createUseStyles({
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '3px 0',
		height: 40,
	},
	TreeRightIcons: {
		display: 'flex',
		// justifyContent: 'space-around',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	TreeLeftIcons: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		width: 20,
		color: 'rgb(194, 88, 88)',
	},
	statusIcon: {
		width: 16,
		position: 'absolute',
		bottom: '-5px',
		right: 0,
		opacity: 0.75,
	},
})

export default function SideMenuCluster() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	const [expanded, setExpanded] = React.useState([])
	const [selected, setSelected] = React.useState([])

	const [expandedKeys, setExpandedKeys] = React.useState([])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	function handleContextMenu(e, obj) {
		if (!obj.type) return console.log('Context menu does not exists.', obj)
		e.preventDefault()
		e.stopPropagation()
		globalContext.openContextMenu(obj.type, obj, e)
	}

	const handleGroupView = (key) => (e) => {
		let valueTemp = e?.target ? (key == 'groupView' ? e.target.checked : e.target.value) : e
		dataContext.updateState(key, valueTemp)
	}

	const handleSelect = (event, obj) => {
		if (obj.type) {
			let link = `/${obj.type}/${obj.id}`
			switch (obj.type) {
				case 'cluster':
					link = `/cluster/${obj.id}`
					break
				case 'node':
					link = `/cluster/${obj.clusterId}/node/${obj.id}`
					break
				case 'container':
					link = `/cluster/${obj.clusterId}/node/${obj.nodeId}/container/${obj.id}`
					break
				default:
					console.error('You added a new parameter, check it!')
					break
			}
			navigate(link)
		}

		setSelected(obj.id)
	}

	const _getStateIcon = (state) => {
		switch (state) {
			case 'running':
				return <CaretRightOutlined className={classes.statusIcon} style={{ color: '#1cb084' }} />
				break

			// case 'paused':
			// 	return <PauseOutlined className={classes.statusIcon} style={{ color: '#c27620' }} />
			// break

			// case 'shutdown':
			// 	return <PoweroffOutlined className={classes.statusIcon} style={{ color: '#c25858' }} />
			// 	break

			// case 'shutoff':
			// 	return <PoweroffOutlined className={classes.statusIcon} style={{ color: '#c25858' }} />
			// 	break

			default:
				break
		}
	}

	const _getIcon = (item) => {
		switch (item.type) {
			case 'cluster':
				return <ClusterOutlined className={classes.icon} />
				break
			case 'node':
				return ''
				break
			case 'container':
				return (
					<div style={{ position: 'relative' }}>
						<AppstoreOutlined className={classes.icon} />
						{item.status == 'READY' ? _getStateIcon(item.state) : ''}
						{item.state == 'start' ? 'start' : ''}
					</div>
				)
				break
			default:
				return null
				break
		}
	}

	const _createListAnt = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (el.type == 'node' ? el.child.containers?.length : el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
								</div>
								<div className={classes.TreeRightIcons}>
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
									<Icons el={el}></Icons>
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}>
						{_createListAnt(el.type == 'node' ? el.child.containers : el.child)}
					</TreeNode>
				)
			else
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								name={el?.name}
								onContextMenu={(e) => handleContextMenu(e, el)}
								onClick={(e) => {
									handleSelect(e, el)
								}}>
								<div className={classes.TreeLeftIcons}>
									{_getIcon(el)}
									{el?.name}
									{el?.status != 'READY' && (
										<Tooltip title="Not ready or failed, check it!" placement="top">
											<ExclamationCircleOutlined className={classes.errorIcon} />
										</Tooltip>
									)}
								</div>
							</div>
						}
						className={el?.type + '-sidebar-item'}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}

	const onExpand = (expandedKeys) => {
		setExpandedKeys(expandedKeys)
	}

	useEffect(() => {
		let expArray = []
		if (dataContext?.state?.list) {
			dataContext?.state.list.forEach((item) => {
				expArray.push(item.id)
				if (typeof item.child != 'undefined') {
					item.child.forEach((item2) => {
						expArray.push(item2.id)
					})
				}
			})
		}
		setExpandedKeys(expArray)
	}, [dataContext.state.list])
	return (
		<>
			{dataContext.state.list?.length ? (
				<Tree
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					className="tree-wrapper-mb1 container-tab-content"
					id="containerSidebarHolder">
					{_createListAnt(dataContext.state?.list)}
				</Tree>
			) : (
				<Empty imageStyle={{ height: 40 }} description={<span>No cluster data.</span>} />
			)}

			<div
				style={{
					alignSelf: 'flex-end',
					marginLeft: 'auto',
					marginRight: 'auto',
					position: 'absolute',
					bottom: 4,
					left: 16,
					fontSize: 11,
					width: 275,
				}}>
				<Button
					id="containerCreate"
					onClick={() => globalContext.updateState('containerCreateAndUpdatePopup', {})}
					style={{ background: '#0f427a', color: '#fff', marginBottom: 8 }}
					block>
					Create a Container
				</Button>
			</div>
		</>
	)
}
