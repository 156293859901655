import React from 'react'
import NodeCreateAndUpdatePopup from './NodeCreateAndUpdatePopup'
import VMCreateAndUpdatePopup from './VMCreateAndUpdatePopup'
import NetworkCreateAndUpdatePopup from './NetworkCreateAndUpdatePopup'
import NetworkProfileCreateAndUpdatePopup from './NetworkProfileCreateAndUpdatePopup'
import VMStoragePoolCreatePopup from './VMStoragePoolCreatePopup'
import TakeSnapshotPopup from './TakeSnapshotPopup'
import ManageSnapshotPopup from './ManageSnapshotPopup'
import DeletePopup from './DeletePopup'
import EditNetworkInterfacePopup from './EditNetworkInterfacePopup'
import VMMigratePopup from './VMMigratePopup'
import EditNodeNetworkInterfacePopup from './EditNodeNetworkInterfacePopup'
import BackupProfileCreatePopup from './BackupProfileCreatePopup'
import ManageBackupPopup from './ManageBackupPopup'
import VmTemplateCreatePopup from './VmTemplateCreatePopup'
import VmCreateFromTemplatePopup from './VmCreateFromTemplatePopup'
import VmGroupCreatePopup from './VmGroupCreatePopup'
import NodeGroupCreatePopup from './NodeGroupCreatePopup'
import EditHighAvailabilityPopup from './EditHighAvailabilityPopup'
import EditRLBPopup from './EditRLBPopup'
import TimeConfPopup from './TimeConfPopup'
import NetworkFilterCreateAndUpdatePopup from './NetworkFilterCreateAndUpdatePopup'
import NetworkOverlayCreateAndUpdatePopup from './NetworkOverlayCreateAndUpdatePopup'
import GlusterCreateAndUpdatePopup from './GlusterCreateAndUpdatePopup'
import GlusterVolumePopup from './GlusterVolumePopup'
import BondInterfacePopup from './BondInterfacePopup'
import VmClonePopup from './VmClonePopup'
import ContainerPopup from './ContainerPopup'
import MoveContainerPopup from './MoveContainerPopup'
import LocalUserPopup from './LocalUserPopup'
import TenantPopup from './TenantPopup'
import SouthBoundNetworkPopup from './SouthBoundNetworkPopup'
import QuotaProfilePopup from './QuotaProfilePopup'
import TenantNetworkPopup from './TenantNetworkPopup'
import AddressPoolPopup from './AddressPoolPopup'
import AssignIpPopup from './AssignIpPopup'
import NodeSubInterfacePopup from './NodeSubInterfacePopup'
import TenantVmPopup from './TenantVmPopup'
import OvfUploadPopup from './OvfUploadPopup'
import IscsiPopup from './IscsiPopup'
import IscsiInitiatorPopup from './IscsiInitiatorPopup'
import StoragePoolGroupCreatePopup from './StoragePoolGroupCreatePopup'
import EditStorageRLBPopup from './EditStorageRLBPopup'
import VmConvertVmwarePopup from './VmConvertVmwarePopup'
import InitClusterPopup from './InitClusterPopup'
import CreateVmFromBackupInstance from './CreateVmFromBackupInstancePopup'
import PeerClusterCreateAndUpdatePopup from './PeerClusterCreateAndUpdatePopup'
import AddNodeToGlusterPopup from './AddNodeToGlusterPopup'
import SetUplinkPopup from './SetUplinkPopup'
import ContainerCreateAndUpdatePopup from './ContainerCreateAndUpdatePopup'
import VolumePathCreateAndUpdatePopup from './VolumePathCreateAndUpdatePopup'
import VeritasBackupProfileCreatePopup from './VeritasBackupProfileCreatePopup'
import ExternalBackupUserPopup from './ExternalBackupUserPopup'

export default function index() {
	return (
		<>
			<NodeCreateAndUpdatePopup />
			<VMCreateAndUpdatePopup />
			<NetworkCreateAndUpdatePopup />
			<NetworkProfileCreateAndUpdatePopup />
			<VMStoragePoolCreatePopup />
			<TakeSnapshotPopup />
			<ManageSnapshotPopup />
			<DeletePopup />
			<EditNetworkInterfacePopup />
			<VMMigratePopup />
			<EditNodeNetworkInterfacePopup />
			<BackupProfileCreatePopup />
			<ManageBackupPopup />
			<VmTemplateCreatePopup />
			<VmCreateFromTemplatePopup />
			<CreateVmFromBackupInstance />
			<VmGroupCreatePopup />
			<EditHighAvailabilityPopup />
			<EditRLBPopup />
			<NodeGroupCreatePopup />
			<TimeConfPopup />
			<NetworkFilterCreateAndUpdatePopup />
			<NetworkOverlayCreateAndUpdatePopup />
			<GlusterCreateAndUpdatePopup />
			<GlusterVolumePopup />
			<BondInterfacePopup />
			<VmClonePopup />
			<ContainerPopup />
			<MoveContainerPopup />
			<LocalUserPopup />
			<TenantPopup />
			<SouthBoundNetworkPopup />
			<QuotaProfilePopup />
			<TenantNetworkPopup />
			<AddressPoolPopup />
			<AssignIpPopup />
			<VeritasBackupProfileCreatePopup />
			<NodeSubInterfacePopup />
			<TenantVmPopup />
			<OvfUploadPopup />
			<IscsiPopup />
			<IscsiInitiatorPopup />
			<StoragePoolGroupCreatePopup />
			<EditStorageRLBPopup />
			<VmConvertVmwarePopup />
			<InitClusterPopup />
			<AddNodeToGlusterPopup />
			<PeerClusterCreateAndUpdatePopup />
			<SetUplinkPopup />
			<ContainerCreateAndUpdatePopup />
			<VolumePathCreateAndUpdatePopup />
			<ExternalBackupUserPopup />
		</>
	)
}
