import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext, useNavigate } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, InputNumber, Button } from 'antd'
const { Option } = Select
import { CloseOutlined } from '@material-ui/icons'
export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [networkProfilesList, setnetworkProfilesList] = useState([])
	const [networksList, setnetworksList] = useState([])
	const [data, setdata] = useState({})
	const [ipdata, setipdata] = useState([])
	const netKeys = ['connectedNetworkProfileId', 'connectedNetworkId']

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (netKeys.includes(key) && valueTemp == '') {
			setdata({ ...data, connectedNetworkProfileId: valueTemp, connectedNetworkId: valueTemp })
		} else {
			setdata({ ...data, [key]: valueTemp })
		}
	}

	const handleChangeIp = (e, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...ipdata]
		newData[index] = valueTemp
		setipdata(newData)
		data['ipAddresses'] = newData
		setdata(data)
	}

	const handleClose = () => {
		globalContext.updateState('editNodeNetworkInterfacePopup', null)
	}
	const add = () => {
		let newData = [...ipdata]
		newData.push(null)
		setipdata(newData)
		data['ipAddresses'] = newData
		setdata(data)
	}
	const del = (index) => {
		let newData = [...ipdata]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setipdata(newData)
		data['ipAddresses'] = newData
		setdata(data)
	}

	const functionTags = ['Storage', 'Hci', 'Computation', 'Overlay', 'Management', 'Migration']

	const updateNodeNetworkInterface = async () => {
		delete data.status
		data.portMtu = data.portMtu.toString()
		let res = await dataService.updateNodeNetworkInterface(data)
		if (res.success) {
			handleClose()
			setTimeout(() => {
				globalContext.updateState('nodeNetworkUpdated', true)
			}, 2000)
			globalContext.Snackbar(`Update [${res.data.name}] node network operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const fetch = async () => {
		let responseNetworkProfiles = await dataService.getNetworkProfiles()
		if (responseNetworkProfiles.success) {
			setnetworkProfilesList(responseNetworkProfiles.data)
			dataContext.updateState('getNetworkProfiles', responseNetworkProfiles.data)
		} else {
			globalContext.Snackbar(responseNetworkProfiles.errorMessage, 'error')
			setnetworkProfilesList([])
			dataContext.updateState('getNetworkProfiles', [])
		}

		let responseNetworks = await dataService.getNetworks()
		if (responseNetworks.success) {
			setnetworksList(responseNetworks.data)
			dataContext.updateState('getNetworks', responseNetworks.data)
		} else {
			globalContext.Snackbar(responseNetworks.errorMessage, 'error')
			setnetworksList([])
			dataContext.updateState('getNetworks', [])
		}
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			globalContext.state.editNodeNetworkInterfacePopup && fetch()
			setdata(globalContext.state.editNodeNetworkInterfacePopup)
			setipdata(globalContext?.state?.editNodeNetworkInterfacePopup?.ipAddresses)
		}
	}, [globalContext.state.editNodeNetworkInterfacePopup])

	return (
		<div>
			<Drawer
				title={'Update Network Interface: ' + globalContext.state.editNodeNetworkInterfacePopup?.name}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.editNodeNetworkInterfacePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="nodeNetworkInterfaceCancel">
							Cancel
						</Button>
						<Button
							onClick={() => updateNodeNetworkInterface()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="nodeNetworkInterfaceUpdate">
							Update
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network Profile
					</label>
					<Select
						value={data?.connectedNetworkProfileId}
						onChange={handleChange('connectedNetworkProfileId')}
						placeholder="Network Profile"
						disabled={data?.status != 'READY'}
						className="fullW"
						id="nodeNetworkInterfaceNetworkProfile">
						<Option value="">None</Option>
						{networkProfilesList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network
					</label>
					<Select
						value={data?.connectedNetworkId}
						onChange={handleChange('connectedNetworkId')}
						placeholder="Network Profile"
						disabled={data?.status != 'READY'}
						className="fullW"
						id="nodeNetwork">
						<Option value="">None</Option>
						{networksList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Port MTU
					</label>
					<InputNumber
						placeholder="Enter here"
						min={1500}
						max={9200}
						value={data?.portMtu}
						onChange={handleChange('portMtu')}
						className="fullW"
						id="portMtu"
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>State
					</label>
					<Select
						value={data?.opState}
						onChange={handleChange('opState')}
						disabled={data?.status != 'READY'}
						className="fullW"
						id="nodeNetworkState">
						<Option value="up">Up</Option>
						<Option value="down">Down</Option>
					</Select>
				</div>
				<div className="form-group-ant">
					<label>Tags</label>
					<Select
						mode="multiple"
						value={data?.functionTags}
						onChange={handleChange('functionTags')}
						placeholder="Function Tags"
						className="fullW"
						id="nodeNetworkFunctionTags">
						{functionTags?.map((option) => (
							<Option key={option} value={option.toLowerCase()} label={option}>
								{option}
							</Option>
						))}
					</Select>
				</div>
				{data?.isBondInterface && (
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Network Interfaces
						</label>
						<Select
							mode="multiple"
							value={data?.bondSlaveIds}
							onChange={handleChange('bondSlaveIds')}
							placeholder="Node Network Interfaces"
							className="fullW"
							id="nodeNetworkInterfaces">
							{dataContext?.state?.networkInterfaces?.map((item) => (
								<Option value={item.id}>{item.name}</Option>
							))}
						</Select>
					</div>
				)}
				<div className="form-group-ant">
					<label>Default Gateway</label>
					<Input
						placeholder="Enter here"
						value={data?.defaultGateway}
						onChange={handleChange('defaultGateway')}
						className="fullW"
					/>
				</div>
				<div className="form-group-ant">
					<label style={{ width: '100%' }}>IP Adresses</label>

					{ipdata
						? ipdata?.map((item, index) => {
								return (
									<div className="form-group-ant-design" style={{ marginTop: 4 }}>
										<Input
											placeholder="___.___.___.___/__"
											value={item}
											onChange={(e) => handleChangeIp(e, index)}
											className="fullS"
											id="nodeNetworkIpAddress"
										/>
										<div style={{ color: '#0f427a', cursor: 'pointer', width: '20%', textAlign: 'center' }}>
											<span onClick={() => del(index)}>Delete</span>
										</div>
									</div>
								)
						  })
						: ''}

					<Button style={{ marginTop: '10px' }} block onClick={() => add()}>
						+ Add Row
					</Button>
				</div>
			</Drawer>
		</div>
	)
}
