import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Switch } from 'antd'
import { getInitialProps } from 'react-i18next'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [storageList, setstorageList] = useState([])
	const [networkList, setNetworkList] = useState([])
	const [networkProfileList, setNetworkProfileList] = useState([])
	const [data, setdata] = useState({
		vmName: '',
		resticSnapshotId: globalContext?.state?.vmCreateFromBackupInstancePopup?.id || '',
		targetNodeId: '',
		targetStoragePoolId: '',
		targetNetworkId: '',
		targetNetworkProfileId: '',
		isTransient: true,
		autostart: true,
	})
	const [record, setRecord] = useState({ ...globalContext.state.vmCreateFromBackupInstancePopup })
	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'targetNodeId') {
			setdata({ ...data, [key]: valueTemp, targetStoragePoolId: '' })
		} else {
			setdata({ ...data, [key]: valueTemp })
		}
	}

	const handleClose = () => {
		setdata(getInitialProps)
		globalContext.updateState('vmCreateFromBackupInstancePopup', null)
	}
	const fetch = async () => {
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			setstorageList(responseStorage.data)
			dataContext.updateState('getVmStoragePools', responseStorage.data)
		} else {
			globalContext.Snackbar(responseStorage.message, 'error')
			setstorageList([])
			dataContext.updateState('getVmStoragePools', [])
		}
		let responseNetwork = await dataService.getNetworks()
		if (responseNetwork.success) {
			setNetworkList(responseNetwork.data)
			dataContext.updateState('getNetworks', responseNetwork.data)
		} else {
			globalContext.Snackbar(responseNetwork.message, 'error')
			setNetworkList([])
			dataContext.updateState('getNetworks', [])
		}
		let responseNetworkProfile = await dataService.getNetworkProfiles()
		if (responseNetworkProfile.success) {
			setNetworkProfileList(responseNetworkProfile.data)
			dataContext.updateState('getNetworkProfiles', responseNetworkProfile.data)
		} else {
			globalContext.Snackbar(responseNetworkProfile.message, 'error')
			setNetworkProfileList([])
			dataContext.updateState('getNetworkProfiles', [])
		}
	}
	useEffect(() => {
		if (localStorage.getItem('token')) {
			globalContext.state.vmCreateFromBackupInstancePopup && fetch()
		}
	}, [globalContext.state.vmCreateFromBackupInstancePopup])

	const create = async () => {
		//let selectedTemplate = dataContext.state?.getResticSnapshots.find((item) => item.id == data.resticSnapshotId)

		let sendData = {
			vmName: data.vmName,
			resticSnapshotId: globalContext?.state?.vmCreateFromBackupInstancePopup?.id,
			targetNodeId: data.targetNodeId,
			targetStoragePoolId: data.targetStoragePoolId,
			sourceStoragePoolId: globalContext?.state?.vmCreateFromBackupInstancePopup?.storagePoolId,
			targetNetworkId: data.targetNetworkId,
			targetNetworkProfileId: data.targetNetworkProfileId,
			isTransient: data.isTransient,
			autostart: data.autostart,
		}

		let res = await dataService.createVmFromBackupInstance(sendData)
		if (res.success) {
			let res2 = await dataService.getClusters()
			if (res2.success) {
				dataContext.updateState('list', res2.data)
			}
			handleClose()

			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	function checkVal() {
		if (!data.targetNodeId || !data.vmName || !data.targetStoragePoolId) {
			return true
		} else {
			return false
		}
	}

	return (
		<div>
			<Drawer
				title={'Create VM from Backup Instance'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.vmCreateFromBackupInstancePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => create()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							disabled={checkVal()}
							id="backupInstanceConfirm">
							Confirm
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Virtual Machine Name
					</label>
					<Input placeholder="Enter here" value={data?.vmName} onChange={handleChange('vmName')} id="vmName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Backup Instance
					</label>
					<Select
						value={globalContext?.state?.vmCreateFromBackupInstancePopup?.id}
						onChange={handleChange('resticSnapshotId')}
						className="fullW"
						id="resticSnapshotId"
						disabled={!data.resticSnapshotId}>
						{dataContext?.state?.getResticSnapshots?.map((item) => (
							<Option value={item.id}>{item.id}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Node
					</label>
					<Select value={data?.nodeId} onChange={handleChange('targetNodeId')} className="fullW" id="targetNodeId">
						{dataContext?.state?.list[0]?.child?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Storage Pool
					</label>
					<Select
						value={data.targetStoragePoolId}
						onChange={handleChange('targetStoragePoolId')}
						className="fullW"
						id="vmTemplateStoragePoolSelect">
						{storageList.map((item) => {
							if (item?.poolUnits?.map((pu) => pu?.nodeId)?.includes(data?.targetNodeId)) {
								return <Option value={item.id}>{item.name}</Option>
							}
						})}
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network
					</label>
					<Select
						value={data.targetNetworkId}
						onChange={handleChange('targetNetworkId')}
						className="fullW"
						id="vmTemplateStoragePoolSelect">
						{networkList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
						<Option value={''}>None</Option>
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network Profile
					</label>
					<Select
						value={data.targetNetworkProfileId}
						onChange={handleChange('targetNetworkProfileId')}
						className="fullW"
						id="vmTemplateStoragePoolSelect">
						{networkProfileList.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
						<Option value={''}>None</Option>
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Transient :
					</label>
					<Switch
						checked={data?.isTransient}
						onChange={handleChange('isTransient')}
						style={{ marginLeft: '5px' }}
						id="vmTemplateStoragePoolSelect"
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Autostart :
					</label>
					<Switch
						checked={data?.autostart}
						onChange={handleChange('autostart')}
						style={{ marginLeft: '5px' }}
						id="vmTemplateStoragePoolSelect"
					/>
				</div>
			</Drawer>
		</div>
	)
}
