import React from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'

const useStyles = createUseStyles(() => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
		'& th': {
			textAlign: 'left',
		},
	},
	sumTable: {
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
	},
	formControl: {
		width: '100%',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	drawerLine: {
		padding: 12,
		boxShadow: 'inset 0px -1px 0px #F0F0F0',
	},
}))

export default function Host({ createVM = () => {}, updateVM = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const complete = async () => {
		createVM()
	}
	const update = async () => {
		updateVM()
	}

	const getStoragePoolName = (id) => {
		if (id && dataContext.state?.getVmStoragePools) {
			let findItem = dataContext.state.getVmStoragePools.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworkProfilesName = (id) => {
		if (id && dataContext.state?.getNetworkProfiles) {
			let findItem = dataContext.state.getNetworkProfiles.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworksName = (id) => {
		if (id && dataContext.state?.getNetworks) {
			let findItem = dataContext.state.getNetworks.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	return (
		<div className={classes.root}>
			<span style={{ fontWeight: 700 }}>Summary</span>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Name] </span>
				{modalData?.name}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Memory / Max Memory] </span>
				{modalData?.memory} GB / {modalData?.maxMemory} GB
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[CPU / Max CPU] </span>
				{modalData?.cpu} / {modalData?.maxCpu}
			</div>
			<div className={classes.drawerLine}>
				<span style={{ fontWeight: 700 }}>[Storage] </span>
				<ul style={{ listStyle: 'none', margin: 0, paddingLeft: 0 }}>
					{modalData?.storage?.map((item) => {
						return (
							<li>
								{getStoragePoolName(item.storagePoolId)} ({item.size} GB)
							</li>
						)
					})}
				</ul>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				{modalData?.id ? (
					<Button
						type="primary"
						onClick={() => update()}
						style={{ marginLeft: 8 }}
						id="vmUpdateSubmit"
						// disabled={command.checkValue(data).length > 0}
						small>
						Update
					</Button>
				) : (
					<Button
						type="primary"
						onClick={() => complete()}
						style={{ marginLeft: 8 }}
						id="vmCreateSubmit"
						// disabled={command.checkValue(data).length > 0}
						small>
						Complete
					</Button>
				)}
			</div>
		</div>
	)
}
