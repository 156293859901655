import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext, useNavigate, useParams } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
import { PanoramaSharp } from '@material-ui/icons'
const { Option } = Select
export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	let params = useParams()
	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('BondInterfacePopup', null)
	}

	const createBondInterface = async () => {
		let sendData = { ...data, type: 'nodeNetworkInterface', isBondInterface: true }
		let res = await dataService.createBondInterface(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(
				`Create [${res.data.name}] bond network interface operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		setdata(globalContext.state.BondInterfacePopup)
	}, [globalContext.state.BondInterfacePopup])

	return (
		<div>
			<Drawer
				title={'Create Bond Interface'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.BondInterfacePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => createBondInterface()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="bondInterfaceSubmit">
							Create
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Name
					</label>
					<Input
						placeholder="Enter here"
						value={data?.name}
						onChange={handleChange('name')}
						id="bondInterfaceName"
					/>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Mode
					</label>
					<Select
						value={data?.bondMode}
						onChange={handleChange('bondMode')}
						placeholder="Select mode"
						className="fullW"
						id="selectMode">
						<Option value="active-backup">Active Backup</Option>
						<Option value="802.3ad">802.3ad</Option>
					</Select>
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Network Interfaces
					</label>
					<Select
						mode="multiple"
						value={data?.bondSlaveIds}
						onChange={handleChange('bondSlaveIds')}
						placeholder="Node Network Interfaces"
						className="fullW"
						id="nodeNetworkInterfaces">
						{dataContext?.state?.networkInterfaces?.map(
							(item) => item.bondMasterId == '' && <Option value={item.id}>{item.name}</Option>
						)}
					</Select>
				</div>
			</Drawer>
		</div>
	)
}
