import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext, useNavigate } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Collapse, Switch, TimePicker } from 'antd'
import { createUseStyles } from 'react-jss'
const { Option } = Select
const { Panel } = Collapse
import moment from 'moment'

const useStyles = createUseStyles((theme) => ({
	rGroup: {
		marginBottom: 12,
	},
}))

export default function DraggableDialog() {
	const classes = useStyles()
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('TimeConfPopup', null)
	}

	const updateTimeConf = async () => {
		globalContext.Snackbar('No service!', 'error')
	}

	const format = 'HH:mm'
	const nTime = new Date()

	useEffect(() => {
		setdata(globalContext.state.TimeConfPopup)
	}, [globalContext.state.TimeConfPopup])

	return (
		<div>
			<Drawer
				title={'Edit Time Configuration'}
				placement="right"
				onClose={handleClose}
				width={500}
				visible={globalContext.state.TimeConfPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="timeConfCancel">
							Cancel
						</Button>
						<Button
							onClick={() => updateTimeConf()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="timeConfUpdate">
							Update
						</Button>
					</div>
				}>
				{
					<div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Time
							</label>
							<TimePicker
								className="fullW"
								value={moment(nTime, format)}
								onChange={handleChange('time')}
								format={format}
							/>
						</div>
						<div
							style={{
								marginBottom: 8,
								display: 'inline-flex',
								width: '100%',
								borderRadius: 3,
								justifyContent: 'space-between',
							}}>
							<div>
								<span style={{ fontSize: 14 }}>Ntp Client: </span>
								<Switch
									checked={data?.ntpClient}
									onChange={handleChange('ntpClient')}
									name="ntpClient"
									color="primary"
								/>
							</div>
						</div>
						<div className="form-group-ant">
							<label>
								<span className="requiredHint">*</span>Ntp Servers
							</label>
							<Input
								placeholder="Enter here"
								value={data?.ntpServers}
								onChange={handleChange('ntpServers')}
								id="timeConfNtpServers"
							/>
						</div>
					</div>
				}
			</Drawer>
		</div>
	)
}
