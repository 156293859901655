import React from 'react'
// import PropTypes from "prop-types";

// import { makeStyles } from "@material-ui/core/styles";
import { Button as MUButton } from '@material-ui/core'
import { Loader, PermissionCheck } from '..'
// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		"& > *": {
// 			margin: theme.spacing(1),
// 		},
// 	},
// }));

export default function Button({ loaderShow = false, permissionList, ...props }) {
	// const classes = useStyles();
	return (
		<PermissionCheck permissionList={permissionList}>
			<Loader button loaderShow={loaderShow} style={props.style} className={props.className}>
				<MUButton {...props} />
			</Loader>
		</PermissionCheck>
	)
}

// Button.propTypes = {
// 	backgroundColor: PropTypes.string,
// 	label: PropTypes.string.isRequired,
// 	onClick: PropTypes.func,
// };

// Button.defaultProps = {
// 	backgroundColor: null,
// 	onClick: undefined,
// };
