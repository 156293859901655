import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Button, Row, Col } from 'antd'
import dataService from 'service/dataService'

const useStyles = createUseStyles((theme) => ({
	root: {},
	footer: {
		position: 'absolute',
		bottom: 8,
		width: '100%',
		textAlign: 'right',
		right: 16,
		paddingRight: 8,
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState({})

	return (
		<div className={classes.root}>
			<Row>
				<Col span={12}></Col>
				<Col span={12}></Col>
				<Col span={12}></Col>
				<Col span={12}></Col>
			</Row>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button style={{ marginLeft: 8 }} onClick={() => next()} type="primary" small className="next">
					Next
				</Button>
			</div>
		</div>
	)
}
