import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('iscsiInitiatorPopup', null)
	}

	const updateIscsiInitiator = async () => {
		let sendData = { ...data }
		sendData.name = data.name
		let res = await dataService.updateIscsiInitiator(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(`Update [${res.data.name}] iscsi initiator operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		setdata(globalContext.state.iscsiInitiatorPopup)
	}, [globalContext.state.iscsiInitiatorPopup])

	return (
		<div>
			<Drawer
				title={'iSCSI Initiator Name Update'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.iscsiInitiatorPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						<Button
							onClick={() => updateIscsiInitiator()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="iscsiConfirm">
							Confirm
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="iscsiName" />
				</div>
			</Drawer>
		</div>
	)
}
