import React, { useState, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, InputNumber, Button, Select } from 'antd'
import { createUseStyles } from 'react-jss'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	form: {
		'& .MuiTextField-root': {
			width: '100%',
		},
	},
	storageRow: {
		display: 'flex',
		width: '100%',
		padding: '12px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 25,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	sto: {
		width: '40%',
		minWidth: 150,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 16,
		paddingRight: 16,
		'& .ant-input-number-group-wrapper': {
			width: '100%',
			'& .ant-input-number': {
				borderRadius: '6px 0px 0px 6px!important',
			},
			'& .ant-input-number-group-addon': {
				borderRadius: '0 6px 6px 0!important',
			},
		},
	},
	th: {
		width: '35%',
		minWidth: 150,
		paddingLeft: 12,
		paddingRight: 12,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	act: {
		width: '20%',
		minWidth: 90,
		paddingLeft: 12,
		paddingRight: 12,
		textAlign: 'center',
		'& button': {
			width: '100%',
		},
	},
}))

export default function DraggableDialog() {
	const classes = useStyles()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState(globalContext?.state?.nodeSubInterfacePopup || {})
	const [subs, setsubs] = useState(globalContext?.state?.nodeSubInterfacePopup?.nodeSubInterfaces || [])
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...subs]
		newData[index][key] = key == 'size' ? (valueTemp ? valueTemp.toString() : '1') : valueTemp
		setsubs(newData)
	}

	const handleClose = () => {
		setVisible(false)
		globalContext.updateState('nodeSubInterfacePopup', null)
		setdata({})
		setsubs([])
	}

	useEffect(() => {
		setdata(globalContext.state.nodeSubInterfacePopup)
		setsubs(globalContext.state.nodeSubInterfacePopup?.nodeSubInterfaces || [])
	}, [globalContext.state.nodeSubInterfacePopup])

	const updateNodeNetworkInterface = async () => {
		let sendData = { ...data, nodeSubInterfaces: subs }
		delete sendData.status
		sendData.portMtu = sendData.portMtu.toString()
		let res = await dataService.updateNodeNetworkInterface(sendData)
		if (res.success) {
			handleClose()
			setTimeout(() => {
				globalContext.updateState('nodeNetworkUpdated', true)
			}, 2000)
			globalContext.Snackbar(
				`Update [${res.data.name}] node network interface operation started successfully`,
				'success'
			)
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const add = () => {
		let newData = [...subs]
		newData.push({
			ipAddressCidr: '',
			nodeNetworkInterfaceId: newData.id,
			vlanId: '',
		})
		setsubs(newData)
	}

	const del = (index) => {
		let newData = [...subs]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setsubs(newData)
	}

	return (
		<Drawer
			title="Node Sub Interfaces"
			placement="right"
			onClose={handleClose}
			width={550}
			visible={globalContext.state.nodeSubInterfacePopup || false}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={handleClose} small>
						Cancel
					</Button>
					<Button
						onClick={updateNodeNetworkInterface}
						style={{ marginLeft: 8 }}
						type="primary"
						id="nodeSubSubmit"
						small>
						Submit
					</Button>
				</div>
			}>
			<div className={classes.storageRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
				<span className={classes.hash}>#</span>
				<span className={classes.sto}>
					<span className="requiredHint">*</span>IP Address CIDR
				</span>
				<span className={classes.th}>
					<span className="requiredHint">*</span>VLAN ID
				</span>
				<span className={classes.act}>Action</span>
			</div>
			{subs?.map((item, index) => (
				<div className={classes.storageRow}>
					<span className={classes.hash}>{index + 1}</span>
					<div className={classes.sto}>
						<Input
							className="ipAddressCidr"
							placeholder="e.g. ___.___.___.___/__"
							value={item.ipAddressCidr}
							onChange={(e) => handleChange(e, 'ipAddressCidr', index)}
						/>
					</div>
					<div className={classes.th}>
						<Input
							className="vlanId"
							placeholder="Enter a single VID"
							value={item.vlanId}
							onChange={(e) => handleChange(e, 'vlanId', index)}
						/>
					</div>
					<div className={classes.act}>
						<Button onClick={() => del(index)} type="link" className="nodeSubDelete">
							Delete
						</Button>
					</div>
				</div>
			))}
			<Button
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="nodeSubAdd">
				+ Add Row
			</Button>
		</Drawer>
	)
}
