import React from 'react'
import { useDataContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	root: {
		'& th': {
			textAlign: 'left',
		},
	},
	sumTable: {
		borderTop: '1px solid #dee2e6',
		width: '90%',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
}))

export default function Host({ migrateVM = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()

	const complete = async () => {
		migrateVM()
	}

	const getStoragePoolName = (id) => {
		if (id && dataContext.state?.getVmStoragePools) {
			let findItem = dataContext.state.getVmStoragePools.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworkProfilesName = (id) => {
		if (id && dataContext.state?.getNetworkProfiles) {
			let findItem = dataContext.state.getNetworkProfiles.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNetworksName = (id) => {
		if (id && dataContext.state?.getNetworks) {
			let findItem = dataContext.state.getNetworks.find((item) => item.id === id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}
	const getNodeName = (id) => {
		if (id && dataContext.state?.list) {
			let findItem = dataContext.state?.list?.[0].child?.find((item) => item.id == id)
			return findItem?.name ?? 'N/A'
		} else {
			return 'N/A'
		}
	}

	return (
		<div className={classes.root}>
			<span style={{ fontWeight: 700 }}>Summary</span>
			<table className={classes.sumTable}>
				<tbody>
					<tr>
						<th>Name:</th>
						<td>{modalData.name}</td>
					</tr>
					<tr>
						<th>Node:</th>
						<td>{getNodeName(modalData.nodeId)}</td>
					</tr>

					<tr>
						<th>Storage:</th>
						<td>
							<ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
								{modalData.storage.map((item) => {
									return (
										<li>
											{getStoragePoolName(item.storagePoolId)} ({item.size} GB)
										</li>
									)
								})}
							</ul>
						</td>
					</tr>
					<tr>
						<th>Network Interfaces:</th>
						<td>
							<ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
								{modalData.networkInterfaces.length ? (
									modalData.networkInterfaces.map((item) => {
										return (
											<li>
												{getNetworksName(item.connectedNetworkId)} (
												{getNetworkProfilesName(item.connectedNetworkProfileId)})
											</li>
										)
									})
								) : (
									<li style={{ color: 'rgb(194, 88, 88)' }}>Network not found!</li>
								)}
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button onClick={() => complete()} type="primary" style={{ marginLeft: 8 }} small id="vmMigrate">
					Migrate
				</Button>
			</div>
		</div>
	)
}
