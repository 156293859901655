import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Button, Col, Progress, Row, Table, TreeSelect, Typography } from 'antd'
import dataService from 'service/dataService'

const { SHOW_CHILD } = TreeSelect
const { Title } = Typography

const useStyles = createUseStyles({
	root: {
		height: '100%',
		justifyContent: 'center',
		position: 'relative',
		alignContent: 'baseline',
	},
	input: { width: '100%' },
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	confArea: {
		height: 'calc(100% - 120px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		paddingRight: '12px',
		'&>div': {
			marginBottom: 8,
		},
	},
})
const vmSel = forwardRef(function Name(
	{ modalDataChange, next = () => {}, modalData = {}, stepChange = () => {} },
	ref
) {
	const classes = useStyles()
	const [selectedVms, setSelectedVms] = useState([])
	const [detailedVms, setDetailedVms] = useState([])
	const vmSelRef = useRef(null)

	const onChange = (newValue) => {
		setSelectedVms(newValue)
		modalDataChange({ ...modalData, selectedVms: newValue })

		let unnecessaryVms = detailedVms?.filter((v) => !newValue.some((s) => v?.id === s))
		if (unnecessaryVms?.length > 0) {
			let necessaryVms = detailedVms?.filter((v) => !unnecessaryVms.some((s) => v?.id === s?.id))
			necessaryVms = necessaryVms?.filter((v) => v)
			setDetailedVms(necessaryVms)
			modalDataChange({ ...modalData, detailedVms: necessaryVms })
		}
		let notGotDetail = newValue.filter((s) => !detailedVms?.some((v) => s === v.id))
		let detailedVmsTmp = detailedVms ? [...detailedVms] : []

		if (notGotDetail.length > 0) {
			const fetchPromises = notGotDetail.map(async (n) => {
				let vm = modalData.vmArr.find((v) => v.key == n)
				return await getVmwareVm({ ...vm, auth: modalData.auth })
			})
			Promise.all(fetchPromises).then((vms) => {
				if (vms) {
					detailedVmsTmp = [...new Set([...detailedVmsTmp, ...vms])]
					detailedVmsTmp = detailedVmsTmp?.filter((v) => v)
					setDetailedVms(detailedVmsTmp)
					modalDataChange({ ...modalData, detailedVms: detailedVmsTmp })
				}
			})
		}
	}

	const columns = [
		{ title: 'Name', dataIndex: 'name', key: 'name' },
		{ title: 'Guest OS', dataIndex: 'guestOs', key: 'guestOs' },
		{ title: 'Bootloader', dataIndex: 'bootLoader', key: 'bootLoader' },
		{ title: 'State', dataIndex: 'state', key: 'state' },
		{ title: 'CPU', dataIndex: 'cpu', key: 'cpu' },
		{ title: 'Memory', dataIndex: 'memory', key: 'memory' },
		{
			title: 'Storage',
			dataIndex: 'allocatedStorage',
			key: 'allocatedStorage',
			width: '160px',
			render: (text, row) => (
				<div style={{ paddingRight: 8 }}>
					<Progress
						percent={((row?.allocatedStorage / row?.provisionedStorage) * 100).toFixed(2)}
						size="small"
						strokeColor={{
							from: '#108ee9',
							to: '#87d068',
						}}
					/>
					<div>{`${row?.allocatedStorage} / ${row?.provisionedStorage} GB`}</div>
				</div>
			),
		},
	]

	const getVmwareVm = async (vm) => {
		let res = await dataService.getVmwareVm(vm)
		if (res.success) {
			return res.data
		}
	}

	const filterVms = () => {
		let filteredVms = modalData?.vmArr?.filter((v) => selectedVms?.includes(v?.key))
		modalDataChange({ ...modalData, filteredVms: filteredVms, detailedVms: detailedVms, selectedVms: selectedVms })
	}

	const nextStep = () => {
		stepChange(2)
	}

	useImperativeHandle(
		ref,
		() => {
			return {
				filterVms,
			}
		},
		[]
	)

	useEffect(() => {
		setSelectedVms(modalData?.selectedVms || [])
		setDetailedVms(modalData?.detailedVms || [])
	}, [])

	useImperativeHandle(ref, () => ({
		filterVms,
	}))

	return (
		<Row className={classes.root}>
			<Col span={24} style={{ paddingBottom: 16 }}>
				<small>Please select virtual machines to convert</small>
				<TreeSelect
					showSearch
					style={{ width: '100%' }}
					value={selectedVms}
					placeholder="Please select"
					allowClear
					treeDefaultExpandAll
					onChange={onChange}
					treeCheckable
					treeData={modalData?.vmwTreeData}
					showCheckedStrategy={SHOW_CHILD}
					treeNodeFilterProp="label"
				/>
			</Col>
			<Col span={24} style={{ height: 'calc(100% - 120px)', paddingRight: 0 }} className="detailed-vms-table">
				<Table
					columns={columns}
					dataSource={detailedVms}
					pagination={false}
					sticky={true}
					scroll={{
						y: 'calc(100% - 55px)',
					}}
				/>
			</Col>
			<div className={classes.footer}>
				<Button onClick={() => stepChange(0)} className="prev">
					Prev
				</Button>
				<Button onClick={() => nextStep()} className="next" style={{ marginLeft: 8 }} type="primary" small>
					Next
				</Button>
			</div>
		</Row>
	)
},
[])

export default vmSel
