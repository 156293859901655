import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
}))
export default function Objects({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()

	const classes = useStyles()
	const [vmList, setvmList] = useState([])
	const [data, setdata] = useState({
		objects: modalData.objects || [],
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Select Vms
				</label>
				<Select
					mode="multiple"
					value={data?.objects}
					onChange={handleChange('objects')}
					placeholder="Select VMs"
					className="fullW"
					id="backupPSelectVms"
					filterOption={(inputValue, option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}>
					{dataContext.state?.getVms?.map(
						(option) =>
							(option.backupProfileId == '' || data.objects.includes(option.id)) && (
								<Option key={option.id} value={option.id} label={option.name}>
									{option.name}
								</Option>
							)
					)}
				</Select>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.objects?.length == 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
