import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Select, Button, Switch } from 'antd'
const { Option } = Select

const useStyles = createUseStyles(() => ({
	root: {
		height: '70vh',
		position: 'relative',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		position: 'relative',
		bottom: 20,
		width: '100%',
		textAlign: 'right',
		right: 4,
		alignItems: 'end',
		marginTop: 'auto',
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
	fw: {
		width: '100%',
	},
	netContainer: {
		overflowY: 'auto',
		height: '80%',
	},
	netRow: {
		display: 'flex',
		width: '95%',
		padding: '5px 0',
		alignItems: 'center',
	},
	hash: {
		width: '5%',
		minWidth: 32,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
	},
	net: {
		width: '30%',
		minWidth: 140,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 10,
		paddingRight: 10,
	},
	mac: {
		width: '30%',
		minWidth: 140,
		borderRight: '1px solid rgba(0, 0, 0, 0.03)',
		paddingLeft: 10,
		paddingRight: 10,
	},
	act: {
		width: '25%',
		minWidth: 120,
		paddingLeft: 10,
		paddingRight: 10,
		'& button': {
			width: '100%',
		},
	},
}))

export default function Network({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const classes = useStyles()
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const [networkProfilesList, setnetworkProfilesList] = useState([])
	const [networksList, setnetworksList] = useState([])

	const [data, setdata] = useState(
		modalData.networkInterfaces || [
			{
				tenantNetworkId: '',
				connectedNetworkId: '',
				connectedNetworkProfileId: '',
				isTenantNetwork: true,
			},
		]
	)

	const netKeys = ['tenantNetworkId', 'connectedNetworkProfileId', 'connectedNetworkId']

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		if (netKeys.includes(key) && valueTemp == '') {
			newData[index]['connectedNetworkId'] = valueTemp
			newData[index]['connectedNetworkProfileId'] = valueTemp
			newData[index]['tenantNetworkId'] = valueTemp
		} else {
			newData[index][key] = valueTemp
			if (key == 'isTenantNetwork') {
				if (e == true) {
					newData[index]['connectedNetworkProfileId'] = ''
					newData[index]['connectedNetworkId'] = ''
				} else {
					newData[index]['tenantNetworkId'] = ''
				}
			}
			if (key == 'connectedNetworkId') {
				let activeNet = networksList.find((n) => n.id == valueTemp)
				if (activeNet?.name == 'default') {
					let defNetP = networkProfilesList.find((np) => np.name == 'default')
					newData[index]['connectedNetworkProfileId'] = defNetP.id
				}
			}
		}
		let newData2 = newData.filter((item) => item.connectedNetworkProfileId)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData2 })
	}

	const nextStep = () => {
		let newData = data.filter((item) => item.tenantNetworkId || item.connectedNetworkId)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
		next()
	}

	const add = () => {
		let newData = [...data]
		newData.push({
			tenantNetworkId: null,
			connectedNetworkId: '' || null,
			connectedNetworkProfileId: '' || null,
			isTenantNetwork: true,
		})
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
	}

	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata(newData)
		modalDataChange({ networkInterfaces: newData })
	}

	const fetch = async () => {
		let responseNetworkProfiles = await dataService.getNetworkProfiles()
		if (responseNetworkProfiles.success) {
			setnetworkProfilesList(
				responseNetworkProfiles.data?.filter((n) => modalData?.quotaProfile?.networkProfileIds?.includes(n.id))
			)
			dataContext.updateState('getNetworkProfiles', responseNetworkProfiles.data)
		} else {
			globalContext.Snackbar(responseNetworkProfiles.message, 'error')
			setnetworkProfilesList([])
			dataContext.updateState('getNetworkProfiles', [])
		}

		let responseNetworks = await dataService.getNetworks()
		if (responseNetworks.success) {
			setnetworksList(responseNetworks.data?.filter((n) => modalData?.quotaProfile?.networkIds?.includes(n.id)))
			dataContext.updateState('getNetworks', responseNetworks.data)
		} else {
			globalContext.Snackbar(responseNetworks.message, 'error')
			setnetworksList([])
			dataContext.updateState('getNetworks', [])
		}
	}

	useEffect(() => {
		fetch()
	}, [])

	return (
		<div className={classes.root}>
			<div className={classes.netContainer}>
				<div className={classes.netRow} style={{ textAlign: 'left', background: '#FAFAFA' }}>
					<span className={classes.hash}>#</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span> Network Type
					</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span>Network
					</span>
					<span className={classes.net}>
						<span className="requiredHint">*</span>Network Profile
					</span>
					<span className={classes.mac}>
						<span className="requiredHint">*</span>Name
					</span>
					<span className={classes.mac}>
						<span className="requiredHint">*</span>MAC
					</span>
					<span className={classes.act}>Action</span>
				</div>
				{data.map((item, index) => (
					<div className={classes.netRow}>
						<span className={classes.hash}>{index + 1}</span>
						<div className={classes.net}>
							<Switch
								id="isTenantNetwork"
								checked={data[index]?.isTenantNetwork || false}
								onChange={(checked) => handleChange(checked, 'isTenantNetwork', index)}
								style={{ marginLeft: '24px' }}
								name="isTenantNetwork"
								color="primary"
								checkedChildren="Tenant"
								unCheckedChildren="Underlay"
							/>
						</div>
						<div className={classes.net}>
							{!data?.[index]?.isTenantNetwork ? (
								<>
									<Select
										className="vmNetwork"
										value={item?.connectedNetworkId}
										onChange={(e) => handleChange(e, 'connectedNetworkId', index)}
										style={{ width: '100%' }}>
										{networksList.map((item) => (
											<Option value={item.id} key={item.id}>
												{item.name}
											</Option>
										))}
									</Select>
								</>
							) : (
								<Select
									className="vmTenantNetwork"
									value={item?.tenantNetworkId}
									onChange={(e) => handleChange(e, 'tenantNetworkId', index)}
									style={{ width: '100%' }}>
									{modalData?.tenant?.tenantNetworks?.map((item) => (
										<Option value={item.id} key={item.id}>
											{item.name}
										</Option>
									))}
								</Select>
							)}
						</div>
						<div className={classes.mac}>
							<Select
								className="vmNetworkProfile"
								value={item?.connectedNetworkProfileId}
								onChange={(e) => handleChange(e, 'connectedNetworkProfileId', index)}
								style={{ width: '100%' }}
								disabled={data?.[index]?.isTenantNetwork}>
								{networkProfilesList.map((item) => (
									<Option value={item.id} key={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</div>

						<div className={classes.mac}>{item?.name}</div>
						<div className={classes.mac}>{item?.macAddress}</div>
						<div className={classes.act}>
							<span
								onClick={() => del(index)}
								style={{ color: '#0f427a', cursor: 'pointer' }}
								className="vmNetConfigDelete">
								Delete
							</span>
						</div>
					</div>
				))}
			</div>
			<Button
				block
				onClick={() => add()}
				disabled={
					data.filter(
						(item) =>
							(item.isTenantNetwork && !item.tenantNetworkId) ||
							(!item.isTenantNetwork && (!item.connectedNetworkId || !item.connectedNetworkProfileId))
					).length > 0
				}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				id="vmNetConfigAdd">
				+ Add Row
			</Button>
			<div className={classes.footer}>
				<Button
					onClick={() => prev()}
					className="prev"
					disabled={
						data.filter(
							(item) =>
								(item.isTenantNetwork && !item.tenantNetworkId) ||
								(!item.isTenantNetwork && (!item.connectedNetworkId || !item.connectedNetworkProfileId))
						).length > 0
					}>
					Prev
				</Button>
				<Button
					className="next"
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					type="primary"
					small
					disabled={
						data.filter(
							(item) =>
								(item.isTenantNetwork && !item.tenantNetworkId) ||
								(!item.isTenantNetwork && (!item.connectedNetworkId || !item.connectedNetworkProfileId))
						).length > 0
					}>
					Save & Next
				</Button>
			</div>
		</div>
	)
}
