import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext, useNavigate } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, InputNumber, Button, Collapse, Row, Col, Radio, Slider } from 'antd'
import { createUseStyles } from 'react-jss'
const { Option } = Select
const { Panel } = Collapse

const useStyles = createUseStyles((theme) => ({
	rGroup: {
		marginBottom: 12,
	},
}))

export default function DraggableDialog() {
	const classes = useStyles()
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('EditRLBPopup', null)
	}

	const updateClusterData = async () => {
		let sendData = { ...data }
		delete sendData.status
		let res = await dataService.updateCls(sendData)
		if (res.success) {
			handleClose()
			globalContext.Snackbar(`Update [${res.data.name}] cluster operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		setdata(globalContext.state.EditRLBPopup)
		if (!globalContext?.state?.EditRLBPopup?.rlbPercentage) {
			setdata({ ...globalContext?.state?.EditRLBPopup, rlbPercentage: 10 })
		}
	}, [globalContext.state.EditRLBPopup])

	return (
		<div>
			<Drawer
				title={'Edit Resource Load Balancing'}
				placement="right"
				onClose={handleClose}
				width={500}
				visible={globalContext.state.EditRLBPopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="cancelRLB">
							Cancel
						</Button>
						<Button
							onClick={() => updateClusterData()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="updateRLB">
							Update
						</Button>
					</div>
				}>
				{
					<Row>
						<Col span={24} style={{ marginBottom: 8 }}>
							<Radio.Group
								onChange={handleChange('rlbAutomationLevel')}
								className={classes.rGroup}
								value={data?.rlbAutomationLevel}
								id="radioRLB">
								<Radio value={'none'}>None</Radio>
								<Radio value={'manual'}>Manual</Radio>
								<Radio value={'auto'}>Auto</Radio>
							</Radio.Group>
						</Col>
						<Col span={24}>RLB Percentage</Col>
						<Col span={16}>
							<Slider min={10} max={80} onChange={handleChange('rlbPercentage')} value={data?.rlbPercentage} />
						</Col>
						<Col span={8}>
							<InputNumber
								min={10}
								max={80}
								style={{
									margin: '0 16px',
								}}
								value={data?.rlbPercentage}
								onChange={handleChange('rlbPercentage')}
							/>
						</Col>
					</Row>
				}
			</Drawer>
		</div>
	)
}
