import React, { useState, useEffect } from 'react'
import { useNavigate, useGlobalContext, useDataContext, useParams } from '../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal, Row, Col, Button, Tree, Tooltip } from 'antd'
const { TreeNode } = Tree
import { CloseCircleOutlined, CodeSandboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 550,
		minHeight: 200,
		display: 'flex',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		paddingBottom: 0,
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: '0 16px 24px 16px',
		flex: 1,
	},
	treeView: {
		height: 216,
		flexGrow: 1,
		maxWidth: 275,
		fontSize: 14,
		'& .MuiTreeItem-iconContainer': {
			width: 0,
		},
	},
	TreeItemLabelContainer: {
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		padding: '8px 0',
	},
	icon: {
		height: 14,
		paddingRight: 6,
	},
	errorIcon: {
		height: 14,
		marginLeft: 4,
		color: 'rgb(194, 88, 88)',
	},
	sumTable: {
		width: '90%',
		borderTop: '1px solid #dee2e6',
		'& td': {
			padding: 6,
			paddingLeft: 0,
			borderBottom: '1px solid #dee2e6',
		},
		'& th': {
			padding: 6,
			paddingRight: 12,
			paddingLeft: 0,
			textAlign: 'left',
			borderBottom: '1px solid #dee2e6',
		},
	},
	subBtns: {
		display: 'inline-flex',
		justifyContent: 'end',
		width: '100%',
		padding: 16,
	},
}))

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()

	const [data, setdata] = useState(globalContext.state.manageSnapshotPopup)

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('manageSnapshotPopup', null)
	}

	const revertSnapshot = async () => {
		let res = await dataService.revertVmSnapshot(dataContext.state.snapshotContent?.id)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}
	const deleteSnapshot = async () => {
		let res = await dataService.deleteVmSnapshot(dataContext.state.snapshotContent?.id)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const createNode = async () => {
		let manageSnapshotPopup = globalContext.state.manageSnapshotPopup
		let newList = [...dataContext.state.getNetworks]

		if (manageSnapshotPopup.id) {
			let findNetworkIndex = newList.findIndex((i) => i.id == manageSnapshotPopup.id)
		} else {
			let res = await dataService.createNode(data)
			if (res.success) {
				newList.push({
					id: res.data.id,
					name: res.data.name,
					status: res.data.status,
					clusterId: res.data.clusterId,
					type: 'node',
				})

				dataContext.updateState('getNodes', newList)
				handleClose()
				globalContext.Snackbar(`Create [${res.data.name}] node operation started successfully`, 'success')
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}

	const _createList = (item) => {
		return item?.map((el, i) => {
			if (!el) return null
			if (!el.child?.length)
				return (
					<TreeNode
						title={
							<div
								className={classes.TreeItemLabelContainer}
								onClick={(e) => {
									setContent(e, el)
								}}>
								<CodeSandboxOutlined style={{ marginRight: 4 }} />
								{el?.name}
								{el?.status != 'READY' && (
									<Tooltip style={{ marginLeft: 4 }} title="Not ready or failed, check it!" placement="top">
										<ExclamationCircleOutlined className={classes.errorIcon} />
									</Tooltip>
								)}
							</div>
						}
						key={el.id}
						nodeId={`${el?.type}${el?.id}`}
						dataRef={el}></TreeNode>
				)
		})
	}
	const formatDate = (timestamp) => {
		// Assuming timestamp is in the format ddmmyyhhmmss
		const defaultResult = 'N/A'

		if (timestamp == null) {
			return defaultResult
		}
		const year = timestamp?.slice(0, 2)
		const month = timestamp?.slice(2, 4)
		const day = timestamp?.slice(4, 6)
		const hour = timestamp?.slice(6, 8)
		const minute = timestamp?.slice(8, 10)
		const second = timestamp?.slice(10, 12)

		const apm = hour < 12 ? 'AM' : 'PM'

		const formattedDate = `${day}/${month}/${year} - ${hour}:${minute}:${second} ${apm}`
		return formattedDate
	}
	const setContent = (e, el) => {
		if (!el) return null
		dataContext.updateState('snapshotContent', el)
	}
	return (
		<div>
			<Modal
				title={'Manage ' + globalContext.state.manageSnapshotPopup?.name + "'s snapshots"}
				className=""
				width={750}
				visible={globalContext.state.manageSnapshotPopup || false}
				closeIcon={<CloseCircleOutlined />}
				footer={null}
				onCancel={handleClose}>
				<Row>
					<Col span={10} style={{ borderRight: '1px solid #31435121' }}>
						<Tree>{_createList(globalContext.state.manageSnapshotPopup?.snapshots)}</Tree>
					</Col>
					<Col span={14}>
						<div style={{ marginLeft: 15 }} className={classes.ssInfo}>
							<h4 style={{ marginTop: 8, marginBottom: 8, fontWeight: 600, fontSize: 16 }}>
								Snapshot Information
							</h4>
							<table className={classes.sumTable}>
								<tbody>
									<tr>
										<th>Current:</th>
										<td>{dataContext.state.snapshotContent?.current?.toString()}</td>
									</tr>
									<tr>
										<th>Memory File:</th>
										<td>{dataContext.state.snapshotContent?.memoryFile || 'N/A'}</td>
									</tr>
									<tr>
										<th>State:</th>
										<td>{dataContext.state.snapshotContent?.state}</td>
									</tr>
									<tr>
										<th>Created On:</th>
										<td>{formatDate(dataContext?.state?.snapshotContent?.timestamp)}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className={classes.subBtns}>
							<Button autoFocus onClick={handleClose} small type="secondary" id="manageSnapshotCancel">
								Cancel
							</Button>
							<Button
								onClick={deleteSnapshot}
								small
								type="primary"
								style={{ marginLeft: 8 }}
								disabled={!dataContext.state.snapshotContent}
								id="manageSnapshotDelete">
								Delete
							</Button>
							<Button
								onClick={revertSnapshot}
								small
								style={{ marginLeft: 8 }}
								type="primary"
								disabled={!dataContext.state.snapshotContent}
								id="manageSnapshotRevert">
								Revert
							</Button>
						</div>
					</Col>
				</Row>
			</Modal>
		</div>
	)
}
