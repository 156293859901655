import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Select, Button } from 'antd'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	fw: {
		width: '100%',
	},
}))
export default function Name({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	const [storageList, setstorageList] = useState([])
	const [data, setdata] = useState({
		storagePoolId: modalData.storagePoolId || globalContext.state?.VMMigratePopup?.storagePoolId,
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const nextStep = async () => {
		modalDataChange(data)
		next()
	}

	const getStoragePool = (id) => {
		if (id && dataContext.state?.getVmStoragePools) {
			let findItem = dataContext.state.getVmStoragePools.find((item) => item.id === id)
			return findItem
		} else {
			return 'N/A'
		}
	}

	const fetch = async () => {
		let responseStorage = await dataService.getVmStoragePools()
		if (responseStorage.success) {
			let filteredStoragePools = [...responseStorage.data]
			filteredStoragePools = filteredStoragePools.filter((s) => !s.isoStorage || s?.poolType == 'local')
			setstorageList(filteredStoragePools)
			dataContext.updateState('getVmStoragePools', responseStorage?.data)
		} else {
			globalContext.Snackbar(responseStorage.errorMessage, 'error')
			setstorageList([])
		}
	}
	useEffect(() => {
		fetch()
	}, [])
	return (
		<div className={classes.root}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Storage Pool
				</label>
				<Select
					value={data?.storagePoolId}
					onChange={handleChange('storagePoolId')}
					className={classes.fw + ' vmStoragePool'}
					disabled={modalData?.createOrUpdate === 'update'}>
					{storageList.map((item) => (
						<Option key={item.id} value={item.id}>
							<span style={{ fontWeight: 'bold' }}>{item.name}</span> - {item.vmStoragePoolStat.allocation} /{' '}
							{item.vmStoragePoolStat.capacity} ({item.vmStoragePoolStat.percentage}%)
						</Option>
					))}
				</Select>
			</div>

			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					// disabled={command.checkValue(data).length > 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
