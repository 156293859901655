///AuthContext
import React, { createContext, useContext, useEffect } from 'react'
import useReducerWithLogger from './Logger'

export const AuthContext = createContext()
export const useAuthContext = () => useContext(AuthContext)

let initialState = {
	token: localStorage.getItem('token') || '',
	tokenExpiry: localStorage.getItem('tokenExpiry') || '',
	permissionList: JSON.parse(localStorage.getItem('permissionList') || '[]'),
	userInfo: JSON.parse(localStorage.getItem('userData') || '{}'),
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'AuthContext_UPDATE_STATE':
			let value = action.payload
			return { ...state, ...value }
		default:
			console.error(`${action.type} ->Action Not Found`)
			return state
	}
}

const AuthContextProvider = (props) => {
	initialState = { ...initialState, ...props.initialState }
	const [state, dispatch] = useReducerWithLogger(reducer, initialState)

	const updateState = (key, value) => {
		dispatch({
			type: 'AuthContext_UPDATE_STATE',
			payload: { [key]: value },
		})
	}

	const login = (data) => {
		var token = data?.token ? data.token : data
		var tokenExpiry = data?.expireTimestamp ? data?.expireTimestamp * 1000 : new Date().getTime() + 1000000
		localStorage.setItem('token', token || '')
		localStorage.setItem('tokenExpiry', tokenExpiry || '')
		localStorage.setItem('userData', JSON.stringify(data.userData || {}))
		// if (typeof token != 'undefined') {
		// if (tokenExpiry < new Date().getTime()) {
		// 	logout()
		// } else {
		// window.location.href = '/'
		// }
		// }
	}

	const logout = () => {
		localStorage.removeItem('token')
		localStorage.removeItem('tokenExpiry')
		localStorage.removeItem('userData')
		localStorage.removeItem('permissionList')
		updateState('token', ``)
		// localStorage.removeItem('userInfo')
		window.location.href = '/'
	}

	const setpermissionList = (list = []) => {
		localStorage.setItem('permissionList', JSON.stringify(list))
		updateState('permissionList', list)
	}
	// const setuserInfo = (list = {}) => {
	// 	localStorage.setItem('userInfo', JSON.stringify(list))
	// 	updateState('userInfo', list)
	// }

	return (
		<AuthContext.Provider
			value={{
				state,
				updateState,
				login,
				logout,
				setpermissionList,
				// setuserInfo,
			}}>
			{props.children}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
