import React, { useEffect, useState } from 'react'
import { useAuthContext, useDataContext, useGlobalContext, useNavigate, useParams, http } from '../../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Table, Button, Empty, Spin, message, Input, Space, Tooltip } from 'antd'
import {
	UpOutlined,
	DownOutlined,
	ArrowUpOutlined,
	ArrowDownOutlined,
	IssuesCloseOutlined,
	ExclamationCircleOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons'
import { BASE_API } from 'components/axios/axios'

const useStyles = createUseStyles({
	root: {
		background: '#fafafa',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		paddingLeft: 0,
		zIndex: 99,
		boxShadow: '0 -1px 7px #cecece',
	},
	table: {
		// minWidth: 650
		padding: '8px 16px',
	},
	title: {
		cursor: 'pointer',
		fontSize: 16,
		padding: '15px 24px',
		color: 'rgba(0, 0, 0, 0.85)',
		fontWeight: '600',
	},
	titleIcon: {
		float: 'right',
		color: 'rgba(0, 0, 0, 0.45)',
		fontSize: 14,
	},
	content: {
		maxHeight: 0,
		overflow: 'auto',
		textAlign: 'center',
		width: '100%',
		borderTop: '1px solid #e9e4e4',
	},
	activeContent: {
		height: 300,
		maxHeight: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	badge: {
		background: 'red',
		padding: 3,
		color: 'white',
		borderRadius: 17,
		fontSize: 10,
	},
	tableRow: {
		'&:hover': {
			opacity: '.8',
		},
		'& .MuiTableCell-root': {
			padding: '6px 12px',
			fontSize: 12.5,
		},
	},
	hide: {
		display: 'none',
	},
	completed: {
		background: '#1cb0843f',
	},
	waiting: {
		background: '#c276203f',
	},
	failed: {
		background: '#c258583f',
	},
	pointer: {
		cursor: 'pointer',
	},
	notallowed: {
		cursor: 'not-allowed',
	},
	successIcon: {
		width: 16,
		color: '#1cb084',
		verticalAlign: 'middle',
		marginLeft: 3,
		marginBottom: 3,
	},
	failIcon: {
		width: 16,
		color: '#c25858',
		verticalAlign: 'middle',
		marginLeft: 3,
		marginBottom: 3,
	},
	waitingIcon: {
		width: 16,
		color: '#c27620',
		verticalAlign: 'middle',
		marginLeft: 3,
		marginBottom: 3,
	},
})

export default function BasicTable() {
	const [openTask, setopenTask] = React.useState(false)
	const navigate = useNavigate()
	let params = useParams()
	const authContext = useAuthContext()
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()
	const classes = useStyles()
	const [filters, setFilters] = useState({ status: [] })

	const toggleTask = () => {
		globalContext.updateState('openTask', !openTask)
		setopenTask(!openTask)
	}

	const goTo = (obj, event) => {
		let relatedObjectKey = event.relatedObjectKey.split(':')
		if (relatedObjectKey?.[0] && relatedObjectKey?.[1]) {
			let link = `/${relatedObjectKey[0]}/${relatedObjectKey[1]}`
			switch (relatedObjectKey[0]) {
				case 'cluster':
					link = `/cluster/${obj.id}`
					break
				case 'node':
					link = `/cluster/${obj.clusterId}/node/${obj.id}`
					break
				case 'vm':
					link = `/cluster/${obj.clusterId}/node/${obj.nodeId}/vm/${obj.id}`
					break
				case 'container':
					link = `/cluster/${obj.clusterId}/node/${obj.nodeId}/container/${obj.id}`
					break
				case 'network':
					link = `/cluster/${obj.clusterId}/network/${obj.id}`
					break
				case 'networkProfile':
					link = `/cluster/${obj.clusterId}/networkProfile/${obj.id}`
					break
				case 'vmStoragePool':
					link = `/cluster/${obj.clusterId}/vmStoragePool/${obj.id}`
					break
			}
			navigate(link)
		}
	}
	const getNodeName = (id) => {
		if (id && dataContext.state?.getNodes) {
			let findItem = dataContext.state?.getNodes.find((item) => item.id == id)
			return findItem?.name
		} else {
			return 'N/A'
		}
	}

	const getOperationsInt = () => {
		const fetch = setInterval(async () => {
			getOperations()
		}, 5000)
		return () => clearInterval(fetch)
	}

	const getOperations = async () => {
		let res = await dataService.getOperations()
		if (res) {
			if (res.success) {
				let operations = [...res.data]
				if (authContext?.state?.userInfo?.userRole == 'tenantAdmin') {
					operations = operations.filter((o) =>
						o?.realmIds?.some((r) => authContext?.state?.userInfo?.realmIds?.includes('tenant:' + r))
					)
				}
				dataContext.updateState('getOperations', operations)
				afterOperations(res.data)
			} else {
				globalContext.Snackbar(res.errorMessage, 'error')
			}
		}
	}

	const getEvents = () => {}

	const eventControls = (evt) => {
		let pageOpen = false
		let relatedKey = evt.relatedObjectKey.split(':')[0]
		if (evt.status == 'COMPLETED' || evt.status == 'FAILED') {
			switch (relatedKey) {
				case 'vmStorage':
				case 'vm':
					pageOpen = Object.keys(globalContext.state.params).includes('vmID') ? true : false
					// let clusters = [...dataContext.state.list]
					// let tenants = [...dataContext.state.tenants]
					// let vmGroups = [...dataContext.state.getVmGroups];
					// let clusterIndex = clusters.findIndex((i) => i.id == evt.clusterId)
					// let nodeIndex = clusters[clusterIndex].child.findIndex((i) => i.id == evt.nodeId)
					// let vmIndex = clusters[clusterIndex]["child"][nodeIndex]["child"]["virtualMachines"].findIndex((i) => i.id == evt.id)
					dataContext.getClusters()
					dataContext.getTenants()
					dataContext.getVmGroups()
					dataContext.getVms()
					dataContext.getVmTemplates()
					break
				case 'container':
					pageOpen = Object.keys(globalContext.state.params).includes('containerID') ? true : false
					dataContext.getClusters()
					dataContext.getContainers()
					break
				case 'node':
					pageOpen = Object.keys(globalContext.state.params).includes('nodeID') ? true : false
					dataContext.getClusters()
					break
				case 'glusterCluster':
					dataContext.getGlusters()
					break
				case 'glusterVolume':
					dataContext.getGlusterVolumes()
					break
				case 'volumePath':
					dataContext.getVolumePathObjects()
					break
				case 'nodeNetworkInterface':
					dataContext.getNodeNetworkInterfaces()
					break
				case 'backupProfile':
					pageOpen = Object.keys(globalContext.state.params).includes('backupProfileID') ? true : false
					dataContext.getBackupProfiles()
					break
				case 'network':
					pageOpen = Object.keys(globalContext.state.params).includes('networkID') ? true : false
					dataContext.getNetworks()
					break
				case 'networkProfile':
					pageOpen = Object.keys(globalContext.state.params).includes('networkProfileID') ? true : false
					dataContext.getNetworkProfiles()
					break
				case 'vmStoragePool':
					pageOpen = Object.keys(globalContext.state.params).includes('storagePoolID') ? true : false
					dataContext.getVmStoragePools()
					break
				case 'backupProfile':
					pageOpen = Object.keys(globalContext.state.params).includes('backupProfileID') ? true : false
					dataContext.getBackupProfiles()
					break
				case 'externalBackupProfile':
					dataContext.getExternalBackupProfiles()
					break
				case 'peerCluster':
					dataContext.getPeerClusters()
					break
				case 'quotaProfile':
					dataContext.getQuotaProfiles()
					break
				case 'containerImage':
					dataContext.getImages()
					break
				case 'ipAddress':
				// missing break is intentional
				case 'tenantNetwork':
					dataContext.getTenants()
					break
				case 'rlbObject':
					dataContext.getRlbObjects()
					break
				case 'iscsi':
				case 'iscsiInitiator':
					dataContext.getIscsiInitiators()
					break
				case 'recoveryOperation':
					dataContext.getClusters()
					dataContext.getVms()
					break
				case 'uploadSession':
					// OVA Import
					dataContext.getClusters()
					dataContext.getVms()
				default:
					console.error('You added a new parameter, check it!')
					break
			}
		}
	}

	const triggerOperation = async (id) => {
		if (id) {
			let res = await dataService.triggerOperationWithId(id)
			if (res.success) {
				globalContext.Snackbar(res.message, 'success')
			} else {
				globalContext.Snackbar('Error happened.', 'error')
			}
		}
	}

	const setPeerCLusters = async () => {
		let res = await dataService.getPeerClusters()
		if (res.success) {
			dataContext.updateState('peerClusters', res.data)
		}
	}

	const afterOperations = (ops) => {
		let lastOps = ops.filter(
				(e) =>
					parseInt(e.creationTime.toString().slice(0, 10)) >
					parseInt(new Date().getTime().toString().slice(0, 10)) - 55
			),
			lastOpType = ''
		if (lastOps.length > 0) {
			eventControls(lastOps[0])
		}
	}

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Operation Type',
			dataIndex: 'operationType',
			key: 'operationType',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{
					text: 'Success',
					title: 'Success',
					value: 'COMPLETED',
				},
				{
					text: 'Failed',
					title: 'Failed',
					value: 'FAILED',
				},
				{
					text: 'Pending',
					title: 'Pending',
					value: 'WAITING',
				},
				{
					text: 'In Progress',
					title: 'In Progress',
					value: 'CONSUMED',
				},
			],
			filteredValue: filters.status,
			onFilter: (value, record) => record.status === value,
			render: (text) => (
				<span style={{ textTransform: 'capitalize' }}>
					{text == 'CONSUMED' ? 'IN PROGRESS' : text}
					{text == 'COMPLETED' ? (
						<CheckCircleOutlined className={classes.successIcon} />
					) : text == 'FAILED' ? (
						<CloseCircleOutlined className={classes.failIcon} />
					) : (
						<ExclamationCircleOutlined className={classes.waitingIcon} />
					)}
				</span>
			),
		},
		{
			title: 'Created On',
			dataIndex: 'creationTime',
			key: 'creationTime',
			render: (text) => (
				<span>
					{Intl.DateTimeFormat('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
					}).format(text)}
				</span>
			),
		},
		{
			title: 'Device Type',
			dataIndex: 'relatedObjectKey',
			key: 'relatedObjectKey',
			width: 200,
			render: (text) => <span>{text.split(':')[0]}</span>,
		},
		{
			title: 'Device',
			dataIndex: 'parameters',
			key: 'parameters',
			render: (text, row) => (
				<Button
					className={`${text['type'] ? classes.pointer : ''}`}
					onClick={() => {
						goTo(text, row)
					}}>
					{text['name']}
				</Button>
			),
		},
		{
			title: 'Related Node',
			dataIndex: 'nodeId',
			key: 'nodeId',
			render: (text) => getNodeName(text),
		},
		// {
		// 	title: 'Related Object Key',
		// 	dataIndex: 'relatedObjectKey',
		// 	key: 'relatedObjectKey',
		// },
		// {
		// 	title: 'Trigger',
		// 	dataIndex: 'status',
		// 	key: 'trigger',
		// 	render: (value, row) =>
		// 		row.status == 'FAILED' ? (
		// 			<Button
		// 				icon={<IssuesCloseOutlined />}
		// 				style={{ background: '#0f427a', color: '#fff' }}
		// 				onClick={() => triggerOperation(row.id)}
		// 				id="triggerAgainButtons">
		// 				Trigger Again
		// 			</Button>
		// 		) : (
		// 			'Operation is ' +
		// 			(row.status == 'CONSUMED' || row.status == 'WAITING' ? 'in progress' : row.status.toLowerCase())
		// 		),
		// },
		{
			title: 'Message',
			dataIndex: 'errorText',
			key: 'errorText',
			render: (text, row) => (
				row.errorText.length > 0 ? row.errorText : '-',
				(
					<Tooltip title={text} overlayStyle={{ color: 'white' }}>
						<div
							style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{text}
						</div>
					</Tooltip>
				)
			),
		},
	]

	// Open websocket for checking operations
	useEffect(() => {
		let baseUrlArray = http.defaults.baseURL?.split('/')
		let ws
		let clearOperationsInterval

		if (authContext.state.token) {
			ws = new WebSocket(
				`${baseUrlArray?.[0] === 'https:' ? 'wss' : 'ws'}://${baseUrlArray?.[2]}/api/v1/events/?accessKey=${
					authContext.state.token
				}&Event-Type=operation`
			)

			ws.onopen = () => {
				getOperations()
				console.log('WebSocket Opened')
				clearOperationsInterval = getOperationsInt() // Start the interval
			}

			ws.onmessage = function (event) {
				let lastEvent = JSON.parse(event?.data?.split('\n')[1])
				eventControls(lastEvent)
				getOperations()
			}

			ws.onclose = () => {
				console.log('WebSocket Closed')
			}

			ws.onerror = (error) => {
				console.error('WebSocket Error', error)
			}
		}

		// Cleanup function
		return () => {
			if (ws) {
				ws.close() // Close WebSocket connection
				console.log('WebSocket Closed')
			}
			if (clearOperationsInterval) {
				clearOperationsInterval() // Clear the interval
			}
		}
	}, [authContext.state.token]) // Include the token as a dependency

	useEffect(() => {
		if (!localStorage.token) {
			authContext.updateState(`token`, ``)
		}
	}, [localStorage.token])

	return (
		<div className={classes.root}>
			<div className={classes.title} onClick={() => toggleTask()}>
				Recent Tasks
				<span className={classes.titleIcon}>{openTask ? <ArrowDownOutlined /> : <ArrowUpOutlined />}</span>
			</div>
			<div className={`${classes.content} ${openTask ? classes.activeContent : ''}`}>
				<Table
					columns={columns}
					dataSource={dataContext.state.getOperations}
					onChange={(filters) => {
						setFilters(filters)
					}}
					size="small"
					scroll={{ y: 200 }}
					style={{ height: '100%' }}
					loading={{
						indicator: (
							<div>
								<Spin />
							</div>
						),
						spinning: dataContext?.state?.getOperations == null,
					}}
				/>
			</div>
		</div>
	)
}
