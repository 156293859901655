import React from 'react'
import './App.less'
import Modals from './Modals'
import ContextMenu from './ContextMenu'

import { Main, Header, SideMenu, Routes } from './components'
import DraftsIcon from '@material-ui/icons/Drafts'
import languages from '../src/Language'
import RecentTask from 'components/layout/RecentTask'
import { BrowserRouter } from 'react-router-dom'
const Home = React.lazy(() => import('screens/Home'))
const Login = React.lazy(() => import('screens/Login'))
const Cluster = React.lazy(() => import('screens/Cluster'))
const Node = React.lazy(() => import('screens/Node'))
const Vm = React.lazy(() => import('screens/Vm'))
const VmTemplate = React.lazy(() => import('screens/VmTemplate'))
const StoragePool = React.lazy(() => import('screens/StoragePool'))
const Network = React.lazy(() => import('screens/Network'))
const NetworkProfile = React.lazy(() => import('screens/NetworkProfile'))
const BackupProfile = React.lazy(() => import('screens/BackupProfile'))
const Container = React.lazy(() => import('screens/Container'))
const Tenant = React.lazy(() => import('screens/Tenant'))

function App() {
	var rootList = [
		{
			path: '/',
			element: Login,
		},
	]

	var rootListPrivate = [
		{
			path: '/',
			name: 'home',
			element: Home,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID',
			name: 'cluster',
			icon: <DraftsIcon />,
			element: Cluster,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/node/:nodeID',
			name: 'node',
			icon: <DraftsIcon />,
			element: Node,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/node/:nodeID/vm/:vmID',
			name: 'vm',
			icon: <DraftsIcon />,
			element: Vm,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/vmTemplate/:vmTemplateID',
			name: 'vm template',
			icon: <DraftsIcon />,
			element: VmTemplate,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},

		{
			path: '/cluster/:clusterID/vmStoragePool/:storagePoolID',
			name: 'storage pool',
			icon: <DraftsIcon />,
			element: StoragePool,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/backupProfile/:backupProfileID',
			name: 'backup profile',
			icon: <DraftsIcon />,
			element: BackupProfile,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/network/:networkID',
			name: 'network',
			element: Network,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/networkProfile/:networkProfileID',
			name: 'network profile',
			element: NetworkProfile,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/cluster/:clusterID/node/:nodeID/container/:containerID',
			name: 'container',
			icon: <DraftsIcon />,
			element: Container,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
		{
			path: '/tenant/:tenantID',
			name: 'tenant',
			icon: <DraftsIcon />,
			element: Tenant,
			permissionList: [],
			showHeader: true,
			showSideMenu: true,
		},
	]

	return (
		<Main
			Header={<Header />}
			PublicRouting={<Routes list={rootList} />}
			SideMenu={<SideMenu permissionCheck list={rootListPrivate} />}
			PrivateRoute={<Routes permissionCheck list={rootListPrivate} />}
			baseUrl=""
			theme={{
				palette: {
					primary: {
						main: '#0f427a',
					},
				},
			}}
			dataContextProviderInitialState={{ ss: 'aaa' }}
			languages={languages}
			modals={Modals}
			contextMenu={ContextMenu}
			Footer={RecentTask}
		/>
	)
}

export default App
