import React from 'react'
import { Menu, Item } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import {
	ReloadOutlined,
	PoweroffOutlined,
	ToolOutlined,
	IssuesCloseOutlined,
	PlusOutlined,
	GlobalOutlined,
	ProfileOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Popconfirm, Modal } from 'antd'
const { confirm } = Modal
import { useGlobalContext, useNavigate } from 'components'
import dataService from 'service/dataService'

export default function NodeContextMenu() {
	const globalContext = useGlobalContext()
	const navigate = useNavigate()

	const showConfirm = (event, props, type) => {
		confirm({
			title: 'Are you sure?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			onOk() {
				switch (type) {
					case 'reboot':
						rebootNode(event?.props)
						break
					case 'shutdown':
						shutdownNode(event?.props)
						break
					case 'setMaintenance':
						setMaintenanceNode(event?.props)
						break
					case 'unsetMaintenance':
						unsetMaintenanceNode(event?.props)
						break
					case 'remove':
						removeNode(event?.props)
						break
					default:
						break
				}
			},
		})
	}

	const rebootNode = async (props) => {
		let res = await dataService.rebootNode(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const shutdownNode = async (props) => {
		let res = await dataService.shutdownNode(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const setMaintenanceNode = async (props) => {
		let res = await dataService.setMaintenanceNode(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const unsetMaintenanceNode = async (props) => {
		let res = await dataService.unsetMaintenanceNode(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const removeNode = async (props) => {
		let res = await dataService.removeNodeCls(props.id)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
			navigate('/')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const VMCreateAndUpdatePopup = ({ event, props }) => {
		globalContext.updateState('VMCreateAndUpdatePopup', { ...props, createOrUpdate: 'create' })
	}

	const ContainerPopup = ({ event, props }) => {
		globalContext.updateState('containerCreateAndUpdatePopup', {})
	}

	const VMCreateFromTemplatePopup = ({ event, props }) => {
		globalContext.updateState('vmCreateFromTemplatePopup', props)
	}

	return (
		<>
			<Menu id={'node'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Node Actions
				</div>
				<hr />
				<Item onClick={(event, props) => showConfirm(event, props, 'reboot')} id="rebootNode">
					<ReloadOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Reboot
				</Item>
				<Item onClick={(event, props) => showConfirm(event, props, 'shutdown')} id="shutdownNode">
					<PoweroffOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Shutdown
				</Item>
				<Item onClick={(event, props) => showConfirm(event, props, 'setMaintenance')} id="setMaintenanceNode">
					<ToolOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Set Maintenance Mode
				</Item>
				<Item onClick={(event, props) => showConfirm(event, props, 'unsetMaintenance')} id="unsetMaintenanceNode">
					<IssuesCloseOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Unset Maintenance Mode
				</Item>
				<Item onClick={(event, props) => showConfirm(event, props, 'remove')} id="removeNodeFromCls">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Remove Node from Cluster
				</Item>
				<hr />
				<Item onClick={VMCreateAndUpdatePopup} id="vmCreate">
					<PlusOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Virtual Machine
				</Item>
				<Item onClick={VMCreateFromTemplatePopup} id="vmCreateFromTemplate">
					<PlusOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Virtual Machine from Template
				</Item>
				<Item onClick={ContainerPopup} id="containerCreate">
					<PlusOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Container
				</Item>
				<Item onClick={() => globalContext.updateState('networkCreateAndUpdatePopup', {})} id="networkCreate">
					<GlobalOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Network
				</Item>
				<Item
					onClick={() => globalContext.updateState('networkProfileCreateAndUpdatePopup', {})}
					id="networkProfileCreate">
					<ProfileOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					New Network Profile
				</Item>
			</Menu>
		</>
	)
}
