import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import languageCodeToName from './languageCodeToName.json'
const useStyles = makeStyles((theme) => ({
	formControlStyle: {
		//   margin: theme.spacing(1),
		//   minWidth: 120,
		color: 'white',
	},
}))

const LanguageSelector = () => {
	const { t, i18n } = useTranslation()
	const classes = useStyles()
	const [languageList, setlanguageList] = React.useState(i18n.languages)
	const handleChange = (event) => {
		i18n.changeLanguage(event.target.value)
	}

	return (
		<FormControl variant="filled" className={classes.formControlStyle}>
			<Select style={{ color: 'white' }} value={i18n.language.split('-')[0]} onChange={handleChange}>
				{languageList.map((i, index) => {
					return (
						<MenuItem key={languageList + index} value={i}>
							{languageCodeToName[i]}
						</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

export default LanguageSelector
