import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()

	const [data, setdata] = useState({})
	const [tenant, setTenant] = useState({})
	const [tenantNet, setTenantNet] = useState({})
	const [assignedIp, setAssignedIp] = useState('')
	const [publicIps, setPublicIps] = useState([])
	const [visible, setVisible] = useState(false)
	const [southBoundNetworks, setSouthBoundNetworks] = useState([])
	const [assignmentType, setAssignmentType] = useState('network')
	const [natPrivateIpObject, setNatPrivateIpObject] = useState({
		clusterId: '',
		tenantId: '',
		tenantNetworkId: '',
		privateAddress: '',
		publicIpAddressId: '',
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setAssignedIp(valueTemp)
	}

	const handleChangePrivateIp = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setNatPrivateIpObject({ ...natPrivateIpObject, [key]: valueTemp })
	}

	const handleChangeAssignmentType = () => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setAssignmentType(valueTemp)
		setTenantNet({})
		setAssignedIp('')
	}

	const handleChangeTenantNetwork = () => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		let tenantNetTemp = tenant.tenantNetworks.find((net) => net.id == valueTemp)
		setTenantNet(tenantNetTemp)
	}

	const handleClose = () => {
		setVisible(false)
		globalContext.updateState('assignIpPopup', null)
		setdata({})
		setAssignedIp('')
		setTenant({})
		setTenantNet({})
		setPublicIps([])
		setAssignmentType('network')
		setNatPrivateIpObject({
			clusterId: '',
			tenantId: '',
			tenantNetworkId: '',
			privateAddress: '',
			publicIpAddressId: '',
		})
	}

	useEffect(() => {
		if (globalContext?.state?.assignIpPopup) {
			setdata(globalContext.state.assignIpPopup)
			setTenant(globalContext?.state?.assignIpPopup?.tenant ?? {})
			setTenantNet(globalContext?.state?.assignIpPopup?.tenantNet ?? {})
			setNatPrivateIpObject({
				...natPrivateIpObject,
				tenantNetworkId: globalContext?.state?.assignIpPopup?.tenantNet?.id,
			})
			setAssignedIp(globalContext?.state?.assignIpPopup?.ipAddress ?? '')
			if (globalContext?.state?.assignIpPopup?.tenantNetwork?.assignedIpAddresses?.length > 1) {
				setAssignedIp(globalContext?.state?.assignIpPopup?.tenantNet?.assignedIpAddresses?.[0]?.id)
			}
			let tenantSB = globalContext?.state?.assignIpPopup?.tenant?.southBoundNetworkId
			let tenantPA = globalContext?.state?.assignIpPopup?.tenant?.publicAddressPoolIds
			if (tenantSB) {
				let relatedSB = globalContext.state.assignIpPopup.southBounds?.find((sb) => sb.id == tenantSB)
				let publics = []
				relatedSB?.addressPools?.forEach((ap) => {
					tenantPA.forEach((allowedId) => {
						if (allowedId == ap.id) {
							ap.ipAddresses.forEach((ip) => {
								ip.assignedObjectId == '' && publics.push({ ...ip })
							})
						}
					})
				})
				setPublicIps(publics)
			}
		}
	}, [globalContext.state.assignIpPopup])

	const assignIp = async () => {
		delete tenantNet.status
		tenantNet.assignedIpAddresses = [publicIps.find((ip) => ip.id == assignedIp)]
		let res = await dataService.updateTenantNetwork(tenantNet)
		if (res.success) {
			globalContext.Snackbar(`Update [${res.data.name}] tenant operation started successfully`, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const assignIpToPrivateIp = async () => {
		let sendData = { ...natPrivateIpObject }
		sendData.tenantNetworkId = tenantNet.id
		sendData.clusterId = tenant.clusterId
		sendData.tenantId = tenant.id
		sendData.publicIpAddressId = assignedIp

		let res = await dataService.assignPrivateIp(sendData)
		if (res.success) {
			globalContext.Snackbar(`Create [${res.data.name}] One-To-One NAT operation started successfully`, 'success')
			handleClose()
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	return (
		<Drawer
			title="Assign Public IP"
			placement="right"
			onClose={handleClose}
			visible={globalContext.state.assignIpPopup || false}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={handleClose} small>
						Cancel
					</Button>
					<Button
						onClick={assignmentType == 'network' ? assignIp : assignIpToPrivateIp}
						style={{ marginLeft: 8 }}
						type="primary"
						id="assignIpSubmit"
						small>
						Submit
					</Button>
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Assignment Type
				</label>
				<Select
					value={assignmentType}
					onChange={handleChangeAssignmentType()}
					className="fullW"
					id="assignmentType">
					<Option value={'network'}>Tenant Network</Option>
					<Option value={'privateIp'}>Private IP</Option>
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Tenant Network
				</label>
				<Select className="fullW" value={tenantNet.id} onChange={handleChangeTenantNetwork()} id="tenantNetworks">
					{tenant?.tenantNetworks?.map((network) => (
						<Option
							disabled={network.assignedIpAddresses.length > 0 && assignmentType == 'network'}
							value={network.id}>
							{network.name}
						</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Public IP
				</label>
				<Select value={assignedIp} onChange={handleChange('assignedIp')} className="fullW" id="publicIps">
					{publicIps.map((ip) => (
						<Option value={ip.id}>{ip.address}</Option>
					))}
				</Select>
			</div>
			{assignmentType === 'privateIp' ? (
				<div>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Private IP
						</label>
						<Input
							value={natPrivateIpObject.privateAddress}
							onChange={handleChangePrivateIp('privateAddress')}
							placeholder={'e.g. ___.___.___.___'}
						/>
					</div>
					<small>
						Please input IP address in the given {tenantNet?.addressPools?.[0].ipNetCIDR} subnet of Tenant
						Network.
					</small>
				</div>
			) : (
				<></>
			)}
		</Drawer>
	)
}
