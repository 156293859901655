import React, { useState, useEffect } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import dataService from 'service/dataService'
import { Input, Select, Button, Switch, InputNumber } from 'antd'
import dataContext from '../../Context/DataContext'
const { Option } = Select

const useStyles = createUseStyles({
	root: {
		height: '90%%',
		position: 'relative',
		paddingBottom: 40,
	},
	input: { width: '100%' },
	footer: {
		height: '10%',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 25,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Hardware({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState({
		nodeId: modalData.nodeId || '',
		cpu: modalData.cpu || '1',
		memory: modalData.memory || '1',
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key != 'nodeId') {
			setdata({ ...data, [key]: valueTemp?.toString() })
		} else {
			setdata({ ...data, [key]: valueTemp, image: '' })
		}
	}

	const [nodeList, setnodeList] = useState([])
	const fetch = async () => {
		let responseNode = await dataService.getNodesList()
		if (responseNode.success) {
			setnodeList(responseNode.data)
			dataContext.updateState('dataService.getNodesList', responseNode.data)
		} else {
			globalContext.Snackbar(responseNode.errorMessage, 'error')
			setnodeList([])
			dataContext.updateState('dataService.getNodesList', [])
		}
	}
	useEffect(() => {
		fetch()
	}, [])

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	return (
		<>
			<div className={classes.root}>
				<div
					style={{
						marginBottom: 8,
						width: '100%',
					}}>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Node
						</label>
						<Select
							className="fullW"
							value={data.nodeId}
							disabled={globalContext?.state?.containerCreateAndUpdatePopup?.createOrUpdate == 'update'}
							onChange={handleChange('nodeId')}
							id="containerNodeId">
							{nodeList?.map((node) => (
								<Option key={node?.id} value={node?.id}>
									<span style={{ fontWeight: 'bold' }}>{node.name}</span> - ({node.nodeStat.cpuPercentage}%
									CPU) ({node.nodeStat.memPercentage}% Memory)
								</Option>
							))}
						</Select>
					</div>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>CPU
						</label>
						<InputNumber
							className="fullW"
							min={1}
							value={data.cpu}
							onChange={handleChange('cpu')}
							addonAfter="CPU"></InputNumber>
					</div>
					<div className="form-group-ant">
						<label>
							<span className="requiredHint">*</span>Memory
						</label>
						<InputNumber
							className="fullW"
							min={1}
							value={data.memory}
							onChange={handleChange('memory')}
							addonAfter="GB"></InputNumber>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={() => prev()} className="prev">
					Prev
				</Button>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={!(data?.nodeId !== '' && data?.cpu !== '' && data?.memory !== '')}
					type="primary"
					className="next"
					small>
					Save & Next
				</Button>
			</div>
		</>
	)
}
