import React, { useState, useEffect } from 'react'
import { command } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Select, Button, Switch } from 'antd'
const { Option } = Select

const useStyles = createUseStyles({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: 40,
	},
	input: { width: '100%' },
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 4,
	},
	next: {
		background: '#2f4352',
		color: 'white',
	},
})
export default function Name({ modalDataChange = () => {}, next = () => {}, modalData = {} }) {
	const classes = useStyles()
	const [data, setdata] = useState({
		name: modalData?.name || '',
		bootLoader: modalData?.bootLoader || 'bios',
		autoStart: modalData?.autoStart || 'false',
		cpuHostModel: modalData?.cpuHostModel || false,
	})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		if (['autoStart'].includes(key)) {
			valueTemp = valueTemp.toString()
		}
		setdata({ ...data, [key]: valueTemp })
		modalDataChange({ ...data, [key]: valueTemp })
	}

	const nextStep = () => {
		modalDataChange(data)
		next()
	}

	useEffect(() => {
		setdata({
			...modalData,
			bootLoader: modalData?.bootLoader || 'bios',
			autoStart: modalData?.autoStart || 'false',
			cpuHostModel: modalData?.cpuHostModel || false,
		})
	}, [modalData])

	return (
		<div className={classes.root}>
			<div
				style={{
					marginBottom: 8,
					width: '100%',
				}}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Name
					</label>
					{modalData?.createOrUpdate == 'update' ? (
						<Input placeholder="Enter here" value={data?.alias} onChange={handleChange('alias')} id="vmAlias" />
					) : (
						<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="vmName" />
					)}
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Description
					</label>
					<Input
						placeholder="Enter here"
						value={data?.description}
						onChange={handleChange('description')}
						id="vmDescription"
					/>
				</div>
				<div className="form-group-ant">
					<label>Bootloader</label>
					<Select
						value={data?.bootLoader || 'bios'}
						onChange={handleChange('bootLoader')}
						disabled={data?.state == 'running'}
						id="vmBootLoader"
						className="fullW">
						<Option value={'uefi'}>UEFI</Option>
						<Option value={'bios'}>BIOS</Option>
					</Select>
				</div>
				<div className={classes.footer}>
					<Button
						onClick={() => nextStep()}
						style={{ marginLeft: 8 }}
						disabled={data?.name?.length < 1}
						type="primary"
						className="next"
						small>
						Save & Next
					</Button>
				</div>
			</div>
			<div
				style={{
					marginBottom: 8,
					display: 'inline-flex',
					width: '100%',
					borderRadius: 3,
					justifyContent: 'start',
				}}>
				<div>
					<span style={{ fontSize: 14 }}>Autostart: </span>
					<Switch
						checked={data?.autoStart == 'true'}
						onChange={handleChange('autoStart')}
						disabled={data?.state == 'running'}
						id="vmAutoStart"
						name="autoStart"
						color="primary"
					/>
				</div>
				<div style={{ marginLeft: 12 }}>
					<span style={{ fontSize: 14 }}>Host CPU Model: </span>
					<Switch
						checked={data?.cpuHostModel}
						onChange={handleChange('cpuHostModel')}
						disabled={data?.state == 'running'}
						id="vmCpuHostModel"
						name="vmCpuHostModel"
						color="primary"
					/>
				</div>
			</div>
		</div>
	)
}
