import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Input, Button, Switch } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({})

	//handleChange
	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		let dataTemp = { ...data }
		dataTemp[key] = valueTemp
		if (['haExcluded', 'haCritical'].includes(key)) {
			dataTemp[key] = valueTemp.toString()
			if (key == 'haExcluded' && valueTemp && data['haCritical'] == 'true') {
				dataTemp['haCritical'] = 'false'
			} else if (key == 'haCritical' && valueTemp && data['haExcluded'] == 'true') {
				dataTemp['haExcluded'] = 'false'
			}
		}
		setdata(dataTemp)
	}

	const handleClose = () => {
		globalContext.updateState('vmGroupCreatePopup', null)
		setdata({})
	}

	const getVmGroups = async () => {
		let res = await dataService.getVmGroups()
		if (res.success) {
			dataContext.updateState('getVmGroups', res.data)
		}
	}

	const getVmList = (objects) => {
		let renderTxt = ''
		objects.map((object, index) => {
			renderTxt =
				renderTxt +
				dataContext.state.getVms.find((item) => item.id === object).name +
				(objects.length == index + 1 ? '' : ', ')
		})

		return renderTxt
	}

	const createVmGroup = async () => {
		var vmsArr = []
		data.objects.forEach((vmId) => {
			vmsArr.push({
				id: vmId,
				name: dataContext.state.getVms.find((e) => e.id == vmId).name,
			})
		})
		let sendData = {
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			haCritical: data.haCritical || 'false',
			haExcluded: data.haExcluded || 'false',
			objects: vmsArr,
		}

		let res = await dataService.createVmGroup(sendData)
		if (res.success) {
			getVmGroups()
			handleClose()

			globalContext.Snackbar(`Create [${res.data.name}] vm group operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateVmGroup = async () => {
		var vmsArr = []
		data.objects.forEach((vmId) => {
			vmsArr.push({
				id: vmId,
				name: dataContext.state.getVms.find((e) => e.id == vmId).name,
			})
		})
		let sendData = {
			id: data.id,
			name: data.name,
			clusterId: dataContext.state.list[0].id,
			objects: vmsArr,
			haCritical: data.haCritical || 'false',
			haExcluded: data.haExcluded || 'false',
		}

		let res = await dataService.updateVmGroup(sendData)
		if (res.success) {
			getVmGroups()
			handleClose()

			globalContext.Snackbar(`Update [${res.data.name}] vm group operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		if (globalContext.state.vmGroupCreatePopup) {
			setdata(globalContext.state.vmGroupCreatePopup)
		}
	}, [globalContext.state.vmGroupCreatePopup])

	return (
		<div>
			<Drawer
				title={
					globalContext.state.vmGroupCreatePopup?.createOrUpdate == 'update' ? 'Update Vm Group' : 'New Vm Group'
				}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.vmGroupCreatePopup || false}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small>
							Cancel
						</Button>
						{globalContext.state.vmGroupCreatePopup?.createOrUpdate == 'update' ? (
							<Button
								onClick={() => updateVmGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								small
								id="vmGroupUpdate">
								Update
							</Button>
						) : (
							<Button
								onClick={() => createVmGroup()}
								style={{ marginLeft: 8 }}
								type="primary"
								disabled={`${data?.objects?.length == 0 && data?.name?.length == 0 ? 'true' : ''}`}
								small
								id="vmGroupCreate">
								Create
							</Button>
						)}
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Group Name
					</label>
					<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="vmGroupName" />
				</div>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Select VMs
					</label>
					<Select
						mode="multiple"
						value={data?.objects}
						onChange={handleChange('objects')}
						placeholder="Select VMs"
						className="fullW"
						id="selectVms">
						{dataContext.state?.getVms?.map((option) => (
							<Option key={option.id} value={option.id} label={option.name}>
								{option.name}
							</Option>
						))}
					</Select>
				</div>
				<div
					style={{
						marginBottom: 8,
						display: 'inline-flex',
						width: '100%',
						borderRadius: 3,
						justifyContent: 'space-between',
					}}>
					<div>
						<span style={{ fontSize: 14 }}>HA Excluded: </span>
						<Switch
							id="vmGroupHaExcluded"
							checked={data?.haExcluded == 'true'}
							onChange={handleChange('haExcluded')}
							name="haExcluded"
							color="primary"
						/>
					</div>
				</div>
				<div
					style={{
						marginBottom: 8,
						display: 'inline-flex',
						width: '100%',
						borderRadius: 3,
						justifyContent: 'space-between',
					}}>
					<div>
						<span style={{ fontSize: 14 }}>HA Critical: </span>
						<Switch
							id="vmGroupHaCritical"
							checked={data?.haCritical == 'true'}
							onChange={handleChange('haCritical')}
							name="haCritical"
							color="primary"
						/>
					</div>
				</div>
			</Drawer>
		</div>
	)
}
