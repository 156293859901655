import React, { useReducer, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useAuthContext, useDataContext, useGlobalContext } from 'Context'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Popconfirm, Popover, Avatar, Tooltip, Progress, Empty, Badge, Typography } from 'antd'
import {
	BarsOutlined,
	SearchOutlined,
	BulbOutlined,
	GlobalOutlined,
	NotificationOutlined,
	LogoutOutlined,
	UserOutlined,
	ReloadOutlined,
} from '@ant-design/icons'
import { GlobalContext } from 'Context/GlobalContext'
import dataService from '../../service/dataService'

const { Link } = Typography
const useStyles = createUseStyles((theme) => ({
	root: {
		height: '100%',
		justifyContent: 'center',
		width: '100%',
	},
	toolBar: {
		minHeight: 72,
		paddingRight: 25,
		paddingLeft: 25,
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
	},
	rightArea: {
		textAlign: 'right',
	},
	userMenu: {
		color: '#fff',
		padding: '5px 0 5px 15px',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 0,
		textTransform: 'lowercase',
	},
	icon: {
		width: 18,
	},
	rightIcos: {
		color: '#fff',
		margin: '0 8px',
		cursor: 'pointer',
	},
}))

export default function Header() {
	const classes = useStyles()
	let navigate = useNavigate()
	const authContext = useAuthContext()
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()
	const [isUploadOpen, setUploadOpen] = React.useState(false)

	const confirmLogout = () => {
		authContext.logout()
	}

	const updateButton = () => {
		dataContext.fetch(true)
	}

	useEffect(() => {
		const unloadCallback = (event) => {
			event.preventDefault()
			event.returnValue = ''
			return ''
		}

		if (globalContext.state.uploads?.length > 0) {
			window.addEventListener('beforeunload', unloadCallback)
			return () => window.removeEventListener('beforeunload', unloadCallback)
		}
	}, [globalContext.state.uploads])

	useEffect(() => {
		let interval

		const checkUploads = async () => {
			if (globalContext?.state?.uploads?.length > 0) {
				let token = localStorage.getItem('token')
				let expiryTime = Number(localStorage.getItem('tokenExpiry')) || 0
				let currentTimestamp = new Date().getTime()
				let timeLeftMilliseconds = expiryTime - currentTimestamp

				if (timeLeftMilliseconds > 0) {
					let minutesLeft = Math.floor(timeLeftMilliseconds / (1000 * 60)) % 60
					let seconds = (59 - minutesLeft) * 60
					if (token !== null) {
						try {
							let res = await dataService.extendAuthToken(token, seconds)
							if (!res.success) {
								console.log(res?.errorMessage || 'Error: Token extend error')
							} else {
								let tokenExpiry = res?.data?.expireTimestamp
									? res?.data?.expireTimestamp * 1000
									: new Date().getTime() + 1000000
								localStorage.setItem('tokenExpiry', tokenExpiry)
							}
						} catch (error) {
							console.error('Error updating token:', error)
						}
					}
				} else {
					console.log('The provided timestamp is in the past. Please log in again.')
				}
			} else {
				clearInterval(interval) // Clear interval if uploads are finished
			}
		}
		interval = setInterval(checkUploads, 300000) // 5 minutes interval

		return () => clearInterval(interval)
	}, [globalContext?.state?.uploads]) // Re-run effect when uploads change

	useEffect(() => {
		if (globalContext.state.uploadContent == true) {
			setUploadOpen(true)
		} else {
			setUploadOpen(false)
		}
	}, [globalContext.state.uploadContent])

	const handleChange = () => {
		setUploadOpen(!isUploadOpen)
	}

	return (
		<div className={classes.root} style={{ background: '#2B5889', padding: 0 }}>
			<Row className={classes.toolBar}>
				<Col span={12}>
					<BarsOutlined style={{ color: '#fff', marginRight: 12 }} />
					<span style={{ fontSize: 20, fontWeight: 600, color: '#fff' }}>{dataContext.state.activePage}</span>
				</Col>
				<Col span={12} className={classes.rightArea}>
					<Link
						style={{ color: '#FFFFFF', marginRight: 5 }}
						onClick={() =>
							window
								.open(
									window.location.protocol +
										'//' +
										window.location.hostname +
										':8000/' +
										'api/v1/swagger/index.html#/',
									'_blank'
								)
								.focus()
						}>
						Doc
					</Link>
					<Popover
						placement="bottom"
						className="notification-content"
						title={'Uploads'}
						onClick={() => handleChange()}
						open={isUploadOpen}
						content={
							<>
								{globalContext?.state?.uploads?.length > 0 ? (
									globalContext?.state?.uploads?.map((u) => (
										<>
											<span>{u.message}</span>
											<Progress percent={u.progress} />
										</>
									))
								) : (
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
							</>
						}>
						<Badge className={classes.rightIcos} dot={globalContext?.state?.uploads?.length > 0}>
							<NotificationOutlined />
						</Badge>
					</Popover>
					<Tooltip title="Reload all data">
						<ReloadOutlined
							className={classes.rightIcos}
							style={{ marginRight: 0, opacity: 1 }}
							onClick={updateButton}
							id="headerUpdateButton"
						/>
					</Tooltip>
					<div className={classes.userMenu}>
						<Avatar icon={<UserOutlined />} style={{ marginRight: 12 }} />
						<span style={{ fontSize: 16, color: '#fff' }}>
							{authContext?.state?.userInfo?.userName || '@sybelle.local'}
						</span>
						<Popconfirm
							placement="bottomLeft"
							title={'Are you sure you want to log out?'}
							onConfirm={confirmLogout}
							okText="Yes"
							cancelText="No">
							<LogoutOutlined style={{ marginLeft: 12 }} id="logoutButton" />
						</Popconfirm>
					</div>
				</Col>
			</Row>
		</div>
	)
}
