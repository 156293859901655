import React from 'react'
import { useNavigate, useGlobalContext, useDataContext, useParams } from '../components'
import dataService from 'service/dataService'
import { createUseStyles } from 'react-jss'
import { Modal } from 'antd'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

const useStyles = createUseStyles((theme) => ({
	root: {
		minWidth: 400,
		display: 'flex',
		alignItems: 'center',
		margin: '15px 0',
		'& *': {
			fontSize: 13,
		},
	},
	title: {
		paddingBottom: 0,
		'& h2': {
			fontSize: 16,
		},
	},
	content: {
		padding: '0 16px 24px 16px',
		flex: 1,
	},
}))

export default function DraggableDialog() {
	let navigate = useNavigate()
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const classes = useStyles()
	let params = useParams()
	const data = globalContext.state.deletePopup

	const handleClose = () => {
		globalContext.updateState('deletePopup', null)
	}

	const deleteDevice = async () => {
		if (data?.type) {
			switch (data.type) {
				case 'vm':
					let newList = dataContext.state.list
					let findClusterIndex = newList.findIndex((i) => i.id == data.clusterId)
					let findNodeIndex = newList[findClusterIndex].child.findIndex((i) => i.id == data.nodeId)

					let res = await dataService.deleteVm(data.id)
					if (res.success) {
						// dataContext.getClusters()
						// dataContext.getTenants()
						handleClose()

						globalContext.Snackbar(res.message, 'success')
						// if (globalContext?.state?.params?.vmID == data.id) {
						// 	setTimeout(() => {
						// 		navigate(`/`)
						// 	}, 2500)
						// }
					} else {
						globalContext.Snackbar(res.errorMessage, 'error')
					}
					break
				case 'network':
					let newListN = dataContext.state.getNetworks
					let res1 = await dataService.deleteNetwork(data.id)
					if (res1.success) {
						newListN = newListN.filter(function (o) {
							return o.id != data.id
						})

						dataContext.updateState('getNetworks', newListN)
						handleClose()
						globalContext.Snackbar(res1.message, 'success')
						if (globalContext?.state?.params?.networkID == data.id) {
							setTimeout(() => {
								navigate(`/`)
							}, 2500)
						}
					} else {
						globalContext.Snackbar(res1.errorMessage, 'error')
					}
					break
				case 'networkProfile':
					let newListNP = dataContext.state.getNetworkProfiles
					let res2 = await dataService.deleteNetworkProfile(data.id)
					if (res2.success) {
						newListNP = newListNP.filter(function (o) {
							return o.id != data.id
						})

						dataContext.updateState('getNetworkProfiles', newListNP)
						handleClose()
						globalContext.Snackbar(res2.message, 'success')
						if (globalContext?.state?.params?.networkProfileID == data.id) {
							setTimeout(() => {
								navigate(`/`)
							}, 2500)
						}
					} else {
						globalContext.Snackbar(res2.errorMessage, 'error')
					}
					break
				case 'vmStoragePool':
					let newListVSP = dataContext.state.getVmStoragePools
					let res3 = await dataService.deleteVmStoragePool(data.id)
					if (res3.success) {
						newListVSP = newListVSP.filter(function (o) {
							return o.id != data.id
						})

						dataContext.updateState('getVmStoragePools', newListVSP)
						handleClose()
						globalContext.Snackbar(res3.message, 'success')
						if (globalContext?.state?.params?.storagePoolID == data.id) {
							setTimeout(() => {
								navigate(`/`)
							}, 2500)
						}
					} else {
						globalContext.Snackbar(res3.errorMessage, 'error')
					}
					break
				case 'backupProfile':
					let newListBP = dataContext.state.getBackupProfiles
					let res4 = await dataService.deleteBackupProfile(data.id)
					if (res4.success) {
						newListBP = newListBP.filter(function (o) {
							return o.id != data.id
						})

						dataContext.updateState('getBackupProfiles', newListBP)
						handleClose()
						globalContext.Snackbar(res4.message, 'success')
						if (globalContext?.state?.params?.backupProfileID == data.id) {
							setTimeout(() => {
								navigate(`/`)
							}, 2500)
						}
					} else {
						globalContext.Snackbar(res4.errorMessage, 'error')
					}
					break
				case 'vmGroup':
					let deleteRes = await dataService.deleteVmGroup(data.id)
					if (deleteRes.success) {
						let res = await dataService.getVmGroups()
						if (res.success) {
							dataContext.updateState('getVmGroups', res.data)
						}
						handleClose()
						globalContext.Snackbar(deleteRes.message, 'success')
					} else {
						globalContext.Snackbar(deleteRes.errorMessage, 'error')
					}
					break
				case 'networkFilter':
					let res6 = await dataService.deleteNwFilter(data.id)
					if (res6.success) {
						let res = await dataService.getNetworkFilters()
						if (res.success) {
							dataContext.updateState('getNetworkFilters', res.data)
						}
						handleClose()
						globalContext.Snackbar(res6.message, 'success')
					} else {
						globalContext.Snackbar(res6.errorMessage, 'error')
					}
					break
				case 'networkOverlay':
					let res7 = await dataService.deleteNwOverlay(data.id)
					if (res7.success) {
						let res = await dataService.getNetworkOverlays()
						if (res.success) {
							dataContext.updateState('getNetworkOverlays', res.data)
						}
						handleClose()
						globalContext.Snackbar(res7.message, 'success')
					} else {
						globalContext.Snackbar(res7.errorMessage, 'error')
					}
					break
				case 'glusterCluster':
					let res8 = await dataService.deleteGluster(data.id)
					if (res8.success) {
						let res = await dataService.getGlusters()
						if (res.success) {
							dataContext.updateState('getGlusters', res.data)
						}
						handleClose()
						globalContext.Snackbar(res8.message, 'success')
					} else {
						globalContext.Snackbar(res8.errorMessage, 'error')
					}
					break

				case 'vmTemplate':
					let res9 = await dataService.deleteVmTemplate(data.id)
					if (res9.success) {
						let res = await dataService.getVmTemplates()
						if (res.success) {
							dataContext.updateState('getVmTemplates', res.data)
						}
						handleClose()
						globalContext.Snackbar(res9.message, 'success')
					} else {
						globalContext.Snackbar(res9.errorMessage, 'error')
					}
					break

				case 'container':
					let res10 = await dataService.deleteContainer(data.id)
					if (res10.success) {
						let res = await dataService.getClusters()
						if (res.success) {
							dataContext.updateState('list', res.data)
							navigate('/')
						}
						handleClose()
						globalContext.Snackbar(res10.message, 'success')
					} else {
						globalContext.Snackbar(res10.errorMessage, 'error')
					}
					break
				case 'tenant':
					let res11 = await dataService.deleteTenant(data.id)
					if (res11.success) {
						let res = await dataService.getTenants()
						if (res.success) {
							dataContext.updateState('tenants', res.data)
							navigate('/')
						}
						handleClose()
						globalContext.Snackbar(res11.message, 'success')
					} else {
						globalContext.Snackbar(res11.errorMessage, 'error')
					}
					break
				case 'storagePoolGroup':
					let res12 = await dataService.deleteSPoolGroup(data.id)
					if (res12.success) {
						let res = await dataService.getSPoolGroups()
						if (res.success) {
							dataContext.updateState('getStoragePoolGroups', res.data)
							navigate('/')
						}
						handleClose()
						globalContext.Snackbar(res12.message, 'success')
					} else {
						globalContext.Snackbar(res12.errorMessage, 'error')
					}
					break
				case 'peerCluster':
					let res13 = await dataService.deletePeerCluster(data.id)
					if (res13.success) {
						let res = await dataService.getPeerClusters()
						if (res.success) {
							dataContext.updateState('peerClusters', res.data)
						}
						handleClose()
						globalContext.Snackbar(res13.message, 'success')
					} else {
						globalContext.Snackbar(res13.errorMessage, 'error')
					}
					break
				case 'veritas':
					let newListVBP = dataContext.state.getExternalBackupProfiles
					let res14 = await dataService.deleteVeritasBackupProfile(data.id)
					if (res14.success) {
						newListVBP = newListVBP.filter(function (o) {
							return o.id != data.id
						})

						dataContext.updateState('getExternalBackupProfiles', newListVBP)
						handleClose()
						if (globalContext?.state?.params?.backupProfileID == data.id) {
							setTimeout(() => {
								navigate(`/`)
							}, 2500)
						}
						globalContext.Snackbar(res14.message, 'success')
					} else {
						globalContext.Snackbar(res14.errorMessage, 'error')
					}
					break
				default:
					alert('Parameter error!')
					break
			}
		}
	}

	return (
		<div>
			<Modal
				title={`Deleting ${globalContext.state.deletePopup?.type?.toString()}: ${globalContext.state.deletePopup?.name?.toString()}`}
				className="smModal"
				width={450}
				visible={globalContext.state.deletePopup || false}
				closeIcon={<CloseCircleOutlined />}
				onOk={deleteDevice}
				onCancel={handleClose}>
				<p style={{ fontSize: 16 }}>
					<InfoCircleOutlined style={{ color: '#c25858', marginRight: 6 }} /> Are you sure? Object will be
					destroyed.
				</p>
			</Modal>
		</div>
	)
}
