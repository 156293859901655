import React, { useEffect, useState } from 'react'
import { command, useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Button, Row, Col, Select, Table, Switch } from 'antd'
import dataService from 'service/dataService'
const { Option } = Select

const useStyles = createUseStyles((theme) => ({
	root: {
		height: 'calc(100% - 24px)',
		overflow: 'hidden',
		overflowY: 'auto',
	},
	diskTable: {
		'& .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td': {
			padding: '8px 16px',
		},
	},
	footer: {
		position: 'absolute',
		bottom: 8,
		width: '100%',
		textAlign: 'right',
		right: 16,
		paddingRight: 8,
	},
}))

export default function Host({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState(modalData.bricks)
	const [selectedRowKeys, setSelectedRowKeys] = useState(modalData.selectedRowKeys)

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		let newData = [...data]
		newData[index][key] = valueTemp
		setdata(newData)
		modalDataChange({ bricks: newData })
	}

	const getNodeName = (id) => {
		if (id && dataContext.state?.getNodes) {
			let findItem = dataContext.state?.getNodes.find((item) => item.id == id)
			return typeof findItem != 'undefined' ? findItem?.name : 'N/A'
		} else {
			return 'N/A'
		}
	}

	const nextStep = async () => {
		modalDataChange({ bricks: data, selectedRowKeys: selectedRowKeys })
		next()
	}

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
		},
		{
			title: 'Device Type',
			dataIndex: 'deviceType',
		},
		{
			title: 'Size',
			dataIndex: 'size',
		},
		{
			title: 'Name',
			dataIndex: 'name',
		},
		// {
		// 	title: 'Tier',
		// 	dataIndex: '',
		// 	key: 'X',
		// 	render: (text, row) => (
		// 		<Switch
		// 			checked={data.find((o) => o.blockDeviceId == row.id)?.brickTier == 'hot'}
		// 			disabled={row.deviceType == 'HDD'}
		// 			onChange={(e) => {
		// 				handleBrickTier(e, 'brickTier', row)
		// 			}}
		// 			checkedChildren="hot"
		// 			unCheckedChildren="cold"
		// 		/>
		// 	),
		// },
	]

	// const handleBrickTier = (e, key, row) => {
	// 	let valueTemp = e ? 'hot' : 'cold'
	// 	let brickIndex = data.findIndex((i) => i.blockDeviceId == row.id)
	// 	let newData = [...data]
	// 	newData[brickIndex][key] = valueTemp
	// 	setdata(newData)
	// 	modalDataChange({ bricks: newData })
	// }

	const onSelect = (row, selected, selectedRows) => {
		var newData = selectedRows
			? selectedRows.map((o) => {
					return { nodeId: o?.nodeId, blockDeviceId: o?.id, brickTier: 'cold' }
			  })
			: []
		var newData2 = [...data, ...newData]
		// for unique objects TODO: merge two sets
		newData2 = [...new Set(newData2.filter((b) => b.blockDeviceId))]
		newData2 = [...new Map(newData2.map((v) => [JSON.stringify([v.blockDeviceId, v.nodeId]), v])).values()]
		// for unique objects TODO: merge two sets
		if (!selected) {
			newData2 = newData2.filter((b) => b.blockDeviceId != row.id)
		}
		var selectedRows = newData2.map((a) => a.blockDeviceId)
		setSelectedRowKeys(selectedRows)
		modalDataChange({ selectedRowKeys: selectedRows })
		setdata(newData2)
		modalDataChange({ bricks: newData2 })
	}

	const rowSelection = {
		columnTitle: <div></div>,
		selectedRowKeys,
		onSelect: onSelect,
	}

	return (
		<div className={classes.root}>
			<Row style={{ paddingBottom: 16 }}>
				{dataContext?.state?.getNodes?.map((node) => (
					<Col span={24} style={{ paddingTop: 8 }}>
						<label className="fullW">
							<span className="requiredHint">*</span>
							{getNodeName(node?.id)}'s Block Devices
						</label>
						<Table
							className={classes.diskTable}
							rowSelection={rowSelection}
							columns={columns}
							dataSource={dataContext?.state?.getBlockDevices?.filter(function (o) {
								return (
									o.storagePoolId == '' &&
									o.glusterVolumeId == '' &&
									o.osInstalled == 'false' &&
									['running', 'live'].includes(o.state) &&
									o.nodeId == node.id &&
									['SSD', 'HDD'].includes(o.deviceType) &&
									o.isTreated != 'true' &&
									!['fc', 'iscsi'].includes(o.transportType)
								)
							})}
							pagination={false}
							rowKey="id"
						/>
					</Col>
				))}
			</Row>
			<div className={classes.footer}>
				<Button onClick={() => prev()}>Prev</Button>
				<Button style={{ marginLeft: 8 }} onClick={() => nextStep()} type="primary" small className="next">
					Next
				</Button>
			</div>
		</div>
	)
}
