import React, { useEffect, useState } from 'react'
import { useDataContext, useGlobalContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Select, Button, Table, message, Badge, Typography, Popconfirm, Empty, Input } from 'antd'
import { RollbackOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { PlusOneRounded } from '@material-ui/icons'
const { Option } = Select
const { Link } = Typography

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()
	const [data, setdata] = useState({})
	const [backupInfo, setBackupInfo] = useState({})
	const [restorePoints, setRestorePoints] = useState([])

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleClose = () => {
		globalContext.updateState('manageBackupPopup', null)
		setdata({})
		setBackupInfo({})
		setRestorePoints([])
	}

	async function getVeritasRestorepoints() {
		if (data) {
			let findItem = dataContext?.state?.getExternalBackupProfiles?.find((item) => item?.id == data?.backupProfileId)
			if (findItem) {
				setBackupInfo(findItem)
				let findNode = dataContext?.state?.getNodes?.find((item) => item?.id == data?.nodeId)

				let res = await dataService.getVeritasBackupImages(data.id, findItem.type, findNode.name)
				if (res.success) {
					setRestorePoints(res.data)
				} else {
					setRestorePoints([])
				}
			} else {
				setBackupInfo({})
			}
		}
	}

	useEffect(() => {
		getVeritasRestorepoints()
	}, [dataContext?.state?.getBackupProfiles, dataContext?.state?.getExternalBackupProfiles, data])

	const formatDate = (timestamp) => {
		// Assuming timestamp is in the format ddmmyyhhmmss
		const year = timestamp.slice(0, 2)
		const month = timestamp.slice(2, 4)
		const day = timestamp.slice(4, 6)
		const hour = timestamp.slice(6, 8)
		const minute = timestamp.slice(8, 10)
		const second = timestamp.slice(10, 12)

		const formattedDate = `${day}/${month}/${year} - ${hour}:${minute}:${second}`
		return formattedDate
	}

	const revertRestoreP = async (id) => {
		message.loading('Action in progress..')
		let res = await dataService.revertRestorePoint(id)
		if (res.success) {
			let getVmData = await dataService.getVmWithId(data.id)
			if (getVmData.success) {
				setdata(getVmData.data)
				globalContext.Snackbar(res.message, 'success')
			}
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const deleteRestoreP = async (id) => {
		message.loading('Action in progress..')
		let res = await dataService.deleteRestorePoint(id)
		if (res.success) {
			let getVmData = await dataService.getVmWithId(data.id)
			if (getVmData.success) {
				setdata({ ...getVmData.data })
				globalContext.Snackbar(res.message, 'success')
			}
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateVmBackup = async () => {
		let manageBackupPopup = globalContext?.state?.manageBackupPopup
		delete manageBackupPopup.state
		delete manageBackupPopup.status
		manageBackupPopup.backupProfileId = data?.backupProfileId
		let res = await dataService.updateVM(manageBackupPopup)
		if (res.success) {
			handleClose()

			globalContext.Snackbar(`Update [${res.data.name}] vm operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const columns = [
		{
			title: 'Id',
			dataIndex: 'resticId',
			key: 'resticId',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Time',
			dataIndex: 'timestamp',
			key: 'timestamp',
			defaultSortOrder: 'descend',
			sorter: (a, b) => parseInt(a?.timestamp) - parseInt(b?.timestamp),
			render: (text) => {
				return formatDate(text)
			},
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{ text: 'Ready', value: 'READY' },
				{ text: 'Failed', value: 'FAILED' },
			],
			onFilter: (value, record) => record.status.includes(value),
			render: (text, row) => (
				<span>
					<Badge color={row['status'] == 'READY' ? 'green' : 'red'} text={row['status']} />
				</span>
			),
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			render: (text, row) => (
				<div>
					<Popconfirm
						title="Are you sure you want to revert to the restore point?"
						onConfirm={() => revertRestoreP(row.id)}
						okText="Yes"
						cancelText="No"
						placement={'topRight'}>
						<RollbackOutlined />
					</Popconfirm>
					<Popconfirm
						title="Are you sure to delete this restore point?"
						onConfirm={() => deleteRestoreP(row.id)}
						okText="Yes"
						cancelText="No"
						placement={'topRight'}>
						<DeleteOutlined />
					</Popconfirm>
				</div>
			),
		},
	]

	async function revertVeritasRestoreP(veritasBackupPoint) {
		let findNode = dataContext?.state?.getNodes?.find((item) => item?.id == data?.nodeId)

		let filesSelection = veritasBackupPoint?.backup?.files?.map((file) => ({
			path:
				veritasBackupPoint?.backup?.folder?.attributes?.path +
				veritasBackupPoint?.backup?.folder?.attributes?.name +
				file,
			backupTime: veritasBackupPoint?.backup?.folder?.attributes?.backupTime,
		}))

		let sendData = {
			recoveryRequest: {
				recoveryPoint: {
					client: veritasBackupPoint?.backup?.folder?.attributes?.clientName,
					policyType: 'STANDARD',
					startDate: '2023-04-26T05:00:00.000Z',
					endDate: '2090-04-26T05:01:01.000Z',
				},
				recoveryOptions: {
					destinationClient: findNode?.name,
					overwriteExistingFiles: true,
					restrictMountPoints: false,
					renameHardLinks: true,
					renameSoftLinks: true,
					accessControlAttributes: false,
					jobPriorityOverride: 90000,
				},
			},
			selection: filesSelection,
			profileID: veritasBackupPoint?.backup?.folder?.attributes?.policyName,
			storagePoolID: '',
			overwriteExistingVm: true,
			vmID: data.id,
		}

		let res = await dataService.startVeritasBackupRecovery(sendData)
		if (res.success) {
			globalContext.Snackbar(res.message, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}

		handleClose()
	}

	async function createVmVeritasRestoreP(veritasBackupPoint) {
		veritasBackupPoint.clusterId = dataContext?.state.list[0].id
		veritasBackupPoint.createType = 'fromRestorePoint'

		globalContext.updateState('VMCreateAndUpdatePopup', veritasBackupPoint)
	}

	const columnsVeritas = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => record.backup.folder.attributes.name, // Accessing attribute directly in render function
		},
		{
			title: 'Policy Name',
			dataIndex: 'policyName',
			key: 'policyName',
			render: (text, record) => record?.backup?.folder?.attributes?.policyName,
		},
		{
			title: 'Backup Type',
			dataIndex: 'attributes.scheduleType',
			key: 'scheduleType',
			render: (_, record) => record.backup.folder.attributes.scheduleType,
		},
		{
			title: 'Backup Time',
			dataIndex: 'backupTime',
			key: 'backupTime',
			render: (_, record) => {
				const backupTime = new Date(record.backup.folder.attributes.backupTime).toLocaleString('en-US', {
					timeZone: 'UTC',
				}) // Adjust timezone as necessary
				return backupTime
			},
		},
		{
			title: 'Last Modified Time',
			dataIndex: 'attributes.lastModifiedTime',
			key: 'lastModifiedTime',
			render: (_, record) => {
				const backupTime = new Date(record.backup.folder.attributes.backupTime).toLocaleString('en-US', {
					timeZone: 'UTC',
				}) // Adjust timezone as necessary
				return backupTime
			},
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			render: (_, row) => (
				<div>
					<Popconfirm
						title={
							<div>
								<p>Are you sure you want to revert to the restore point?</p>
							</div>
						}
						onConfirm={() => revertVeritasRestoreP(row)}
						okText="Yes"
						cancelText="No"
						placement={'topRight'}>
						<RollbackOutlined />
					</Popconfirm>
					<span style={{ paddingLeft: '5px' }} onClick={() => createVmVeritasRestoreP(row)}>
						<PlusOutlined />
					</span>
				</div>
			),
		},
	]

	useEffect(() => {
		setdata(globalContext.state.manageBackupPopup)
	}, [globalContext.state.manageBackupPopup])

	return (
		<div>
			<Drawer
				title={'Update VM Backup Profile'}
				placement="right"
				onClose={handleClose}
				visible={globalContext.state.manageBackupPopup || false}
				width={'50%'}
				footer={
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<Button onClick={handleClose} small id="manageCancel">
							Cancel
						</Button>
						<Button
							onClick={() => updateVmBackup()}
							style={{ marginLeft: 8 }}
							type="primary"
							small
							id="manageUpdate">
							Update
						</Button>
					</div>
				}>
				<div className="form-group-ant">
					<label>
						<span className="requiredHint">*</span>Backup Profile
					</label>
					<Select
						value={data?.backupProfileId}
						onChange={handleChange('backupProfileId')}
						placeholder="Backup Profile"
						className="fullW"
						id="manageSelectBackup">
						<Option value="">None</Option>
						{dataContext?.state?.getExternalBackupProfiles?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
						{dataContext?.state?.getBackupProfiles?.map((item) => (
							<Option value={item.id}>{item.name}</Option>
						))}
					</Select>
				</div>
				<p
					style={{
						marginTop: 24,
						fontWeight: 600,
					}}>
					Restore Points
				</p>
				{backupInfo?.type != 'veritas' ? (
					<Table columns={columns} dataSource={data?.restorePoints} pagination={false} />
				) : (
					<Table columns={columnsVeritas} dataSource={restorePoints} pagination={false} />
				)}
			</Drawer>
		</div>
	)
}
