import React, { useState, componentDidMount, useEffect } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select, Popconfirm } from 'antd'
const { Option } = Select

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ name: '' })
	const [visible, setVisible] = useState(false)

	//handleChange
	const handleChange = (key) => (value) => {
		let valueTemp = value?.target ? value.target.value : value

		if (key === 'southBoundNetworkId') {
			data.publicAddressPoolIds = []
		}
		setdata({ ...data, [key]: valueTemp })
	}

	const onClose = () => {
		setVisible(false)
		globalContext.updateState('tenantPopup', null)
		setdata({ name: '' })
	}

	const createTenant = async () => {
		let sendData = { ...data, clusterId: dataContext?.state?.list?.[0]?.id }
		let res = await dataService.createTenant(sendData)
		if (res.success) {
			let tenantRes = await dataService.getTenants()
			if (tenantRes.success) {
				dataContext.updateState('tenants', tenantRes.data)
			}
			onClose()
			globalContext.Snackbar(`Create [${res.data.name}] tenant operation started successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	const updateTenant = async () => {
		let sendData = { ...data }
		let res = await dataService.updateTenant(sendData)
		if (res.success) {
			let tenantRes = await dataService.getTenants()
			if (tenantRes.success) {
				dataContext.updateState('tenants', tenantRes.data)
			}
			onClose()
			globalContext.Snackbar(`Update [${res.data.name}] tenant successfully`, 'success')
		} else {
			globalContext.Snackbar(res.errorMessage, 'error')
		}
	}

	useEffect(() => {
		if (globalContext.state.tenantPopup?.createOrUpdate == 'update') {
			setdata(globalContext.state.tenantPopup)
		}
	}, [globalContext.state.tenantPopup])

	return (
		<Drawer
			title={globalContext?.state?.tenantPopup?.createOrUpdate == 'update' ? 'Edit Tenant' : 'New Tenant'}
			placement="right"
			onClose={onClose}
			visible={globalContext.state.tenantPopup || false}
			destroyOnClose={true}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					{data?.id ? (
						<Button onClick={updateTenant} style={{ marginLeft: 8 }} type="primary" small>
							Update
						</Button>
					) : (
						<Button id="tenantSubmit" onClick={createTenant} type="primary" style={{ marginLeft: 8 }} small>
							Create
						</Button>
					)}
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Tenant Name
				</label>
				<Input placeholder="Enter here" value={data?.name} onChange={handleChange('name')} id="tenantName" />
				<span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>Specify a unique name</span>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>South Bound Network
				</label>
				<Select
					value={data?.southBoundNetworkId}
					onChange={handleChange('southBoundNetworkId')}
					className="fullW"
					id="tenantSouthBoundNetwork">
					{dataContext?.state?.southBoundNetworks?.map((item) => (
						<Option value={item.id}>{item.name}</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>South Bound Address Pools
				</label>
				<Select
					mode="multiple"
					value={data?.publicAddressPoolIds}
					onChange={handleChange('publicAddressPoolIds')}
					className="fullW"
					id="tenantPublicAddressPool">
					{dataContext?.state?.southBoundNetworks?.map((item) => {
						if (item?.id?.includes(data?.southBoundNetworkId)) {
							return item?.addressPools?.map((ap) => <Option value={ap.id}>{ap.name}</Option>)
						}
						return null
					})}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Overlay Network
				</label>
				<Select
					value={data?.overlayNetworkId}
					onChange={handleChange('overlayNetworkId')}
					className="fullW"
					id="tenantOverlayNetwork">
					{dataContext?.state?.getNetworkOverlays?.map((item) => (
						<Option value={item.id}>{item.segmentName ? item.segmentName : item.id}</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Quota Profile
				</label>
				<Select
					value={data?.quotaProfileId}
					onChange={handleChange('quotaProfileId')}
					className="fullW"
					id="tenantQuotaProfile">
					{dataContext?.state?.quotaProfiles?.map((item) => (
						<Option value={item.id}>{item.name}</Option>
					))}
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Storage Pool
				</label>
				<Select
					value={data?.storagePoolId}
					onChange={handleChange('storagePoolId')}
					className="fullW"
					id="tenantStoragePool">
					{dataContext?.state?.getVmStoragePools?.map((item) => (
						<Option value={item.id}>{item.name}</Option>
					))}
				</Select>
			</div>
		</Drawer>
	)
}
