import React, { useEffect, useState } from 'react'
import { PlayCircleOutlined, StopOutlined, BlockOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Menu, Item, useContextMenu, contextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { useGlobalContext, useDataContext } from 'components'
import dataService from 'service/dataService'
import globalContext from '../Context/GlobalContext'

export let startContainer = (globalContext) => async ({ event, props }) => {
	let res = await dataService.startContainer(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let stopContainer = (globalContext) => async ({ event, props }) => {
	let res = await dataService.stopContainer(props.id)
	if (res.success) {
		globalContext.Snackbar(res.message, 'success')
	} else {
		globalContext.Snackbar(res.errorMessage, 'error')
	}
}
export let moveContainer = (globalContext) => async ({ event, props }) => {
	let res = await dataService.getContainer(props.id)
	if (res.success) {
		globalContext.updateState('moveContainerPopup', res.data)
	} else {
		globalContext.Snackbar('Container data fetch error', 'error')
	}
}

export let updateContainer = (globalContext) => async ({ event, props }) => {
	let res = await dataService.getContainer(props.id)
	if (res.success) {
		res.data.createOrUpdate = 'update'
		globalContext.updateState('containerCreateAndUpdatePopup', res.data)
	} else {
		globalContext.Snackbar('Container data fetch error', 'error')
	}
}

export default function VMContextMenu() {
	const globalContext = useGlobalContext()
	const [data, setdata] = useState(globalContext.state.contextMenuData)
	const dataContext = useDataContext()

	const DeletePopup = async ({ event, props }) => {
		globalContext.updateState('deletePopup', props)
	}

	useEffect(() => {
		setdata(globalContext.state.contextMenuData)
	}, [globalContext.state.contextMenuData])

	return (
		<>
			<Menu id={'container'} style={{ background: '#fafafa', fontSize: 12 }}>
				<div
					style={{
						textAlign: 'center',
						padding: '3px 15px',
					}}>
					Container Actions | ({data?.state})
				</div>
				<hr />
				<Item onClick={startContainer(globalContext)} disabled={data?.state == 'running'} id="containerStart">
					<PlayCircleOutlined
						style={{
							color: '#1cb084',
							paddingRight: 8,
						}}
					/>
					Start
				</Item>

				<Item
					onClick={stopContainer(globalContext)}
					disabled={['stopped', 'shutdown'].includes(data?.state)}
					id="containerStop">
					<StopOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Stop
				</Item>
				<hr />
				<Item onClick={moveContainer(globalContext)} disabled={false} id="containerMove">
					<BlockOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Move Container
				</Item>
				<hr />
				<Item onClick={updateContainer(globalContext)} id="containerEdit">
					<EditOutlined
						style={{
							color: '#004085',
							paddingRight: 8,
						}}
					/>
					Edit Container
				</Item>
				<Item onClick={DeletePopup} id="containerDelete">
					<DeleteOutlined
						style={{
							color: '#c25858',
							paddingRight: 8,
						}}
					/>
					Delete Container
				</Item>
			</Menu>
		</>
	)
}
