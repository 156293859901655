import React, { useEffect, useState } from 'react'
import { command, useGlobalContext, useDataContext } from '../components'
import dataService from 'service/dataService'
import { Drawer, Input, Button, Select } from 'antd'
import dataContext from '../Context/DataContext'

export default function DraggableDialog() {
	const globalContext = useGlobalContext()
	const dataContext = useDataContext()

	const [data, setdata] = useState({ hostname: '', username: '', password: '', url: '', apiVersion: '10.0' })
	const [scheme, setScheme] = useState('https://')
	const [port, setPort] = useState('')
	const [type, setType] = useState('')

	const handleChange = (key) => (e) => {
		let valueTemp = e?.target ? e.target.value : e
		setdata({ ...data, [key]: valueTemp })
	}

	const handleSchemeChange = (value) => {
		setScheme(value)
	}

	const handlePortChange = (e) => {
		setPort(e.target.value)
	}

	const onClose = () => {
		globalContext.updateState('externalBackupUser', null)
		setdata({ username: '', password: '', url: '', apiVersion: '10.0' })
		setScheme('https://')
		setPort('')
	}
	useEffect(() => {
		if (globalContext?.state?.externalBackupUser?.createOrUpdate === 'update') {
			const user = globalContext?.state?.externalBackupUser
			setScheme(user?.url?.includes('https://') ? 'https://' : 'http://')
			const urlWithoutScheme = user?.url?.replace(/^https?:\/\//, '')
			const urlParts = urlWithoutScheme?.split(':')
			setdata({ ...user, url: urlParts?.[0] })
			setPort(urlParts?.[1] || '')
			setType(user?.type)
		}
	}, [globalContext.state.externalBackupUser])

	async function createBackupUser(type) {
		let sendData = { ...data }

		switch (type) {
			case 'veritas':
				sendData.hostname = data.url
				sendData.url = scheme + data.url + '/netbackup' + (port !== '' ? `:${port}` : '')
				sendData.username.replace(/\\/g, '\\\\')

				let res = await dataService.createExternalBackupUserCredentials(sendData, type)
				if (res.success) {
					globalContext.Snackbar(`Veritas creation successfully created`, 'success')
				} else {
					globalContext.Snackbar(res.errorMessage, 'error')
				}
		}
		dataContext.getExternalBackupUsers()
		onClose()
	}

	async function updateBackupUser(type) {
		let sendData = { ...data }

		switch (type) {
			case 'veritas':
				sendData.hostname = data.url
				sendData.url = scheme + data.url + '/netbackup' + (port !== '' ? `:${port}` : '')
				let res = await dataService.updateExternalBackupUserCredentials(sendData, type)
				if (res.success) {
					globalContext.Snackbar(res.message, 'success')
				} else {
					globalContext.Snackbar(res.errorMessage, 'error')
				}
		}
		dataContext.getExternalBackupUsers()
		onClose()
	}

	return (
		<Drawer
			title={
				globalContext?.state?.externalBackupUser?.createOrUpdate == 'update'
					? 'Update External Backup User'
					: 'Create External Backup User'
			}
			placement="right"
			onClose={onClose}
			visible={globalContext.state.externalBackupUser || false}
			destroyOnClose={true}
			width={'35%'}
			footer={
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={onClose} small>
						Cancel
					</Button>
					<Button
						id="networkSubmit"
						type="primary"
						style={{ marginLeft: 8 }}
						onClick={
							globalContext?.state?.externalBackupUser?.createOrUpdate == 'update'
								? () => updateBackupUser('veritas')
								: () => createBackupUser('veritas')
						}
						small>
						{globalContext?.state?.externalBackupUser?.createOrUpdate == 'update' ? 'Update' : 'Create'}
					</Button>
				</div>
			}>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Username
				</label>
				<Input placeholder="Enter here" value={data?.username} onChange={handleChange('username')} id="username" />
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Password
				</label>
				<Input.Password
					placeholder="Enter here"
					value={data?.password}
					onChange={handleChange('password')}
					id="password"
				/>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Api Version
				</label>
				<Select className={'fullW'} value={data?.apiVersion} onChange={handleChange('apiVersion')}>
					<Option value="7.0">7</Option>
					<Option value="8.0">8</Option>
					<Option value="9.0">9</Option>
					<Option value="10.0">10</Option>
				</Select>
			</div>
			<div className="form-group-ant">
				<label>
					<span className="requiredHint">*</span>Url
				</label>
				<Input.Group compact className={'fullW'}>
					<Select value={scheme} onChange={handleSchemeChange} style={{ width: '30%' }}>
						<Option value="http://">http://</Option>
						<Option value="https://">https://</Option>
					</Select>
					<Input
						style={{ width: '50%' }}
						placeholder="Enter url here"
						value={data?.url}
						onChange={handleChange('url')}
						id="url"
					/>
					<Input style={{ width: '20%' }} placeholder="Port" value={port} onChange={handlePortChange} id="port" />
				</Input.Group>
			</div>
		</Drawer>
	)
}
