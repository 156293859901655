import React, { useState, useEffect } from 'react'
import { useDataContext, useGlobalContext } from '../../components'
import { createUseStyles } from 'react-jss'
import { Input, Button } from 'antd'

const useStyles = createUseStyles((theme) => ({
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		textAlign: 'right',
		right: 24,
	},
	aps: {
		position: 'relative',

		'& .form-group-ant': {
			alignItems: 'center',
			marginBottom: 12,
		},
		'& label': {
			textAlign: 'right',
			paddingRight: 12,
		},
	},

	del: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}))
export default function Name({ modalDataChange = () => {}, next = () => {}, prev = () => {}, modalData = {} }) {
	const dataContext = useDataContext()
	const globalContext = useGlobalContext()

	const classes = useStyles()
	const [data, setdata] = useState(
		modalData.addressPools
			? modalData
			: {
					addressPools: [
						{
							ipNetCIDR: '',
							defaultGateway: '',
							nameServers: [''],
						},
					],
					name: '',
			  }
	)

	//handleChange
	const handleChange = (e, key, index) => {
		let valueTemp = e?.target ? e.target.value : e
		if (key == 'name') {
			setdata({ ...data, name: valueTemp })
		} else {
			let newData = [...data.addressPools]
			newData[index][key] = valueTemp
			setdata({ ...data, addressPools: newData })
		}
	}

	const nextStep = async () => {
		modalDataChange({ addressPools: data.addressPools, name: data.name })
		next()
	}

	const add = () => {
		let newData = [...data]
		newData.push({
			name: '',
			ipNetCIDR: '',
			defaultGateway: '',
			nameServers: [''],
		})
		setdata(newData)
		modalDataChange({ addressPools: newData })
	}

	const del = (index) => {
		let newData = [...data]
		newData = newData.filter((item, indexItem) => indexItem != index)
		setdata(newData)
		modalDataChange({ addressPools: newData })
	}

	useEffect(() => {
		setdata({
			...(modalData.addressPools
				? modalData
				: {
						addressPools: [
							{
								ipNetCIDR: '',
								defaultGateway: '',
								nameServers: [''],
							},
						],
						name: '',
				  }),
		})
	}, [modalData])

	return (
		<div className={classes.root}>
			{data?.addressPools?.map((item, index) => (
				<div className={classes.aps}>
					<div className="form-group-ant">
						<label>Name</label>
						<Input
							placeholder="Enter here"
							value={data?.name}
							onChange={(e) => handleChange(e, 'name', index)}
							id="publicNetworkName"
						/>
					</div>
					<div className="form-group-ant">
						<label>Address Block (CIDR)</label>
						<Input
							placeholder="e.g. 192.0.2.0/24"
							value={item?.ipNetCIDR}
							onChange={(e) => handleChange(e, 'ipNetCIDR', index)}
						/>
					</div>
					<div className="form-group-ant">
						<label>Default Gateway</label>
						<Input
							placeholder="e.g. 192.0.2.1"
							value={item?.defaultGateway}
							onChange={(e) => handleChange(e, 'defaultGateway', index)}
						/>
					</div>
					{/* <div className={classes.del}>
						<span
							onClick={() => del(index)}
							style={{ color: '#0f427a', cursor: 'pointer' }}
							className="addressPoolDelete">
							Delete
						</span>
					</div> */}
				</div>
			))}
			{/* <Button
				block
				onClick={() => add()}
				style={{ border: '1px dashed #D9D9D9', marginTop: 12, marginBottom: 12 }}
				className="addressPoolAdd">
				+ Add Row
			</Button> */}

			<div className={classes.footer}>
				<Button
					onClick={() => nextStep()}
					style={{ marginLeft: 8 }}
					disabled={data?.objects?.length == 0}
					type="primary"
					small
					className="save-next">
					Save & Next
				</Button>
			</div>
		</div>
	)
}
