import React from 'react'
import { Spin } from 'antd'

export default function index() {
	return (
		<div
			style={{
				position: 'fixed',
				width: '100vw',
				height: '100vh',
				background: '#ffffff',
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				zIndex: 999999,
			}}>
			<Spin />
		</div>
	)
}
